import './bookingContact.scss';

import {
  IonCol,
  IonGrid,
  IonRow,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import injectables from 'application/injectables';
import useCityName from 'application/pages/BookingAddress/useCityName';
import AddressForm, {
  addressInitialValues,
  addressRequiredFields,
  generateAddressValidationSchema,
} from 'application/pages/BookingContact/AddressForm';
import SocialLoginButtons from 'application/pages/Login/SocialLoginButtons';
import type INotificationService from 'application/services/INotificationService';
import { useAuth } from 'application/state/AuthProvider';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { Address, TreatmentClientData } from 'application/types';
import type { FormikHelpers, FormikTouched } from 'formik';
import { FormikProvider, getIn, useFormik } from 'formik';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import BookingSummaryFooter from 'ui/elements/BookingSummaryFooter';
import TreatmentTypeGalleryHeader from 'ui/elements/TreatmentTypeGalleryHeader';
import SubPageLayout from 'ui/layout/SubPageLayout';
import HeaderProfilePicture from 'ui/layout/SubPageLayout/HeaderProfilePicture';
import { useContextTranslation } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';
import isPhoneNumber from 'ui/utils/isPhoneNumber';
import * as Yup from 'yup';

import ContactForm from './ContactForm';
import Button from '../../../ui/elements/Button/Button';

// TODO: refactor required
/* eslint-disable  @typescript-eslint/no-floating-promises */
/* eslint-disable  @typescript-eslint/no-explicit-any */

const initialValues = {
  email: '',
  name: '',
  surname: '',
  phoneNumber: '',
  companyName: '',
  ...addressInitialValues,
};

const requiredFields: (keyof FormikTouched<TreatmentClientData & Address>)[] = [
  'email',
  'name',
  'surname',
  'phoneNumber',
  ...addressRequiredFields,
];

const BookingContact: React.FC = () => {
  const {
    experts,
    date,
    length,
    location,
    type,
    clientData,
    locationId,
    setClientData,
    setLocation,
  } = useTreatmentBuilder();
  const { isAuthenticated, setRedirectUri } = useAuth();
  const { i18n } = useTranslation();
  const [notification] = useInject<INotificationService>(
    injectables.services.NotificationService,
  );
  const t = useContextTranslation('page.booking_contact');
  const st = useContextTranslation('page.booking_address');

  const history = useHistory();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('email_required'))
          .email(t('email_format')),
        name: Yup.string()
          .required(t('name_required'))
          .min(3, ({ min }) => t('name_min_length', { min }))
          .max(128, ({ max }) => t('name_max_length', { max })),
        surname: Yup.string()
          .required(t('surname_required'))
          .min(2, ({ min }) => t('surname_min_length', { min }))
          .max(128, ({ max }) => t('surname_max_length', { max })),
        phoneNumber: Yup.string()
          .required(t('phone_number_required'))
          .max(32, t('phone_number_invalid_format'))
          .test({
            message: t('phone_number_invalid_format'),
            test: (value) => !!value && isPhoneNumber(value),
          }),
        companyName: Yup.string()
          .optional()
          .max(256, ({ max }) =>
            t('company_name_max_length', {
              max,
            }),
          ),
        ...generateAddressValidationSchema(st),
      }),
    [t],
  );

  const onSubmit = React.useCallback<
    (
      values: TreatmentClientData & Address,
      helpers: FormikHelpers<TreatmentClientData & Address>,
    ) => void
  >(
    (values, helpers) => {
      setClientData({
        email: values.email,
        name: values.name,
        phoneNumber: values.phoneNumber,
        surname: values.surname,
        companyName: values.companyName || undefined,
        notificationToken: notification.getToken() || undefined,
        locale: i18n.language,
      });
      setLocation({
        postalCode: values.postalCode,
        locality: values.locality,
        street: values.street,
        streetNumber: values.streetNumber,
        type: location?.type || 'atHome',
      });
      helpers.setSubmitting(false);
      history.push('/booking/payment');
    },
    [setClientData, history, setLocation, location?.type],
  );

  const formik = useFormik<TreatmentClientData & Address>({
    validationSchema,
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
  });

  const hasCompleteData = Boolean(date && length && location);

  useIonViewWillEnter(() => {
    formik.setValues({
      email: '',
      name: '',
      surname: '',
      phoneNumber: '',
      companyName: '',
      postalCode: '',
      locality: '',
      street: '',
      streetNumber: '',
      ...(clientData || {}),
      ...(location || {}),
    });
    if (clientData) {
      Object.keys(clientData).forEach((field) => {
        const formikField = field as keyof typeof clientData;
        if (clientData[formikField]) {
          formik.setFieldTouched(field);
        }
      });
    }
    if (location) {
      Object.keys(location).forEach((field) => {
        const formikField = field as keyof typeof location;
        if (location[formikField]) {
          formik.setFieldTouched(field);
        }
      });
    }
    if (!hasCompleteData) {
      history.replace('/treatments');
    }
    if (isAuthenticated) {
      history.replace('/booking/address');
    }
  }, [
    hasCompleteData,
    history,
    isAuthenticated,
    clientData,
    location,
    isAuthenticated,
  ]);

  useIonViewWillLeave(() => {
    setClientData({
      name: clientData?.name || formik.values.name,
      locale: clientData?.locale || formik.values.locale,
      email: clientData?.email || formik.values.email,
      surname: clientData?.surname || formik.values.surname,
      phoneNumber: clientData?.phoneNumber || formik.values.phoneNumber,
      // Field hidden
      companyName: '',
    });
  }, [formik.values, clientData]);

  const nativeFormSubmit = () => {
    const element = document.querySelector<HTMLFormElement>(
      '.booking-contact form',
    );
    element?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  };

  const enableNext = React.useMemo(() => {
    const untouchedFields = requiredFields.filter(
      (el): boolean => !getIn(formik.touched, el),
    );
    return !untouchedFields.length;
  }, [formik.touched]);

  useCityName({
    onLoad: (cityName) => {
      if (locationId) {
        return;
      }
      if (!formik.values.locality) {
        formik.setValues({
          ...formik.values,
          locality: cityName,
        });
        formik.setFieldTouched('locality');
      }
    },
    postalCode: formik.values.postalCode,
  });

  return (
    <SubPageLayout
      className="booking-contact"
      beforeContent={type && <TreatmentTypeGalleryHeader type={type} small />}
      footerContent={() =>
        length && (
          <BookingSummaryFooter
            length={length}
            onNext={() => nativeFormSubmit()}
            nextEnabled={enableNext}
          />
        )
      }
    >
      {experts?.length && <HeaderProfilePicture experts={experts} />}
      <IonGrid>
        <IonRow className="booking-header">
          <IonCol size="12">
            <h4>{t('title')}</h4>
          </IonCol>
        </IonRow>
        <FormikProvider value={formik}>
          <AutocompleteForm>
            <ContactForm />
            {!locationId && <AddressForm />}
          </AutocompleteForm>
          {!(
            formik.values.name ||
            formik.values.surname ||
            formik.values.email
          ) && (
            <>
              <IonRow>
                <IonCol size="12" className="booking-login-header">
                  <h4 className="ion-no-margin">{t('login_options_title')}</h4>
                </IonCol>
              </IonRow>
              <div className="login-buttons">
                <IonRow className="ion-justify-content-center">
                  <IonCol className="login-button-wrapper">
                    <SocialLoginButtons />
                    <Button
                      className="with-sh-btn"
                      onClick={() => {
                        history.replace('/login', {
                          direction: 'forward',
                        });
                        if (locationId) {
                          setRedirectUri('/booking/payment');
                          return;
                        }
                        setRedirectUri('/booking/address');
                      }}
                    >
                      {t('sign_in')}
                    </Button>
                  </IonCol>
                </IonRow>
              </div>
            </>
          )}
        </FormikProvider>
      </IonGrid>
    </SubPageLayout>
  );
};

export default BookingContact;
