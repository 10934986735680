import { gql } from '@apollo/client';
import type {
  ExternalRegistrationParams,
  ExternalRegistrationResult,
} from 'application/pages/Register/IRegistrationAdapter';
import type IRegistrationAdapter from 'application/pages/Register/IRegistrationAdapter';
import {
  AccountExistError,
  InvalidCodeError,
} from 'application/pages/Register/IRegistrationAdapter';
import { formatISO } from 'date-fns';
import client from 'infrastructure/apollo';
import {
  getGraphQLErrorMessages,
  getMobilePlatform,
} from 'infrastructure/utils';

const CONFIRM_ACCOUNT_MUTATION = gql`
  mutation ConfirmAccount($email: String!, $verificationCode: String!) {
    confirmAccount(email: $email, verificationCode: $verificationCode)
  }
`;

const CREATE_ACCOUNT_MUTATION = gql`
  mutation CreateAccount($registrationData: ClientRegistrationData) {
    registerClient(clientData: $registrationData)
  }
`;

const CREATE_EXTERNALLY_AUTHENTICATED_ACCOUNT_MUTATION = gql`
  mutation CreateExternallyAuthenticatedAccount(
    $registrationData: ClientExternallyAuthenticatedRegistrationData!
  ) {
    loginData: registerExternallyAuthenticatedClient(
      clientData: $registrationData
    ) {
      accessToken
    }
  }
`;

const INVALID_CODE_ERROR = 'invalid_input';
const ACCOUNT_EXISTS_ERROR = 'email_exists';
const EXTERNAL_ACCOUNT_EXISTS_ERROR = 'account_exists';

const GraphQLRegistrationAdapter: IRegistrationAdapter = {
  async createAccount(registrationParams): Promise<void> {
    try {
      await client.mutate({
        mutation: CREATE_ACCOUNT_MUTATION,
        variables: {
          registrationData: {
            ...registrationParams,
            birthday: formatISO(registrationParams.birthday),
            companyName:
              registrationParams.companyName &&
              registrationParams.companyName.length > 1
                ? registrationParams.companyName
                : undefined,
            platform: getMobilePlatform(),
          },
        },
      });
    } catch (error) {
      const messages = getGraphQLErrorMessages(error);
      if (messages?.includes(ACCOUNT_EXISTS_ERROR)) {
        throw new AccountExistError();
      }
      throw error;
    }
  },
  async confirmAccount(email: string, verificationCode: string): Promise<void> {
    try {
      await client.mutate({
        mutation: CONFIRM_ACCOUNT_MUTATION,
        variables: {
          email,
          verificationCode,
        },
      });
    } catch (error) {
      const messages = getGraphQLErrorMessages(error);
      if (messages?.includes(INVALID_CODE_ERROR)) {
        throw new InvalidCodeError();
      }
      throw error;
    }
  },

  async createExternallyAuthenticatedAccount(
    data: ExternalRegistrationParams,
  ): Promise<ExternalRegistrationResult> {
    try {
      const result = await client.mutate<{
        loginData: ExternalRegistrationResult;
      }>({
        mutation: CREATE_EXTERNALLY_AUTHENTICATED_ACCOUNT_MUTATION,
        variables: {
          registrationData: {
            ...data,
            birthday: formatISO(data.birthday ?? new Date()),
            companyName:
              data.companyName && data.companyName.length > 1
                ? data.companyName
                : undefined,
            platform: getMobilePlatform(),
          },
        },
      });

      if (!result.data) {
        throw new Error('No token in response');
      }

      return {
        accessToken: result.data.loginData.accessToken,
      };
    } catch (error) {
      const messages = getGraphQLErrorMessages(error);
      if (messages?.includes(EXTERNAL_ACCOUNT_EXISTS_ERROR)) {
        throw new AccountExistError();
      }
      throw error;
    }
  },
};

export default GraphQLRegistrationAdapter;
