import {
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonTextarea,
  useIonAlert,
} from '@ionic/react';
import injectables from 'application/injectables';
import injectablesType from 'application/pages/injectables';
import BookingRequestDateSelector from 'application/pages/Treatments/BookingRequest/BookingRequestDateSelector';
import type {
  HotelBookingRequestData,
  HotelRequestData,
} from 'application/pages/Treatments/BookingRequest/IBookingRequestAdapter';
import type IBookingRequestAdapter from 'application/pages/Treatments/BookingRequest/IBookingRequestAdapter';
import type { ITreatmentAdapter } from 'application/pages/Treatments/ITreatmentAdapter';
import TreatmentTypeSelector from 'application/pages/Treatments/TreatmentTypeSelector';
import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { loggingServiceSymbol } from 'application/services/IErrorLoggingService';
import type {
  Address,
  ObjectID,
  TreatmentDate,
  TreatmentType,
} from 'application/types';
import type { FormikHelpers } from 'formik';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { useInject } from 'inversify-hooks';
import React, { useState } from 'react';
import ExpandableSelect from 'ui/elements/ExpandableSelect';
import { useContextTranslation, useTranslateString } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';
import IonicField from 'ui/utils/IonicField';
import * as Yup from 'yup';

import { generateAddressValidationSchema } from '../BookingContact/AddressForm';

// TODO: refactor required
/* eslint-disable  @typescript-eslint/no-floating-promises */

const useHotelBookingRequestSchema = () => {
  const t = useContextTranslation('validation');
  const td = useContextTranslation('layout.date_input');
  return {
    hotelBookingRequestSchema: Yup.object().shape({
      hotelName: Yup.string()
        .min(3, ({ min }) => t('name_min_length', { min }))
        .max(128, ({ max }) => t('name_max_length', { max }))
        .required(),
      receptionEmail: Yup.string()
        .required(t('email_required'))
        .email(t('email_format')),
      date: Yup.date()
        .required()
        .typeError(td('invalid_date'))
        .min(new Date(), td('invalid_date')),
      treatmentType: Yup.string().required(),
      therapistGender: Yup.string().required(),
      roomNumber: Yup.string()
        .max(32, ({ max }) => t('name_max_length', { max }))
        .required(),
      guestFirstname: Yup.string()
        .min(3, ({ min }) => t('name_min_length', { min }))
        .max(128, ({ max }) => t('name_max_length', { max }))
        .required(),
      guestSurname: Yup.string()
        .min(3, ({ min }) => t('name_min_length', { min }))
        .max(128, ({ max }) => t('name_max_length', { max }))
        .required(),
      receptionistName: Yup.string()
        .min(3, ({ min }) => t('name_min_length', { min }))
        .max(128, ({ max }) => t('name_max_length', { max }))
        .required(),
      numberOfExperts: Yup.number()
        .required()
        .typeError(t('numberOfExperts'))
        .min(1)
        .max(2),
      notes: Yup.string().max(1000, t('notes_too_long')).optional(),
      ...generateAddressValidationSchema(t),
    }),
  };
};

const HotelBookingRequestForm = ({
  hotelData,
  onSuccess,
}: {
  hotelData: HotelRequestData & { token: string; id: ObjectID };
  onSuccess: () => void;
}): JSX.Element => {
  const t = useContextTranslation('page.hotel_booking_request');
  const mt = useContextTranslation('misc');
  const translate = useTranslateString();

  const [type, setType] = useState<TreatmentType | null>(null);
  const [date, setDate] = useState<TreatmentDate | null>(null);
  const [numberOfExperts, setNumberOfExperts] = useState<number | null>(null);
  const [expertGender, setExpertGender] = useState<string | null>(null);
  const [showAlert] = useIonAlert();
  const { hotelBookingRequestSchema } = useHotelBookingRequestSchema();

  const [bookingRequestAdapter] = useInject<IBookingRequestAdapter>(
    injectables.pages.BookingRequestAdapter,
  );
  const [loggingAdapter] =
    useInject<IErrorLoggingService>(loggingServiceSymbol);

  const onSubmit = async (
    values: HotelBookingRequestData & Address,
    formikHelpers: FormikHelpers<HotelBookingRequestData & Address>,
  ) => {
    try {
      await bookingRequestAdapter.sendHotelBookingRequest({
        ...values,
        hotelRequestId: hotelData.id,
        token: hotelData.token,
      });
      formikHelpers.setSubmitting(false);
      onSuccess();
    } catch (e) {
      loggingAdapter.traceException(e);
      formikHelpers.setSubmitting(false);
      showAlert(mt('something_went_wrong'));
    }
  };

  const formik = useFormik<HotelBookingRequestData & Address>({
    onSubmit,
    initialValues: {
      hotelName: hotelData.hotelName,
      receptionEmail: hotelData.receptionEmail,
      hotelRequestId: '',
      token: '',
      date: null,
      treatmentType: '',
      guestFirstname: '',
      guestSurname: '',
      roomNumber: '',
      receptionistName: '',
      numberOfExperts: null,
      therapistGender: '',
      notes: '',
      street: hotelData.address?.street || '',
      locality: hotelData.address?.locality || '',
      streetNumber: hotelData.address?.streetNumber || '',
      postalCode: hotelData.address?.postalCode || '',
    },
    validationSchema: hotelBookingRequestSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });
  const [service] = useInject<ITreatmentAdapter>(
    injectablesType.TreatmentAdapter,
  );
  const availableTypes = service.useTreatmentTypes();

  const numberOfExpertsLabels = {
    1: t('number_of_experts.one'),
    2: t('number_of_experts.two'),
  };

  const expertGenderLabels = {
    male: t('expert_gender.male'),
    female: t('expert_gender.female'),
    not_specified: t('expert_gender.not_specified'),
  };

  const multiExpertGenderLabels = {
    '1male1female': t('expert_gender.1male1female'),
    '2male': t('expert_gender.2male'),
    '2female': t('expert_gender.2female'),
    not_specified: t('expert_gender.not_specified'),
  };
  const [expanded, setExpanded] = React.useState<string | null>(null);

  return (
    <FormikProvider value={formik}>
      <AutocompleteForm>
        <IonGrid className="hotel-booking-request">
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" className="">
              <IonText>
                <h5 className="treatment-header">{t('request_header')}</h5>
              </IonText>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <Field
                    component={IonicField}
                    name="hotelName"
                    className="active"
                    type="text"
                    placeholder={t('hotel_name_placeholder')}
                    showErrors
                    disabled
                  />
                </IonCol>
                <IonCol size="12">
                  <BookingRequestDateSelector
                    date={date}
                    onSelect={(selectedDate) => {
                      formik.setFieldValue('date', selectedDate.date);
                      setDate(selectedDate);
                    }}
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="form-error"
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <TreatmentTypeSelector
                    onClick={() =>
                      setExpanded(
                        expanded === 'treatments' ? null : 'treatments',
                      )
                    }
                    onChange={(selectedType) => {
                      formik.setFieldValue(
                        'treatmentType',
                        translate(selectedType.name),
                      );
                      setType(selectedType);
                      setExpanded(null);
                    }}
                    expanded={expanded === 'treatments'}
                    selectedType={type}
                    treatmentTypes={availableTypes}
                  />
                  <ErrorMessage
                    name="treatmentType"
                    component="div"
                    className="form-error"
                  />
                </IonCol>
                <IonCol size="12">
                  <Field
                    component={IonicField}
                    name="guestFirstname"
                    type="text"
                    placeholder={t('guest_name_placeholder')}
                    showErrors
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <Field
                    component={IonicField}
                    name="guestSurname"
                    type="text"
                    placeholder={t('guest_surname_label')}
                    showErrors
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <Field
                    component={IonicField}
                    name="roomNumber"
                    type="text"
                    placeholder={t('room_placeholder')}
                    showErrors
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <Field
                    component={IonicField}
                    name="receptionistName"
                    type="text"
                    placeholder={t('receptionist_name_placeholder')}
                    showErrors
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <Field
                    component={IonicField}
                    name="receptionEmail"
                    type="email"
                    inputMode="email"
                    autocomplete="email"
                    placeholder={t('reception_email_placeholder')}
                    showErrors
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <ExpandableSelect
                    options={numberOfExpertsLabels}
                    className={`button-selector experts-number-selector ${
                      numberOfExperts?.toString() ? '' : ' not-active'
                    }`}
                    placeholder={t('number_of_experts.placeholder')}
                    name="numberOfExperts"
                    expanded={expanded === 'expandedNumberOfExperts'}
                    value={numberOfExperts?.toString()}
                    onClick={() => {
                      setExpanded(
                        expanded === 'expandedNumberOfExperts'
                          ? null
                          : 'expandedNumberOfExperts',
                      );
                    }}
                    onSelectChange={(event) => {
                      setExpanded(null);
                      setNumberOfExperts(Number(event.currentTarget.value));
                      formik.setFieldValue(
                        'numberOfExperts',
                        Number(event.currentTarget.value),
                      );
                    }}
                  />
                  <ErrorMessage
                    name="numberOfExperts"
                    component="div"
                    className="form-error"
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <ExpandableSelect
                    options={{
                      ...(numberOfExperts === 2
                        ? multiExpertGenderLabels
                        : expertGenderLabels),
                    }}
                    className={`button-selector experts-number-selector ${
                      expertGender ? '' : ' not-active'
                    }`}
                    placeholder={t('expert_gender.placeholder')}
                    name="expertGender"
                    expanded={expanded === 'expandedGender'}
                    value={expertGender}
                    onClick={() => {
                      setExpanded(
                        expanded === 'expandedGender' ? null : 'expandedGender',
                      );
                    }}
                    onSelectChange={(event) => {
                      setExpanded(null);
                      setExpertGender(event.currentTarget.value);
                      formik.setFieldValue(
                        'therapistGender',
                        event.currentTarget.value,
                      );
                    }}
                  />
                  <ErrorMessage
                    name="therapistGender"
                    component="div"
                    className="form-error"
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <Field
                    component={IonicField}
                    ionicComponent={IonTextarea}
                    className="form-input"
                    name="notes"
                    showErrors
                    type="text"
                    placeholder={t('notes_placeholder')}
                    rows={5}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" sizeMd="8" sizeLg="8">
                  <IonButton
                    className="submit-button"
                    expand="block"
                    type="submit"
                  >
                    {t('submit_label')}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </AutocompleteForm>
    </FormikProvider>
  );
};

export default HotelBookingRequestForm;
