import './bookingDetails.scss';

import {
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
  useIonAlert,
  useIonViewDidEnter,
} from '@ionic/react';
import injectables from 'application/injectables';
import type IAnonymousBookingsService from 'application/services/IAnonymousBookingsService';
import { useAuth } from 'application/state/AuthProvider';
import type { ObjectID } from 'application/types';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';

import { useTreatmentBuilder } from '../../state/TreatmentContext';
import CartDetailsModal from '../BookingDetailsModal/CartDetailsModal';
import type IBookingDetailsAdapter from './IBookingDetailsAdapter';

export interface MultipleBookingsDetailsParams {
  shoppingCartId: ObjectID;
  token: ObjectID;
}

const BookingDetails = (): JSX.Element => {
  const t = useContextTranslation('page.booking_details');
  const mt = useContextTranslation('misc');

  const history = useHistory();
  const [showAlert] = useIonAlert();
  const treatmentBuilder = useTreatmentBuilder();
  const [adapter] = useInject<IBookingDetailsAdapter>(
    injectables.pages.BookingDetailsAdapter,
  );

  const { isAuthenticated } = useAuth();
  const [anonymousBookingsService] = useInject<IAnonymousBookingsService>(
    injectables.services.AnonymousBookingsService,
  );

  const savedBookings = anonymousBookingsService.useSavedBookings();

  const params = useParams<MultipleBookingsDetailsParams>();
  const details = adapter.useMultipleBookingsDetails(
    params.shoppingCartId,
    params.token,
  );

  const isPaid = details.value?.status === 'paid';

  React.useEffect(() => {
    const existingBooking = savedBookings.value?.find(
      (booking) => booking.id === params.shoppingCartId,
    );
    if (
      !isAuthenticated &&
      details.value?.status === 'paid' &&
      !existingBooking
    ) {
      anonymousBookingsService
        .storeBooking(details.value)
        .catch(() => showAlert(mt('something_went_wrong')));
    }
  }, [details.value, savedBookings.value, params.shoppingCartId]);

  const [, setRefetched] = React.useState<boolean>(false);
  React.useEffect(() => {
    setRefetched(true);
    if (!details.error && !details.loading && !isPaid) {
      const timeout = setTimeout(() => {
        setRefetched(false);
        details.refetch();
      }, 3000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  });

  useIonViewDidEnter(() => {
    treatmentBuilder.clearData();
  }, []);

  return (
    <SubPageLayout className="booking-details">
      {(details.loading || (!isPaid && !details.error)) && (
        <IonGrid>
          <IonRow className="ion-justify-content-center spinner-row">
            <IonCol size="12">
              <IonSpinner color="secondary" name="crescent" />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {details.error && (
        <div className="error">{mt('something_went_wrong')}</div>
      )}
      {!isPaid && !details.error && (
        <div className="not-paid">{t('still_not_paid')}</div>
      )}
      {details.value && isPaid && (
        <CartDetailsModal
          isOpen
          cart={details.value}
          onClose={() =>
            history.replace('/bookings', {
              direction: 'forward',
            })
          }
        />
      )}
    </SubPageLayout>
  );
};

export default BookingDetails;
