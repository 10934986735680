import './date.scss';

import { useLazyQuery } from '@apollo/client';
import {
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import type { TreatmentDate, TreatmentTimeRange } from 'application/types';
import { GET_NEXT_AVAILABLE_EXPERTS } from 'infrastructure/adapters/graphql/TreatmentAdapter';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomModal from 'ui/elements/CustomModal/CustomModal';
import { useContextTranslation } from 'ui/translation';

import { useTreatmentBuilder } from '../../../state/TreatmentContext';
import { getTimeRangeForDayAndRelativeRange } from '../../../types/utils';
import injectablesType from '../../injectables';
import type {
  NextExpertAvailableSlots,
  ITreatmentAdapter,
} from '../ITreatmentAdapter';
import { Availabilities } from '../TreatmentResult/TreatmentResult';
import DynamicHighlightCalendarWrapper from './DynamicHighlightCalendarWrapper';
import NoAvailabilitiesCalendarWrapper from './NoAvailabilitiesCalendarWrapper';
import Button from 'ui/elements/Button/Button';

interface DateModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (date: TreatmentDate) => void;
  selectedDate: TreatmentDate | null;
  withoutAvailabilities?: boolean;
  isSpa?: boolean;
}

const SpaSlots = ({
  date,
  timeRange,
  onSelect,
}: {
  date?: Date;
  timeRange?: TreatmentTimeRange;
  onSelect: (date: TreatmentDate) => void;
}): JSX.Element => {
  const t = useContextTranslation('page.treatment.date');
  const { locationId, slotTime, setType, setSlotTime } = useTreatmentBuilder();
  const [availableSlots, setAvailableSlots] = useState<Date[] | null>(null);
  const [service] = useInject<ITreatmentAdapter>(
    injectablesType.TreatmentAdapter,
  );
  const { value } = service.useSPATreatmentTypesQuery({
    locationId: locationId || '',
    pagination: {
      offset: 0,
      limit: 25,
    },
  });

  const spaPassType = value?.[0];

  const [getAvailableSlots] = useLazyQuery<{
    multipleExpertsAvailabilities: {
      slots: NextExpertAvailableSlots[];
    };
  }>(GET_NEXT_AVAILABLE_EXPERTS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setAvailableSlots(
        data?.multipleExpertsAvailabilities.slots?.map(
          (slot) => new Date(slot.slot),
        ),
      );
    },
  });
  const history = useHistory();

  useEffect(() => {
    setSlotTime(null);
    if (date && timeRange && spaPassType) {
      const timeRanges = getTimeRangeForDayAndRelativeRange(date, timeRange);
      void getAvailableSlots({
        variables: {
          postalCode: '',
          rangeStart: timeRanges?.start || null,
          rangeEnd: timeRanges?.end || null,
          numberOfExperts: 0,
          selectedExpertIds: [],
          locationId,
          treatmentTypeIds: [spaPassType?.id],
        },
      });
    }
  }, [date, timeRange, spaPassType?.id]);

  const onSubmit = () => {
    if (!date || !timeRange || !spaPassType) {
      return;
    }
    onSelect({
      timeRange,
      date,
    });
    setType(spaPassType);
    history.push('/spa-booking/summary');
  };

  if (!spaPassType) {
    return <IonSpinner color="secondary" />;
  }

  return (
    <>
      <div className="availabilities-container spa">
        {availableSlots && timeRange && date && (
          <Availabilities showBookingRequestCTA slots={availableSlots} />
        )}
      </div>
      <Button disabled={!(slotTime && timeRange)} onClick={onSubmit}>
        {t('next')}
      </Button>
    </>
  );
};

const DateModal: React.FC<DateModalProps> = ({
  open,
  onClose,
  onSelect,
  selectedDate,
  withoutAvailabilities = false,
  isSpa = false,
}) => {
  const t = useContextTranslation('page.treatment.date');
  const [date, setDate] = React.useState<Date>();
  const [timeRange, setTimeRange] = React.useState<TreatmentTimeRange>();
  const { setSlotTime } = useTreatmentBuilder();

  React.useEffect(() => {
    setDate(selectedDate?.date);
    setTimeRange(selectedDate?.timeRange);
  }, [selectedDate]);

  const onSubmit = () => {
    if (!date || !timeRange) {
      return;
    }
    setSlotTime(null);
    onSelect({
      timeRange,
      date,
    });
  };

  return (
    <CustomModal cssClass="date-modal" isOpen={open} onDidDismiss={onClose}>
      <IonHeader>
        <div className="header-container">
          <button
            type="button"
            aria-label={t('back')}
            onClick={() => onClose()}
          />
        </div>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            {withoutAvailabilities ? (
              <NoAvailabilitiesCalendarWrapper
                date={date}
                setDate={setDate}
                timeRange={timeRange}
                setTimeRange={setTimeRange}
              />
            ) : (
              <DynamicHighlightCalendarWrapper
                date={date}
                setDate={setDate}
                timeRange={timeRange}
                setTimeRange={setTimeRange}
              />
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        {isSpa ? (
          <SpaSlots date={date} timeRange={timeRange} onSelect={onSelect} />
        ) : (
          <Button disabled={!(date && timeRange)} onClick={onSubmit}>
            {t('next')}
          </Button>
        )}
      </IonFooter>
    </CustomModal>
  );
};

export default DateModal;
