import EventEmitter from 'events';

import { gql, useLazyQuery } from '@apollo/client';
import { Preferences as Storage } from '@capacitor/preferences';
import type IAnonymousBookingsService from 'application/services/IAnonymousBookingsService';
import type { HookData, ObjectID, TypedEventEmitter } from 'application/types';
import {
  BookingAndCartCombined,
  StoredBooking,
  StoredCart,
} from 'application/types/mapBookingJSONToObject';
import mapBookingJSONToObject, {
  mapCartJSONToObject,
} from 'application/types/mapBookingJSONToObject';
import * as React from 'react';

const BOOKINGS_KEY = 'anonymous_bookings';

interface HookEventEmitter {
  changed: () => void;
}

const GET_MY_ANONYMOUS_BOOKINGS_QUERY = gql`
  query GetMyAnonymousBookings($access: [AnonymousBookingAccess]!) {
    myAnonymousCarts(access: $access) {
      carts {
        bookings {
          treatmentType {
            media {
              uri
              type
            }
            id
            description {
              lang
              value
            }
            name {
              value
              lang
            }
          }
          expert {
            id
            name
            age
            bookingCount
            description {
              lang
              value
            }
            experience {
              value
              lang
            }
            languages {
              lang
              value
            }
            media {
              type
              uri
            }
            profilePictureUrl
            slogan {
              value
              lang
            }
            treatmentTypes
          }
          id
        }
        id
        status
        address {
          locality
          postalCode
          street
          streetNumber
        }
        client
        clientData {
          birthday
          companyName
          email
          locale
          name
          phoneNumber
          surname
        }
        createdAt
        customId
        details {
          massageTable
          notes
          pets
        }
        hasReview
        length
        locationId
        time
        price {
          components {
            value
            type
          }
          discountStatus
          total
          totalTax
          voucherRemainingBalance
          voucherStatus
        }
        updatedAt
        token
        isWithoutOffset
      }
    }
  }
`;

type AnonymousBookings = {
  myAnonymousCarts: {
    carts: StoredCart[];
  };
};
export default class StorageAnonymousBookingsService
  implements IAnonymousBookingsService
{
  private eventEmitter: TypedEventEmitter<HookEventEmitter> =
    new EventEmitter();

  private cachedBookings: BookingAndCartCombined[] = [];

  async initialize(): Promise<void> {
    try {
      const stored = await Storage.get({ key: BOOKINGS_KEY });
      if (!stored.value) {
        return;
      }
      const parsed = JSON.parse(stored.value) as StoredBooking[];

      this.cachedBookings = parsed.map(mapBookingJSONToObject);

      this.eventEmitter.emit('changed');
    } catch (e) {
      // todo: sentry here
      // eslint-disable-next-line no-console
      console.error('Could not load stored bookings', e);
    }
  }

  useSavedBookings(): HookData<BookingAndCartCombined[]> {
    const [getBooking, { data, error, loading, called }] =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useLazyQuery<AnonymousBookings>(GET_MY_ANONYMOUS_BOOKINGS_QUERY);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      const onChanged = () => {
        const accesses = this.cachedBookings.map((booking) => ({
          customId: booking.customId,
          token: booking.token,
        }));
        void getBooking({ variables: { access: accesses } });
      };

      this.eventEmitter.addListener('changed', onChanged);

      onChanged();

      return () => {
        this.eventEmitter.removeListener('changed', onChanged);
      };
    }, [getBooking]);

    return {
      value: data
        ? [...data.myAnonymousCarts.carts.map(mapCartJSONToObject)]
        : null,
      loading: !called || loading,
      error: error?.clientErrors?.[0],
    };
  }

  async storeBooking(booking: BookingAndCartCombined): Promise<void> {
    const existingBooking = this.cachedBookings.find(
      (bookingObj) => bookingObj.id === booking.id,
    );

    if (existingBooking) {
      return;
    }

    const newBookings = [
      { ...booking, hasReview: booking.hasReview || false },
      ...this.cachedBookings,
    ];
    await Storage.set({
      key: BOOKINGS_KEY,
      value: JSON.stringify(newBookings),
    });
    this.cachedBookings = newBookings;
    this.eventEmitter.emit('changed');
  }

  async clear(): Promise<void> {
    await Storage.remove({ key: BOOKINGS_KEY });
    this.eventEmitter.emit('changed');
  }

  async removeBooking(bookingId: ObjectID): Promise<void> {
    const newBookings = this.cachedBookings.filter(
      (booking) => booking.id !== bookingId,
    );
    await Storage.set({
      key: BOOKINGS_KEY,
      value: JSON.stringify(newBookings),
    });
    this.cachedBookings = newBookings;
    this.eventEmitter.emit('changed');
  }
}
