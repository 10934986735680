import './voucherOrderFooter.scss';

import { useVoucherContext } from 'application/state/VoucherContext';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';
import currencyFormat from 'ui/utils/currencyFormat';
import Button from 'ui/elements/Button/Button';

interface VoucherOrderFooterProps {
  displayContent: boolean;
  nextEnabled?: boolean;
  onNext: () => void;
}

const VoucherOrderFooter: React.FC<VoucherOrderFooterProps> = ({
  displayContent,
  onNext,
  nextEnabled = true,
}) => {
  const t = useContextTranslation('misc.voucher_order');

  const { voucherValue, voucherAmount } = useVoucherContext();

  return (
    <div className="voucher-order-footer">
      <div className="text">
        {displayContent && voucherValue && voucherAmount && (
          <>
            {t('voucher_details_text', {
              value: currencyFormat(voucherValue),
              amount: voucherAmount,
            })}
            <br />
            {t('voucher_summary', {
              price: currencyFormat(voucherValue.multiply(voucherAmount)),
            })}
          </>
        )}
      </div>
      <div className="next-container">
        <Button color="primary" disabled={!nextEnabled} onClick={onNext}>
          {t('next')}
        </Button>
      </div>
    </div>
  );
};

export default VoucherOrderFooter;
