import * as React from 'react';
import { useHistory } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';

import VoucherPaymentConfirmationModal from './VoucherPaymentConfirmationModal';

const VoucherDetails: React.FC = () => {
  const history = useHistory();
  return (
    <SubPageLayout className="voucher-details">
      <VoucherPaymentConfirmationModal
        isOpen
        onClose={() =>
          history.replace('/', { unmount: true, direction: 'back' })
        }
      />
    </SubPageLayout>
  );
};

export default VoucherDetails;
