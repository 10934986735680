import { useEffect, useState } from 'react';

const breakpoints: Record<BreakPoint, number[]> = {
  large: [1401, 10000],
  medium: [993, 1400],
  small: [378, 992],
  xsmall: [0, 377],
};

type BreakPoint = 'small' | 'medium' | 'large' | 'xsmall';

function useBreakpoint(): BreakPoint {
  const [width, setWidth] = useState(window.innerWidth);

  const widthChangeListener = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', widthChangeListener);
    return () => window.removeEventListener('resize', widthChangeListener);
  });

  const currentBreakPoint = Object.keys(breakpoints).find((brpoint) => {
    if (
      width >= breakpoints[brpoint as BreakPoint][0] &&
      width <= breakpoints[brpoint as BreakPoint][1]
    ) {
      return brpoint;
    }
    return false;
  });

  return (currentBreakPoint as BreakPoint) || 'large';
}

export default useBreakpoint;
