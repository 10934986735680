import type { TreatmentDate } from 'application/types';
import { TreatmentTimeRange } from 'application/types';
import { format } from 'date-fns';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';

interface DateFormatterProps {
  date: TreatmentDate;
  short?: boolean;
  showSlot?: boolean;
}

const DateFormatter: React.FC<DateFormatterProps> = ({
  date,
  short = false,
  showSlot = false,
}) => {
  const t = useContextTranslation('page.treatment.date');
  const translatedTimeRanges = React.useMemo(
    () => ({
      [TreatmentTimeRange.Morning]: t('morning'),
      [TreatmentTimeRange.Afternoon]: t('afternoon'),
      [TreatmentTimeRange.Evening]: t('evening'),
    }),
    [t],
  );

  const timeRange = translatedTimeRanges[date.timeRange];

  if (short) {
    const formattedDate = format(date.date, 'EEEEEE dd.LL.');
    return (
      <span>
        {formattedDate}, {timeRange}
      </span>
    );
  }

  if (showSlot) {
    const formattedDate = format(date.date, 'dd.LL.yyyy - HH:mm');
    return <span>{formattedDate}</span>;
  }

  const formattedDate = format(date.date, 'dd.LL.yyyy');

  return (
    <span>
      {formattedDate} / {timeRange}
    </span>
  );
};

export default DateFormatter;
