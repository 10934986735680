import './referall.scss';

import { gql, useQuery } from '@apollo/client';
import { ReactComponent as ShareIcon } from 'ui/theme/images/arrow-forward-up.svg';
import { IonCol, IonGrid, IonRow, IonSpinner, IonText } from '@ionic/react';
import type ILoginService from 'application/services/ILoginService';
import type { HookData } from 'application/types';
import currency from 'currency.js';
import type { IShareAdapter } from 'infrastructure/adapters/native/NativeShareAdapter';
import { useInject } from 'inversify-hooks';
import { useState } from 'react';
import { useContextTranslation } from 'ui/translation';

import CustomAlert from '../../../ui/elements/CustomModal/CustomAlert';
import SubPageLayout from '../../../ui/layout/SubPageLayout';
import appInjectables from '../../injectables';
import injectables from '../injectables';

// TODO: refactor required
/* eslint-disable @typescript-eslint/no-misused-promises*/
/* eslint-disable react-hooks/rules-of-hooks */

const GET_REFERRAL_WALLET_BALANCE = gql`
  query getReferralWalletBalance($userId: ID!) {
    referralWallet {
      referralBalance
      numberOfPurchasesWithReferral
    }
    client(id: $userId) {
      referralCode
    }
  }
`;

export const useGetReferralWalletBalance = (): HookData<{
  client: { referralCode: string };
  referralWallet: { referralBalance: string };
}> => {
  const [loginService] = useInject<ILoginService>(
    appInjectables.services.LoginService,
  );
  const userId = loginService.getUserId();

  const query = useQuery<{
    client: { referralCode: string };
    referralWallet: { referralBalance: string };
  }>(GET_REFERRAL_WALLET_BALANCE, {
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  return {
    value: query.data ? query.data : null,
    loading: query.loading,
    error: query.error,
  };
};
const Referral = (): JSX.Element => {
  const t = useContextTranslation('page.referral');
  const [showCopiedAlert, setShowCopiedAlert] = useState(false);

  const { value, loading } = useGetReferralWalletBalance();

  const handleShare = async (code: string) => {
    const [shareAdapter] = useInject<IShareAdapter>(injectables.ShareAdapter);

    const shareResult = await shareAdapter.handleUrlNativeShare(
      `/treatments?referralCode=${code}`,
      t('share_referral_text'),
    );
    if (shareResult.notifyUser) {
      setShowCopiedAlert(true);
    }
  };

  return (
    <SubPageLayout className="referral">
      <IonGrid>
        <IonRow>
          <IonCol className="referral_how" size="12">
            <h2 className="referral__title">{t('title')}</h2>
            <p>{t('how_it_works')}</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="referral__your-code" size="12">
            <p>{t('your_code')}</p>
          </IonCol>
        </IonRow>
        {loading && <IonSpinner color="secondary" name="dots" />}
        {value && (
          <>
            <IonRow>
              <IonCol size="12">
                <div className="referral__code-wrapper">
                  <div className="referral__code">
                    {value.client.referralCode}
                  </div>

                  <button
                    className="share"
                    type="button"
                    aria-label={t('share')}
                    onClick={() => handleShare(value.client.referralCode)}
                  >
                    <ShareIcon />
                  </button>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="8">
                <IonText className="referral__balance">
                  {t('wallet_balance')}
                </IonText>
              </IonCol>
              <IonCol size="12" sizeMd="8" sizeLg="4">
                <IonText className="referral__price">
                  {currency(value.referralWallet.referralBalance).value} €
                </IonText>
              </IonCol>
            </IonRow>
          </>
        )}
      </IonGrid>
      <CustomAlert
        isOpen={showCopiedAlert}
        message={t('share_alert')}
        button={t('alert_close')}
        onDidDismiss={() => setShowCopiedAlert(false)}
      />
    </SubPageLayout>
  );
};

export default Referral;
