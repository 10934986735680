import './Membership.scss';

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import membershipImg from 'ui/theme/images/membership.jpg';
import { useContextTranslation } from 'ui/translation';

export default function Membership(): JSX.Element {
  const t = useContextTranslation('page.membership');
  const history = useHistory();
  return (
    <SubPageLayout
      beforeContent={
        <div className="membership-gallery-header">
          <img
            className="membership-img"
            src={membershipImg}
            alt={t('media')}
          />
        </div>
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonCard className="membership-card">
              <IonCardSubtitle>{t('subtitle')}</IonCardSubtitle>
              <IonCardTitle className="membership-title">
                {t('title')}
              </IonCardTitle>
              <IonCardContent>
                <p className="membership-letter">{t('content')}</p>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton
              className="membership-button"
              onClick={() => history.push('/home')}
            >
              {t('out_of_stock')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </SubPageLayout>
  );
}
