import './bookingSummaryTable.scss';

import {
  faCalendar,
  faClock,
  faStar,
} from '@fortawesome/free-regular-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonItem, IonList } from '@ionic/react';
import type {
  TreatmentLength,
  TreatmentLocation,
  TreatmentTypeBriefData,
} from 'application/types';
import * as React from 'react';
import LocationFormatter from 'ui/elements/LocationFormatter';
import { useContextTranslation, useTranslateString } from 'ui/translation';
import formatLocalizedDate from 'ui/utils/formatLocalizedDate';

import { ReactComponent as TreatmentIcon } from '../../application/pages/Treatments/images/physiotherapy.svg';

interface CartSummaryTableProps {
  expertTreatments: {
    expert: {
      id: string;
      name: string;
      profilePictureUrl: string;
    } | null;
    type: TreatmentTypeBriefData;
  }[];
  length?: TreatmentLength;
  time: Date;
  location: TreatmentLocation;
}

const CartSummaryTable: React.FC<CartSummaryTableProps> = ({
  location,
  expertTreatments,
  time: rawTime,
  length,
}) => {
  const translate = useTranslateString();
  const t = useContextTranslation('misc');
  const time = formatLocalizedDate(rawTime, 'cccccc, dd.MM.yyyy, H:mm');

  const types = expertTreatments
    .map(({ type }) => translate(type.name))
    .join(', ');
  const experts = expertTreatments
    .filter(({ expert }) => !!expert)
    .map(({ expert }) => expert?.name).join(', ');

  return (
    <IonList className="booking-summary-table" mode="ios" lines="full">
      {types && (
        <IonItem detail={false} mode="ios">
          <TreatmentIcon className="treatment-icon" />
          <span className="text">{types}</span>
        </IonItem>
      )}
      <IonItem detail={false} mode="ios">
        <FontAwesomeIcon icon={faCalendar} />
        <span className="text">{t('time_format', { time })}</span>
      </IonItem>
      {length && (
        <IonItem detail={false} mode="ios">
          <FontAwesomeIcon icon={faClock} />
          <span className="text">
            {t('length_format', { length: length.length })}
          </span>
        </IonItem>
      )}
      {!!experts && (
        <IonItem detail={false} mode="ios">
          <FontAwesomeIcon icon={faStar}/>
          <span className="text">{t('expert_name', { name: experts })}</span>
        </IonItem>
      )}
      {location.locality && (
        <IonItem detail={false} lines="none" mode="ios">
          <FontAwesomeIcon icon={faHome} />
          <span className="text">
            <LocationFormatter location={location} />
          </span>
        </IonItem>
      )}
    </IonList>
  );
};

export default CartSummaryTable;
