export class InvalidPasswordError extends Error {
  constructor() {
    super('invalid_password');
  }
}

export class NoPasswordError extends Error {
  constructor() {
    super('user_has_no_password');
  }
}

export default interface IPasswordChangeAdapter {
  changePassword(oldPassword: string, newPassword: string): Promise<void>;
}
