import { gql, useQuery } from '@apollo/client';
import type { SavedCard } from 'application/pages/SavedCards/ISavedCardsAdapter';
import type ISavedCardsAdapter from 'application/pages/SavedCards/ISavedCardsAdapter';
import type { HookData } from 'application/types';
import client from 'infrastructure/apollo';

const SAVED_CARDS_QUERY = gql`
  query SavedCards {
    savedCards {
      brand
      expiryMonth
      expiryYear
      id
      numberSuffix
      type
    }
  }
`;

const DELETE_SAVED_CARD_QUERY = gql`
  mutation DeleteSavedCard($cardId: String!) {
    deleteSavedCard(cardId: $cardId)
  }
`;

const SavedCardsAdapter: ISavedCardsAdapter = {
  async deleteSavedCard(cardId: string): Promise<void> {
    await client.mutate({
      mutation: DELETE_SAVED_CARD_QUERY,
      variables: {
        cardId,
      },
      refetchQueries: ['SavedCards'],
    });
  },
  useSavedCards(): HookData<SavedCard[]> {
    const query = useQuery<{ savedCards: SavedCard[] }>(SAVED_CARDS_QUERY, {
      fetchPolicy: 'no-cache',
    });

    return {
      loading: query.loading,
      error: query.error,
      value: query.data?.savedCards || null,
    };
  },
};

export default SavedCardsAdapter;
