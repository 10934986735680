import './ratingField.scss';

import { IonIcon } from '@ionic/react';
import { useField, useFormikContext } from 'formik';
import { star, starOutline } from 'ionicons/icons';
import Rating from 'react-rating';

export default function RatingField(): JSX.Element {
  const formik = useFormikContext();
  const [field] = useField('rating');
  return (
    <Rating
      start={0}
      stop={5}
      fractions={2}
      initialRating={field.value}
      emptySymbol={<IonIcon icon={starOutline} />}
      fullSymbol={<IonIcon icon={star} />}
      onChange={(rating) => {void formik.setFieldValue('rating', rating)}}
    />
  );
}
