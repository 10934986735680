import currency from 'currency.js';
import React from 'react';
import { useContextTranslation } from 'ui/translation';
import currencyFormat from 'ui/utils/currencyFormat';
import ExpandableSelect from 'ui/elements/ExpandableSelect';

export const availableVoucherValues = [25, 50, 75, 100, 200];

const VoucherValueSelector: React.FC<{
  expanded: boolean;
  selectedValue?: string;
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  expandSection: () => void;
}> = ({ expanded, selectedValue, onSelect, expandSection }) => {
  const t = useContextTranslation('page.voucher');
  const AVAILABLE_VALUES = availableVoucherValues.reduce((acc, value) => {
    acc[value] = t(`voucher_amount_as_value`, { value: currencyFormat(currency(value)) });
    return acc;
  }, {} as Record<number, string>);

  return (
    <ExpandableSelect
      placeholder={t('voucher_value_select')}
      options={AVAILABLE_VALUES}
      onSelectChange={onSelect}
      expanded={expanded}
      value={selectedValue}
      onClick={expandSection}
    />
  );
};

export default VoucherValueSelector;
