import './MyBookings.scss';

import {
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
  useIonViewWillEnter,
} from '@ionic/react';
import injectables from 'application/injectables';
import type IAnonymousBookingsService from 'application/services/IAnonymousBookingsService';
import { useAuth } from 'application/state/AuthProvider';
import { addMinutes, isAfter, isBefore } from 'date-fns';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import BookingCard from 'ui/elements/BookingCard';
import RequestCallToExpert from 'ui/elements/RequestCallToExpert/RequestCallToExpert';
import { useContextTranslation } from 'ui/translation';

import type { BookingAndCartCombined } from '../../types/mapBookingJSONToObject';
import CartDetailsModal from '../BookingDetailsModal/CartDetailsModal';
import type IAccountBookingsAdapter from './IAccountBookingsAdapter';
import SubPageLayout from '../../../ui/layout/SubPageLayout';

const AuthorizedBookingsView: React.FC = () => {
  const t = useContextTranslation('page.my_bookings');
  const mt = useContextTranslation('misc');

  const [adapter] = useInject<IAccountBookingsAdapter>(
    injectables.pages.AccountBookingsAdapter,
  );

  const [activeVisible, setActiveVisible] = React.useState<boolean>(false);
  const [pastVisible, setPastVisible] = React.useState<boolean>(false);

  const bookings = adapter.useAccountBookings();

  React.useEffect(() => {
    setActiveVisible(!!bookings.value?.bookings?.length);
  }, [bookings.value?.bookings?.length]);

  const history = useHistory();

  useIonViewWillEnter(() => {
    if (history.action === 'REPLACE') {
      bookings.refetch();
    }
  }, [history]);

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [callModalOpen, setCallModalOpen] = React.useState<boolean>(false);

  const [selectedBookingDetails, setSelectedBookingDetails] =
    React.useState<BookingAndCartCombined | null>(null);

  if (bookings.loading) {
    return <IonSpinner color="secondary" name="dots" />;
  }

  const now = new Date();

  const activeBookings =
    bookings.value?.bookings?.filter((booking) =>
      isBefore(now, booking.time),
    ) || [];
  const pastBookings =
    bookings.value?.bookings?.filter((booking) => isAfter(now, booking.time)) ||
    [];

  return (
    <IonGrid>
      <IonRow>
        <IonCol className="bookings-col active-bookings" size="12">
          <button
            type="button"
            className="toggle"
            onClick={() => setActiveVisible(!activeVisible)}
          >
            {activeVisible ? mt('hide') : mt('show')}
          </button>
          <h3>{t('active_bookings')}</h3>
          {activeVisible &&
            activeBookings.map((booking) => (
              <BookingCard
                key={booking.id}
                booking={booking}
                onSelect={(isCall) => {
                  setSelectedBookingDetails(booking);
                  if (isCall) {
                    setCallModalOpen(true);
                  } else {
                    setModalOpen(true);
                  }
                }}
              />
            ))}
          {activeVisible && activeBookings.length === 0 && (
            <p>{t('no_active_bookings')}</p>
          )}
        </IonCol>
        <IonCol className="bookings-col past-bookings" size="12">
          <button
            type="button"
            className="toggle"
            onClick={() => setPastVisible(!pastVisible)}
          >
            {pastVisible ? mt('hide') : mt('show')}
          </button>
          <h3>{t('past_bookings')}</h3>
          {pastVisible &&
            pastBookings.map((booking) => (
              <BookingCard
                key={booking.id}
                booking={booking}
                pastBooking
                onSelect={(isCall) => {
                  setSelectedBookingDetails(booking);
                  if (isCall) {
                    setCallModalOpen(true);
                  } else {
                    setModalOpen(true);
                  }
                }}
              />
            ))}
          {pastVisible && pastBookings.length === 0 && (
            <p>{t('no_past_bookings')}</p>
          )}
        </IonCol>
        <CartDetailsModal
          isOpen={modalOpen}
          cart={selectedBookingDetails}
          onClose={() => setModalOpen(false)}
        />
        {selectedBookingDetails && (
          <RequestCallToExpert
            isOpen={callModalOpen}
            setModalOpen={setCallModalOpen}
            cart={selectedBookingDetails}
          />
        )}
      </IonRow>
    </IonGrid>
  );
};

const AnonymousBookingsView: React.FC = () => {
  const t = useContextTranslation('page.my_bookings');

  const [service] = useInject<IAnonymousBookingsService>(
    injectables.services.AnonymousBookingsService,
  );

  const bookings = service.useSavedBookings();

  const activeBookings =
    bookings.value?.filter((booking) =>
      isAfter(addMinutes(booking.time, booking.length), new Date()),
    ) || [];

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [callModalOpen, setCallModalOpen] = React.useState<boolean>(false);
  const [selectedBookingDetails, setSelectedBookingDetails] =
    React.useState<BookingAndCartCombined | null>(null);

  return (
    <IonGrid>
      <IonRow>
        <IonCol className="bookings-col active-bookings" size="12">
          <h3>{t('active_bookings')}</h3>
          {activeBookings.map((booking) => (
            <BookingCard
              key={booking.id}
              booking={booking}
              onSelect={(isCall) => {
                setSelectedBookingDetails(booking);
                if (isCall) {
                  setCallModalOpen(true);
                } else {
                  setModalOpen(true);
                }
              }}
            />
          ))}

          {activeBookings.length === 0 && <p>{t('no_active_bookings')}</p>}
        </IonCol>
        <CartDetailsModal
          isOpen={modalOpen}
          cart={selectedBookingDetails}
          onClose={() => setModalOpen(false)}
        />
        {selectedBookingDetails && (
          <RequestCallToExpert
            isOpen={callModalOpen}
            setModalOpen={setCallModalOpen}
            cart={selectedBookingDetails}
          />
        )}
      </IonRow>
    </IonGrid>
  );
};

const MyBookings: React.FC = () => {
  const t = useContextTranslation('page.my_bookings');
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  const beforeContent = isAuthenticated ? undefined : (
    <div className="slide-content">
      <p className="slide-title">
        <strong>{t('non_logged_header')}</strong>
      </p>
      <IonButton
        mode="ios"
        fill="outline"
        color="dark"
        size="small"
        onClick={() => history.push('/login')}
        className="slide-button"
      >
        {t('log_in_or_sign_in')}
      </IonButton>
    </div>
  );

  return (
    <SubPageLayout beforeContent={beforeContent} className="my-bookings">
      {isAuthenticated ? <AuthorizedBookingsView /> : <AnonymousBookingsView />}
    </SubPageLayout>
  );
};

export default MyBookings;
