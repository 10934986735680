import {
  IonContent,
  IonHeader,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import injectables from 'application/injectables';
import { container } from 'inversify-props';
import React from 'react';

import type IPaymentProvider from './IPaymentProvider';
import type { PaymentInstanceData, PaymentParams } from './IPaymentProvider';

interface PaymentMethodProps {
  params: PaymentParams;
  provider: IPaymentProvider;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  params,
  provider: Provider,
  // eslint-disable-next-line react/jsx-props-no-spreading
}) => <Provider.Render {...params} />;

interface PaymentHandlerModalProps {
  instance?: PaymentInstanceData;
  onSuccess: () => void;
  onError: (e: Error) => void;
  title: string;
}

const PaymentHandlerModal: React.FC<PaymentHandlerModalProps> = ({
  instance,
  onError,
  onSuccess,
  title,
}) => {
  const isOpen = Boolean(instance);

  const renderPaymentMethod = (instanceData: PaymentInstanceData) => {
    const provider = container.getNamed<IPaymentProvider>(
      injectables.pages.PaymentProvider,
      instanceData.method,
    );

    if (!provider) {
      return <IonSpinner color="secondary" />;
    }

    return (
      <PaymentMethod
        params={{
          instance: instanceData,
          onSuccess,
          onError,
        }}
        provider={provider}
      />
    );
  };

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>{instance && renderPaymentMethod(instance)}</IonContent>
    </IonModal>
  );
};

export default PaymentHandlerModal;
