import { gql } from '@apollo/client';
import client from 'infrastructure/apollo';
import React, { useRef } from 'react';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonModal,
  IonRow,
  IonText,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import type { TFunction } from 'react-i18next';
import type { FormikHelpers } from 'formik';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { useContextTranslation } from 'ui/translation';
import IonicField from 'ui/utils/IonicField';
import * as Yup from 'yup';
import { useAuth } from 'application/state/AuthProvider';
import { useHistory } from 'react-router-dom';

// TODO: refactor required
/* eslint-disable  @typescript-eslint/no-floating-promises */
/* eslint-disable  @typescript-eslint/no-explicit-any */

const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccount($email: String!, $password: String!) {
    deleteMyAccount(email: $email, password: $password)
  }
`;

const DELETE_EXTERNAL_ACCOUNT_MUTATION = gql`
  mutation DeleteExternalAccount {
    deleteExternalAccount
  }
`;

export function useDeleteExternalAccountMutation() {
  return async (): Promise<void> => {
    await client.mutate({
      mutation: DELETE_EXTERNAL_ACCOUNT_MUTATION,
    });
  };
}

export function useDeleteAccount() {
  return async (email: string, password: string): Promise<any> =>
    client.mutate({
      mutation: DELETE_ACCOUNT_MUTATION,
      variables: {
        email,
        password,
      },
    });
}

const getValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string().required(t('validation.field_required')),
    password: Yup.string().required(t('validation.field_required')),
  });

function DeleteAccountForm({
  onSubmit,
  onCancel,
}: {
  onSubmit: (data: { email: string; password: string }) => Promise<void>;
  onCancel: () => void;
}) {
  const t = useContextTranslation('page.account.delete_account');
  const formik = useFormik<{ email: string; password: string }>({
    onSubmit,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: getValidationSchema(t),
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <IonText>
              <h5>{t('disclaimer_1')}</h5>
            </IonText>
            <IonText color="tertiary">
              <h6>{t('disclaimer_2')}</h6>
            </IonText>
            <IonText>
              <h6>{t('disclaimer')}</h6>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <Field
              component={IonicField}
              name="email"
              type="email"
              autocomplete="email"
              required
              placeholder={t('email_placeholder')}
              showErrors
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <Field
              component={IonicField}
              name="password"
              type="password"
              autocomplete="password"
              required
              placeholder={t('password_placeholder')}
              showErrors
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <IonButton
              type="submit"
              color="danger"
              expand="block"
              className="submit-button"
            >
              {t('submit_label')}
            </IonButton>
            <IonButton
              type="button"
              onClick={() => onCancel()}
              expand="block"
              fill="clear"
              color="tertiary"
            >
              {t('cancel_label')}
            </IonButton>
          </IonCol>
        </IonRow>
      </Form>
    </FormikProvider>
  );
}

function DeleteExternalAccountForm({
  onSubmit,
  onCancel,
}: {
  onSubmit: (
    data: { confirmation: string },
    helpers: FormikHelpers<{
      confirmation: string;
    }>,
  ) => Promise<void>;
  onCancel: () => void;
}) {
  const t = useContextTranslation('page.account.delete_account');

  const formik = useFormik<{ confirmation: string }>({
    onSubmit,
    initialValues: {
      confirmation: '',
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <IonText>
              <h5>{t('disclaimer_1')}</h5>
            </IonText>
            <IonText color="primary">
              <h6>{t('disclaimer_2')}</h6>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <Field
              component={IonicField}
              name="confirmation"
              type="text"
              required
              placeholder={t('confirmation_placeholder')}
              showErrors
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <IonButton
              type="submit"
              color="danger"
              expand="block"
              className="submit-button"
            >
              {t('submit_label')}
            </IonButton>
            <IonButton
              type="button"
              onClick={() => onCancel()}
              expand="block"
              fill="clear"
            >
              {t('cancel_label')}
            </IonButton>
          </IonCol>
        </IonRow>
      </Form>
    </FormikProvider>
  );
}

export default function DeleteAccountDialog({
  t,
}: {
  t: TFunction;
}): JSX.Element {
  const modal = useRef<HTMLIonModalElement>(null);
  const deleteAccount = useDeleteAccount();
  const deleteExternalAccount = useDeleteExternalAccountMutation();

  const [present] = useIonToast();
  const { logout, externalProvider } = useAuth();
  const history = useHistory();

  const onSubmit = async (data: { email: string; password: string }) => {
    try {
      await deleteAccount(data.email, data.password);
      present({
        color: 'success',
        message: t('deletion_success_message'),
        duration: 3000,
      });
      await logout();
      history.push('/home');
    } catch (e) {
      present({
        color: 'danger',
        message: t('invalid_credentials_message'),
        duration: 3000,
      });
    }
  };

  const onSubmitExternal = async (
    data: { confirmation: string },
    helpers: FormikHelpers<{ confirmation: string }>,
  ) => {
    if (data.confirmation.trim() !== 'delete') {
      helpers.setFieldError(
        'confirmation',
        t('delete_account.invalid_confirmation_message'),
      );
      helpers.setSubmitting(false);
      return;
    }

    try {
      await deleteExternalAccount();
      present({
        color: 'success',
        message: t('deletion_success_message'),
        duration: 3000,
      });
      await logout();
      history.push('/home');
    } catch (e) {
      present({
        color: 'danger',
        message: t('invalid_credentials_message'),
        duration: 3000,
      });
    }
  };

  const [isDeleteDialogOpened, setIsDeleteDialogOpened] =
    React.useState<boolean>(false);

  return (
    <>
      <IonButton
        expand="block"
        color="danger"
        onClick={() => setIsDeleteDialogOpened(true)}
      >
        {t('delete_account_label')}
      </IonButton>
      <IonModal
        ref={modal}
        isOpen={isDeleteDialogOpened}
        onDidDismiss={() => setIsDeleteDialogOpened(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsDeleteDialogOpened(false)}>
                {t('delete_account.cancel_label')}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {externalProvider ? (
            <DeleteExternalAccountForm
              onSubmit={onSubmitExternal}
              onCancel={() => setIsDeleteDialogOpened(false)}
            />
          ) : (
            <DeleteAccountForm
              onSubmit={onSubmit}
              onCancel={() => setIsDeleteDialogOpened(false)}
            />
          )}
        </IonContent>
      </IonModal>
    </>
  );
}
