import './style.scss';

import {
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
  useIonViewWillEnter,
} from '@ionic/react';
import injectables from 'application/injectables';
import type IRegistrationAdapter from 'application/pages/Register/IRegistrationAdapter';
import type { RegisterFormValues } from 'application/pages/Register/RegisterForm';
import { RegisterForm } from 'application/pages/Register/RegisterForm';
import { useAuth } from 'application/state/AuthProvider';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import { useVoucherContext } from 'application/state/VoucherContext';
import { formatISO, parseISO, set } from 'date-fns';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import i18n from 'infrastructure/i18n';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import isPhoneNumber from 'ui/utils/isPhoneNumber';
import * as Yup from 'yup';

const initialValues = {
  name: '',
  surname: '',
  birthday: formatISO(new Date()),
  phoneNumber: '',
  companyName: '',
};

type RegisterExternallyFormValues = Omit<
  RegisterFormValues,
  'password' | 'repeatPassword' | 'email'
>;

export interface RegisterExternallyRouteState {
  redirectUri?: string;
  name?: string;
  surname?: string;
  providerName?: string;
  externalAuthenticationStatus?: string;
}

const RegisterExternallyWrapper: React.FC<{
  location: { state: RegisterExternallyRouteState };
}> = ({ location }) => {
  const t = useContextTranslation('page.register');
  const td = useContextTranslation('layout.date_input');
  const history = useHistory();
  const auth = useAuth();
  const [adapter] = useInject<IRegistrationAdapter>(
    injectables.pages.RegistrationAdapter,
  );

  const treatment = useTreatmentBuilder();
  const voucher = useVoucherContext();

  const { name: initialName, surname: initialSurname } = location.state || {};

  const initialValuesWithExternallyProvided = React.useMemo(
    () => ({
      ...initialValues,
      name: initialName || '',
      surname: initialSurname || '',
    }),
    [initialName, initialSurname],
  );

  const shouldHideNames =
    location.state?.providerName === 'apple' &&
    !!initialName &&
    !!initialSurname;

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        name: shouldHideNames
          ? Yup.string().optional()
          : Yup.string()
              .required(t('name_required'))
              .min(3, ({ min }) => t('name_min_length', { min }))
              .max(128, ({ max }) => t('name_max_length', { max })),
        surname: shouldHideNames
          ? Yup.string().optional()
          : Yup.string()
              .required(t('surname_required'))
              .min(3, ({ min }) => t('surname_min_length', { min }))
              .max(128, ({ max }) => t('surname_max_length', { max })),
        birthday: Yup.date()
          .typeError(td('invalid_date'))
          .max(new Date(), td('invalid_date'))
          .min(
            set(new Date(), {
              year: 1920,
              month: 1,
              date: 1,
            }),
            td('invalid_date'),
          ),
        companyName: Yup.string()
          .optional()
          .max(256, ({ max }) =>
            t('company_name_max_length', {
              max,
            }),
          ),
        phoneNumber: Yup.string()
          .required(t('phone_number_required'))
          .max(32, t('phone_number_invalid_format'))
          .test({
            message: t('phone_number_invalid_format'),
            test: (value) => !!value && isPhoneNumber(value),
          }),
      }),
    [t, shouldHideNames],
  );

  const onSubmit = React.useCallback<
    (
      values: RegisterExternallyFormValues,
      helpers: FormikHelpers<RegisterExternallyFormValues>,
    ) => Promise<void>
  >(
    async (values, helpers) => {
      if (!auth.externalRegistration) {
        return;
      }
      try {
        const { name, phoneNumber, companyName, birthday, surname } = values;
        const result = await adapter.createExternallyAuthenticatedAccount({
          name,
          surname,
          birthday: parseISO(birthday),
          phoneNumber,
          locale: i18n.language,
          companyName,
          registrationToken: auth.externalRegistration.registrationToken,
          providerName: location.state?.providerName || '',
        });
        await auth.login(result.accessToken);

        const {
          slotTime,
          length,
          location: treatmentLocation,
          experts,
          details,
          locationId,
        } = treatment;
        if (treatmentLocation && experts && details && slotTime && length) {
          history.replace(
            locationId ? '/booking/payment' : '/booking/address',
            { direction: 'forward' },
          );
        } else if (voucher.voucherValue && voucher.voucherAmount) {
          history.replace('/vouchers/payment', { direction: 'forward' });
        } else if (location.state?.redirectUri) {
          history.replace(location.state?.redirectUri, {
            direction: 'forward',
          });
        } else {
          history.replace('/home', { direction: 'back', unmount: true });
        }
        helpers.setStatus(null);
      } catch (e) {
        helpers.setStatus({
          error: e,
        });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [t, history, treatment],
  );

  useIonViewWillEnter(() => {
    if (auth.isAuthenticated || !auth.externalRegistration) {
      history.replace('/home');
    }
  }, [history, auth.isAuthenticated, auth.externalRegistration]);

  return (
    <SubPageLayout>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h1>{t('title')}</h1>
          </IonCol>
        </IonRow>
        <Formik
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValuesWithExternallyProvided}
          onSubmit={onSubmit}
        >
          <RegisterForm hideCredentials hideNames={shouldHideNames} />
        </Formik>
      </IonGrid>
    </SubPageLayout>
  );
};

const RegisterExternally: React.FC = () => {
  const location = useLocation<RegisterExternallyRouteState>();

  if (!location.state) {
    return <IonSpinner color="secondary" className="loader" name="dots" />;
  }

  return <RegisterExternallyWrapper location={location} />;
};

export default RegisterExternally;
