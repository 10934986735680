import { IonCol } from '@ionic/react';
import { Field } from 'formik';
import { useContextTranslation } from 'ui/translation';
import IonicField from 'ui/utils/IonicField';
import IonicPhoneField from 'ui/utils/IonicPhoneField';

const BookingRequestFields = (): JSX.Element => {
  const t = useContextTranslation('page.treatment');

  return (
    <>
      <IonCol size="12">
        <Field
          component={IonicField}
          autocomplete="given-name"
          name="name"
          type="text"
          required
          placeholder={t('name_placeholder')}
          showErrors
        />
      </IonCol>
      <IonCol size="12">
        <Field
          component={IonicField}
          name="surname"
          autocomplete="family-name"
          type="text"
          required
          placeholder={t('surname_placeholder')}
          showErrors
        />
      </IonCol>
      <IonCol size="12">
        <Field
          component={IonicField}
          name="email"
          type="email"
          inputMode="email"
          autocomplete="email"
          required
          placeholder={t('email_placeholder')}
          showErrors
        />
      </IonCol>
      <IonCol size="12">
        <Field
          component={IonicPhoneField}
          className="phoneNumber"
          defaultCountry="DE"
          name="phoneNumber"
          placeholder={t('phone_number_placeholder')}
          showErrors
        />
      </IonCol>
    </>
  );
};

export default BookingRequestFields;
