import { useHistory } from 'react-router';
import { useIonAlert, useIonViewWillEnter } from '@ionic/react';
import { useContextTranslation } from 'ui/translation';
import MainLayout from 'ui/layout/MainLayout';

export default function NewsletterConfirmedPage(): JSX.Element {
  const t = useContextTranslation('page.newsletter_confirm');
  const history = useHistory();
  const [showAlert] = useIonAlert();

  useIonViewWillEnter(() => {
    void showAlert(t('message'), [
      {
        text: t('dismiss'),
        handler: () => {
          history.replace('/home');
        },
      },
    ]);
  });

  return <MainLayout />;
}
