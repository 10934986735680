import type { MediaData } from 'application/types';
import { useContextTranslation } from 'ui/translation';
import { Swiper, SwiperSlide } from './Swiper';

type BeforeContentSwiperProps = {
  media: MediaData[];
};
const BeforeContentSwiper = ({
  media,
}: BeforeContentSwiperProps): JSX.Element => {
  const t = useContextTranslation('misc');

  return (
    <Swiper navigation pagination slidesPerView={1}>
      {media.map((media) => (
        <SwiperSlide key={media.uri}>
          {media.type === 'video' ? (
            <video
              key={media.uri}
              playsInline
              controls={false}
              src={media.uri}
              muted
              autoPlay
              loop
            />
          ) : (
            <img key={media.uri} src={media.uri} alt={t('media')} />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BeforeContentSwiper;
