import type { AvailableExpertsQuery } from '../ITreatmentAdapter';
import styles from './resultsWithAvailabilities.module.scss';
import {
  useContextTranslation,
  useTranslateString,
} from '../../../../ui/translation';
import * as React from 'react';
import useCalcMaxHeight from '../../../../ui/utils/useCalcMaxHeight';
import { useEffect } from 'react';
import { useTreatmentBuilder } from '../../../state/TreatmentContext';
import { useInject } from 'inversify-hooks';
import type ITreatmentTypeDetailsAdapter from '../../TreatmentTypeDetails/ITreatmentTypeDetailsAdapter';
import injectables from '../../injectables';
import {
  IonButton,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
} from '@ionic/react';
import type { ObjectID, TreatmentType } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useSetAtom } from 'jotai';
import { treatmentTypeDetailsAtom } from './treatmentAtoms';

const TreatmentTypesList = ({ expert }: { expert: AvailableExpertsQuery }) => {
  const t = useContextTranslation('page.treatment');

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [ref, maxHeight] = useCalcMaxHeight(expanded);

  const setTreatmentTypeDetailsId = useSetAtom(treatmentTypeDetailsAtom);

  const { type, expertId: builderExpertId, locationId } = useTreatmentBuilder();
  const [adapter] = useInject<ITreatmentTypeDetailsAdapter>(
    injectables.TreatmentDetailsAdapter,
  );

  const availableTypes = adapter.useTreatmentDetails(locationId || null);

  const availableExpertTypes = availableTypes.value?.filter((treatmentType) =>
    expert.treatmentTypes.includes(treatmentType.id),
  );

  const hiddenTypes = availableExpertTypes?.slice(3) || [];
  const showedTypes = availableExpertTypes?.slice(0, 3) || [];

  useEffect(() => {
    if (expert.id !== builderExpertId || expanded) {
      return;
    }
    if (hiddenTypes?.find((el) => el.id === type?.id)) {
      setExpanded(true);
    }
  }, [expanded, type?.id]);

  if (availableTypes.loading) {
    return <IonSpinner color="secondary" name="dots" />;
  }

  if (!availableExpertTypes?.length) {
    return <></>;
  }

  return (
    <div className={styles.treatmentTypes}>
      <IonRadioGroup
        allowEmptySelection
        value={expert.id === builderExpertId ? type?.id : null}
      >
        {showedTypes.map((treatmentType: TreatmentType) => (
          <TreatmentTypeItem
            key={`${treatmentType.id}_${expert.id}`}
            itemType={treatmentType}
            itemExpert={expert}
            setTreatmentTypeDetails={setTreatmentTypeDetailsId}
          />
        ))}
        <div className={styles.expandableSelect}>
          <div
            className={`${styles.selectableContainer} ${
              !maxHeight ? styles.selectableHidden : ''
            }`}
            ref={ref}
          >
            {hiddenTypes.map((treatmentType: TreatmentType) => (
              <TreatmentTypeItem
                key={`${treatmentType.id}_${expert.id}`}
                itemType={treatmentType}
                itemExpert={expert}
                setTreatmentTypeDetails={setTreatmentTypeDetailsId}
              />
            ))}
          </div>
        </div>
        <IonButton
          className={`${styles.showMoreBtn} ${
            expanded || !hiddenTypes.length ? styles.hidden : ''
          }`}
          expand="block"
          onClick={() => {
            setExpanded(true);
          }}
          fill="clear"
        >
          {t('show_more')}
          <FontAwesomeIcon icon={faChevronDown} />
        </IonButton>
      </IonRadioGroup>
    </div>
  );
};

const TreatmentTypeItem: React.FC<{
  itemType: TreatmentType;
  itemExpert: AvailableExpertsQuery;
  setTreatmentTypeDetails: (treatmentTypeId: ObjectID | null) => void;
}> = ({ itemType, itemExpert, setTreatmentTypeDetails }) => {
  const translate = useTranslateString();
  const {
    experts,
    expertId,
    setType,
    setExpertId,
    setExperts,
    isFirstExpertSelection,
  } = useTreatmentBuilder();
  const t = useContextTranslation('page.treatment');

  const handleExpertSelection = (
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
  ) => {
    setExperts(
      !isFirstExpertSelection && experts?.[0]
        ? [
            experts?.[0],
            {
              expertId: itemExpert.id,
              name: itemExpert.name,
              profilePictureUrl: itemExpert.profilePictureUrl,
              type: itemType,
            },
          ]
        : [
            {
              expertId: itemExpert.id,
              name: itemExpert.name,
              profilePictureUrl: itemExpert.profilePictureUrl,
              type: itemType,
            },
          ],
    );
    e.stopPropagation();
    setExpertId(itemExpert.id);
    setType(itemType);
  };

  return (
    <div key={`${itemType.id}_${expertId}`} className={styles.treatmentItem}>
      <IonLabel
        onClick={(e) => {
          e.stopPropagation();
          setTreatmentTypeDetails(itemType.id);
        }}
        className={styles.typeLabel}
        mode="ios"
        color="primary"
      >
        {translate(itemType.name)}
      </IonLabel>
      <button
        className={styles.details}
        onClick={(e) => {
          e.stopPropagation();
          setTreatmentTypeDetails(itemType.id);
        }}
      >
        {t('about_treatment')}
      </button>
      <IonButton
        fill="clear"
        type="button"
        className={styles.selectButton}
        onClick={handleExpertSelection}
        mode="md"
      >
        <IonLabel mode="md" className="select-label">
          {t('select')}
        </IonLabel>
        <IonRadio color="tertiary" mode="md" slot="end" value={itemType.id} />
      </IonButton>
    </div>
  );
};

export default TreatmentTypesList;
