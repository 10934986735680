import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

type BaseButtonAttributes = React.ComponentPropsWithoutRef<'button'>;

interface ButtonProps extends BaseButtonAttributes {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  children: React.ReactNode;
  className?: string;
  routerLink?: string;
  shrink?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  disabled = false,
  children,
  shrink = false,
  routerLink,
  ...rest
}) => {
  const history = useHistory();

  const handleClick = (event: React.MouseEvent) => {
    if (disabled) return;
    onClick?.(event);
    if (routerLink) {
      history.push(routerLink);
    }
  };

  const className = classNames({
    [styles.customButton]: true,
    [styles.disabled]: disabled,
    [styles.shrink]: shrink,
    ...(rest.className ? { [rest.className]: true } : {}),
  });

  return (
    <button onClick={handleClick} {...rest} className={className}>
      {children}
    </button>
  );
};

export default Button;
