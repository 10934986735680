import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { loggingServiceSymbol } from 'application/services/IErrorLoggingService';
import type IService from 'application/services/IService';
import { SERVICE_IDENTIFIER } from 'infrastructure/services';
import { container } from 'inversify-props';
import React, { useEffect, useState } from 'react';

const ServiceContainer: React.FC<{children?: React.ReactNode}> = ({ children }) => {
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const loggingService =
      container.get<IErrorLoggingService>(loggingServiceSymbol);

    const services = container.getAll<IService>(SERVICE_IDENTIFIER);

    loggingService.initialize();
    void Promise.all(services.map((service) => service.initialize())).finally(
      () => {
        setInitialized(true);
      },
    );
  }, []);

  if (!initialized) {
    return <div className="service-loading">Loading ...</div>;
  }

  return <>{children}</>;
};

export default ServiceContainer;
