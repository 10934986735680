import type { VoucherClientData } from 'application/types';
import type currency from 'currency.js';
import React, { useCallback, useState } from 'react';

interface VoucherContextState {
  voucherValue?: currency;
  voucherAmount?: number;
  message: string;
  clientData?: VoucherClientData;
}

interface VoucherContextValues extends VoucherContextState {
  setVoucherValue: (newVoucherValue: currency) => void;
  setVoucherAmount: (newVoucherAmount: number) => void;
  setMessage: (newVoucherMessage: string) => void;
  setClientData: (newClientData: VoucherClientData) => void;
  clearState: () => void;
}

const voucherContextDefaultState: VoucherContextValues = {
  message: '',
  setClientData: () => {},
  setVoucherAmount: () => {},
  setMessage: () => {},
  setVoucherValue: () => {},
  clearState: () => {},
};

const VoucherContext = React.createContext<VoucherContextValues>(
  voucherContextDefaultState,
);

export const useVoucherContext = (): VoucherContextValues =>
  React.useContext(VoucherContext);

export const VoucherContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [voucherValue, setVoucherValue] = useState<currency | undefined>();
  const [voucherAmount, setVoucherAmount] = useState<number | undefined>();
  const [message, setMessage] = useState<string>('');
  const [clientData, setClientData] = useState<VoucherClientData | undefined>();

  const clearState = useCallback(() => {
    setVoucherValue(undefined);
    setVoucherAmount(undefined);
    setMessage('');
    setClientData(undefined);
  }, []);
  return (
    <VoucherContext.Provider
      value={{
        voucherValue,
        voucherAmount,
        message,
        clientData,
        setClientData,
        setVoucherValue,
        setVoucherAmount,
        setMessage,
        clearState,
      }}
    >
      {children}
    </VoucherContext.Provider>
  );
};
