import { gql } from '@apollo/client';
import type {
  BookingRequestData,
  HotelBookingRequestData,
} from 'application/pages/Treatments/BookingRequest/IBookingRequestAdapter';
import type IBookingRequestAdapter from 'application/pages/Treatments/BookingRequest/IBookingRequestAdapter';
import client from 'infrastructure/apollo';

import type { Address } from '../../../application/types';

const BOOKING_REQUEST = gql`
  mutation SendBookingRequest(
    $date: Date!
    $clientData: BookingRequestClientData!
    $length: Int!
    $address: BookingAddressParams!
    $treatmentTypeId: ID!
  ) {
    sendBookingRequest(
      date: $date
      length: $length
      clientData: $clientData
      address: $address
      treatmentTypeId: $treatmentTypeId
    )
  }
`;

const HOTEL_BOOKING_REQUEST = gql`
  mutation SendHotelBookingRequest(
    $date: Date!
    $guestFirstname: String!
    $guestSurname: String!
    $hotelName: String!
    $hotelRequestId: ID!
    $notes: String!
    $numberOfExperts: Int!
    $receptionEmail: String!
    $receptionistName: String!
    $roomNumber: String!
    $therapistGender: String!
    $token: String!
    $treatmentType: String!
    $address: AddressInput!
  ) {
    sendHotelBookingRequest(
      input: {
        date: $date
        guestFirstname: $guestFirstname
        guestSurname: $guestSurname
        hotelName: $hotelName
        hotelRequestId: $hotelRequestId
        notes: $notes
        numberOfExperts: $numberOfExperts
        receptionEmail: $receptionEmail
        receptionistName: $receptionistName
        roomNumber: $roomNumber
        therapistGender: $therapistGender
        token: $token
        treatmentType: $treatmentType
        address: $address
      }
    )
  }
`;

const BookingRequestAdapter: IBookingRequestAdapter = {
  async sendBookingRequest(input: BookingRequestData): Promise<void> {
    await client.mutate({
      mutation: BOOKING_REQUEST,
      variables: {
        clientData: {
          name: input.name,
          surname: input.surname,
          phoneNumber: input?.phoneNumber || null,
          email: input.email,
        },
        date: input.date.date,
        length: input.length,
        treatmentTypeId: input.treatmentTypeId,
        address: {
          locality: input.address.locality,
          postalCode: input.address.postalCode,
          street: input.address.street,
          streetNumber: input.address.streetNumber,
        },
      },
    });
  },

  async sendHotelBookingRequest(
    input: HotelBookingRequestData & Address,
  ): Promise<void> {
    await client.mutate({
      mutation: HOTEL_BOOKING_REQUEST,
      variables: {
        date: input.date,
        guestFirstname: input.guestFirstname,
        guestSurname: input.guestSurname,
        hotelName: input.hotelName,
        hotelRequestId: input.hotelRequestId,
        notes: input.notes,
        numberOfExperts: input.numberOfExperts,
        receptionEmail: input.receptionEmail,
        receptionistName: input.receptionistName,
        roomNumber: input.roomNumber,
        therapistGender: input.therapistGender,
        token: input.token,
        treatmentType: input.treatmentType,
        address: {
          street: input.street,
          streetNumber: input.streetNumber,
          locality: input.locality,
          postalCode: input.postalCode,
        },
      },
    });
  },
};

export default BookingRequestAdapter;
