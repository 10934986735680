import { gql } from '@apollo/client';
import type IPasswordResetAdapter from 'application/pages/PasswordReset/IPasswordResetAdapter';
import {
  ExpiredCodeError,
  InvalidCodeError,
  InvalidEmailError,
} from 'application/pages/PasswordReset/IPasswordResetAdapter';
import client from 'infrastructure/apollo';
import { getGraphQLErrorMessages } from 'infrastructure/utils';

const SEND_PASSWORD_RESET_CODE_MUTATION = gql`
  mutation SendPasswordResteCode($email: String!) {
    sendPasswordResetCode(email: $email, role: client)
  }
`;

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!, $code: String!) {
    resetPassword(email: $email, code: $code, role: client)
  }
`;

const INVALID_EMAIL_ERROR = 'user_not_found';
const INVALID_CODE_ERROR = 'code_invalid';
const EXPIRED_CODE_ERROR = 'code_expired';

const GraphQLPasswordResetAdapter: IPasswordResetAdapter = {
  async resetPassword(email: string, code: string): Promise<void> {
    try {
      await client.mutate({
        mutation: RESET_PASSWORD_MUTATION,
        variables: {
          email,
          code,
        },
      });
    } catch (error) {
      const messages = getGraphQLErrorMessages(error);
      if (messages?.includes(INVALID_EMAIL_ERROR)) {
        throw new InvalidEmailError();
      }
      if (messages?.includes(INVALID_CODE_ERROR)) {
        throw new InvalidCodeError();
      }
      if (messages?.includes(EXPIRED_CODE_ERROR)) {
        throw new ExpiredCodeError();
      }
      throw error;
    }
  },

  async sendPasswordResetCode(email: string): Promise<void> {
    try {
      await client.mutate({
        mutation: SEND_PASSWORD_RESET_CODE_MUTATION,
        variables: {
          email,
        },
      });
    } catch (error) {
      const messages = getGraphQLErrorMessages(error);
      if (messages?.includes(INVALID_EMAIL_ERROR)) {
        throw new InvalidEmailError();
      }
      throw error;
    }
  },
};

export default GraphQLPasswordResetAdapter;
