import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateModal from 'application/pages/Treatments/DateModal';
import type { TreatmentDate } from 'application/types';
import * as React from 'react';
import DateFormatter from 'ui/elements/DateFormatter';
import { useContextTranslation } from 'ui/translation';

interface TreatmentDateSelectorProps {
  onSelect: (date: TreatmentDate) => void;
  date: TreatmentDate | null;
  disabled?: boolean;
  withoutAvailabilities?: boolean;
  isSpa?: boolean;
  showSlot?: boolean;
}

const TreatmentDateSelector: React.FC<TreatmentDateSelectorProps> = ({
  onSelect,
  date,
  disabled = false,
  withoutAvailabilities = false,
  isSpa = false,
  showSlot = false,
}) => {
  const t = useContextTranslation('page.treatment');
  const [modalOpen, setModalOpen] = React.useState<boolean | null>(null);
  return (
    <section
      className={`button-selector treatment-date${disabled ? ' disabled' : ''}`}
    >
      <button
        type="button"
        className={`expand-button${date ? ' selected' : ''}`}
        onClick={() => {
          if (disabled) {
            return;
          }
          setModalOpen(true);
        }}
      >
        <div className="icon-container">
          <FontAwesomeIcon icon={faCalendar} />
        </div>
        <div className="text-container">
          {date ? (
            <DateFormatter showSlot={showSlot} date={date} />
          ) : (
            t('treatment_date_select')
          )}
        </div>
      </button>
      <DateModal
        open={!!modalOpen}
        onClose={() => setModalOpen(false)}
        onSelect={(selectedDate) => {
          setModalOpen(false);
          onSelect(selectedDate);
        }}
        selectedDate={date}
        withoutAvailabilities={withoutAvailabilities}
        isSpa={isSpa}
      />
    </section>
  );
};

export default TreatmentDateSelector;
