/* eslint-disable react/jsx-props-no-spreading */
// a hack component for making Chrome autocomplete work with Formik+React forms
// to work properly in useFormik object requires:
// validateOnBlur: false,
// validateOnChange: false,

import { useFormikContext } from 'formik';
import * as React from 'react';

export type FormikFormProps = Pick<
  React.FormHTMLAttributes<HTMLFormElement>,
  Exclude<
    keyof React.FormHTMLAttributes<HTMLFormElement>,
    'onReset' | 'onSubmit'
  >
>;

type FormProps = React.ComponentPropsWithoutRef<'form'>;

const AutocompleteForm: React.FC<FormProps> = (props: FormikFormProps) => {
  // iOS needs an "action" attribute for nice input: https://stackoverflow.com/a/39485162/406725
  // We default the action to "#" in case the preventDefault fails (just updates the URL hash)
  const { action, ...rest } = props;
  const { handleReset, handleSubmit, values, setValues } = useFormikContext<{
    [key: string]: string;
  }>();
  const ref = React.useRef<HTMLFormElement>(null);

  const handleSubmitProxy: (e?: React.FormEvent<HTMLFormElement>) => void = (
    e,
  ) => {
    // check native input values
    if (ref.current) {
      const form: HTMLFormElement = ref.current;
      const inputs = form.querySelectorAll('input');
      const prefilledValues: { [key: string]: string } = {};
      inputs.forEach((input) => {
        const fieldName = input.getAttribute('name');
        const fieldValue = input.value;
        if (!fieldName) {
          return;
        }
        if (
          typeof values[fieldName] === 'string' &&
          values[fieldName] !== fieldValue
        ) {
          prefilledValues[fieldName] = fieldValue;
        }
      });
      if (Object.keys(prefilledValues).length > 0) {
        void setValues(
          {
            ...values,
            ...prefilledValues,
          },
          true,
        );
      }
    }

    e?.preventDefault();
    handleSubmit(e);
  };
  return (
    <form
      onSubmit={handleSubmitProxy}
      ref={ref}
      onReset={handleReset}
      action={action ?? '#'}
      {...rest}
    />
  );
};

export default AutocompleteForm;
