export default {
  apiUrl:
    process.env.REACT_APP_API_URL || 'https://api.testing.soulhouse.spinney.io',
  googleApiKey:
    process.env.REACT_APP_GOOGLE_API_KEY ||
    'AIzaSyCQ0GO8ORyRqAU1sDtXofbTIcByzOFkUR8',
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
  appleClientId: process.env.REACT_APP_APPLE_CLIENT_ID,
  appleRedirectUri: process.env.REACT_APP_APPLE_REDIRECT_URI,
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  universalLinkPrefix:
    process.env.REACT_APP_UNIVERSAL_LINK_PREFIX ||
    'https://testing.soulhouse.spinney.io',
  googlePayUseTesting:
    Number(process.env.REACT_APP_GOOGLE_PAY_USE_TESTING) === 1,
  googlePayLocation: process.env.REACT_APP_GOOGLE_PAY_LOCATION || 'DE',
  applePayMerchantId: process.env.REACT_APP_APPLE_PAY_MERCHANT_ID,
  customerioSiteId: process.env.REACT_APP_CUSTOMERIO_SITE_ID || '',
  customerioApiKey: process.env.REACT_APP_CUSTOMERIO_API_KEY || '',
  paypalClientId: String(process.env.REACT_APP_PAYPAL_CLIENT_ID),
};
