import './customModal.scss';

import { isPlatform } from '@ionic/react';
import * as React from 'react';
import type { ReactNode } from 'react';
import Modal from 'react-modal';

interface CustomModalProps {
  isOpen: boolean;
  onDidDismiss?: (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => void;
  cssClass?: string;
  overlayCssClass?: string;
  backdropDismiss?: boolean;
  children: ReactNode;
}

Modal.setAppElement('#root');

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onDidDismiss,
  cssClass,
  overlayCssClass,
  backdropDismiss = true,
  children,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onDidDismiss}
    className={`custom-modal ${cssClass || ''}`}
    overlayClassName={`custom-modal-overlay ${overlayCssClass || ''}`}
    shouldCloseOnOverlayClick={backdropDismiss}
    shouldCloseOnEsc={backdropDismiss}
    closeTimeoutMS={isPlatform('mobile') ? 250 : 0}
  >
    <div className={`modal-wrapper${isPlatform('mobile') ? ' mobile' : ''} `}>
      {children}
    </div>
  </Modal>
);

export default CustomModal;
