import { IonCol, IonSpinner } from '@ionic/react';
import injectables from 'application/pages/injectables';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { TreatmentTimeRange } from 'application/types';
import { isSameMonth, isSameYear } from 'date-fns';
import { useInject } from 'inversify-hooks';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useContextTranslation } from 'ui/translation';

import type { ITreatmentAdapter } from '../ITreatmentAdapter';
import CalendarWithDynamicHighlights from './CalendarWithDynamicHighlights';
import { useDateRanges } from './dateModalUtils';
import TimeRangeSelector from './TimeRangeSelector';
import { assistedStretchType } from '../../AssistedStretch/AssistedStretchAtoms';
import { useAtomValue } from 'jotai';

const DynamicHighlightCalendarWrapper = ({
  date,
  setDate,
  timeRange,
  setTimeRange,
}: {
  date?: Date;
  timeRange?: TreatmentTimeRange;
  setDate: Dispatch<SetStateAction<Date | undefined>>;
  setTimeRange: Dispatch<SetStateAction<TreatmentTimeRange | undefined>>;
}): JSX.Element => {
  const t = useContextTranslation('page.treatment.date');
  const [calendarViewDate, setCalendarViewDate] = useState(date || new Date());

  useEffect(() => {
    if (
      date &&
      (!isSameMonth(date, calendarViewDate) ||
        !isSameYear(date, calendarViewDate))
    ) {
      setCalendarViewDate(date);
    }
  }, [date]);

  const [service] = useInject<ITreatmentAdapter>(injectables.TreatmentAdapter);
  const { location, locationId, isSpaBooking, variant } = useTreatmentBuilder();
  const dateRanges = useDateRanges(calendarViewDate);
  const type = useAtomValue(assistedStretchType);

  /// TODO refactor
  const getVariant = () => {
    if(isSpaBooking) {
      return 'spa'
    }
    if(variant === 'stretch') {
      return 'stretch'
    }
    return 'normal'
  }

  const availabilityService = service.useCurrentlyAvailableBookings({
    dateRange: {
      fromDate: dateRanges.start,
      toDate: dateRanges.end,
    },
    type: null,
    location,
    locationId,
    spaAvailabilities: Boolean(isSpaBooking),
    treatmentTypeId: (variant === 'stretch' && type) ? type.id : null,
    treatmentTypeVariant: getVariant()
  });
  const availableBookings = availabilityService?.value;

  return (
    <>
      {availabilityService.loading || !availableBookings ? (
        <IonCol>
          <IonSpinner color="secondary" name="dots" />
        </IonCol>
      ) : (
        <>
          <IonCol size="12" className="calendar-wrapper">
            <h4 className="calendar-info">{t('available_days_info')}</h4>
            <CalendarWithDynamicHighlights
              date={date}
              setDate={setDate}
              setTimeRange={setTimeRange}
              calendarViewDate={calendarViewDate}
              setCalendarViewDate={setCalendarViewDate}
              availableBookings={availableBookings}
            />
          </IonCol>
          <IonCol size="12">
            <h4>{t('time_period_header')}</h4>
            <TimeRangeSelector
              date={date}
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              availableBookings={availableBookings}
            />
          </IonCol>
        </>
      )}
    </>
  );
};

export default DynamicHighlightCalendarWrapper;
