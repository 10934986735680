import { gql, useMutation } from '@apollo/client';
import { faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { loggingServiceSymbol } from 'application/services/IErrorLoggingService';
import type { BookingAndCartCombined } from 'application/types/mapBookingJSONToObject';
import { useInject } from 'inversify-hooks';
import { useEffect } from 'react';
import CustomModal from 'ui/elements/CustomModal/CustomModal';
import { useContextTranslation } from 'ui/translation';
import Button from 'ui/elements/Button/Button';

const REQUEST_CALL_TO_EXPERT = gql`
  mutation requestCallToExpert($bookingId: ID!, $token: String) {
    requestCallToExpert(bookingId: $bookingId, token: $token)
  }
`;

const CallButton = ({
  bookingId,
  token,
}: {
  bookingId: string;
  token: string;
}) => {
  const [loggingAdapter] =
    useInject<IErrorLoggingService>(loggingServiceSymbol);

  const [requestCallToExpert, { data }] = useMutation<{
    requestCallToExpert: string;
  }>(REQUEST_CALL_TO_EXPERT, {
    variables: {
      bookingId,
      token,
    },
  });

  useEffect(() => {
    requestCallToExpert().catch((e) => {
      loggingAdapter.traceException(e);
    });
  }, [requestCallToExpert, loggingAdapter]);

  return data?.requestCallToExpert ? (
    <div className="divider">
      <Button>
        <a href={`tel:${data.requestCallToExpert}`}>
          {data.requestCallToExpert}
        </a>
      </Button>
    </div>
  ) : (
    <IonSpinner color="secondary" />
  );
};

const RequestCallToExpert = ({
  isOpen,
  setModalOpen,
  cart,
}: {
  isOpen: boolean;
  setModalOpen: (state: boolean) => void;
  cart: BookingAndCartCombined;
}): JSX.Element => {
  const mt = useContextTranslation('misc');
  const t = useContextTranslation('page.my_bookings');

  return (
    <CustomModal
      backdropDismiss={false}
      isOpen={isOpen}
      cssClass="booking-modal booking-details-modal"
    >
      <IonContent>
        <button
          type="button"
          aria-label={mt('close')}
          className="close-button"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <FontAwesomeIcon className="header-icon" icon={faPhone} />
              <h3 className="message">{t('contact_expert')}</h3>
            </IonCol>
          </IonRow>
          {cart.bookings?.map((booking) => (
            <IonRow key={booking.id}>
              <IonCol size="12">
                <p>
                  <b>{t('call_expert', { name: booking.expert?.name })}</b>
                </p>
                <CallButton bookingId={booking.id} token={cart.token} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
      <IonFooter>
        <div className="content">
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
            color="primary"
          >
            {mt('close')}
          </Button>
        </div>
      </IonFooter>
    </CustomModal>
  );
};

export default RequestCallToExpert;
