import './index.scss';

import { IonContent, IonHeader, IonPage } from '@ionic/react';
import * as React from 'react';
import Header from 'ui/layout/Header/Header';

interface MainLayoutProps {
  className?: string;
  beforeContent?: React.ReactNode;
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  className,
  beforeContent,
}) => {
  const headerRef = React.useRef<HTMLIonHeaderElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <IonPage className={`main-layout${className ? ` ${className}` : ''}`}>
      <IonHeader mode="md" translucent ref={headerRef}>
        <Header />
      </IonHeader>
      <IonContent
        forceOverscroll={false}
        scrollEvents
        onIonScroll={(event) => {
          if (!headerRef.current || !scrollContainerRef.current) {
            return;
          }
          if (event.detail.scrollTop > 20) {
            headerRef.current.classList.add('shadow');
            scrollContainerRef.current.classList.add('scrolled');
          } else {
            headerRef.current.classList.remove('shadow');
            scrollContainerRef.current.classList.remove('scrolled');
          }
        }}
      >
        <div slot="background" className="mobile-background" />
        <div ref={scrollContainerRef} className="scroll-container">
          <div className="before-content">{beforeContent}</div>
          <div className="content-container">{children}</div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MainLayout;
