import { IonSpinner } from '@ionic/react';
import { WITHOUT_OFFSET_TREATMENT_LENGTH } from 'application/pages/Locations/useLocations';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import { sortBy } from 'lodash';
import { useContextTranslation } from 'ui/translation';
import type { HookData, TreatmentLength } from '../../../types';
import ExpandableSelect from 'ui/elements/ExpandableSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const LengthSelectorContent = ({
  treatmentLengths,
  selectedLength,
  onSelect,
  onClick,
  expanded,
}: {
  treatmentLengths: HookData<TreatmentLength[]>;
  selectedLength: TreatmentLength | null;
  onSelect: (treatmentType: TreatmentLength) => void;
  onClick: () => void;
  expanded: boolean;
}): JSX.Element => {
  const t = useContextTranslation('page.treatment');
  const { location, isSpaBooking } = useTreatmentBuilder();

  if (treatmentLengths.loading || !treatmentLengths.value) {
    return <IonSpinner color="secondary" name="dots" />;
  }

  //TODO refactor lengthOptions
  const tls = treatmentLengths.value.map((treatmentLength) => ({
    ...treatmentLength,
    length:
      location?.isWithoutOffset && !isSpaBooking
        ? treatmentLength.length - WITHOUT_OFFSET_TREATMENT_LENGTH
        : treatmentLength.length,
  }));
  const sortedLengths = sortBy(tls, ['length']);

  const lengthOptions = sortedLengths.reduce(
    (acc, treatmentLength: TreatmentLength) => {
      return {
        ...acc,
        [treatmentLength.id]: `${t('length_minutes', {
          length: treatmentLength.length,
        })} / ${t('length_price', { price: treatmentLength.price })}`,
      };
    },
    {},
  );

  return (
    <ExpandableSelect
      placeholder={t('treatment_length_select')}
      options={lengthOptions}
      onSelectChange={(e) => {
        const selectedLength = tls.find(
          (treatmentLength) => treatmentLength.id === e.target.value,
        );
        if (selectedLength) {
          onSelect(selectedLength);
        }
      }}
      expanded={expanded}
      value={selectedLength?.id}
      onClick={onClick}
      buttonIcon={<FontAwesomeIcon icon={faClock} />}
    />
  );
};

export default LengthSelectorContent;
