import { TreatmentTimeRange } from 'application/types/index';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  getDate,
  getHours,
  getMonth,
  getYear,
  isAfter,
  max,
  set,
  setHours,
  setMinutes,
} from 'date-fns';

export interface TimeRange {
  start: Date;
  end: Date;
}

export const ZERO_MINUTES = {
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

export const END_MINUTES = {
  minutes: 59,
  seconds: 59,
  milliseconds: 999,
};
export const UTC_RANGES: { [key in TreatmentTimeRange]: TimeRange } = {
  [TreatmentTimeRange.Morning]: {
    start: set(new Date(), {
      hours: 0,
      ...ZERO_MINUTES,
    }),
    end: set(new Date(), {
      hours: 12,
      ...END_MINUTES,
    }),
  },
  [TreatmentTimeRange.Afternoon]: {
    start: set(new Date(), {
      hours: 13,
      ...ZERO_MINUTES,
    }),
    end: set(new Date(), {
      hours: 17,
      ...END_MINUTES,
    }),
  },
  [TreatmentTimeRange.Evening]: {
    start: set(new Date(), {
      hours: 18,
      ...ZERO_MINUTES,
    }),
    end: set(new Date(), {
      hours: 21,
      ...END_MINUTES,
    }),
  },
};

export function getTimeRangeForDayAndRelativeRange(
  day: Date,
  timeRange: TreatmentTimeRange,
): TimeRange {
  const base = UTC_RANGES[timeRange];
  const dayData = {
    year: getYear(day),
    month: getMonth(day),
    date: getDate(day),
  };
  const start = max([set(base.start, dayData), addHours(new Date(), 2)]);
  return {
    start: set(start, dayData),
    end: set(base.end, dayData),
  };
}

const SLOT_BUILDER_GRANULARITY_MINUTES = 15;

export function slotsBuilder(
  selectedDate: Date,
  timeRange: TreatmentTimeRange,
): Date[] {
  const targetRange = getTimeRangeForDayAndRelativeRange(
    selectedDate,
    timeRange,
  );

  if (getHours(targetRange.start) === 0) {
    targetRange.start = setHours(targetRange.start, 9);
  }
  targetRange.start = setMinutes(targetRange.start, 0);

  const rangeLength = differenceInMinutes(targetRange.end, targetRange.start);

  const slotLength = Math.floor(rangeLength / SLOT_BUILDER_GRANULARITY_MINUTES);
  const slots = Array.from({ length: slotLength }, (x, ndx) =>
    addMinutes(targetRange.start, ndx * SLOT_BUILDER_GRANULARITY_MINUTES),
  );
  const currentDate = new Date();
  return slots.filter((date: Date) => isAfter(date, currentDate));
}
