import { IonCol, IonRow, IonText } from '@ionic/react';
import type { Address } from 'application/types';
import type { FormikTouched } from 'formik';
import { Field } from 'formik';
import type { TFunction } from 'react-i18next';
import { useContextTranslation } from 'ui/translation';
import IonicField from 'ui/utils/IonicField';
import * as Yup from 'yup';

export const addressInitialValues = {
  postalCode: '',
  locality: '',
  street: '',
  streetNumber: '',
};

export const addressRequiredFields: (keyof FormikTouched<Address>)[] = [
  'postalCode',
  'locality',
  'street',
  'streetNumber',
];

export const generateAddressValidationSchema = (t: TFunction) => ({
  postalCode: Yup.string()
    .min(4, ({ min }) => t('postal_code_min_length', { min }))
    .max(10, ({ max }) => t('postal_code_max_length', { max }))
    .required(t('postal_code_required')),
  locality: Yup.string()
    .min(2, ({ min }) => t('locality_min_length', { min }))
    .max(255, ({ max }) => t('locality_max_length', { max }))
    .required(t('locality_required')),
  street: Yup.string()
    .min(2, ({ min }) => t('street_min_length', { min }))
    .max(255, ({ max }) => t('street_max_length', { max }))
    .required(t('street_required')),
  streetNumber: Yup.string()
    .min(1, ({ min }) => t('street_number_min_length', { min }))
    .max(16, ({ max }) => t('street_number_max_length', { max }))
    .required(t('street_number_required')),
});

export default function AddressForm({
  disabled = false,
}: {
  disabled?: boolean;
}): JSX.Element {
  const t = useContextTranslation('page.booking_address');

  return (
    <>
      <IonRow>
        <IonCol size="12">
          <IonText>
            <h6 className="address-header">{t('address_title')}</h6>
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="street"
            type="text"
            required
            placeholder={t('street_placeholder')}
            showErrors
            disabled={disabled}
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="streetNumber"
            type="text"
            required
            placeholder={t('street_number_placeholder')}
            showErrors
            disabled={disabled}
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="postalCode"
            type="text"
            required
            showErrors
            disabled
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="locality"
            type="text"
            required
            placeholder={t('locality_placeholder')}
            showErrors
            disabled={disabled}
          />
        </IonCol>
      </IonRow>
      <input type="submit" className="invisible-auto-submit" />
    </>
  );
}
