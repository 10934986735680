import './treatment.scss';

import {
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
} from '@ionic/react';
import ExpertProfileModal from 'application/pages/ExpertProfile/ExpertProfileModal';
import {
  TreatmentTypeDetailsModal,
} from 'application/pages/Treatments/TreatmentResult/ResultsWithAvabilities';
import type { TreatmentLocation } from 'application/types';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';
import BookingRequestForm from './BookingRequest/BookingRequestForm';
import type { AvailableExpertsQuery } from './ITreatmentAdapter';

// TODO Refactor required
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */

const RESULTS_PER_PAGE = 5;

const TreatmentResults = (
  {
    results,
    page,
    location,
    setPage,
    renderResults,
  }: {
    results: any;
    page: number;
    location: TreatmentLocation;
    timeRange: any;
    setPage: any;
    renderResults: (result: AvailableExpertsQuery) => JSX.Element;
  }): JSX.Element => {
  const mt = useContextTranslation('misc');

  const disabled = Boolean(
    results.value &&
    results.value.length < page * RESULTS_PER_PAGE &&
    !results.loading,
  );

  const infiniteScroll = React.useRef<HTMLIonInfiniteScrollElement>(null);
  const wasTriggered = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (wasTriggered.current) {
      // eslint-disable-next-line no-console
      infiniteScroll.current?.complete().catch(console.error);
      wasTriggered.current = false;
    }
  }, [results.value]);

  const expertsList = results.value || [];


  return (
    <IonRow className="ion-justify-content-center treatment-results-container">
      {expertsList.map((result: AvailableExpertsQuery) => (
        <IonCol size="12" key={result.id}>
          {renderResults(result)}
        </IonCol>
      ))}
      {expertsList.length === 0 && !results.loading && location && (
        <IonCol className="no-results no-experts" size="12">
          <BookingRequestForm address={location} />
        </IonCol>
      )}
      <div>
        <IonInfiniteScroll
          ref={infiniteScroll}
          threshold="10%"
          disabled={disabled}
          onIonInfinite={() => {
            results?.fetchMore?.({
              variables: {
                pagination: {
                  offset: RESULTS_PER_PAGE * page,
                  limit: RESULTS_PER_PAGE,
                },
              },
            });
            wasTriggered.current = true;
            setPage((p: number) => p + 1);
          }}
        >
          <IonInfiniteScrollContent loadingText={mt('loading')} />
        </IonInfiniteScroll>
      </div>
      <TreatmentTypeDetailsModal />
      <ExpertProfileModal />
    </IonRow>
  );
};

export default TreatmentResults;
