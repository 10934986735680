import type IService from 'application/services/IService';

export class NotificationsRejectedError extends Error {
  constructor() {
    super('notifications_rejected');
  }
}

export default interface INotificationService extends IService {
  getToken(): string | null;

  registerDevice(onTokenChanged: () => void): Promise<void>;

  setRouteListener(
    onNotificationRoute: (path: string, isFirst: boolean) => void,
  ): void;
}
