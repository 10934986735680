import './stripePayments.scss';

import { Stripe as StripeNative } from '@capacitor-community/stripe';
import { isPlatform } from '@ionic/react';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from 'config';
import { injectable } from 'inversify';

export const STRIPE_PROVIDER_NAME = Symbol('StripeProvider');

if (isPlatform('capacitor')) {
  if (!config.stripePublishableKey) {
    throw new Error(
      'Stripe native cant be configured without stripe publishable key',
    );
  }

  StripeNative.initialize({
    publishableKey: config.stripePublishableKey,
  }).catch((e) => {
    // eslint-disable-next-line no-console
    console.error('Could not initialize native stripe', e);
  });
}

@injectable()
class StripeProvider {
  private stripe: Stripe | null = null;

  constructor() {
    if (!config.stripePublishableKey) {
      throw new Error('Stripe not configured');
    }

    loadStripe(config.stripePublishableKey)
      .then((stripe) => {
        this.stripe = stripe;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Could not load stripe.js', error);
      });
  }

  getStripe(): Stripe {
    if (!this.stripe) {
      throw new Error('Stripe is unavailable');
    }

    return this.stripe;
  }
}

export default StripeProvider;
