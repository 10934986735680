import { IonCol, IonRow, useIonViewWillEnter } from '@ionic/react';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useContextTranslation } from 'ui/translation';

import ExpandableSelect from '../../../ui/elements/ExpandableSelect';
import type { Location, ObjectID } from '../../types';
import LocationDetailsModal from './details/LocationDetailsModal';
import LocationCard from './LocationCard';

const SoulhouseLocations = ({
  locations,
}: {
  locations: Location[];
}): JSX.Element => {

  const locationCities = locations.reduce(
    (acc, location) => ({
      ...acc,
      [location.address.locality.toLowerCase()]: location.address.locality,
    }),
    {},
  );
  const t = useContextTranslation('page.locations');
  const [expanded, setExpanded] = useState(false);
  const [cityFilter, setCityFilter] = useState<string | null>(null);
  const [locationDetailsId, setLocationDetailsId] = useState<ObjectID | null>(
    null,
  );

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const detailsId = queryParams.get('details');

  useIonViewWillEnter(() => {
    if (detailsId) {
      setLocationDetailsId(detailsId);
    }
  }, [detailsId]);

  const filterLocations = locations
    .filter(
      (location) =>
        !cityFilter || location.address.locality.toLowerCase() === cityFilter,
    )
    .sort(
      (prev, next) =>
        Number(prev.state === 'opening_soon') -
        Number(next.state === 'opening_soon'),
    );

  return (
    <section className="locations">
        <IonRow>
          <IonCol size="12">
            <h4>{t('our_locations')}</h4>
          </IonCol>

          <IonCol size="12">
            <ExpandableSelect
              options={locationCities}
              className="button-selector"
              placeholder={t('city_selector_placeholder')}
              name="citySelector"
              expanded={expanded}
              value={cityFilter}
              onClick={() => setExpanded((prev) => !prev)}
              onSelectChange={(event) => {
                setExpanded(false);
                setCityFilter(event.currentTarget.value.toLowerCase());
              }}
            />
          </IonCol>
        </IonRow>
        {filterLocations.map((location) => (
          <IonCol size="12" className="locations__list" key={location.id}>
            <LocationCard
              setLocationDetailsId={() => setLocationDetailsId(location.id)}
              location={location}
            />
          </IonCol>
        ))}
        {locationDetailsId && (
          <LocationDetailsModal
            locationId={locationDetailsId}
            resetLocation={() => setLocationDetailsId(null)}
          />
        )}
    </section>
  );
};

export default SoulhouseLocations;
