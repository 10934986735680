import { gql, useQuery } from '@apollo/client';
import { Preferences as Storage } from '@capacitor/preferences';
import type {
  MultipleBookingsParams,
  PlaceMultiBookingsResult,
  PlaceMultipleBookingsParams,
} from 'application/pages/BookingPayment/IBookingPaymentAdapter';
import type IBookingPaymentAdapter from 'application/pages/BookingPayment/IBookingPaymentAdapter';
import { BookingNotAvailableError } from 'application/pages/BookingPayment/IBookingPaymentAdapter';
import type {
  BookingPriceInfo,
  HookData,
  ObjectID,
  TreatmentClientData,
  TreatmentDetails,
  TreatmentLocation,
  DiscountStatus,
  VoucherApplianceStatus,
  ReferralApplianceStatus,
} from 'application/types';
import currency from 'currency.js';
import { parseISO } from 'date-fns';
import client from 'infrastructure/apollo';
import { getGraphQLErrorMessages } from 'infrastructure/utils';

// TODO Refactor required
/* eslint-disable @typescript-eslint/no-explicit-any */

const MULTIPLE_BOOKING_PRICE_QUERY = gql`
  query MultipleBookingPriceQuery(
    $bookings: [PlaceManyBookingsBooking!]!
    $clientEmail: String
    $discountOrVoucherCode: String
    $locationId: ID
    $postalCode: String!
    $time: Date!
    $payWithYourReferral: Boolean
  ) {
    multiBookingPriceInfo(
      bookings: $bookings
      clientEmail: $clientEmail
      discountOrVoucherCode: $discountOrVoucherCode
      locationId: $locationId
      postalCode: $postalCode
      time: $time
      payWithYourReferral: $payWithYourReferral
    ) {
      total
      totalTax
      components {
        type
        value
      }
      discountStatus
      voucherStatus
      voucherRemainingBalance
      referralStatus
    }
  }
`;

const PLACE_MULTI_BOOKINGS_MUTATION = gql`
  mutation PlaceMultiBooking($params: PlaceManyBookingsParams!) {
    placeMultiBookings(params: $params) {
      bookings {
        address {
          locality
          postalCode
          street
          streetNumber
        }
        client
        clientData {
          companyName
          email
          name
          phoneNumber
        }
        createdAt
        details {
          massageTable
          notes
          pets
        }
        expert
        length
        price {
          components {
            type
            value
          }
          total
          totalTax
        }
        status
        time
        treatmentType
        updatedAt
      }
      customId
      shoppingCartId
      token
    }
  }
`;

interface BookingPriceInfoResult {
  total: string;
  totalTax: string;
  components: { type: 'atHome' | 'treatment' | 'discount'; value: string }[];
  discountStatus?: DiscountStatus;
  voucherStatus?: VoucherApplianceStatus;
  referralStatus?: ReferralApplianceStatus;
  voucherRemainingBalance?: string;
}

interface BookingPriceQueryResult {
  multiBookingPriceInfo: BookingPriceInfoResult;
}

interface PlaceManyBookingsMutationResult {
  placeMultiBookings: {
    bookings: {
      id: ObjectID;
      price: BookingPriceInfoResult;
      address: TreatmentLocation;
      client?: ObjectID;
      clientData: TreatmentClientData;
      customId: ObjectID;
      createdAt: string;
      details: TreatmentDetails;
      expert: ObjectID;
      length: number;
      status: string;
      time: string;
      token: string;
      treatmentType: ObjectID;
      updatedAt: string;
    }[];
    shoppingCartId: string;
    customId: string;
    token: string;
  };
}

const BOOKING_NOT_AVAILABLE_ERROR = 'not_available';
const REFERRAL_CODE = 'referral_Code';
const CUSTOM_TAG = 'customTagTracking';

const BookingPaymentAdapter: IBookingPaymentAdapter = {
  async placeBooking(
    params: PlaceMultipleBookingsParams,
  ): Promise<PlaceMultiBookingsResult> {
    const { address } = params;
    const storedTag = await Storage.get({
      key: CUSTOM_TAG,
    });
    const customTag = storedTag.value;

    await Storage.remove({
      key: CUSTOM_TAG,
    });
    await Storage.remove({
      key: REFERRAL_CODE,
    });

    try {
      const result = await client.mutate<PlaceManyBookingsMutationResult>({
        mutation: PLACE_MULTI_BOOKINGS_MUTATION,
        variables: {
          params: {
            marketingEmailsAccepted: params.marketingEmailsAccepted,
            discountOrVoucherCode: params.discountOrVoucherCode,
            locationId: params.locationId,
            time: params.time,
            customTag,
            bookings: params.experts
              ? params.experts.map((expert) => ({
                  expertId: expert.expertId,
                  treatmentLength: params.treatmentLength,
                  treatmentTypeId: expert.type?.id,
                }))
              : {
                  treatmentLength: params.treatmentLength,
                  treatmentTypeId: params.treatmentTypeId,
                },
            details: {
              massageTable: Number(params.details.massageTable),
              notes: params.details.notes,
            },
            clientData: params.clientData && {
              ...params.clientData,
            },
            address: {
              locality: address.locality,
              postalCode: address.postalCode,
              street: address.street,
              streetNumber: address.streetNumber,
            },
            place: params.details.place,
            payWithYourReferral: params.payWithYourReferral,
          },
        },
      });

      if (!result.data) {
        throw new Error('no_response');
      }

      return {
        customId: result.data.placeMultiBookings.customId,
        token: result.data.placeMultiBookings.token,
        shoppingCartId: result.data.placeMultiBookings.shoppingCartId,
        totalPrice: result.data.placeMultiBookings.bookings.reduce(
          (acc, booking) => currency(booking.price.total).add(acc),
          currency(0),
        ),

        bookings: result.data.placeMultiBookings.bookings.map((booking) => ({
          ...booking,
          price: {
            total: currency(booking.price.total),
            totalTax: currency(booking.price.totalTax),
            components: booking.price.components.map((component) => ({
              type: component.type as any,
              value: currency(component.value) as any,
            })),
          },
          clientData: booking.clientData,
          createdAt: parseISO(booking.createdAt),
          updatedAt: parseISO(booking.updatedAt),
          time: parseISO(booking.time),
          hasReview: false,
          status: 'placed' as const,
        })),
      };
    } catch (error) {
      const messages = getGraphQLErrorMessages(error);
      if (messages) {
        if (messages.includes(BOOKING_NOT_AVAILABLE_ERROR)) {
          throw new BookingNotAvailableError();
        }
        throw new Error(messages.join(', '));
      }
      throw error;
    }
  },

  useBookingPriceInfo(
    params: MultipleBookingsParams,
  ): HookData<BookingPriceInfo> {
    const query = useQuery<BookingPriceQueryResult>(
      MULTIPLE_BOOKING_PRICE_QUERY,
      {
        variables: {
          bookings: params.experts
            ? params.experts.map((expert) => ({
                expertId: expert.expertId,
                treatmentLength: params.treatmentLength,
                treatmentTypeId: expert.type?.id,
              }))
            : {
                treatmentLength: params.treatmentLength,
                treatmentTypeId: params.treatmentType,
              },
          clientEmail: params.clientEmail,
          discountOrVoucherCode: params.discountOrVoucherCode,
          locationId: params.locationId,
          postalCode: params.postalCode,
          time: params.time,
          payWithYourReferral: params.payWithYourReferral,
        },
        fetchPolicy: 'network-only' as const,
      },
    );

    const value: BookingPriceInfo | null = query.data
      ? {
          total: currency(query.data.multiBookingPriceInfo.total),
          totalTax: currency(query.data.multiBookingPriceInfo.totalTax),
          components: query.data.multiBookingPriceInfo.components.map((c) => ({
            type: c.type,
            value: currency(c.value),
          })),
          discountStatus: query.data.multiBookingPriceInfo.discountStatus,
          voucherStatus: query.data.multiBookingPriceInfo.voucherStatus,
          referralStatus: query.data.multiBookingPriceInfo.referralStatus,
          voucherRemainingBalance: query.data.multiBookingPriceInfo
            .voucherRemainingBalance
            ? currency(query.data.multiBookingPriceInfo.voucherRemainingBalance)
            : undefined,
        }
      : null;

    return {
      loading: query.loading,
      error: query.error,
      value,
    };
  },
};

export default BookingPaymentAdapter;
