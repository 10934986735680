import './bookingSummaryFooter.scss';

import { isPlatform } from '@ionic/react';
import type { TreatmentDate, TreatmentLength } from 'application/types';
import * as React from 'react';
import DateFormatter from 'ui/elements/DateFormatter';
import { useContextTranslation } from 'ui/translation';
import formatLocalizedDate from 'ui/utils/formatLocalizedDate';

import type { TreatmentExpert } from '../../application/state/TreatmentContext';
import Button from './Button/Button';

interface BookingSummaryFooterProps {
  expert?: TreatmentExpert;
  slotTime?: Date;
  date?: TreatmentDate;
  length?: TreatmentLength;
  nextEnabled?: boolean;
  price?: string;
  onNext: () => void;
  nextText?: string;
  confirmButton?: boolean;
}

const BookingSummaryFooter: React.FC<BookingSummaryFooterProps> = ({
  slotTime,
  expert,
  length,
  date,
  onNext,
  nextEnabled = true,
  nextText = 'next',
  price,
  confirmButton = false,
}) => {
  const t = useContextTranslation('misc.booking_summary');

  const timeSummary =
    slotTime && formatLocalizedDate(slotTime, 'cccccc, dd.MM');

  return (
    <div className="booking-summary-footer">
      {expert || date || price ? (
        <div className="text">
          {expert && (
            <>
              {date && <DateFormatter date={date} />}
              {t('time_format', { time: timeSummary })}
              <br />
              {length
                ? `${t('summary_format', {
                    name: expert.name,
                    length: length.length,
                  })} 
                  ${price ? `, ${price} €` : ''}`
                : expert.name}
            </>
          )}
        </div>
      ) : (
        (!confirmButton || !isPlatform('capacitor')) && <div className="text" />
      )}
      <div className="next-container">
        <Button color="primary" disabled={!nextEnabled} onClick={onNext}>
          {t(nextText)}
        </Button>
      </div>
    </div>
  );
};

export default BookingSummaryFooter;
