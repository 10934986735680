import { IonCol, IonText } from '@ionic/react';
import { useInject } from 'inversify-hooks';
import * as React from 'react';

import { useContextTranslation } from '../../../../ui/translation';
import injectables from '../../../injectables';
import type { ITreatmentAdapter } from '../ITreatmentAdapter';
import TreatmentResults from '../TreatmentResults';
import {
  TreatmentDate,
  TreatmentLength,
  TreatmentLocation,
} from '../../../types';
import ResultsWithAvailabilities from '../TreatmentResult/ResultsWithAvabilities';

const RESULTS_PER_PAGE = 5;

type SearchNextExpertProps = {
  params: {
    date: TreatmentDate | null;
    length: TreatmentLength | null;
    location: TreatmentLocation;
    numberOfExperts: number | null;
    expertsIds: string[];
    locationId: string | null;
  };
};

const SearchNextExpert = ({
  params: { location, date, expertsIds, locationId },
}: SearchNextExpertProps): JSX.Element => {
  const [adapter] = useInject<ITreatmentAdapter>(
    injectables.pages.TreatmentAdapter,
  );
  const [page, setPage] = React.useState<number>(1);
  const t = useContextTranslation('page.next_expert');

  const resultsExperts = adapter.useAvailableExpertsQuery({
    locationId,
    postalCode: location.postalCode,
    date: date || null,
    pagination: {
      offset: 0,
      limit: RESULTS_PER_PAGE,
    },
    selectedExpertIds: expertsIds,
  });

  const expertsList = resultsExperts.value || [];

  return (
    <>
      {expertsList.length !== 0 && (
        <IonCol size="12" className="treatment-header-wrapper">
          <IonText>
            <h5 className="treatment-header">{t('heading')}</h5>
          </IonText>
        </IonCol>
      )}

      <TreatmentResults
        location={location}
        results={resultsExperts}
        page={page}
        setPage={setPage}
        timeRange={date?.timeRange}
        renderResults={(result) => (
          <ResultsWithAvailabilities
            result={result}
          />
        )}
      />
    </>
  );
};

export default SearchNextExpert;
