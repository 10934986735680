import './style.scss';
import * as React from 'react';
import FullscreenLayout from 'ui/layout/FullscreenLayout';
import { Swiper, SwiperSlide } from 'ui/elements/Swiper/Swiper';
import { useContextTranslation } from 'ui/translation';
import { useEffect } from 'react';
import { isPlatform } from '@ionic/react';
import { useInject } from 'inversify-hooks';
import type IOnboardingService from 'application/services/IOnboardingService';
import injectables from 'application/injectables';
import { useHistory } from 'react-router';
import type { SwiperContainer } from 'swiper/swiper-element';
import Button from '../../../ui/elements/Button/Button';
import { ReactComponent as Logo } from './images/logo.svg';

const OnboardingPage: React.FC = () => {
  const history = useHistory();
  const t = useContextTranslation('page.onboarding');
  const slidesRef = React.useRef<SwiperContainer>(null);

  const [onboarding] = useInject<IOnboardingService>(
    injectables.services.OnboardingService,
  );

  useEffect(() => {
    if (!onboarding.shouldRedirectToOnboarding() || !isPlatform('mobile')) {
      history.replace('/home');
    }
  }, []);

  const onNextClick = () => {
    slidesRef.current?.swiper.slideNext();
  };

  const onFinish = () => {
    void onboarding.setOnboardingViewed().finally(() => {
      history.replace('/home', { direction: 'forward' });
    });
  };

  return (
    <FullscreenLayout>
      <Swiper
        className="onboarding-slider"
        pagination
        slidesPerView={1}
        ref={slidesRef}
      >
        <SwiperSlide className="slide step-1">
          <div className="slide-content">
            <Logo className="logo" />
            <h2 className="content">{t('step1.title')}</h2>
            <Button className="slide-btn" onClick={onNextClick}>
              {t('next')}
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide step-2">
          <div className="slide-content">
            <Logo className="logo" />
            <h2 className="content">{t('step2.title')}</h2>
            <Button className="slide-btn" onClick={onNextClick}>
              {t('next')}
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide step-3">
          <div className="slide-content">
            <Logo className="logo" />
            <h2 className="content">{t('step3.title')}</h2>
            <Button className="slide-btn" onClick={onFinish}>
              {t('get_started')}
            </Button>
          </div>
        </SwiperSlide>
      </Swiper>
    </FullscreenLayout>
  );
};

export default OnboardingPage;
