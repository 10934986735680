import type currency from 'currency.js';

const format: currency.Format = (c) => {
  if (!c) {
    return '?';
  }
  const cents = Math.abs(c.cents());
  const centsPart = cents !== 0 ? `,${cents.toString().padStart(2, '0')}` : '';

  return c ? `${c.dollars()}${centsPart} €` : 'NaN';
};

export default format;
