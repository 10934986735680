import type {
  Booking,
  BookingPriceInfo,
  HookData,
  ObjectID,
  TreatmentClientData,
  TreatmentDetails,
  TreatmentLocation,
} from 'application/types';
import type currency from 'currency.js';

import type { TreatmentExpert } from '../../state/TreatmentContext';

export interface MultipleBookingsParams {
  experts: TreatmentExpert[] | null;
  treatmentLength: number;
  treatmentType?: ObjectID;
  postalCode: string;
  time: Date;
  discountOrVoucherCode?: string;
  clientEmail?: string;
  locationId?: ObjectID;
  payWithYourReferral: boolean;
}

export interface PlaceMultipleBookingsParams {
  experts: TreatmentExpert[] | null;
  treatmentTypeId?: ObjectID;
  treatmentLength: number;
  time: Date;
  discountOrVoucherCode?: string;
  clientEmail?: string;
  clientData?: TreatmentClientData;
  address: TreatmentLocation;
  details: TreatmentDetails;
  marketingEmailsAccepted: boolean;
  locationId?: ObjectID;
  payWithYourReferral: boolean;
}

export interface PlaceMultiBookingsResult {
  bookings: Booking[];
  customId: ObjectID;
  totalPrice: currency;
  shoppingCartId: ObjectID;
  token: ObjectID;
}

export class BookingNotAvailableError extends Error {
  constructor() {
    super('not_available');
  }
}

export default interface IBookingPaymentAdapter {
  useBookingPriceInfo(
    params: MultipleBookingsParams,
  ): HookData<BookingPriceInfo>;

  placeBooking(
    params: PlaceMultipleBookingsParams,
  ): Promise<PlaceMultiBookingsResult>;
}
