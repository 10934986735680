import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonLoading,
  IonRadio,
  IonRadioGroup,
  IonRow,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import injectables from 'application/injectables';
import BillSummary from 'application/pages/BookingPayment/BillSummary';
import DiscountCodeField from 'application/pages/BookingPayment/DiscountCodeField';
import { WITHOUT_OFFSET_TREATMENT_LENGTH } from 'application/pages/Locations/useLocations';
import { useGetReferralWalletBalance } from 'application/pages/Referral/Referral';
import { useAuth } from 'application/state/AuthProvider';
import type {
  ObjectID,
  TreatmentClientData,
  TreatmentDetails,
  TreatmentLength,
  TreatmentLocation,
} from 'application/types';
import {
  DiscountStatus,
  ReferralApplianceStatus,
  VoucherApplianceStatus,
} from 'application/types';
import currency from 'currency.js';
import { getMobilePlatform } from 'infrastructure/utils';
import { useInject } from 'inversify-hooks';
import { container } from 'inversify-props';
import * as React from 'react';
import { useImperativeHandle } from 'react';
import HeaderProfilePicture from 'ui/layout/SubPageLayout/HeaderProfilePicture';
import { useContextTranslation } from 'ui/translation';

import type { TreatmentExpert } from '../../state/TreatmentContext';
import type IBookingPaymentAdapter from './IBookingPaymentAdapter';
import type { PlaceMultiBookingsResult } from './IBookingPaymentAdapter';
import { BookingNotAvailableError } from './IBookingPaymentAdapter';
import type IPaymentProvider from './IPaymentProvider';

interface PlaceBookingTreatmentParams {
  experts: TreatmentExpert[] | null;
  slotTime: Date;
  length: TreatmentLength;
  location: TreatmentLocation;
  details: TreatmentDetails;
  clientData?: TreatmentClientData;
  locationId?: ObjectID;
  type?: ObjectID;
  isWithoutOffset?: boolean;
}

interface PlaceBookingProps {
  treatment: PlaceBookingTreatmentParams;
  onPlacementAvailable: (available: boolean) => void;
  onBookingPlaced: (
    placeBookingResult: PlaceMultiBookingsResult,
    paymentMethod?: string,
  ) => void;
}

export type PlaceBookingHandle = {
  placeBooking: () => void;
};

// TODO
// eslint-disable-next-line react/display-name
const PlaceBooking = React.forwardRef<PlaceBookingHandle, PlaceBookingProps>(
  ({ treatment, onPlacementAvailable, onBookingPlaced }, ref) => {
    const t = useContextTranslation('page.booking_payment');
    const [showAlert] = useIonAlert();

    const {
      length,
      experts,
      slotTime,
      location,
      clientData,
      details,
      locationId,
      type,
    } = treatment;
    const [adapter] = useInject<IBookingPaymentAdapter>(
      injectables.pages.BookingPaymentAdapter,
    );

    const { value: referralData } = useGetReferralWalletBalance();

    const paymentProviders = container.getAll<IPaymentProvider>(
      injectables.pages.PaymentProvider,
    );
    paymentProviders.sort(
      (
        paymentMethodFirst: IPaymentProvider,
        paymentMethodSecond: IPaymentProvider,
      ) => paymentMethodFirst.weight - paymentMethodSecond.weight,
    );
    const [bookingPaymentAdapter] = useInject<IBookingPaymentAdapter>(
      injectables.pages.BookingPaymentAdapter,
    );

    const { isAuthenticated } = useAuth();
    const [paymentMethod, setPaymentMethod] = React.useState<
      string | undefined
    >();
    const [tocAccepted, setTocAccepted] = React.useState<boolean>(false);
    const [payWithReferral, setPayWithReferral] =
      React.useState<boolean>(false);
    const [marketingEmailsAccepted, setMarketingEmailsAccepted] =
      React.useState<boolean>(false);
    const [placementInProgress, setPlacementInProgress] =
      React.useState<boolean>(false);
    const [discountOrVoucherCode, setDiscountOrVoucherCode] = React.useState<
      string | undefined
    >();

    useIonViewWillEnter(() => {
      setTocAccepted(false);
      setMarketingEmailsAccepted(false);
      setPaymentMethod(undefined);
      setPlacementInProgress(false);
      setDiscountOrVoucherCode(undefined);
      onPlacementAvailable(false);
    }, []);

    const bill = adapter.useBookingPriceInfo({
      experts,
      treatmentType: type,
      time: slotTime,
      postalCode: location.postalCode,
      treatmentLength:
        location.isWithoutOffset && experts?.length
          ? length.length + WITHOUT_OFFSET_TREATMENT_LENGTH
          : length.length,
      discountOrVoucherCode,
      payWithYourReferral: payWithReferral,
      clientEmail: clientData?.email,
      locationId,
    });

    const isFree = bill.value?.total?.intValue === 0;

    React.useEffect(() => {
      onPlacementAvailable(
        Boolean(bill.value) &&
          tocAccepted &&
          (Boolean(paymentMethod) || bill.value?.total?.intValue === 0) &&
          !bill.loading &&
          !bill.error,
      );
    }, [
      bill.value,
      bill.loading,
      bill.error,
      tocAccepted,
      placementInProgress,
    ]);

    const onPlaceBooking = React.useCallback(() => {
      if (
        slotTime &&
        length &&
        location &&
        (clientData || isAuthenticated) &&
        details
      ) {
        setPlacementInProgress(true);
        const passedClientData = clientData || undefined;
        const platform = getMobilePlatform();
        if (passedClientData && platform) {
          passedClientData.platform = platform;
        }
        bookingPaymentAdapter
          .placeBooking({
            address: location,
            treatmentLength:
              location.isWithoutOffset && experts?.length
                ? length.length + WITHOUT_OFFSET_TREATMENT_LENGTH
                : length.length,
            clientData: passedClientData,
            details,
            time: slotTime,
            experts,
            treatmentTypeId: type,
            locationId,
            discountOrVoucherCode,
            marketingEmailsAccepted,
            payWithYourReferral: payWithReferral,
          })
          .then((booking) => {
            onBookingPlaced(booking, paymentMethod);
          })
          .catch((error: Error) => {
            if (error instanceof BookingNotAvailableError) {
              void showAlert(t('not_available_error'));
              return;
            }
            void showAlert(t('placing_error', { message: error?.message }));
          })
          .finally(() => setPlacementInProgress(false));
      }
    }, [
      bookingPaymentAdapter,
      onBookingPlaced,
      discountOrVoucherCode,
      marketingEmailsAccepted,
      clientData,
      payWithReferral,
      paymentMethod,
    ]);

    useImperativeHandle(ref, () => ({
      placeBooking: () => {
        onPlaceBooking();
      },
    }));

    return (
      <>
        <IonLoading
          isOpen={placementInProgress}
          message={t('payment_in_progress')}
        />
        {experts && <HeaderProfilePicture experts={experts} />}
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              {bill.value && (
                <BillSummary
                  experts={experts}
                  bill={bill.value}
                  inSpa={Boolean(locationId)}
                />
              )}
              <DiscountCodeField
                discountStatus={
                  bill.value?.discountStatus || DiscountStatus.NONE
                }
                voucherStatus={
                  bill.value?.voucherStatus || VoucherApplianceStatus.NONE
                }
                referralStatus={
                  bill.value?.referralStatus || ReferralApplianceStatus.NONE
                }
                onCodeSet={(code) => {
                  setDiscountOrVoucherCode(code);
                }}
                payWithReferral={payWithReferral}
              />
              {referralData &&
                currency(referralData.referralWallet.referralBalance).value >
                  // TODO remove " && false" before referral release
                  0 &&
                false && (
                  <IonItem className="pay-with-referral" lines="none">
                    <IonCheckbox
                      slot="start"
                      mode="ios"
                      disabled={
                        placementInProgress ||
                        Boolean(
                          bill.value?.discountStatus ===
                            DiscountStatus.APPLIED ||
                            bill.value?.voucherStatus ===
                              VoucherApplianceStatus.APPLIED,
                        )
                      }
                      checked={payWithReferral}
                      onIonChange={(e) => setPayWithReferral(e.detail.checked)}
                    />
                    <span className="referral-label">
                      {t('pay_with_referral')}
                    </span>
                  </IonItem>
                )}
              {!isFree && (
                <div className="payment-method">
                  <IonRadioGroup
                    value={paymentMethod}
                    onIonChange={(e) => setPaymentMethod(e.detail.value)}
                  >
                    <IonList lines="none" mode="ios">
                      {paymentProviders
                        .filter((provider) => provider.isAvailable())
                        .map((provider) => (
                          <IonItem
                            disabled={placementInProgress}
                            key={provider.key}
                          >
                            <IonRadio
                              labelPlacement="end"
                              justify="start"
                              value={provider.key}
                            >
                              <span
                                className={`provider-logo provider-logo-${provider.key}`}
                              />
                              <p className="provider-label">
                                {provider.getName()}
                              </p>
                            </IonRadio>
                          </IonItem>
                        ))}
                    </IonList>
                  </IonRadioGroup>
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItem className="toc-confirmation" lines="full">
          <IonCheckbox
            mode="ios"
            disabled={placementInProgress}
            checked={tocAccepted}
            onIonChange={(e) => setTocAccepted(e.detail.checked)}
            labelPlacement="end"
            justify="start"
            className="toc-checkbox"
          >
            <span className="toc-label">{t('toc_confirmation')}</span>
          </IonCheckbox>
        </IonItem>
      </>
    );
  },
);

export default PlaceBooking;
