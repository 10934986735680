import './createReview.scss';

import {
  IonCol,
  IonGrid,
  IonLoading,
  IonRow,
  IonTextarea,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import { useContextTranslation } from 'ui/translation';
import IonicField from '../../../ui/utils/IonicField';
import type { ObjectID } from '../../types';
import type { BookingAndCartCombined } from '../../types/mapBookingJSONToObject';
import BookingDetails from './BookingDetails';
import type { CreateReviewInput } from './IReviewAdapter';
import RatingField from './RatingField';
import Button from 'ui/elements/Button/Button';

interface Props {
  booking: BookingAndCartCombined;
  createReview: (
    bookingId: ObjectID,
    values: CreateReviewInput & { token?: string },
  ) => Promise<void>;
}

interface CreateReviewFormValues {
  text: string;
  rating: number;
}

export default function CreateReviewForm({
  createReview,
  booking,
}: Props): JSX.Element {
  const t = useContextTranslation('page.create_review');
  const mt = useContextTranslation('misc');

  const [showAlert] = useIonAlert();

  const history = useHistory();

  const formik = useFormik<CreateReviewFormValues>({
    initialValues: {
      text: '',
      rating: 0,
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await createReview(booking.id, {
          text: values.text || undefined,
          rating: Math.floor(values.rating * 2),
          token: booking.token,
        });
        formikHelpers.setSubmitting(false);
        history.push('/bookings');
      } catch (e) {
        formikHelpers.setSubmitting(false);
        void showAlert(mt('something_went_wrong'));
      }
    },
    validationSchema: Yup.object().shape({
      rating: Yup.number().positive(t('no_rating_error')).max(5),
      text: Yup.string()
        .nullable()
        .optional()
        .max(200, ({ max }) => t('rating_text_too_long', { max })),
    }),
  });

  useIonViewWillEnter(() => {
    formik.resetForm();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form>
        <IonLoading isOpen={formik.isSubmitting} />
        <IonGrid>
          <div className="info-card">
            <BookingDetails booking={booking} />
            <IonRow>
              <IonCol className="rating-field-container">
                <RatingField />
              </IonCol>
              {formik.touched.rating && formik.errors.rating && (
                <IonCol size="12" className="error-message">
                  <ErrorMessage name="rating" />
                </IonCol>
              )}
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <p className="label">{t('text_label')}</p>
              </IonCol>
              <IonCol>
                <Field
                  name="text"
                  component={IonicField}
                  type="text"
                  ionicComponent={IonTextarea}
                  placeholder={t('text_placeholder')}
                  showErrors
                  rows={6}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Button
                  type="submit"
                  className="submit-button"
                  disabled={!formik.values.rating}
                >
                  {t('button_label')}
                </Button>
              </IonCol>
            </IonRow>
          </div>
        </IonGrid>
      </Form>
    </FormikProvider>
  );
}
