import './voucherContact.scss';

import {
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react';
import injectables from 'application/injectables';
import ContactForm from 'application/pages/BookingContact/ContactForm';
import SocialLoginButtons from 'application/pages/Login/SocialLoginButtons';
import VoucherOrderFooter from 'application/pages/Vouchers/VoucherOrderFooter';
import type INotificationService from 'application/services/INotificationService';
import { useAuth } from 'application/state/AuthProvider';
import { useVoucherContext } from 'application/state/VoucherContext';
import type { VoucherClientData } from 'application/types';
import type { FormikTouched } from 'formik';
import { FormikProvider, useFormik } from 'formik';
import { useInject } from 'inversify-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';
import * as Yup from 'yup';
import Button from '../../../ui/elements/Button/Button';
import VoucherBackground from 'ui/theme/images/Voucher.jpg';

// TODO Refactor required
/* eslint-disable @typescript-eslint/unbound-method */

const requiredFields: (keyof FormikTouched<VoucherClientData>)[] = [
  'email',
  'name',
  'surname',
];

export default function VoucherContact(): JSX.Element {
  const t = useContextTranslation('page.voucher');
  const history = useHistory();
  const { voucherValue, clientData, setClientData, voucherAmount } =
    useVoucherContext();

  const { i18n } = useTranslation();
  const [notification] = useInject<INotificationService>(
    injectables.services.NotificationService,
  );

  const { isAuthenticated, setRedirectUri } = useAuth();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('email_required'))
          .email(t('email_format')),
        name: Yup.string()
          .required(t('name_required'))
          .min(3, ({ min }) => t('name_min_length', { min }))
          .max(128, ({ max }) => t('name_max_length', { max })),
        surname: Yup.string()
          .required(t('surname_required'))
          .min(2, ({ min }) => t('surname_min_length', { min }))
          .max(128, ({ max }) => t('surname_max_length', { max })),
        companyName: Yup.string()
          .optional()
          .max(256, ({ max }) =>
            t('company_name_max_length', {
              max,
            }),
          ),
      }),
    [t],
  );

  const formik = useFormik<VoucherClientData>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: '',
      surname: '',
      email: '',
      companyName: '',
    },
    onSubmit: (values, formikHelpers) => {
      setClientData({
        email: values.email,
        name: values.name,
        surname: values.surname,
        companyName: values.companyName || undefined,
        notificationToken: notification.getToken() || undefined,
        locale: i18n.language,
      });
      formikHelpers.setSubmitting(false);
      history.push('/vouchers/payment');
    },
    validationSchema,
  });

  useIonViewWillEnter(() => {
    void formik.setValues({
      name: '',
      surname: '',
      email: '',
      companyName: '',
      ...(clientData || {}),
    });
    if (!voucherValue || !voucherAmount) {
      history.replace('/vouchers');
      return;
    }
    if (isAuthenticated) {
      history.replace('/vouchers/payment');
    }
  }, [history, isAuthenticated, clientData, voucherAmount, voucherValue]);

  const nativeFormSubmit = () => {
    const element = document.querySelector<HTMLFormElement>(
      '.voucher-contact form',
    );
    element?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  };

  const enableNext = React.useMemo(() => {
    const untouchedFields = requiredFields.filter(
      (el): boolean => !formik.touched[el],
    );
    return !untouchedFields.length;
  }, [formik.touched]);

  const footerContent = !!voucherValue && !!voucherAmount && (
    <VoucherOrderFooter
      displayContent
      nextEnabled={enableNext}
      onNext={() => nativeFormSubmit()}
    />
  );

  return (
    <FormikProvider value={formik}>
      <SubPageLayout
        className="voucher-contact"
        footerContent={() => footerContent}
        beforeContent={
          <div className="voucher-gallery-header">
            <img
              className="default-img"
              src={VoucherBackground}
              alt="Voucher"
            />
          </div>
        }
      >
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonText>
                <h4 className="continue-as-guest">
                  {t('contact_guest_header')}
                </h4>
              </IonText>
            </IonCol>
          </IonRow>
          <AutocompleteForm>
            <ContactForm isWithoutPhone />
          </AutocompleteForm>
          {!(formik.touched && formik.dirty) && (
            <>
              <IonRow>
                <IonCol size="12" className="voucher-login-header">
                  <h4 className="ion-no-margin">{t('login_options_title')}</h4>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol className="login-button-wrapper">
                  <SocialLoginButtons />
                  <Button
                    className="with-sh-btn"
                    onClick={() => {
                      history.replace('/login', {
                        direction: 'forward',
                      });
                      setRedirectUri('/vouchers/payment');
                    }}
                  >
                    {t('sign_in')}
                  </Button>
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
      </SubPageLayout>
    </FormikProvider>
  );
}
