import './createReview.scss';

import { IonAvatar, IonCol, IonRow, IonSpinner } from '@ionic/react';
import { useContextTranslation } from 'ui/translation';

import type { BookingAndCartCombined } from '../../types/mapBookingJSONToObject';

export default function BookingDetails({
  booking,
}: {
  booking: BookingAndCartCombined | null;
}): JSX.Element {
  const t = useContextTranslation('page.create_review');
  const mt = useContextTranslation('misc');

  return (
    <>
      {booking ? (
        <>
          <IonRow>
            <IonCol size="12" className="expert-img-container">
              <div className="avatar-wrapper">
                {booking.bookings.map((singleBooking) => (
                  <IonAvatar
                    className="expert-avatar"
                    key={singleBooking.expert?.profilePictureUrl}
                  >
                    <img
                      className="expert-img"
                      src={singleBooking.expert?.profilePictureUrl}
                      alt={mt('profile_picture')}
                    />
                  </IonAvatar>
                ))}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <h5 className="create-review-header">
                {t('header', {
                  expertName:
                    booking.bookings[0].expert?.name +
                    (booking.bookings[1]?.expert?.name
                      ? `, ${booking.bookings[1].expert.name}`
                      : ''),
                })}
              </h5>
            </IonCol>
          </IonRow>
        </>
      ) : (
        <IonRow>
          <IonCol className="loader-container">
            <IonSpinner color="secondary" />
          </IonCol>
        </IonRow>
      )}
    </>
  );
}
