import { Preferences as Storage } from '@capacitor/preferences';
import type IOnboardingService from 'application/services/IOnboardingService';

const ONBOARDING_KEY = 'onboarding_viewed';

export default class StorageOnboardingService implements IOnboardingService {
  private viewed = false;

  async initialize(): Promise<void> {
    const stored = await Storage.get({ key: ONBOARDING_KEY });
    this.viewed = Boolean(stored.value);
  }

  async setOnboardingViewed(): Promise<void> {
    this.viewed = true;
    await Storage.set({
      key: ONBOARDING_KEY,
      value: String(true),
    });
  }

  shouldRedirectToOnboarding(): boolean {
    return !this.viewed;
  }
}
