import { IonCol, IonLoading, IonRow } from '@ionic/react';
import { Field, useFormikContext } from 'formik';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';
import IonicField from 'ui/utils/IonicField';
import Button from 'ui/elements/Button/Button';

export interface PasswordChangeFormValues {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export const PasswordChangeForm: React.FC = () => {
  const { isSubmitting, status } = useFormikContext();
  const t = useContextTranslation('page.password_change');
  const statusErrorText = status?.error && t('error_unknown');
  return (
    <AutocompleteForm>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="oldPassword"
            type="password"
            autocomplete="old-password"
            required
            placeholder={t('old_password_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="newPassword"
            type="password"
            autocomplete="new-password"
            required
            placeholder={t('new_password_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="repeatPassword"
            type="password"
            autocomplete="new-password"
            required
            placeholder={t('repeat_password_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Button className="submit-button" type="submit">
            {t('submit_label')}
          </Button>
          {statusErrorText && (
            <div className="form-error">{statusErrorText}</div>
          )}
        </IonCol>
      </IonRow>
      <IonLoading isOpen={isSubmitting} message={t('loading')} />
      <input type="submit" className="invisible-auto-submit" />
    </AutocompleteForm>
  );
};
