import './voucherPayment.scss';

import { gql, useQuery } from '@apollo/client';
import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonLoading,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import injectables from 'application/injectables';
import DiscountCodeField from 'application/pages/BookingPayment/DiscountCodeField';
import type IPaymentProvider from 'application/pages/BookingPayment/IPaymentProvider';
import PaymentHandlerModal from 'application/pages/BookingPayment/PaymentHandlerModal';
import type IVoucherPaymentAdapter from 'application/pages/VoucherPayment/IVoucherPaymentAdapter';
import BillSummary from 'application/pages/VoucherPayment/VoucherPrice';
import VoucherOrderFooter from 'application/pages/Vouchers/VoucherOrderFooter';
import type { ITrackingService } from 'application/services/ITrackingService';
import { useAuth } from 'application/state/AuthProvider';
import { useVoucherContext } from 'application/state/VoucherContext';
import {
  DiscountStatus,
  ReferralApplianceStatus,
  VoucherApplianceStatus,
} from 'application/types';
import currency from 'currency.js';
import { useInject } from 'inversify-hooks';
import { container } from 'inversify-props';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import currencyFormat from 'ui/utils/currencyFormat';
import VoucherBackground from 'ui/theme/images/Voucher.jpg';

// TODO Refactor required
/* eslint-disable @typescript-eslint/unbound-method */

export const VOUCHER_PRICE_QUERY = gql`
  query VoucherPriceQuery(
    $clientEmail: String
    $discountCode: String
    $OrderVoucherItem: [OrderVoucherItem!]!
  ) {
    vouchersPrice(
      input: {
        clientEmail: $clientEmail
        discountCode: $discountCode
        items: $OrderVoucherItem
      }
    ) {
      discountResultStatus
      priceComponents {
        type
        discountCode
        value
      }
      total
    }
  }
`;

export default function VoucherPayment(): JSX.Element {
  const t = useContextTranslation('page.voucher');
  const mt = useContextTranslation('misc');
  const { voucherAmount, voucherValue, clientData, message, clearState } =
    useVoucherContext();
  const [alert] = useIonAlert();
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const [paymentMethod, setPaymentMethod] = React.useState<
    string | undefined
  >();
  const [tocAccepted, setTocAccepted] = React.useState<boolean>(false);
  const [paymentInProgress, setPaymentInProgress] =
    React.useState<boolean>(false);
  const [paymentTargetId, setPaymentTargetId] = React.useState<
    string | undefined
  >();

  const [voucherPaymentAdapter] = useInject<IVoucherPaymentAdapter>(
    injectables.pages.VoucherPaymentAdapter,
  );
  const [trackingService] = useInject<ITrackingService>(
    injectables.services.TrackingService,
  );

  const [discountCode, setDiscountCode] = React.useState<string | undefined>();

  const { loading, data: { vouchersPrice } = {} } = useQuery(
    VOUCHER_PRICE_QUERY,
    {
      variables: {
        clientEmail: clientData?.email,
        discountCode,
        OrderVoucherItem: [
          {
            amount: voucherAmount,
            value: voucherValue?.value.toString(),
          },
        ],
      },
      fetchPolicy: 'network-only' as const,
    },
  );

  const totalCurrencyPrice = currency(vouchersPrice?.total);
  const isFree = totalCurrencyPrice?.intValue === 0;

  const hasCompleteData = React.useMemo(
    () =>
      Boolean(voucherAmount && voucherValue && (clientData || isAuthenticated)),
    [voucherValue, voucherAmount, clientData],
  );

  const paymentProviders = container
    .getAll<IPaymentProvider>(injectables.pages.PaymentProvider)
    .filter((provider) => provider.isAvailable());
  paymentProviders.sort(
    (
      paymentMethodFirst: IPaymentProvider,
      paymentMethodSecond: IPaymentProvider,
    ) => paymentMethodFirst.weight - paymentMethodSecond.weight,
  );

  const forgetEverythingAndStartOver = () => {
    history.replace('/', { umount: true });
  };

  const onOrderVoucher = React.useCallback(() => {
    if (voucherValue && voucherAmount && (clientData || isAuthenticated)) {
      setPaymentInProgress(true);
      voucherPaymentAdapter
        .orderVouchers({
          buyerData: clientData,
          amount: voucherAmount,
          value: voucherValue,
          discountCode,
          message,
        })
        .then((res) => {
          if (vouchersPrice && isFree) {
            history.replace('/vouchers/finished', {
              unmount: true,
              direction: 'forward',
            });
            return;
          }
          setPaymentTargetId(res);
        })
        .finally(() => setPaymentInProgress(false))
        .catch(() => {
          forgetEverythingAndStartOver();
        });
    }
  }, [
    discountCode,
    isAuthenticated,
    clientData,
    voucherAmount,
    voucherValue,
    vouchersPrice,
  ]);

  useIonViewWillEnter(() => {
    setTocAccepted(false);
    setPaymentMethod(undefined);
    setPaymentInProgress(false);
    if (!hasCompleteData) {
      history.push('/vouchers');
    }
  }, [history, hasCompleteData]);

  const onPaymentSuccess = (amount?: number, value?: currency) => {
    if (value && amount) {
      trackingService.trackEvent({
        name: 'voucher_purchase_completed',
        data: {
          price: currency(value).multiply(amount).value,
          transaction_id: paymentTargetId,
        },
      });
    }

    setPaymentInProgress(false);
    setPaymentTargetId(undefined);
    setPaymentMethod(undefined);
    clearState();
    history.replace('/vouchers/finished', {
      unmount: true,
      direction: 'forward',
    });
  };

  return (
    <SubPageLayout
      className="voucher-payment"
      footerContent={() =>
        Boolean(voucherValue) && (
          <VoucherOrderFooter
            displayContent
            onNext={onOrderVoucher}
            nextEnabled={
              tocAccepted && ((!paymentInProgress && !!paymentMethod) || isFree)
            }
          />
        )
      }
      beforeContent={
        <div className="voucher-gallery-header">
          <img className="default-img" src={VoucherBackground} alt="Voucher" />
        </div>
      }
    >
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            {loading ? (
              <IonSpinner color="secondary" />
            ) : (
              <BillSummary
                voucherAmount={voucherAmount}
                vouchersPrice={vouchersPrice}
                voucherValue={voucherValue?.value}
              />
            )}
            <DiscountCodeField
              discountStatus={
                vouchersPrice?.discountResultStatus || DiscountStatus.NONE
              }
              voucherStatus={VoucherApplianceStatus.NONE}
              referralStatus={ReferralApplianceStatus.NONE}
              onCodeSet={(code) => {
                setDiscountCode(code);
              }}
              payWithReferral={false}
            />
            {!isFree && (
              <div className="payment-method">
                <IonRadioGroup
                  value={paymentMethod}
                  onIonChange={(e) => setPaymentMethod(e.detail.value)}
                >
                  <IonList lines="none" mode="ios">
                    {paymentProviders.map((provider) => (
                      <IonItem disabled={paymentInProgress} key={provider.key}>
                        <IonRadio
                          value={provider.key}
                          labelPlacement="end"
                          justify="start"
                        >
                          <span
                            className={`provider-logo provider-logo-${provider.key}`}
                          />
                          <p className="provider-label">{provider.getName()}</p>
                        </IonRadio>
                      </IonItem>
                    ))}
                  </IonList>
                </IonRadioGroup>
              </div>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonItem className="toc-confirmation" lines="full">
        <IonCheckbox
          mode="ios"
          disabled={paymentInProgress}
          checked={tocAccepted}
          onIonChange={(e) => setTocAccepted(e.detail.checked)}
          className="toc-checkbox"
          labelPlacement="end"
          justify="start"
        >
          <span className="toc-label">{t('toc_confirmation')}</span>
        </IonCheckbox>
      </IonItem>
      <IonLoading
        isOpen={paymentInProgress}
        message={t('payment_in_progress')}
      />
      <PaymentHandlerModal
        instance={
          paymentMethod && paymentTargetId && voucherAmount && voucherValue
            ? {
                subject: 'voucher',
                targetId: paymentTargetId,
                method: paymentMethod,
                name: t('voucher_number_with_value_label', {
                  count: voucherAmount,
                  value: currencyFormat(totalCurrencyPrice),
                }),
                price: totalCurrencyPrice,
              }
            : undefined
        }
        title={t('payment_header')}
        onError={(e) => {
          void alert({
            message: t('payment_error', { message: e?.message }),
            buttons: [mt('ok')],
            onDidDismiss: () => forgetEverythingAndStartOver(),
          });
        }}
        onSuccess={() => onPaymentSuccess(voucherAmount, voucherValue)}
      />
    </SubPageLayout>
  );
}
