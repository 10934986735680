import { ApolloError } from '@apollo/client';
import { isPlatform } from '@ionic/react';

export function getGraphQLErrorMessages(error: unknown): string[] | null {
  if (error instanceof ApolloError && error.graphQLErrors.length > 0) {
    return error.graphQLErrors.map(
      (e) => (e.extensions?.code as string) || e.message,
    );
  }
  return null;
}

export function getMobilePlatform(): string | null {
  if (isPlatform('capacitor')) {
    if (isPlatform('android')) {
      return 'android';
    }
    if (isPlatform('ios')) {
      return 'ios';
    }
  }

  return null;
}

export default {
  getGraphQLErrorMessages,
};
