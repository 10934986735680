import type { AvailableBookingDay } from 'application/pages/Treatments/ITreatmentAdapter';
import { differenceInCalendarDays } from 'date-fns';

export function isSameDay(firstDate: Date, secondDate: Date): boolean {
  return differenceInCalendarDays(firstDate, secondDate) === 0;
}

const isTileDisabled = (
  numberOfExperts: number | null,
  calendarDate: Date,
  bookingAvailability?: AvailableBookingDay[],
): boolean => {
  if (!calendarDate) return true;
  if (numberOfExperts && numberOfExperts > 1) {
    const currentDay = bookingAvailability?.find((disabledDate) =>
      isSameDay(new Date(disabledDate.date), calendarDate),
    );

    return !(
      currentDay?.availableExperts &&
      Object.values(currentDay.availableExperts || {}).find(
        (numberOfAvlb) => numberOfAvlb > 1,
      )
    );
  }
  return !bookingAvailability?.find((disabledDate) =>
    isSameDay(new Date(disabledDate.date), calendarDate),
  );
};

export default isTileDisabled;
