import type {
  ISofortPaymentAdapter,
  SofortPaymentParams,
} from 'application/pages/SofortPayment/ISofortPaymentAdapter';
import StripeProvider, {
  STRIPE_PROVIDER_NAME,
} from 'infrastructure/adapters/payment/StripeProvider';
import { inject, injectable } from 'inversify';

@injectable()
class SofortPaymentAdapter implements ISofortPaymentAdapter {
  @inject(STRIPE_PROVIDER_NAME)
  private stripeProvider!: StripeProvider;

  async createPaymentAndRedirect(params: SofortPaymentParams): Promise<void> {
    const stripe = this.stripeProvider.getStripe();

    await stripe.confirmSofortPayment(params.paymentIntentSecret, {
      payment_method: {
        sofort: {
          country: 'DE',
        },
      },
      return_url: params.returnUrl,
    });
  }
}

export default SofortPaymentAdapter;
