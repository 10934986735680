import { IonCol } from '@ionic/react';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useRef } from 'react';
import { useContextTranslation } from 'ui/translation';

import injectables from '../../../injectables';
import type {
  TreatmentSearchParams,
  ITreatmentAdapter,
} from '../ITreatmentAdapter';
import TreatmentResults from '../TreatmentResults';
import ResultsWithAvailabilities from '../TreatmentResult/ResultsWithAvabilities';

type SearchSingleExpertParams = {
  params: TreatmentSearchParams & {
    locationId: string | null;
    scroll: () => void;
  };
};

const RESULTS_PER_PAGE = 5;

const SearchFirstExpert = ({
  params: {
    location,
    date,
    length,
    locationId,
    numberOfExperts,
    scroll,
  },
}: SearchSingleExpertParams): JSX.Element => {
  const [adapter] = useInject<ITreatmentAdapter>(
    injectables.pages.TreatmentAdapter,
  );
  const [page, setPage] = React.useState<number>(1);
  const t = useContextTranslation('page.treatment');

  const results = adapter.useAvailableExpertsQuery({
    locationId,
    postalCode:
      location?.postalCode && location.postalCode.length > 3
        ? location.postalCode
        : null,
    date: date || null,
    pagination: {
      offset: 0,
      limit: RESULTS_PER_PAGE,
    },
  });
  const shouldScroll = useRef(false);

  if (date && results.loading) {
    shouldScroll.current = true;
  }

  if (results.value && shouldScroll.current) {
    scroll();
    shouldScroll.current = false;
  }
  const expertsList = results.value || [];

  const getHeading = () => {
    if (!date || !location) {
      t('results_heading');
    }
    if (numberOfExperts === 1) {
      return t('results_heading_single');
    }
    return t('results_heading_fulfilled');
  };

  React.useEffect(() => {
    setPage(1);
  }, [length, date, location]);

  return (
    <>
      {expertsList.length !== 0 && (
        <IonCol className="results-heading">
          <h5 className="fulfilled">{getHeading()}</h5>
        </IonCol>
      )}
      {location && (
        <TreatmentResults
          results={results}
          page={page}
          setPage={setPage}
          timeRange={date?.timeRange}
          location={location}
          renderResults={(result) => (
            <ResultsWithAvailabilities
              result={result}
            />
          )}
        />
      )}
    </>
  );
};

export default SearchFirstExpert;
