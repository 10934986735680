import { gql } from '@apollo/client';
import type ICredentialsLoginAdapter from 'application/pages/Login/ICredentialsLoginAdapter';
import {
  AccountBlocked,
  AccountNotVerifiedError,
  CredentialsError,
} from 'application/pages/Login/ICredentialsLoginAdapter';
import client from 'infrastructure/apollo';
import { getGraphQLErrorMessages } from 'infrastructure/utils';

const INVALID_CREDENTIALS_ERROR = 'invalid_credentials';
const ACCOUNT_NOT_VERIFIED_ERROR = 'not_verified';
const ACCOUNT_BLOCKED_ERROR = 'blocked';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    loginData: loginWithEmail(
      email: $email
      password: $password
      role: client
    ) {
      accessToken
    }
  }
`;

interface LoginMutationResponse {
  loginData: {
    accessToken: string;
  };
}

const CredentialLoginAdapter: ICredentialsLoginAdapter = {
  async getAccessToken(email: string, password: string): Promise<string> {
    try {
      const response = await client.mutate<LoginMutationResponse>({
        mutation: LOGIN_MUTATION,
        variables: {
          email,
          password,
        },
      });
      if (!response.data) {
        throw new Error('No access token returned');
      }
      return response.data.loginData.accessToken;
    } catch (error) {
      const messages = getGraphQLErrorMessages(error);
      if (messages) {
        if (messages.includes(INVALID_CREDENTIALS_ERROR)) {
          throw new CredentialsError();
        }
        if (messages.includes(ACCOUNT_NOT_VERIFIED_ERROR)) {
          throw new AccountNotVerifiedError();
        }
        if (messages.includes(ACCOUNT_BLOCKED_ERROR)) {
          throw new AccountBlocked();
        }
      }
      throw error;
    }
  },
};

export default CredentialLoginAdapter;
