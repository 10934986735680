import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/react';
import type INotificationService from 'application/services/INotificationService';
import { NotificationsRejectedError } from 'application/services/INotificationService';

const CHANNEL_NAME = 'sh_notifications';

let notificationSeq = 1;

export default class FirebaseNotificationService
  implements INotificationService
{
  private fcmToken: string | null = null;

  private onTokenChanged?: () => void;

  private afterLaunchPath?: string;

  private onNotificationRoute?: (path: string, isFirst: boolean) => void;

  getToken(): string | null {
    return this.fcmToken;
  }

  async initialize(): Promise<void> {
    this.fcmToken = null;
    if (!isPlatform('capacitor')) {
      return;
    }
    await PushNotifications.removeAllListeners();

    if (isPlatform('android')) {
      try {
        const pushChannels = await PushNotifications.listChannels();
        if (!pushChannels.channels.find((ch) => ch.id === CHANNEL_NAME)) {
          await PushNotifications.createChannel({
            id: CHANNEL_NAME,
            vibration: true,
            lights: true,
            importance: 4,
            visibility: 1,
            name: 'Notifications',
            description: 'Reminders and marketing notifications',
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(
          'Ignoring notification channel because of an exception. May be due to incompatibility',
          e,
        );
      }
    }

    await PushNotifications.addListener('registration', (token) => {
      this.fcmToken = token.value;
      if (typeof this.onTokenChanged === 'function') {
        this.onTokenChanged();
      }
    });
    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        if (notification.title && notification.body && isPlatform('android')) {
          void LocalNotifications.schedule({
            notifications: [
              {
                ...notification,
                channelId: CHANNEL_NAME,
                id: notificationSeq,
                title: notification.title,
                body: notification.body,
                extra: {
                  ...(notification.data as Record<string, unknown>),
                },
              },
            ],
          });
          notificationSeq += 1;
        }
      },
    );
    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action) => {
        if (action.notification.data?.appPath) {
          if (this.onNotificationRoute) {
            this.onNotificationRoute(action.notification.data?.appPath, false);
          } else {
            this.afterLaunchPath = action.notification.data?.appPath;
          }
        }
      },
    );
    await LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (action) => {
        if (action.notification.extra?.appPath && this.onNotificationRoute) {
          this.onNotificationRoute(action.notification.extra.appPath, false);
        }
      },
    );
  }

  async registerDevice(onTokenChanged: () => void): Promise<void> {
    this.onTokenChanged = onTokenChanged;
    if (!isPlatform('capacitor')) {
      return;
    }
    const pushResult = await PushNotifications.requestPermissions();
    const localResult = await LocalNotifications.requestPermissions();
    if (pushResult.receive === 'granted' && localResult.display === 'granted') {
      await PushNotifications.register();
    } else {
      throw new NotificationsRejectedError();
    }
  }

  setRouteListener(
    onNotificationRoute: (path: string, isFirst: boolean) => void,
  ): void {
    this.onNotificationRoute = onNotificationRoute;
    if (this.afterLaunchPath) {
      this.onNotificationRoute(this.afterLaunchPath, true);
      this.afterLaunchPath = undefined;
    }
  }
}
