import { ApolloClient, InMemoryCache } from '@apollo/client';

import config from '../config';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        expertAvailabilityInfo: {
          keyArgs: false,
          merge(existing: unknown[], incoming: unknown[], opt) {
            if (!opt.args?.pagination?.offset) {
              return incoming;
            }
            if (!incoming) return existing;
            if (!existing) return incoming;

            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  uri: `${config.apiUrl}/graphql`,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;
