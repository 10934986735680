import injectables from 'application/injectables';
import useSelectedBooking from 'application/pages/CreateReview/useSelectedBooking';
import type IAccountBookingsAdapter from 'application/pages/MyBookings/IAccountBookingsAdapter';
import { useInject } from 'inversify-hooks';
import React from 'react';

import type { BookingAndCartCombined } from '../../types/mapBookingJSONToObject';

export default function BookingProvider({
  children,
}: {
  children: (booking: BookingAndCartCombined | null) => React.ReactNode;
}): JSX.Element {
  const [accountBookingService] = useInject<IAccountBookingsAdapter>(
    injectables.pages.AccountBookingsAdapter,
  );

  const bookings = accountBookingService.useAccountBookings();

  const booking = useSelectedBooking(bookings.value?.bookings || null);

  return <>{children(booking)}</>;
}
