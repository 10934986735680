import './style.scss';

import type { FieldProps } from 'formik';
import * as React from 'react';
import type { Props } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';

export interface IonicFieldProps extends FieldProps, Omit<Props, 'form'> {
  label?: string;
  showErrors?: boolean;
}

const IonicPhoneField: React.FC<IonicFieldProps> = ({
  field,
  form,
  showErrors,
  ...rest
}) => (
  <div>
    <PhoneInput
      {...rest}
      className={field.name}
      international
      value={field.value}
      onChange={(e) => {
        void form.setFieldValue('phoneNumber', e || '');
        void form.setFieldTouched('phoneNumber', true, false);
      }}
      onBlur={() => field.onBlur('phoneNumber')}
    />
    {showErrors && form.errors[field.name] && (
      <div className="form-error">{form.errors[field.name]}</div>
    )}
  </div>
);

export default IonicPhoneField;
