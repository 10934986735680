import './hotelBookingRequest.scss';

import { gql, useLazyQuery } from '@apollo/client';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import HotelBookingRequestForm from 'application/pages/HotelBookingRequest/HotelBookingRequestForm';
import type { ObjectID } from 'application/types';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import CustomModal from 'ui/elements/CustomModal/CustomModal';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';

import type { HotelRequestData } from '../Treatments/BookingRequest/IBookingRequestAdapter';
import { SwiperSlide } from '../../../ui/elements/Swiper/Swiper';

const GET_HOTEL_REQUEST = gql`
  query hotelRequest($id: ID!) {
    hotelRequest(id: $id) {
      hotelName
      hotelPicture
      receptionEmail
      address {
        locality
        postalCode
        street
        streetNumber
      }
    }
  }
`;

const useFetchHotelRequest = () =>
  useLazyQuery<{ hotelRequest: HotelRequestData }>(GET_HOTEL_REQUEST, {
    fetchPolicy: 'network-only',
  });

const HotelBookingRequest = (): JSX.Element => {
  const t = useContextTranslation('page.hotel_booking_request');
  const mt = useContextTranslation('misc');
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();

  const [get, { data }] = useFetchHotelRequest();

  const { hotelId, token } = useParams<{
    hotelId: ObjectID;
    token: string;
  }>();

  useEffect(() => {
    if (hotelId) {
      void get({ variables: { id: hotelId } });
    }
  }, [hotelId]);

  return (
    <SubPageLayout
      beforeContent={
        <div className="treatment-type-gallery-header">
          {data?.hotelRequest.hotelPicture && (
            <SwiperSlide>
              <img src={data?.hotelRequest.hotelPicture} alt={t('media')} />
            </SwiperSlide>
          )}
        </div>
      }
      className="treatment-selector"
      onGoBack={() => {}}
    >
      {data?.hotelRequest && (
        <HotelBookingRequestForm
          hotelData={{ ...data.hotelRequest, token, id: hotelId }}
          onSuccess={() => {
            setIsOpen(true);
          }}
        />
      )}
      <CustomModal isOpen={isOpen} cssClass="booking-modal" backdropDismiss>
        <IonContent>
          <button
            type="button"
            aria-label={mt('close')}
            className="close-button"
            onClick={() => {
              setIsOpen(false);
              history.push('/home');
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <FontAwesomeIcon className="header-icon" icon={faCheckCircle} />
                <h3>{t('success_title')}</h3>
                <p className="message">{t('success_message')}</p>
                <p className="message">{t('success_footer')}</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </CustomModal>
    </SubPageLayout>
  );
};

export default HotelBookingRequest;
