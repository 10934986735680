import * as React from 'react';

type Action<T> = () => PromiseLike<T> | T;

interface ActionState<T> {
  result: T | null;
  loading: boolean;
  error: Error | null;
}

interface ActionReturn<T> extends ActionState<T> {
  trigger: () => void;
}

function useAction<T>(
  action: Action<T>,
  onSuccess?: (result: T) => void,
  onError?: (error: Error) => void,
): ActionReturn<T> {
  const [state, setState] = React.useState<ActionState<T>>({
    error: null,
    loading: false,
    result: null,
  });

  const trigger = React.useCallback(() => {
    setState((oldState) => ({
      ...oldState,
      loading: true,
    }));
    Promise.resolve<T>(action())
      .then((result: T) => {
        setState((oldState) => ({
          ...oldState,
          error: null,
          loading: false,
          result,
        }));
        if (onSuccess) {
          onSuccess(result);
        }
      })
      .catch((error) => {
        setState((oldState) => ({
          ...oldState,
          error,
          loading: false,
          result: null,
        }));
        if (onError) {
          onError(error);
        }
      });
  }, [action, onSuccess, onError]);

  return {
    ...state,
    trigger,
  };
}

export default useAction;
