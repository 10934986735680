import { useInject } from 'inversify-hooks';
import { useEffect } from 'react';

import injectables from '../../../injectables';
import type ILoginService from '../../../services/ILoginService';

// TODO: refactor required
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */

const AuthenticatedUserAutoFill = ({
  formik,
}: {
  formik: any;
}): JSX.Element => {
  const [loginService]: ILoginService[] = useInject<ILoginService>(
    injectables.services.LoginService,
  );
  const userProfile = loginService.useUserProfile();

  useEffect(() => {
    if (
      !userProfile.value?.email ||
      !userProfile.value?.name ||
      !userProfile.value?.surname ||
      !userProfile.value?.phoneNumber
    ) {
      return;
    }

    formik.setValues({
      ...formik.values,
      email: userProfile.value.email,
      name: userProfile.value.name,
      surname: userProfile.value.surname,
      phoneNumber: userProfile.value.phoneNumber,
    });
  }, [
    formik.setValues,
    formik.values?.email,
    formik.values?.phoneNumber,
    formik.values?.surname,
    formik.values?.name,
    userProfile.value?.email,
    userProfile.value?.phoneNumber,
    userProfile.value?.surname,
    userProfile.value?.name,
  ]);

  return <></>;
};

export default AuthenticatedUserAutoFill;
