import './style.scss';

import type { SelectChangeEventDetail } from '@ionic/core/components';
import type { IonInputCustomEvent } from '@ionic/core/dist/types/components';
import {
  InputChangeEventDetail,
  IonCol,
  IonInput,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import {
  formatISO,
  getDate,
  getMonth,
  getYear,
  isMatch,
  isValid,
  parseISO,
  set,
} from 'date-fns';
import * as React from 'react';
import { type HTMLAttributes, useEffect, useState } from 'react';
import { useContextTranslation } from 'ui/translation';
import formatLocalizedDate from 'ui/utils/formatLocalizedDate';
import type { FieldProps } from 'formik';

export interface DateInputFieldProps
  extends FieldProps<string | null>,
    HTMLAttributes<HTMLIonInputElement> {
  label?: string;
  ionicComponent?: React.ForwardRefExoticComponent<unknown>;
  showErrors?: boolean;
}

const DateInput: React.FC<DateInputFieldProps> = ({
  field,
  form,
  showErrors,
  label,
}) => {
  const t = useContextTranslation('layout.date_input');
  const [date, setDate] = useState({
    day: getDate(new Date()),
    month: getMonth(new Date()),
    year: getYear(new Date()),
  });

  // TODO remove effect
  useEffect(() => {
    const parsedDate = field.value && parseISO(field.value);
    if (isValid(parsedDate)) {
      setDate({
        day: getDate(parsedDate as Date),
        month: getMonth(parsedDate as Date),
        year: getYear(parsedDate as Date),
      });
    }
  }, [field.value]);

  const newDateFromState = ({
    day = date.day,
    month = date.month,
    year = date.year,
  }) => {
    if (year > 100 && isMatch(`${day}/${month + 1}/${year}`, 'dd/MM/yyyy')) {
      return formatISO(new Date(year, month, day));
    }
    return null;
  };

  const handleDateChange = (
    event: IonInputCustomEvent<InputChangeEventDetail>,
  ) => {
    const newDate = newDateFromState({ day: Number(event.detail.value) });
    if (newDate) {
      void form.setFieldValue(field.name, newDate);
    } else {
      setDate((prev) => ({
        ...prev,
        day: Number(event.detail.value),
      }));
      void form.setFieldValue(field.name, null);
    }
  };

  const handleMonthChange = (event: CustomEvent<SelectChangeEventDetail>) => {
    setDate((prev) => ({
      ...prev,
      month: Number(event.detail.value),
    }));
  };

  const onSelectBlur = () => {
    const newDate = newDateFromState({});
    void form.setFieldValue(field.name, newDate);
  };

  const handleYearChange = (
    event: IonInputCustomEvent<InputChangeEventDetail>,
  ) => {
    const newDate = newDateFromState({ year: Number(event.detail.value) });
    if (newDate) {
      void form.setFieldValue(field.name, newDate);
    } else {
      setDate((prev) => ({
        ...prev,
        year: Number(event.detail.value),
      }));
      void form.setFieldValue(field.name, null);
    }
  };

  return (
    <IonRow className="ion-justify-content-center ion-no-padding date-input">
      {label && (
        <IonCol size="12" className="form-label ion-no-padding">
          {label}
        </IonCol>
      )}
      <IonCol className="ion-no-padding" size="3">
        <IonInput
          className="form-input"
          value={date.day}
          maxlength={2}
          type="text"
          required
          inputmode="numeric"
          placeholder={t('day')}
          onIonChange={(e) => handleDateChange(e)}
        />
      </IonCol>
      <IonCol size="6" className="month-select">
        <IonSelect
          className="form-input"
          interfaceOptions={{
            header: t('month'),
          }}
          value={date.month}
          placeholder={t('month')}
          onIonBlur={onSelectBlur}
          onIonChange={(e) => handleMonthChange(e)}
        >
          {Array.from({ length: 12 }, (_, i) => i).map((month) => (
            <IonSelectOption value={month} key={month}>
              {formatLocalizedDate(set(new Date(), { month }), 'LLLL')}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonCol>
      <IonCol size="3" className="ion-no-padding">
        <IonInput
          className="form-input"
          value={date.year}
          maxlength={4}
          type="text"
          required
          placeholder={t('year')}
          onIonChange={(e) => handleYearChange(e)}
        />
      </IonCol>
      {showErrors && form.touched[field.name] && form.errors[field.name] && (
        <div className="form-error">{form.errors[field.name] as string}</div>
      )}
    </IonRow>
  );
};

export default DateInput;
