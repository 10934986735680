import type {
  ObjectID,
  TreatmentLength,
  TreatmentTimeRange,
} from 'application/types';
import { useInject } from 'inversify-hooks';

import type { TreatmentExpert } from '../../../state/TreatmentContext';
import { useTreatmentBuilder } from '../../../state/TreatmentContext';
import injectables from '../../injectables';
import type { ITreatmentAdapter } from '../ITreatmentAdapter';
import LengthSelectorContent from '../TreatmentLengthSelector/LengthSelectorContent';

interface TreatmentTypeSelectorProps {
  onClick: () => void;
  expanded: boolean;
  treatmentLength: TreatmentLength | null;
  onSelect: (treatmentType: TreatmentLength) => void;
  experts: Partial<TreatmentExpert>[];
  slotTime: Date;
  treatmentTimeRange: TreatmentTimeRange;
  locationId?: ObjectID;
}

const MultipleExpertsTreatmentLengthSelector = ({
  onClick,
  expanded,
  treatmentLength: selectedLength,
  onSelect,
  experts,
  slotTime,
  treatmentTimeRange,
  locationId,
}: TreatmentTypeSelectorProps): JSX.Element => {
  const [service] = useInject<ITreatmentAdapter>(injectables.TreatmentAdapter);
  const { location } = useTreatmentBuilder();
  const treatmentLengths = service.useMultipleBookingLengthSearchResults({
    slotTime,
    experts,
    treatmentTimeRange,
    locationId,
    ...(!locationId && location ? { postalCode: location.postalCode } : {}),
  });

  return (
    <LengthSelectorContent
      treatmentLengths={treatmentLengths}
      selectedLength={selectedLength}
      onSelect={onSelect}
      onClick={onClick}
      expanded={expanded}
    />
  );
};
export default MultipleExpertsTreatmentLengthSelector;
