import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { loggingServiceSymbol } from 'application/services/IErrorLoggingService';
import type INotificationService from 'application/services/INotificationService';
import type { ITrackingService } from 'application/services/ITrackingService';
import type IClientMetadataAdapter from 'application/state/IClientMetadataAdapter';
import i18n from 'infrastructure/i18n';
import { useInject } from 'inversify-hooks';
import { createContext, useReducer } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import injectables from '../injectables';
import type IAnonymousBookingsService from '../services/IAnonymousBookingsService';
import type ILoginService from '../services/ILoginService';
import type { ObjectID } from '../types';

// TODO Refactor required
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */

export interface ExternalRegistration {
  registrationToken: string;
}

export interface IAuthContext {
  isInitialized: boolean;
  isAuthenticated: boolean;
  userId: ObjectID | null;
  externalProvider: string | null;
  externalRegistration: ExternalRegistration | null;
  login: (accessToken: string) => Promise<void>;
  logout: () => Promise<void>;
  redirectUri: string | null;
  setExternalRegistration: (
    externalRegistration: ExternalRegistration | null,
  ) => void;
  setRedirectUri: (redirectUri: string | null) => void;
}

const defaultState: IAuthContext = {
  isAuthenticated: false,
  isInitialized: false,
  userId: null,
  externalProvider: null,
  redirectUri: null,
  externalRegistration: null,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setExternalRegistration: () => {},
  setRedirectUri: () => {},
};

export type AuthAction =
  | {
      type: 'INITIALIZE';
      userId: ObjectID | null;
      externalProvider: string | null;
    }
  | { type: 'LOGIN'; userId: ObjectID; externalProvider: string | null }
  | { type: 'LOGOUT' }
  | {
      type: 'SET_EXTERNAL_REGISTRATION';
      externalRegistration: ExternalRegistration | null;
    }
  | {
      type: 'SET_REDIRECT_URI';
      redirectUri: string | null;
    };

const AuthContext = createContext<IAuthContext>(defaultState);

const reducer: React.Reducer<IAuthContext, AuthAction> = (
  prevState,
  action,
) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...prevState,
        isInitialized: true,
        isAuthenticated: Boolean(action.userId),
        userId: action.userId,
        externalProvider: action.externalProvider,
        externalRegistration: null,
      };
    case 'LOGIN':
      return {
        ...prevState,
        isAuthenticated: true,
        userId: action.userId,
        externalProvider: action.externalProvider,
        externalRegistration: null,
      };
    case 'LOGOUT':
      return {
        ...prevState,
        isAuthenticated: false,
        userId: null,
        externalRegistration: null,
      };
    case 'SET_EXTERNAL_REGISTRATION':
      return {
        ...prevState,
        externalRegistration: action.externalRegistration,
      };
    case 'SET_REDIRECT_URI':
      return {
        ...prevState,
        redirectUri: action.redirectUri,
      };

    default:
      return { ...prevState };
  }
};

const AuthProvider: React.FC<{children?: React.ReactNode}> = ({ children }) => {
  const [loginService] = useInject<ILoginService>(
    injectables.services.LoginService,
  );
  const [anonymousBookingsService] = useInject<IAnonymousBookingsService>(
    injectables.services.AnonymousBookingsService,
  );
  const [notificationService] = useInject<INotificationService>(
    injectables.services.NotificationService,
  );
  const [clientMetadata] = useInject<IClientMetadataAdapter>(
    injectables.state.ClientMetadataAdapter,
  );
  const [trackingService] = useInject<ITrackingService>(
    injectables.services.TrackingService,
  );
  const [loggingAdapter] =
    useInject<IErrorLoggingService>(loggingServiceSymbol);
  const history = useHistory();
  const [state, dispatch] = useReducer<typeof reducer>(
    reducer,
    defaultState,
    undefined,
  );

  const login = async (accessToken: string) => {
    await loginService.login(accessToken);
    const userId = loginService.getUserId();
    const externalProvider = loginService.getExternalAuthProvider();
    if (!userId) {
      throw new Error('User id is unavailable');
    }
    await anonymousBookingsService.clear();

    dispatch({
      type: 'LOGIN',
      userId,
      externalProvider,
    });

    if (userId) {
      trackingService.trackEvent({
        name: 'startup',
        data: {
          id: userId,
        },
      });
    }

    const token = notificationService.getToken();
    await clientMetadata
      .sync({
        notificationToken: token,
        locale: i18n.language,
      })
      .catch((e) => {
        loggingAdapter.traceException(e);
      });
  };

  const logout = async () => {
    await clientMetadata
      .sync({
        notificationToken: null,
        locale: i18n.language,
      })
      .catch((e) => {
        loggingAdapter.traceException(e);
      });
    await loginService.logout();
    await anonymousBookingsService.clear();
    dispatch({
      type: 'LOGOUT',
    });
    trackingService.trackEvent({
      name: 'logout',
    });
  };

  const setExternalRegistration = (
    externalRegistration: ExternalRegistration | null,
  ) => {
    dispatch({
      type: 'SET_EXTERNAL_REGISTRATION',
      externalRegistration,
    });
  };
  const setRedirectUri = (redirectUri: string | null) => {
    dispatch({
      type: 'SET_REDIRECT_URI',
      redirectUri,
    });
  };

  React.useEffect(() => {
    (async () => {
      await loginService.initialize();
      const userId = loginService.getUserId();

      dispatch({
        type: 'INITIALIZE',
        userId,
        externalProvider: loginService.getExternalAuthProvider(),
      });
      if (userId) {
        trackingService.trackEvent({
          name: 'startup',
          data: {
            id: userId,
          },
        });
      }
    })().catch((error) => {
      // only a silent notification for dev team
      // eslint-disable-next-line no-console
      console.error('Logged out due to an exception', error);
      dispatch({
        type: 'INITIALIZE',
        userId: null,
        externalProvider: null,
      });
    });

    const loginServiceErrorListener = () => {
      dispatch({
        type: 'LOGOUT',
      });
      history.replace('/');
    };

    loginService.eventEmitter.on('error', loginServiceErrorListener);

    return () => {
      loginService.eventEmitter.removeListener(
        'error',
        loginServiceErrorListener,
      );
    };
  }, [loginService]);

  if (!state.isInitialized) {
    return <h2>Loading...</h2>;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        setExternalRegistration,
        setRedirectUri,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext =>
  React.useContext<IAuthContext>(AuthContext);

export default AuthProvider;
