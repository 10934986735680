import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from 'ui/translation/languages/english.json';
import germanTranslations from 'ui/translation/languages/german.json';

i18next
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
    keySeparator: '.',
    resources: {
      en: { translation: englishTranslations },
      de: { translation: germanTranslations },
    },
  })
  .catch((e) => {
    // this should be an especially rare event and we just want to log it
    // eslint-disable-next-line no-console
    console.error('Could not load translations', e);
  });

export default i18next;
