import './expertProfile.scss';

import { IonSpinner } from '@ionic/react';
import type IExpertProfileAdapter from 'application/pages/ExpertProfile/IExpertProfileAdapter';
import { ExpertProfileBeforeContent, ExpertProfileInfo } from './index';
import injectables from 'application/pages/injectables';
import { useInject } from 'inversify-hooks';
import CustomModal from 'ui/elements/CustomModal/CustomModal';
import SubPageWrapperForModal from 'ui/elements/CustomModal/SubPageWrapperForModal';
import { useAtomValue, useSetAtom } from 'jotai';
import { expertDetailsAtom } from '../Treatments/TreatmentResult/treatmentAtoms';

const ExpertProfileModal = (): JSX.Element => {
  const expertId = useAtomValue(expertDetailsAtom);
  if (!expertId) {
    return <></>;
  }

  return <ExpertProfileModalContent expertId={expertId} />;
};

const ExpertProfileModalContent = ({ expertId }: { expertId: string }) => {
  const setExpertId = useSetAtom(expertDetailsAtom);
  const [adapter] = useInject<IExpertProfileAdapter>(
    injectables.ExpertProfileAdapter,
  );
  const profile = adapter.useExpertProfile(expertId);

  return (
    <CustomModal
      isOpen
      onDidDismiss={(e) => {
        e.stopPropagation();
        setExpertId(null);
      }}
    >
      <SubPageWrapperForModal
        wrapperClassName="expert-profile"
        beforeContent={
          <div className="expert-profile-before-content modal">
            {profile.value && (
              <ExpertProfileBeforeContent
                profile={profile.value}
                setIsFullscreen={() => {
                }}
                isFullscreen={false}
              />
            )}
          </div>
        }
        onClose={(e) => {
          e.stopPropagation();
          setExpertId(null);
        }}
      >
        {!profile?.value && profile.loading && <IonSpinner color="secondary" />}
        {profile?.value && (
          <ExpertProfileInfo
            profile={profile.value}
            onClickShowVideo={undefined}
          />
        )}
      </SubPageWrapperForModal>
    </CustomModal>
  );
};

export default ExpertProfileModal;
