import { gql } from '@apollo/client';
import type IClientMetadataAdapter from 'application/state/IClientMetadataAdapter';
import type { ClientMetadata } from 'application/state/IClientMetadataAdapter';
import apollo from 'infrastructure/apollo';
import { getMobilePlatform } from 'infrastructure/utils';

const UPDATE_CLIENT_METADATA = gql`
  mutation UpdateClientMetadata(
    $locale: String
    $notificationToken: String
    $platform: String
  ) {
    updateClientMetadata(
      metadata: {
        locale: $locale
        notificationToken: $notificationToken
        platform: $platform
      }
    )
  }
`;

export default class ClientMetadataAdapter implements IClientMetadataAdapter {
  async sync(metadata: ClientMetadata): Promise<void> {
    await apollo.mutate({
      mutation: UPDATE_CLIENT_METADATA,
      variables: {
        locale: metadata.locale,
        notificationToken: metadata.notificationToken,
        platform: getMobilePlatform(),
      },
    });
  }
}
