import './treatmentTypeDatails.scss';

import {
  useIonViewDidEnter,
  IonCol,
  IonGrid,
  IonLoading,
  IonRow,
} from '@ionic/react';
import injectables from 'application/pages/injectables';
import type { ObjectID, TreatmentType } from 'application/types';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import CustomAlert from 'ui/elements/CustomModal/CustomAlert';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation, useTranslateString } from 'ui/translation';

import type { IShareAdapter } from 'infrastructure/adapters/native/NativeShareAdapter';
import type ITreatmentTypeDetailsAdapter from './ITreatmentTypeDetailsAdapter';
import BeforeContentSwiper from '../../../ui/elements/Swiper/BeforeContentSwiper';
import { ReactComponent as ShareIcon } from 'ui/theme/images/arrow-forward-up.svg';

interface TreatmentDetailsParams {
  treatmentId: ObjectID;
}

interface TreatmentDetailsProps {
  treatment: TreatmentType;
}

export const TreatmentInfo: React.FC<TreatmentDetailsProps> = ({
  treatment,
}) => {
  const t = useContextTranslation('page.treatment_details');

  const selectMassageRef = useRef<HTMLIonColElement>(null);

  const translate = useTranslateString();
  const [shareAdapter] = useInject<IShareAdapter>(injectables.ShareAdapter);

  useIonViewDidEnter(() => {
    selectMassageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, []);

  const [showCopiedAlert, setShowCopiedAlert] = useState(false);

  const handleShare = () => {
    (async () => {
      const shareResult = await shareAdapter.handleUrlNativeShare(
        `/treatmentDetails/${treatment.id}`,
      );
      if (shareResult.notifyUser) {
        setShowCopiedAlert(true);
      }
    })().catch(() => {});
  };

  // //Old flow allows to select treatment type and expert
  // const onTreatmentTypeSelect = (treatmentTypeId: ObjectID) => {
  //   setTreatmentTypeId(treatmentTypeId);
  //   history.push(`/expert/${expertId}`);
  // };
  // const { setType } = useTreatmentBuilder();

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12">
          {treatment.details && (
            <div className="info-card">
              <button
                className="share"
                type="button"
                aria-label={t('share')}
                onClick={handleShare}
              >
                <ShareIcon />
              </button>
              <h4>{translate(treatment.name)}</h4>
              <p className="slogan">{translate(treatment.description)}</p>
              <div className="details-container">
                <div className="cell bookings-count">
                  <div className="value">
                    {translate(treatment.details.origin)}
                  </div>
                  <div className="label">{t('origin')}</div>
                </div>
                <div className="cell certification">
                  <div className="value">
                    {translate(treatment.details.pressure)}
                  </div>
                  <div className="label">{t('pressure')}</div>
                </div>
              </div>
              <div className="details-container">
                <div className="cell age">
                  <div className="value">
                    {translate(treatment.details.level)}
                  </div>
                  <div className="label">{t('level')}</div>
                </div>
                <div className="cell experience">
                  <div className="value">
                    {treatment.details.oil ? t('yes') : t('no')}
                  </div>
                  <div className="label">{t('oil')}</div>
                </div>
              </div>
            </div>
          )}
        </IonCol>
        {!!treatment.longDescription?.length && (
          <IonCol className="description" size="12">
            <h4>{t('about', { name: translate(treatment.name) })}</h4>
            <p>{translate(treatment.longDescription)}</p>
          </IonCol>
        )}
        {/* //Old flow allows to select treatment type and expert */}
        {/* {!!expertId && ( */}
        {/*   <IonCol className="select-button" size="12" ref={selectMassageRef}> */}
        {/*     <IonButton */}
        {/*       type="button" */}
        {/*       color="primary" */}
        {/*       expand="block" */}
        {/*       onClick={() => { */}
        {/*         onTreatmentTypeSelect(treatment.id); */}
        {/*         setType(treatment); */}
        {/*       }} */}
        {/*     > */}
        {/*       {t('select')} */}
        {/*     </IonButton> */}
        {/*   </IonCol> */}
        {/* )} */}
      </IonRow>
      <CustomAlert
        isOpen={showCopiedAlert}
        message={t('share_alert')}
        button={t('alert_close')}
        onDidDismiss={() => setShowCopiedAlert(false)}
      />
    </IonGrid>
  );
};

const TreatmentDetailsPage: React.FC = () => {
  const t = useContextTranslation('page.treatment_details');
  const { treatmentId } = useParams<TreatmentDetailsParams>();
  const [treatmentElement, setTreatmentElement] = useState<TreatmentType>();

  const [adapter] = useInject<ITreatmentTypeDetailsAdapter>(
    injectables.TreatmentDetailsAdapter,
  );
  const treatment = adapter.useTreatmentDetails(null,treatmentId);

  useEffect(() => {
    if (treatment.value) {
      setTreatmentElement(treatment.value.find((el) => treatmentId === el.id));
    }
  }, [treatment, treatmentId, setTreatmentElement]);

  return (
    <SubPageLayout
      className="treatment-details"
      beforeContent={
        <div className="treatment-details-before-content">
          {treatmentElement && (
            <BeforeContentSwiper media={treatmentElement.media} />
          )}
        </div>
      }
    >
      <IonLoading isOpen={treatment.loading} message={t('loading')} />
      {treatmentElement && <TreatmentInfo treatment={treatmentElement} />}
    </SubPageLayout>
  );
};

export default TreatmentDetailsPage;
