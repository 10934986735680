import type { RefCallback } from 'react';
import { useCallback, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useCalcMaxHeight = (
  expanded: boolean,
): [ref: RefCallback<HTMLElement | null>, maxHeight: number] => {
  const [height, setHeight] = useState<number | undefined>();
  const [maxHeight, setMaxHeight] = useState(0);
  const selectRef = useRef<HTMLElement | null>(null);

  const ref: RefCallback<HTMLElement | null> = useCallback(
    (node: HTMLElement | null) => {
      if (typeof window === 'undefined' || !('ResizeObserver' in window)) {
        return;
      }

      const resizeObserver = new ResizeObserver((entries) => {
        if (entries.length) {
          setHeight(entries[0].target.clientHeight);
        }
      });
      if (node) {
        selectRef.current = node;
        resizeObserver.observe(node);
        return;
      }

      resizeObserver.disconnect();
    },
    [],
  );

  if (!maxHeight && height && selectRef.current) {
    setMaxHeight(height);
    selectRef.current.style.maxHeight = '0';
    selectRef.current.style.transition = `max-height linear ${height * 0.6}ms`;
  }

  if (expanded && selectRef.current && maxHeight) {
    selectRef.current.style.maxHeight = `${maxHeight}px`;
  } else if (selectRef.current && maxHeight) {
    selectRef.current.style.maxHeight = '0';
  }

  return [ref, maxHeight];
};

export default useCalcMaxHeight;
