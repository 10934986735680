import './NotFound.scss';

import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';

const NotFound: React.FC = () => {
  const t = useContextTranslation('page.not_found');
  const history = useHistory();
  return (
    <SubPageLayout className="not-found" onGoBack={() => history.push('/')}>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h1>{t('title')}</h1>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <p>{t('text')}</p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </SubPageLayout>
  );
};

export default NotFound;
