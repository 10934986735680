import 'react-phone-number-input/style.css';

import './bookingContact.scss';

import { IonCol, IonRow } from '@ionic/react';
import { Field, useFormikContext } from 'formik';
import * as React from 'react';
import { useLocation } from 'react-router';
import { useContextTranslation } from 'ui/translation';
import IonicField from 'ui/utils/IonicField';
import IonicPhoneField from 'ui/utils/IonicPhoneField';

const ContactForm: React.FC<{ isWithoutPhone?: boolean }> = ({
  isWithoutPhone = false,
}) => {
  const location = useLocation();
  const t = useContextTranslation('page.booking_contact');
  const { status } = useFormikContext();
  const statusErrorText = status?.error && t('error_unknown');
  return (
    <>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="name"
            type="text"
            autocomplete="given-name"
            required
            placeholder={t('name_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            autocomplete="family-name"
            name="surname"
            type="text"
            required
            placeholder={t('surname_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="email"
            type="email"
            inputMode="email"
            autocomplete="email"
            required
            placeholder={t(
              location.pathname.startsWith('/voucher')
                ? 'voucher_email_placeholder'
                : 'email_placeholder',
            )}
            showErrors
          />
        </IonCol>
      </IonRow>
      {!isWithoutPhone && (
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <Field
              className="PhoneInput"
              component={IonicPhoneField}
              defaultCountry="DE"
              name="phoneNumber"
              placeholder={t('phone_number_placeholder')}
              required
              showErrors
            />
          </IonCol>
        </IonRow>
      )}
      {statusErrorText && (
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <div className="form-error">{statusErrorText}</div>
          </IonCol>
        </IonRow>
      )}
      <input type="submit" className="invisible-auto-submit" />
    </>
  );
};

export default ContactForm;
