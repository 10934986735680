import type { TreatmentTimeRange } from 'application/types';
import {
  differenceInCalendarMonths,
  getMonth,
  getYear,
  set,
  startOfDay,
} from 'date-fns';
import type { Dispatch, SetStateAction } from 'react';
import Calendar from 'react-calendar';
import formatLocalizedDate from 'ui/utils/formatLocalizedDate';
import isTileDisabled from 'ui/utils/isTileDisabled';

import { useTreatmentBuilder } from '../../../state/TreatmentContext';
import type { CurrentlyAvailableBookings } from '../ITreatmentAdapter';

interface CalendarWithDynamicHighlightsProps {
  date?: Date;
  setDate: Dispatch<SetStateAction<Date | undefined>>;
  setTimeRange: Dispatch<SetStateAction<TreatmentTimeRange | undefined>>;
  calendarViewDate: Date;
  setCalendarViewDate: Dispatch<SetStateAction<Date>>;
  availableBookings: CurrentlyAvailableBookings;
}

const CalendarWithDynamicHighlights = ({
  date,
  setDate,
  setTimeRange,
  calendarViewDate,
  setCalendarViewDate,
  availableBookings,
}: CalendarWithDynamicHighlightsProps): JSX.Element => {
  const { numberOfExperts } = useTreatmentBuilder();

  return (
    <Calendar
      value={date}
      onChange={(d: Date[]) => {
        const newDate = Array.isArray(d) ? d[0] : d;
        setDate(newDate);
        setTimeRange(undefined);
      }}
      activeStartDate={calendarViewDate}
      prev2Label={null}
      next2Label={null}
      minDate={startOfDay(new Date())}
      minDetail="month"
      formatMonthYear={(locale, d) => formatLocalizedDate(d, 'MMMM yyyy')}
      tileDisabled={({ date: calendarDate }) =>
        isTileDisabled(
          numberOfExperts,
          calendarDate,
          availableBookings.availableDays,
        )
      }
      formatShortWeekday={(locale, d) => formatLocalizedDate(d, 'EEEEE')}
      onActiveStartDateChange={({ activeStartDate, view }) => {
        if (
          view === 'month' &&
          differenceInCalendarMonths(activeStartDate, calendarViewDate)
        ) {
          setDate(undefined);
          setTimeRange(undefined);
          setCalendarViewDate(() =>
            set(new Date(), {
              year: getYear(activeStartDate),
              month: getMonth(activeStartDate),
              date: 1,
            }),
          );
        }
      }}
    />
  );
};

export default CalendarWithDynamicHighlights;
