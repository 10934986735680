import type { IOpenSourceNoticesAdapter } from 'application/pages/Settings/IOpenSourceNoticesAdapter';
import { CapacitorOSSNotices } from 'capacitor-oss-notices';

const OpenSourceNoticesAdapter: IOpenSourceNoticesAdapter = {
  showNotices(): void {
    // eslint-disable-next-line no-console
    CapacitorOSSNotices.showNotices().catch(console.error);
  },
};

export default OpenSourceNoticesAdapter;
