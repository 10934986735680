import { isPlatform } from '@ionic/react';
import type ICalendarAdapter from 'application/pages/BookingDetailsModal/ICalendarAdapter';
import type { CalendarEventData } from 'application/pages/BookingDetailsModal/ICalendarAdapter';
import { ics } from 'calendar-link';
import {
  CapacitorCalendar,
  PluginPermission,
} from '@ebarooni/capacitor-calendar';
import FileSaver from 'file-saver';

const NativeCalendarAdapter: ICalendarAdapter = {
  async addTreatmentToCalendar(eventData: CalendarEventData): Promise<void> {
    if (isPlatform('capacitor') && CapacitorCalendar) {
      await CapacitorCalendar.requestPermission({
        alias: PluginPermission.WRITE_CALENDAR,
      });
      await CapacitorCalendar.createEventWithPrompt({
        title: eventData.name,
        location: eventData.location,
        startDate: eventData.start.valueOf(),
        endDate: eventData.end.valueOf(),
        notes: eventData.description,
      });
      return;
    }

    const icsData = ics({
      start: eventData.start,
      end: eventData.end,
      title: eventData.name,
      description: eventData.description,
      location: eventData.location,
    });

    FileSaver.saveAs(icsData, 'soulhouse.ics');
  },
};

export default NativeCalendarAdapter;
