import { gql, useQuery } from '@apollo/client';
import type { BookingDetailsData } from 'application/pages/BookingDetails/IBookingDetailsAdapter';
import type IBookingDetailsAdapter from 'application/pages/BookingDetails/IBookingDetailsAdapter';
import type { HookData, ObjectID, WithRefetch } from 'application/types';
import type {
  BookingAndCartCombined,
  StoredCart,
} from 'application/types/mapBookingJSONToObject';
import { mapCartJSONToObject } from 'application/types/mapBookingJSONToObject';
import { parseISO } from 'date-fns';

type BookingDetailsQueryData = Omit<
  BookingDetailsData,
  'time' | 'createdAt' | 'updatedAt'
> & {
  time: string;
  createdAt: string;
  updatedAt: string;
};

const GET_BOOKING_DETAILS_QUERY = gql`
  query GetBookingDetails($id: ID!, $token: String!) {
    details: myBooking(id: $id, token: $token) {
      address {
        locality
        postalCode
        street
        streetNumber
      }
      client
      clientData {
        companyName
        email
        name
        phoneNumber
      }
      customId
      createdAt
      details {
        massageTable
        notes
        pets
      }
      expert {
        profilePictureUrl
        id
        name
      }
      hasReview
      id
      length
      price {
        components {
          type
          value
        }
        discountStatus
        total
        totalTax
        voucherStatus
      }
      status
      time
      treatmentType {
        id
        media {
          type
          uri
        }
        name {
          lang
          value
        }
      }
      updatedAt
      token
      isInSpa
    }
  }
`;

const GET_MULTIPLE_BOOKING_DETAILS_QUERY = gql`
  query GetMultipleBookingDetails($id: ID!, $token: String!) {
    details: myCart(id: $id, token: $token) {
      address {
        locality
        postalCode
        street
        streetNumber
      }
      client
      clientData {
        companyName
        email
        name
        phoneNumber
      }
      customId
      createdAt
      details {
        massageTable
        notes
        pets
      }
      bookings {
        expert {
          profilePictureUrl
          id
          name
        }
        treatmentType {
          id
          media {
            type
            uri
          }
          name {
            lang
            value
          }
        }
        id
      }
      hasReview
      id
      length
      price {
        components {
          type
          value
        }
        discountStatus
        total
        totalTax
        voucherStatus
      }
      status
      time
      updatedAt
      token
      isInSpa
      locationId
      isWithoutOffset
    }
  }
`;

const BookingDetailsAdapter: IBookingDetailsAdapter = {
  useBookingDetails(
    bookingId: ObjectID,
    token: string,
  ): WithRefetch<HookData<BookingDetailsData>> {
    const query = useQuery<{ details: BookingDetailsQueryData }>(
      GET_BOOKING_DETAILS_QUERY,
      {
        variables: {
          id: bookingId,
          token,
        },
      },
    );

    return {
      refetch: () => {
        query.refetch().catch(() => {});
      },
      loading: query.loading,
      error: query.error,
      value: query.data?.details
        ? {
            ...query.data.details,
            token,
            updatedAt: parseISO(query.data.details.updatedAt),
            createdAt: parseISO(query.data.details.createdAt),
            time: parseISO(query.data.details.time),
          }
        : null,
    };
  },

  useMultipleBookingsDetails(
    shoppingCartId: ObjectID,
    token: string,
  ): WithRefetch<HookData<BookingAndCartCombined>> {
    const query = useQuery<{ details: StoredCart }>(
      GET_MULTIPLE_BOOKING_DETAILS_QUERY,
      {
        variables: {
          id: shoppingCartId,
          token,
        },
      },
    );

    return {
      refetch: () => {
        query.refetch().catch(() => {});
      },
      loading: query.loading,
      error: query.error,
      value: query.data?.details
        ? {
            ...mapCartJSONToObject(query.data.details),
          }
        : null,
    };
  },
};

export default BookingDetailsAdapter;
