import { useInject } from 'inversify-hooks';

import type { TreatmentLength, TreatmentType } from '../../../types';
import injectables from '../../injectables';
import type { ITreatmentAdapter } from '../ITreatmentAdapter';
import LengthSelectorContent from './LengthSelectorContent';

const LengthSelectorWithOutTimeRanges = ({
  treatmentType,
  selectedLength,
  onSelect,
  onClick,
  expanded,
}: {
  treatmentType: TreatmentType | null;
  selectedLength: TreatmentLength | null;
  onSelect: (treatmentType: TreatmentLength) => void;
  onClick: () => void;
  expanded: boolean;
}): JSX.Element => {
  const [service] = useInject<ITreatmentAdapter>(injectables.TreatmentAdapter);
  const treatmentLengths = service.useTreatmentLengths(treatmentType?.id);
  return (
    <LengthSelectorContent
      treatmentLengths={treatmentLengths}
      selectedLength={selectedLength}
      onSelect={onSelect}
      onClick={onClick}
      expanded={expanded}
    />
  );
};

export default LengthSelectorWithOutTimeRanges;
