import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { EditProfileFormValues } from 'application/pages/EditProfile/EditProfileForm';
import { EditProfileForm } from 'application/pages/EditProfile/EditProfileForm';
import { formatISO, parseISO, set } from 'date-fns';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory } from 'react-router';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import isPhoneNumber from 'ui/utils/isPhoneNumber';
import * as Yup from 'yup';

import type ILoginService from '../../services/ILoginService';
import injectables from '../../services/injectables';
import pageInjectables from '../injectables';
import type IEditProfileAdapter from './IEditProfileAdapter';

// TODO: refactor required
/* eslint-disable @typescript-eslint/unbound-method */

const EditProfile: React.FC = () => {
  const t = useContextTranslation('page.edit_profile');
  const td = useContextTranslation('layout.date_input');
  const history = useHistory();
  const [adapter] = useInject<IEditProfileAdapter>(
    pageInjectables.EditProfileAdapter,
  );

  const [loginService]: ILoginService[] = useInject<ILoginService>(
    injectables.LoginService,
  );
  const userProfile = loginService.useUserProfile();

  const initialValues = {
    name: userProfile?.value?.name || '',
    surname: userProfile?.value?.surname || '',
    birthday: formatISO(userProfile?.value?.birthday || new Date()),
    companyName: userProfile?.value?.companyName || '',
    phoneNumber: userProfile?.value?.phoneNumber || '',
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .min(3, ({ min }) => t('name_min_length', { min }))
          .max(128, ({ max }) => t('name_max_length', { max }))
          .required(),
        surname: Yup.string()
          .min(3, ({ min }) => t('name_min_length', { min }))
          .max(128, ({ max }) => t('name_max_length', { max }))
          .required(),
        birthday: Yup.date()
          .typeError(td('invalid_date'))
          .max(new Date(), td('invalid_date'))
          .min(
            set(new Date(), {
              year: 1920,
              month: 1,
              date: 1,
            }),
            td('invalid_date'),
          ),
        companyName: Yup.string()
          .optional()
          .max(256, ({ max }) =>
            t('company_name_max_length', {
              max,
            }),
          ),
        phoneNumber: Yup.string()
          .max(32, t('phone_number_invalid_format'))
          .test({
            message: t('phone_number_invalid_format'),
            test: (value) => !!value && isPhoneNumber(value),
          })
          .required(),
      }),
    [t],
  );

  const onSubmit = React.useCallback<
    (
      values: EditProfileFormValues,
      helpers: FormikHelpers<EditProfileFormValues>,
    ) => Promise<void>
  >(
    async (
      values: EditProfileFormValues,
      helpers: FormikHelpers<EditProfileFormValues>,
    ) => {
      try {
        const { name, surname, birthday, phoneNumber, companyName } = values;

        await adapter.editProfile({
          name,
          surname,
          birthday: parseISO(birthday),
          phoneNumber,
          companyName,
        });

        helpers.setStatus(null);
        history.replace('/account', { unmount: true, direction: 'back' });
      } catch (e) {
        helpers.setStatus({
          error: e,
        });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [t, adapter.editProfile, history],
  );

  return (
    <SubPageLayout>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h1>{t('title')}</h1>
          </IonCol>
        </IonRow>
        <Formik
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <EditProfileForm />
        </Formik>
      </IonGrid>
    </SubPageLayout>
  );
};

export default EditProfile;
