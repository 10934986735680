export enum ExternalAuthenticationStatus {
  LOGGED_IN = 'logged_in',
  ACCOUNT_CREATED = 'account_created',
  APPLE_NOT_FIRST_LOGIN = 'apple_not_first_login',
}

export class AccountProviderMismatch extends Error {
  constructor() {
    super('account_provider_mismatch');
  }
}

export type ExternalAuthenticationResult =
  | {
      status: ExternalAuthenticationStatus.LOGGED_IN;
      accessToken: string;
    }
  | {
      status: ExternalAuthenticationStatus.ACCOUNT_CREATED;
      registrationToken: string;
    }
  | {
      status: ExternalAuthenticationStatus.APPLE_NOT_FIRST_LOGIN;
    };

export default interface IExternalAuthenticationAdapter {
  getAuthenticationResult(
    providerName: string,
    clientSecret: string,
    name?: string,
    surname?: string,
  ): Promise<ExternalAuthenticationResult>;
}
