import type {
  TranslatableString,
  TranslatableStringLanguage,
} from 'application/types';
import { useCallback } from 'react';
import * as React from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import i18n from '../../infrastructure/i18n';
import { TOptions } from 'i18next';

type TranslationFunction = TFunction;

export const useContextTranslation = (context: string): TranslationFunction => {
  const { t } = useTranslation();
  return useCallback(
    (key: string, interpolationMap: string | TOptions<object> | undefined) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      t(`${context}.${key}`, interpolationMap),
    [t, context],
  );
};

export const translateString: (
  value: { value: string; lang: string }[],
  language: string,
) => string = (value, language) =>
  (
    value.find((curr) => curr.lang === language) ||
    value.find((curr) => curr.lang === 'en') ||
    value[0]
  ).value;

export const translateOptionalString: (
  value: TranslatableStringLanguage[],
  language: string,
) => string | undefined = (value, language) => {
  const translatedString = value.find((curr) => curr.lang === language)?.value;
  return translatedString || value.find((curr) => curr.value)?.value;
};

export const useTranslatedString: (
  value?: TranslatableString,
) => string | null = (value) => {
  const {
    i18n: { language },
  } = useTranslation();

  if (!value || !Array.isArray(value)) {
    return null;
  }
  return translateString(value, language);
};

export const useTranslateString = (): ((
  value: TranslatableString,
) => string | undefined) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (value: TranslatableString) => translateString(value, language);
};

export const useTranslateOptionalString = (): ((
  value: TranslatableStringLanguage[],
) => string | undefined) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (value: TranslatableStringLanguage[]) =>
    translateOptionalString(value, language);
};

interface TranslatableFormatterProps {
  value?: TranslatableString;
}

export const TranslatableFormatter: React.FC<TranslatableFormatterProps> = ({
  value,
}) => {
  const text = useTranslatedString(value);
  return React.createElement('span', null, text);
};

export default {
  i18n,
  useContextTranslation,
  TranslatableFormatter,
};
