import './treatmentCard.scss';

import { IonAvatar, IonButton, IonRouterLink, IonText } from '@ionic/react';
import type { TreatmentSearchResult } from 'application/pages/Treatments/ITreatmentAdapter';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import { format, isEqual } from 'date-fns';
import * as React from 'react';
import { TranslatableFormatter, useContextTranslation } from 'ui/translation';

interface TreatmentResultCard {
  result: TreatmentSearchResult;
  displayAvailabilities?: boolean;
  showBookingRequestCTA?: boolean;
  showBookingCTA?: boolean;
}

export const Availabilities: React.FC<{
  slots: Date[];
  showBookingRequestCTA?: boolean;
}> = ({ slots, showBookingRequestCTA }) => {
  const t = useContextTranslation('page.treatment');
  const builder = useTreatmentBuilder();

  return (
    <>
      {slots?.length ? (
        slots.map(date => (
          <button
            key={date.toISOString()}
            className={
              builder.slotTime && isEqual(date, builder.slotTime)
                ? 'selected'
                : undefined
            }
            type="button"
            onClick={() => {
              builder.setSlotTime(date);
            }}
          >
            {format(date, 'H:mm')}
          </button>
        ))
      ) : (
        <IonText color="tertiary">
          <h6>{t('no_availabilities_1')}</h6>
          {showBookingRequestCTA ? (
            <h6 className="no-availabilities-text">
              {t('no_availabilities_2')}
            </h6>
          ) : null}
        </IonText>
      )}
    </>
  );
};

const TreatmentResult: React.FC<TreatmentResultCard> = ({
  result,
  displayAvailabilities,
  showBookingRequestCTA,
  showBookingCTA,
}) => {
  const t = useContextTranslation('page.treatment');

  return (
    <div className="treatment-result-card">
      <div className="card-header">
        <IonAvatar>
          <img src={result.profilePictureUrl} alt={t('profile_picture')} />
        </IonAvatar>
        <div className="name-container">
          <div className="name">{result.name}</div>
          <div className="age">{t('profile_age', { age: result.age })}</div>
        </div>
        <div className="submenu">
          <IonRouterLink routerLink={`/expert/${result.id}/info`}>
            {t('view_profile')}
          </IonRouterLink>
        </div>
      </div>
      <div className="description">
        <TranslatableFormatter value={result.slogan} />
      </div>
      <div className="footer">
        <div className="availabilities-container">
          {showBookingCTA && (
            <IonButton
              expand="block"
              color="primary"
              className="cta-continue"
              routerLink={`/expert/${result.id}`}
            >
              {t('cta_continue')}
            </IonButton>
          )}

          {!showBookingCTA && !displayAvailabilities && (
            <IonText color="tertiary">
              <h6>{t('choose_params_to_see_availabilities')}</h6>
            </IonText>
          )}
          {displayAvailabilities && (
            <Availabilities
              showBookingRequestCTA={showBookingRequestCTA}
              slots={result.availabilities}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TreatmentResult;
