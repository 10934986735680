import type IAnonymousBookingsService from 'application/services/IAnonymousBookingsService';
import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { loggingServiceSymbol } from 'application/services/IErrorLoggingService';
import type ILocaleService from 'application/services/ILocaleService';
import type INotificationService from 'application/services/INotificationService';
import type { ITrackingService } from 'application/services/ITrackingService';
import { container } from 'inversify-props';

import type ILoginService from '../../application/services/ILoginService';
import injectables from '../../application/services/injectables';
import type IOnboardingService from '../../application/services/IOnboardingService';
import type IService from '../../application/services/IService';
import CustomerioGTMTrackingService from './CustomerioGTMTrackingService';
import FirebaseNotificationService from './FirebaseNotificationService';
import NativeLocaleService from './NativeLocaleService';
import SentryErrorLoggingService from './SentryErrorLoggingService';
import StorageAnonymousBookingsService from './StorageAnonymousBookingsService';
import StorageLoginService from './StorageLoginService';
import StorageOnboardingService from './StorageOnboardingService';

container.addSingleton<ILoginService>(
  StorageLoginService,
  injectables.LoginService,
);

container.addSingleton<IOnboardingService>(
  StorageOnboardingService,
  injectables.OnboardingService,
);

container.addSingleton<IAnonymousBookingsService>(
  StorageAnonymousBookingsService,
  injectables.AnonymousBookingsService,
);

container.addSingleton<INotificationService>(
  FirebaseNotificationService,
  injectables.NotificationService,
);

container.addSingleton<ITrackingService>(
  CustomerioGTMTrackingService,
  injectables.TrackingService,
);

container.addSingleton<ILocaleService>(
  NativeLocaleService,
  injectables.LocaleService,
);

export const SERVICE_IDENTIFIER = Symbol('Service');

Object.values(injectables).forEach((serviceIdentifier) => {
  container
    .bind<IService>(SERVICE_IDENTIFIER)
    .toConstantValue(container.get<IService>(serviceIdentifier));
});

container.addSingleton<IErrorLoggingService>(
  SentryErrorLoggingService,
  loggingServiceSymbol,
);

export default {
  SERVICE_IDENTIFIER,
};
