export default {
  HomeAdapter: Symbol('HomeAdapter'),
  TreatmentAdapter: Symbol('TreatmentAdapter'),
  HistoricalLocationAdapter: Symbol('HistoricalLocationAdapter'),
  LocationResolverAdapter: Symbol('LocationResolverAdapter'),
  RegistrationAdapter: Symbol('RegistrationAdapter'),
  ExpertProfileAdapter: Symbol('ExpertProfile'),
  PasswordChangeAdapter: Symbol('PasswordChangeAdapter'),
  PasswordResetAdapter: Symbol('PasswordResetAdapter'),
  BookingPaymentAdapter: Symbol('BookingPaymentAdapter'),
  CalendarAdapter: Symbol('CalendarAdapter'),
  PaymentProvider: Symbol('PaymentProvider'),
  AccountBookingsAdapter: Symbol('AccountBookingsAdapter'),
  VoucherPaymentAdapter: Symbol('VoucherPaymentAdapter'),
  CredentialsLoginAdapter: Symbol('CredentialsLoginAdapter'),
  ReviewAdapter: Symbol('ReviewAdapter'),
  ExternalAuthenticationProvider: Symbol('ExternalAuthenticationProvider'),
  BookingDetailsAdapter: Symbol('BookingDetailsAdapter'),
  EditProfileAdapter: Symbol('EditProfileAdapter'),
  SavedCardsAdapter: Symbol('SavedCardsAdapter'),
  SofortPaymentAdapter: Symbol('SofortPaymentAdapter'),
  CustomGoBackAdapter: Symbol('CustomGoBackAdapter'),
  TreatmentDetailsAdapter: Symbol('TreatmentDetailsAdapter'),
  ShareAdapter: Symbol('ShareAdapter'),
  OpenSourceNoticesAdapter: Symbol('OpenSourceNoticesAdapter'),
  BookingRequestAdapter: Symbol('BookingRequestAdapter'),
};
