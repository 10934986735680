import { gql, useQuery } from '@apollo/client';
import type {
  AvailableExpertsQuery,
  CurrentlyAvailableBookings,
  ITreatmentAdapter,
  NextExpertAvailableSlots,
  TypesInfoParams,
} from 'application/pages/Treatments/ITreatmentAdapter';
import type {
  HookData,
  ObjectID,
  TranslatableString,
  TreatmentLength,
  TreatmentPrice,
  TreatmentType,
} from 'application/types';
import { getTimeRangeForDayAndRelativeRange } from 'application/types/utils';
import { useMemo } from 'react';

// TODO Refactor required
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */

const GET_TREATMENT_TYPE_INFO = gql`
  query GetTreatmentTypeInfo(
    $locationId: ID
    $onlySpaTypes: Boolean
    $treatmentTypeVariant: TreatmentTypeVariant
    $ignoreOnlySpa: Boolean
  ) {
    treatmentTypeInfo(
      locationId: $locationId
      onlySpaTypes: $onlySpaTypes
      treatmentTypeVariant: $treatmentTypeVariant
      ignoreOnlySpa: $ignoreOnlySpa
    ) {
      id
      description {
        lang
        value
      }
      media {
        type
        uri
      }
      name {
        lang
        value
      }
      prices {
        length
        morning
        afternoon
        evening
      }
    }
  }
`;

export const GET_SPA_TREATMENT_TYPES = gql`
  query GetSpaTreatmentTypes($locationId: ID!, $pagination: Pagination!) {
    spaTreatmentTypes(locationId: $locationId, pagination: $pagination) {
      locationTreatmentTypes {
        id
        description {
          lang
          value
        }
        media {
          type
          uri
        }
        name {
          lang
          value
        }
        prices {
          length
        }
      }
    }
  }
`;

const GET_BOOKING_LENGTHS_SEARCH_RESULTS = gql`
  query GetBookingLengthsSearchResults(
    $bookingTime: Date!
    $treatmentTypeId: ID!
    $expertId: ID!
  ) {
    availableBookingLengths(
      bookingTime: $bookingTime
      treatmentTypeId: $treatmentTypeId
      expertId: $expertId
    ) {
      length
      morning
      afternoon
      evening
    }
  }
`;

const GET_MULTIPLE_BOOKING_LENGTHS_SEARCH_RESULTS = gql`
  query GetMultipleBookingLengthsSearchResults(
    $bookingTime: Date!
    $treatments: [ExpertTreatment!]!
    $locationId: ID
    $postalCode: String
  ) {
    availableMultiBookingLengths(
      bookingTime: $bookingTime
      treatments: $treatments
      locationId: $locationId
      postalCode: $postalCode
    ) {
      length
      morning
      afternoon
      evening
    }
  }
`;

const GET_AVAILABILITIES_IN_TIME_RANGE = gql`
  query GetAvailabilitiesInTimeRange(
    $postalCode: String
    $treatmentTypeId: ID
    $timeRange: TimeRange!
    $spaAvailabilities: Boolean
    $locationId: ID
    $treatmentTypeVariant: TreatmentTypeVariant
  ) {
    expertAvailabilitiesInRange(
      postalCode: $postalCode
      treatmentTypeId: $treatmentTypeId
      timeRange: $timeRange
      spaAvailabilities: $spaAvailabilities
      locationId: $locationId
      treatmentTypeVariant: $treatmentTypeVariant
    ) {
      totalExperts
      availableDays {
        date
        availableExperts {
          morning
          afternoon
          evening
        }
        minimalTreatmentPrice {
          morning
          afternoon
          evening
        }
      }
    }
  }
`;

const GET_AVAILABLE_EXPERTS = gql`
  query availableExperts(
    $postalCode: String
    $timeRange: TimeRange
    $pagination: Pagination!
    $selectedExpertIds: [ID]
    $locationId: ID
  ) {
    availableExperts(
      postalCode: $postalCode
      timeRange: $timeRange
      selectedExpertIds: $selectedExpertIds
      pagination: $pagination
      locationId: $locationId
    ) {
      id
      age
      bookingCount
      name
      treatmentTypes
      media {
        type
        uri
      }
      rating
      slogan {
        lang
        value
      }
      profilePictureUrl
    }
  }
`;

export const GET_NEXT_AVAILABLE_EXPERTS = gql`
  query multipleExpertsAvailabilities(
    $postalCode: String!
    $rangeStart: Date!
    $rangeEnd: Date!
    $numberOfExperts: Int!
    $selectedExpertIds: [ID]!
    $locationId: ID
    $treatmentTypeIds: [ID]
  ) {
    multipleExpertsAvailabilities(
      postalCode: $postalCode
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
      numberOfExperts: $numberOfExperts
      selectedExpertIds: $selectedExpertIds
      locationId: $locationId
      treatmentTypeIds: $treatmentTypeIds
    ) {
      slots {
        slot
        experts
      }
    }
  }
`;

export interface GraphQLTreatmentSearchResult {
  id: ObjectID;
  age: number;
  name: string;
  slogan: TranslatableString;
  profilePictureUrl: string;
  availabilities: string[];
}

export function useTreatmentTypeInfo(
  params?: TypesInfoParams,
): HookData<TreatmentType[]> {
  const query = useQuery<{
    treatmentTypeInfo: TreatmentType[];
  }>(GET_TREATMENT_TYPE_INFO, {
    variables: {
      locationId: params?.locationId,
      ignoreOnlySpa: params?.ignoreOnlySpa,
      onlySpaTypes: params?.isSpaBooking,
      treatmentTypeVariant: params?.treatmentTypeVariant,
    },
  });

  return {
    loading: query.loading,
    error: query.error,
    value: query.data?.treatmentTypeInfo || null,
  };
}

function getMinimalPrice(price: TreatmentPrice): number {
  return Math.min(
    parseFloat(price.morning),
    parseFloat(price.afternoon),
    parseFloat(price.evening),
  );
}

const TreatmentAdapter: ITreatmentAdapter = {
  useTreatmentLengths(treatmentTypeId): HookData<TreatmentLength[]> {
    const types = useTreatmentTypeInfo();

    if (types.loading || types.error || !types.value) {
      return {
        value: null,
        loading: types.loading,
        error: types.error,
      };
    }

    // get treatment lengths of a selected type
    if (treatmentTypeId) {
      const matchedType = types.value.find(
        (treatmentType) => treatmentType.id === treatmentTypeId,
      );
      if (matchedType) {
        return {
          loading: false,
          value: matchedType.prices.map((price) => ({
            length: price.length,
            id: String(price.length),
            price: getMinimalPrice(price),
          })),
        };
      }
    }

    // get full set of available lengths by default
    const value: TreatmentLength[] = Object.values(
      types.value
        .map((treatmentType) => treatmentType.prices)
        .flat()
        .reduce<{ [key: number]: TreatmentLength }>((acc, price) => {
          if (!acc[price.length]) {
            acc[price.length] = {
              id: String(price.length),
              length: price.length,
              price: getMinimalPrice(price),
            };
          }
          acc[price.length].price = Math.min(
            acc[price.length].price,
            getMinimalPrice(price),
          );
          return acc;
        }, {}),
    );

    return {
      loading: false,
      value,
    };
  },

  useCurrentlyAvailableBookings(params): HookData<CurrentlyAvailableBookings> {
    const query = useQuery<{
      expertAvailabilitiesInRange: CurrentlyAvailableBookings;
    }>(GET_AVAILABILITIES_IN_TIME_RANGE, {
      variables: {
        postalCode: params.location?.postalCode,
        timeRange: params?.dateRange,
        locationId: params?.locationId,
        spaAvailabilities: params?.spaAvailabilities,
        treatmentTypeId: params?.treatmentTypeId,
        treatmentTypeVariant: params?.treatmentTypeVariant,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

    const val = query.data?.expertAvailabilitiesInRange
      ? {
          availableDays:
            query.data.expertAvailabilitiesInRange.availableDays?.filter(
              (availableDay) => {
                const { availableExperts } = availableDay;
                return (
                  availableExperts.morning ||
                  availableExperts.afternoon ||
                  availableExperts.evening
                );
              },
            ),
          totalExperts: query.data.expertAvailabilitiesInRange.totalExperts,
        }
      : null;

    return {
      loading: query.loading,
      error: query.error,
      value: val,
    };
  },

  useBookingLengthSearchResults(params): HookData<TreatmentLength[]> {
    const query = useQuery<{
      availableBookingLengths: TreatmentPrice[];
    }>(GET_BOOKING_LENGTHS_SEARCH_RESULTS, {
      variables: {
        bookingTime: params.slotTime,
        treatmentTypeId: params.treatmentTypeId,
        expertId: params.expertId,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

    if (!query.data?.availableBookingLengths || query.loading || query.error) {
      return {
        value: null,
        loading: query.loading,
        error: query.error,
      };
    }

    return {
      loading: query.loading,
      error: query.error,
      value: query.data.availableBookingLengths.map((length) => ({
        id: String(length.length),
        length: length.length,
        price: Number(length[params.treatmentTimeRange]),
      })),
    };
  },

  useMultipleBookingLengthSearchResults(params): HookData<TreatmentLength[]> {
    const query = useQuery<{
      availableMultiBookingLengths: TreatmentPrice[];
    }>(GET_MULTIPLE_BOOKING_LENGTHS_SEARCH_RESULTS, {
      variables: {
        bookingTime: params.slotTime,
        treatments: params.experts.map((expert) => ({
          expertId: expert.expertId,
          treatmentTypeId: expert.type?.id,
        })),
        postalCode: params.postalCode,
        locationId: params.locationId,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

    if (
      !query.data?.availableMultiBookingLengths ||
      query.loading ||
      query.error
    ) {
      return {
        value: null,
        loading: query.loading,
        error: query.error,
      };
    }

    return {
      loading: query.loading,
      error: query.error,
      value: query.data.availableMultiBookingLengths.map((length) => ({
        id: String(length.length),
        length: length.length,
        price: Number(length[params.treatmentTimeRange]),
      })),
    };
  },

  useTreatmentTypes(params?: TypesInfoParams): HookData<TreatmentType[]> {
    return useTreatmentTypeInfo(params);
  },

  useSPATreatmentTypesQuery(params): HookData<TreatmentType[]> {
    const query = useQuery<{
      spaTreatmentTypes: { locationTreatmentTypes: TreatmentType[] };
    }>(GET_SPA_TREATMENT_TYPES, {
      variables: {
        locationId: params.locationId,
        pagination: params.pagination,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

    return {
      loading: query.loading,
      error: query.error,
      value: query.data?.spaTreatmentTypes.locationTreatmentTypes || null,
    };
  },

  useAvailableExpertsQuery(params): HookData<AvailableExpertsQuery[]> {
    const timeRange = useMemo(
      () =>
        params.date
          ? getTimeRangeForDayAndRelativeRange(
              params.date.date,
              params.date.timeRange,
            )
          : null,
      [params.date?.date, params.date?.timeRange],
    );

    const query = useQuery<{
      availableExperts: AvailableExpertsQuery[];
    }>(GET_AVAILABLE_EXPERTS, {
      variables: {
        postalCode: params.postalCode,
        locationId: params.locationId,
        timeRange: timeRange?.start
          ? {
              fromDate: timeRange?.start || null,
              toDate: timeRange?.end || null,
            }
          : null,
        selectedExpertIds: params.selectedExpertIds,
        pagination: params.pagination,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

    return {
      loading: query.loading,
      error: query.error,
      value:
        query.data?.availableExperts
          .filter((expert) => expert.treatmentTypes.length)
          .map<AvailableExpertsQuery>((result) => ({
            ...result,
            slogan: result.slogan.map((translatable) => ({
              lang: translatable.lang,
              value: translatable.value,
            })) as TranslatableString,
          })) || null,
      fetchMore: (fetchMoreParams) =>
        query.fetchMore({
          ...fetchMoreParams,
          updateQuery: (prev, { fetchMoreResult }) => {
            const ids =
              fetchMoreResult?.availableExperts?.reduce(
                (acc, result) => {
                  acc[result.id] = true;
                  return acc;
                },
                {} as { [key: string]: boolean },
              ) || {};
            return {
              availableExperts: [
                ...(prev?.availableExperts?.filter((el) => !ids[el.id]) || []),
                ...(fetchMoreResult?.availableExperts.filter(
                  (expert) => expert.treatmentTypes.length,
                ) || []),
              ],
            };
          },
        }),
    };
  },

  useNextExpertsSlotsQuery(params): HookData<NextExpertAvailableSlots[]> {
    const timeRange = useMemo(
      () =>
        params.date
          ? getTimeRangeForDayAndRelativeRange(
              params.date.date,
              params.date.timeRange,
            )
          : null,
      [params.date?.date, params.date?.timeRange],
    );

    const query = useQuery<{
      multipleExpertsAvailabilities: { slots: NextExpertAvailableSlots[] };
    }>(GET_NEXT_AVAILABLE_EXPERTS, {
      variables: {
        rangeStart: timeRange?.start || null,
        rangeEnd: timeRange?.end || null,
        numberOfExperts: params.numberOfExperts,
        selectedExpertIds: params.selectedExpertIds,
        postalCode: params.postalCode,
        locationId: params.locationId || undefined,
        treatmentTypeIds: params.treatmentTypeIds,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

    return {
      loading: query.loading,
      error: query.error,
      value: query.data?.multipleExpertsAvailabilities?.slots || null,
    };
  },
};

export default TreatmentAdapter;
