import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonLoading,
  IonRow,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import type { PaymentParams } from 'application/pages/BookingPayment/IPaymentProvider';
import type IPaymentProvider from 'application/pages/BookingPayment/IPaymentProvider';
import config from 'config';
import useStripePaymentIntent from 'infrastructure/adapters/payment/useStripePaymentIntent';
import { injectable } from 'inversify';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useContextTranslation } from 'ui/translation';

// TODO refactor required -> hooks should be not used in class components
/* eslint-disable react-hooks/rules-of-hooks */

@injectable()
class SofortPaymentProvider implements IPaymentProvider {
  key = 'sofort';

  weight = 20;

  getName(): string {
    return 'Sofort';
  }

  isAvailable(): boolean {
    return true;
  }

  Render(params: PaymentParams): JSX.Element {
    const t = useContextTranslation('payment.sofort');
    const paymentSheet = useStripePaymentIntent({
      subject: params.instance.subject,
      targetId: params.instance.targetId,
      paymentMethod: 'sofort',
    });

    const history = useHistory();
    const [showAlert] = useIonAlert();

    const startPayment = React.useCallback(() => {
      if (!paymentSheet.value) {
        if (paymentSheet.error) {
          void showAlert({
            message: `Could not create payment: ${paymentSheet.error.message}`,
          });
        }
        return;
      }

      const startPaymentPath = `/payment/sofort/${params.instance.subject}/${
        params.instance.targetId
      }/${paymentSheet.value.paymentIntent}${
        params.instance.secret ? `/${params.instance.secret}` : ''
      }`;

      if (isPlatform('capacitor')) {
        App.getInfo()
          .then((appInfo) => {
            const basePath = config.universalLinkPrefix;
            return Browser.open({
              url: `${basePath}${startPaymentPath}?return_prefix=${appInfo.id}`,
              presentationStyle: 'fullscreen',
              windowName: 'Sofort',
            });
          })
          .catch(params.onError);
        return;
      }

      history.replace(startPaymentPath, {
        unmount: true,
        direction: 'forward',
      });
    }, [paymentSheet.value]);

    React.useEffect(() => {
      startPayment();
    }, [paymentSheet.value]);

    return (
      <div className="stripe-payment">
        <IonLoading isOpen={paymentSheet.loading} />
        {!paymentSheet.loading && (
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <p>{t('canceled_title')}</p>
              </IonCol>
              <IonCol>
                <IonButton onClick={() => startPayment()} expand="block">
                  {t('retry')}
                </IonButton>
                <IonButton
                  color="danger"
                  expand="block"
                  onClick={() => params.onError(new Error(t('cancelled')))}
                >
                  {t('cancel')}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </div>
    );
  }
}

export default SofortPaymentProvider;
