import { gql, useQuery } from '@apollo/client';
import type ITreatmentTypeDetailsAdapter from 'application/pages/TreatmentTypeDetails/ITreatmentTypeDetailsAdapter';
import type { HookData, TreatmentType } from 'application/types';

const GET_TREATMENT_TYPE_INFO = gql`
  query GetTreatmentTypeInfo($locationId: ID, $treatmentId: ID) {
    treatmentTypeInfo(locationId: $locationId, treatmentId: $treatmentId) {
      id
      description {
        lang
        value
      }
      longDescription {
        lang
        value
      }
      media {
        type
        uri
      }
      name {
        lang
        value
      }
      details {
        oil
        level {
          lang
          value
        }
        pressure {
          lang
          value
        }
        origin {
          lang
          value
        }
      }
    }
  }
`;

function useTreatmentTypeInfo(
  locationId?: string | null,
  treatmentId?: string | null,
): HookData<TreatmentType[]> {
  const query = useQuery<{
    treatmentTypeInfo: TreatmentType[];
  }>(GET_TREATMENT_TYPE_INFO, {
    variables: {
      locationId,
      treatmentId,
    },
  });

  return {
    loading: query.loading,
    error: query.error,
    value: query.data?.treatmentTypeInfo || null,
  };
}

const TreatmentTypeDetailsAdapter: ITreatmentTypeDetailsAdapter = {
  useTreatmentDetails(
    locationId?: string | null,
    treatmentId?: string | null,
  ): HookData<TreatmentType[]> {
    return useTreatmentTypeInfo(locationId, treatmentId);
  },
};

export default TreatmentTypeDetailsAdapter;
