import './bookingSummary.scss';

import { IonCol, IonGrid, IonRow, useIonViewWillEnter } from '@ionic/react';
import BookingDetailsForm from 'application/pages/BookingSummary/BookingDetailsForm';
import { useAuth } from 'application/state/AuthProvider';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { TreatmentDetails } from 'application/types';
import { BookingPlace } from 'application/types';
import type { FormikHelpers } from 'formik';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import BookingSummaryFooter from 'ui/elements/BookingSummaryFooter';
import BookingSummaryTable from 'ui/elements/BookingSummaryTable';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import * as Yup from 'yup';

import BeforeContentGallery, {
  defaultMedia,
} from '../../../ui/elements/BeforeContentGallery';
import MultipleExpertsTreatmentLengthSelector from '../Treatments/MultipleExperts/MultipleExpertsTreatmentLengthSelector';

// TODO: refactor required
/* eslint-disable  @typescript-eslint/no-floating-promises */
/* eslint-disable  @typescript-eslint/no-explicit-any */

type TreatmentDetailsValues = Omit<TreatmentDetails, 'massageTable'>;

const SPABookingSummary: React.FC = () => {
  const {
    slotTime,
    date,
    length,
    location,
    type,
    isSpaBooking,
    locationMedia,
    details,
    locationId,
    setDetails,
    setLength,
  } = useTreatmentBuilder();
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const t = useContextTranslation('page.booking_summary');
  const [expandedLength, setExpandedLength] = React.useState<boolean>(false);
  const hasCompleteData = Boolean(
    slotTime && date && location && locationId && type && isSpaBooking,
  );

  const onSubmit = React.useCallback<
    (
      values: TreatmentDetailsValues,
      helpers: FormikHelpers<TreatmentDetailsValues>,
    ) => any
  >(
    (values, helpers) => {
      helpers.setSubmitting(false);
      setDetails({
        ...values,
        massageTable: null,
      });
      if (isAuthenticated) {
        history.push('/booking/payment');
        return;
      }
      history.push('/booking/contact');
    },
    [history, isAuthenticated],
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        notes: Yup.string().max(1000, t('form.notes_too_long')).optional(),
      }),
    [t],
  );

  const formik = useFormik<TreatmentDetailsValues>({
    initialValues: details
      ? {
          ...details,
        }
      : { notes: '', place: BookingPlace.Hotel },
    validationSchema,
    onSubmit,
  });

  useIonViewWillEnter(() => {
    if (!hasCompleteData) {
      history.replace('/treatments');
    }
    if (!details) {
      formik.resetForm();
    }
  }, [hasCompleteData, history, details]);

  useEffect(() => {
    setDetails({
      ...formik.values,
      massageTable: null,
    });
  }, [formik.values]);

  return (
    <SubPageLayout
      className="booking-summary"
      beforeContent={
        <BeforeContentGallery media={locationMedia || defaultMedia} />
      }
      footerContent={() =>
        slotTime && (
          <BookingSummaryFooter
            nextEnabled={formik.isValid && !!length}
            onNext={() => {
              formik.submitForm();
            }}
          />
        )
      }
    >
      {slotTime && location && type && (
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol className="no-padding-top" size="12">
              <h4>{t('header')}</h4>
              <BookingSummaryTable
                slotTime={slotTime}
                location={location}
                type={type}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <h4>{t('form_header')}</h4>
              {date?.timeRange && (
                <MultipleExpertsTreatmentLengthSelector
                  slotTime={slotTime}
                  onClick={() => setExpandedLength(!expandedLength)}
                  expanded={expandedLength}
                  treatmentLength={length}
                  treatmentTimeRange={date.timeRange}
                  experts={[{ type }]}
                  locationId={locationId || undefined}
                  onSelect={(selectedLength) => {
                    setLength(selectedLength);
                    setExpandedLength(false);
                  }}
                />
              )}
              <FormikProvider value={formik}>
                <BookingDetailsForm inSpa />
              </FormikProvider>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </SubPageLayout>
  );
};

export default SPABookingSummary;
