import { IonContent, IonHeader, IonPage } from '@ionic/react';
import * as React from 'react';

interface FullscreenLayoutProps {
  header?: React.ReactElement;
}

const FullscreenLayout: React.FC<FullscreenLayoutProps> = ({
  children,
  header,
}) => (
  <IonPage className="fullscreen-layout">
    {header && <IonHeader>{header}</IonHeader>}
    <IonContent forceOverscroll={false} scrollEvents>
      {children}
    </IonContent>
  </IonPage>
);

export default FullscreenLayout;
