import './subPageWrapperForModal.scss';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonContent } from '@ionic/react';
import React from 'react';

const SubPageWrapperForModal: React.FC<{
  beforeContent: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  wrapperClassName?: string;
  children: React.ReactNode;
}> = ({ beforeContent, onClose, wrapperClassName, children }) => {
  const headerRef = React.useRef<HTMLIonHeaderElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <button
        type="button"
        aria-label="close"
        className="close-button"
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className={`sub-page-wrapper-for-modal ${wrapperClassName || ''}`}>
        <IonContent
          forceOverscroll={false}
          scrollEvents
          onIonScroll={(event) => {
            if (!headerRef.current || !scrollContainerRef.current) {
              return;
            }
            if (event.detail.scrollTop > 20) {
              headerRef.current.classList.add('shadow');
              scrollContainerRef.current.classList.add('scrolled');
            } else {
              headerRef.current.classList.remove('shadow');
              scrollContainerRef.current.classList.remove('scrolled');
            }
          }}
        >
          <div ref={scrollContainerRef} className="scroll-container">
            <div className="before-content">{beforeContent}</div>
            <div className="content-container">{children}</div>
          </div>
        </IonContent>
      </div>
    </>
  );
};

export default SubPageWrapperForModal;
