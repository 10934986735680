import { gql } from '@apollo/client';
import type { OrderVoucherParams } from 'application/pages/VoucherPayment/IVoucherPaymentAdapter';
import type IVoucherPaymentAdapter from 'application/pages/VoucherPayment/IVoucherPaymentAdapter';
import client from 'infrastructure/apollo';

const ORDER_VOUCHERS = gql`
  mutation OrderVouchers($input: OrderVoucherInput!) {
    orderVouchers(input: $input) {
      paymentTargetId
    }
  }
`;

const VoucherPaymentAdapter: IVoucherPaymentAdapter = {
  async orderVouchers(data: OrderVoucherParams): Promise<string> {
    const res = await client.mutate({
      mutation: ORDER_VOUCHERS,
      variables: {
        input: {
          buyerData: data.buyerData && {
            ...data.buyerData,
          },
          items: [{ value: data.value.toString(), amount: data.amount }],
          message: data.message || undefined,
          discountCode: data.discountCode || undefined,
        },
      },
    });

    if (!res.data.orderVouchers) {
      throw new Error('Error while ordering vouchers');
    }

    //TODO: Check if this is the correct return value
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return res.data.orderVouchers.paymentTargetId;
  },
};

export default VoucherPaymentAdapter;
