import type {
  BookingPriceInfo,
  BookingPriceInfoComponent,
} from 'application/types';
import currency from 'currency.js';
import * as React from 'react';
import { useContextTranslation, useTranslateString } from 'ui/translation';
import Currency from 'ui/utils/Currency';

import type { TreatmentExpert } from '../../state/TreatmentContext';
import { useTreatmentBuilder } from '../../state/TreatmentContext';

interface BookingPaymentFormProps {
  experts: TreatmentExpert[] | null;
  bill: BookingPriceInfo;
  inSpa: boolean;
}

interface BillComponentProps {
  label: string;
  className: string;
  price: currency;
}

const BillComponent: React.FC<BillComponentProps> = ({
  label,
  className,
  price,
}) => {
  if (!price.intValue) {
    return null;
  }

  return (
    <div className={`price ${className}`}>
      <div className="label">{label}</div>
      <div className="value">
        <Currency value={price} />
      </div>
    </div>
  );
};

const getTreatmentPrice = (
  bill: BookingPriceInfoComponent[],
  componentType: string[],
): currency =>
  bill.reduce((acc, comp) => {
    if (componentType.includes(comp.type)) {
      return acc.add(comp.value);
    }
    return acc;
  }, currency(0));

const BillSummary: React.FC<BookingPaymentFormProps> = ({
  experts,
  bill,
  inSpa,
}) => {
  const t = useContextTranslation('page.booking_payment');

  const { isSpaBooking, type } = useTreatmentBuilder();

  const treatmentValue = getTreatmentPrice(bill.components, [
    'treatment',
    'pricingAdjustment',
  ]);
  const feeValue = getTreatmentPrice(bill.components, ['atHome']);
  const discountValue = getTreatmentPrice(bill.components, ['discount']);
  const voucherValue = getTreatmentPrice(bill.components, ['voucher']);
  // const referralValue = getTreatmentPrice(bill.components, ['referral']);

  const translate = useTranslateString();

  const pricingHeader =
    isSpaBooking && type
      ? translate(type.name)
      : experts
          ?.reduce<string[]>(
            (acc, booking) => [...acc, translate(booking.type.name) || ''],
            [],
          )
          .join(', ');

  return (
    <div className="bill-summary">
        <h4>{pricingHeader}</h4>
        <div className="prices">
          <BillComponent
            label={t('treatment_price')}
            className="treatment"
            price={treatmentValue}
          />
          <BillComponent
            label={inSpa ? t('at_hotel_fee') : t('at_home_fee')}
            className="at-home"
            price={feeValue}
          />
          <BillComponent
            label={t('discount')}
            className="discount"
            price={discountValue}
          />
          <BillComponent
            label={t('voucher')}
            className="discount"
            price={voucherValue}
          />
          {/* <BillComponent */}
          {/*   label={t('referral')} */}
          {/*   className="referral" */}
          {/*   price={referralValue} */}
          {/* /> */}
          {bill.voucherRemainingBalance &&
            bill.voucherRemainingBalance.intValue > 0 && (
              <div className="voucher-remaining-balance">
                {t('voucher_has_remaining_balance')}
              </div>
            )}
          <div className="price total">
            <div className="label">
              {t('total')}{' '}
              <div className="tax-info">
                {t('tax_info', { totalTax: bill.totalTax })}
              </div>
            </div>
            <div className="value">
              <Currency value={bill.total} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default BillSummary;
