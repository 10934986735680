import styles from './resultsWithAvailabilities.module.scss';
import expertStyles from './EpxertInfo.module.scss';
import '../../TreatmentTypeDetails/treatmentTypeDatails.scss';

import { IonAvatar, IonSpinner } from '@ionic/react';
import injectables from 'application/pages/injectables';
import type { AvailableExpertsQuery } from 'application/pages/Treatments/ITreatmentAdapter';
import { TreatmentInfo } from 'application/pages/TreatmentTypeDetails';
import type ITreatmentTypeDetailsAdapter from 'application/pages/TreatmentTypeDetails/ITreatmentTypeDetailsAdapter';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { TreatmentType } from 'application/types';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useEffect, useState } from 'react';
import CustomModal from 'ui/elements/CustomModal/CustomModal';
import SubPageWrapperForModal from 'ui/elements/CustomModal/SubPageWrapperForModal';
import BeforeContentSwiper from '../../../../ui/elements/Swiper/BeforeContentSwiper';
import TreatmentTypesList from './TreatmentTypeList';
import { treatmentTypeDetailsAtom } from './treatmentAtoms';
import { useAtom } from 'jotai';
import {
  MinimalPriceForExpert,
  ViewExpertProfileButton,
} from '../../../../ui/elements/ExpertCard/ExpertNameWithRating';
import ExpertRating from '../../../../ui/elements/ExpertCard/ExpertRating';

export interface ResultsWithAvailabilitiesCard {
  result: AvailableExpertsQuery;
}

export const TreatmentTypeDetailsModal = (): JSX.Element => {
  const [adapter] = useInject<ITreatmentTypeDetailsAdapter>(
    injectables.TreatmentDetailsAdapter,
  );

  const [treatmentTypeId, setTreatmentTypeDetails] = useAtom(treatmentTypeDetailsAtom);

  const { locationId } = useTreatmentBuilder();

  const treatment = adapter.useTreatmentDetails(locationId);
  const [treatmentElement, setTreatmentElement] =
    useState<TreatmentType | null>(null);

  useEffect(() => {
    if (treatment.value) {
      setTreatmentElement(
        treatment.value.find((el) => treatmentTypeId === el.id) || null,
      );
    }
  }, [treatment, treatmentTypeId, setTreatmentElement]);

  return (
    <CustomModal
      isOpen={Boolean(treatmentTypeId)}
      onDidDismiss={(e) => {
        e.stopPropagation();
        setTreatmentTypeDetails(null);
      }}
    >
      <SubPageWrapperForModal
        wrapperClassName="treatment-details"
        beforeContent={
          <div className="treatment-details-before-content modal">
            {treatmentElement && (
              <BeforeContentSwiper media={treatmentElement.media} />
            )}
          </div>
        }
        onClose={(e) => {
          e.stopPropagation();
          setTreatmentTypeDetails(null);
        }}
      >
        {!treatmentElement && <IonSpinner color="secondary" />}
        {treatmentElement && <TreatmentInfo treatment={treatmentElement} />}
      </SubPageWrapperForModal>
    </CustomModal>
  );
};

const ResultsWithAvailabilities: React.FC<ResultsWithAvailabilitiesCard> = (
  {
    result,
  }) => {
  const { location, date } = useTreatmentBuilder();

  const imageExpert =
    result.media.find((media) => media.type === 'image')?.uri ||
    result.profilePictureUrl;

  if (!date) {
    return (
      <div className={styles.resultCard}>
        <IonAvatar>
          <img src={result.profilePictureUrl} alt={'profile_picture'} />
        </IonAvatar>
        <div className={expertStyles.expertInfo}>
          <div className={expertStyles.name}>{result.name}</div>
          <div className={expertStyles.rating}>
            <ExpertRating rating={result.rating} />
          </div>
          <div className={expertStyles.price}>
            <MinimalPriceForExpert expertTreatmentTypes={result.treatmentTypes} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.resultCardWithAvailabilities}>
      <img
        src={imageExpert}
        alt={'profile_picture'}
        className={styles.expertPhoto}
      />
      <div className={styles.cardWrapper}>
        <div className={expertStyles.expertInfo}>
          <div className={expertStyles.name}>{result.name} <ViewExpertProfileButton expertId={result.id} /></div>
          <div className={expertStyles.rating}>
            <ExpertRating rating={result.rating} />
          </div>
          <div className={expertStyles.price}>
            <MinimalPriceForExpert expertTreatmentTypes={result.treatmentTypes} />
          </div>
        </div>
        {location?.postalCode && date && <TreatmentTypesList expert={result} />}
      </div>
    </div>
  );
};

export default ResultsWithAvailabilities;
