import type { SelectChangeEventDetail } from '@ionic/core/components';
import './settings.scss';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption, IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import injectables from 'application/injectables';
import type ILocaleService from 'application/services/ILocaleService';
import type INotificationService from 'application/services/INotificationService';
import type IClientMetadataAdapter from 'application/state/IClientMetadataAdapter';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useContextTranslation } from 'ui/translation';

import { useAuth } from '../../state/AuthProvider';
import type { IOpenSourceNoticesAdapter } from './IOpenSourceNoticesAdapter';

const Settings: React.FC = () => {
  const t = useContextTranslation('page.settings');
  const mt = useContextTranslation('misc');
  const [showAlert] = useIonAlert();
  const [, i18n] = useTranslation();
  const [localeService] = useInject<ILocaleService>(
    injectables.services.LocaleService,
  );
  const [notificationService] = useInject<INotificationService>(
    injectables.services.NotificationService,
  );
  const [clientMetadata] = useInject<IClientMetadataAdapter>(
    injectables.state.ClientMetadataAdapter,
  );
  const [ossNoticesAdapter] = useInject<IOpenSourceNoticesAdapter>(
    injectables.pages.OpenSourceNoticesAdapter,
  );
  const { isAuthenticated } = useAuth();

  const onLanguageChange = (event: CustomEvent<SelectChangeEventDetail>) => {
    localeService
      .setManualLanguage(event.detail.value)
      .then(async () => {
        if (isAuthenticated) {
          await clientMetadata.sync({
            notificationToken: notificationService.getToken(),
            locale: event.detail.value,
          });
        }
      })
      .catch(() => {
        void showAlert(mt('something_went_wrong'));
      });
  };

  const onOSSOpen = () => {
    ossNoticesAdapter.showNotices();
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/home"} color="tertiary" />
          </IonButtons>
          <IonTitle>{t('header')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList lines="full">
          <IonItemGroup>
            <IonItem>
              <IonText slot={"start"}>{t('language_label')}</IonText>
              <IonSelect
                value={i18n.language}
                okText={mt('ok')}
                cancelText={mt('cancel')}
                onIonChange={onLanguageChange}
                slot="end"
                justify="end"
                mode="md"
              >
                <IonSelectOption className="settings-select" value="en">
                  {t('languages.en')}
                </IonSelectOption>
                <IonSelectOption className="settings-select" value="de">
                  {t('languages.de')}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonItemGroup>
          {isPlatform('capacitor') && (
            <IonItemGroup>
              <IonItem button onClick={onOSSOpen}>
                <IonLabel>{t('licenses')}</IonLabel>
              </IonItem>
            </IonItemGroup>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
