import { useTreatmentBuilder } from '../../state/TreatmentContext';
import type { Location, TreatmentType } from '../../types';
import { useHistory, useParams } from 'react-router';
import { gql, useQuery } from '@apollo/client';
import { assistedStretchType } from './AssistedStretchAtoms';
import { useAtom } from 'jotai';

export const GET_LOCATION_AND_STRETCH_TYPE = gql`
    query GetLocationAndStretchType($id: ID!) {
        location(id: $id) {
            state
            id
            cardPicture
            cardSubheader {
                lang
                value
            }
            name {
                lang
                value
            }
            cardHeader {
                xsmall {
                    lang
                    value
                }
            }
            media {
                type
                uri
            }
            details {
                locationHours {
                    lang
                    value
                }
                features {
                    lang
                    value
                }
            }
            address {
                postalCode
                locality
                street
                streetNumber
            }
            description {
                value
                lang
            }
            confirmationStepTexts {
                index
                value {
                    lang
                    value
                }
            }
        }
        treatmentTypeInfo(
            locationId: $id
            treatmentTypeVariant: stretch
            ignoreOnlySpa: true
        ) {
            id
            description {
                lang
                value
            }
            media {
                type
                uri
            }
            name {
                lang
                value
            }
            prices {
                length
                morning
                afternoon
                evening
            }
        }
    }
`;

const HandleStretchRedirect = () => {
  const { locationId } = useParams<{
    locationId: string;
  }>();

  const [globalStretchType, setType] = useAtom(assistedStretchType);

  const { setLocationId, setLocation, setLocationMedia, setVariant, setNumberOfExperts } = useTreatmentBuilder();

  const history = useHistory();

  useQuery(GET_LOCATION_AND_STRETCH_TYPE, {
    variables: {
      id: locationId,
    },
    onCompleted: (data) => {
      const location = data.location as Location;
      const stretchType = data.treatmentTypeInfo[0] as TreatmentType;
      setLocation({
        type: 'atHome',
        locality: location.address.locality,
        street: location.address.street,
        postalCode: location.address.postalCode,
        streetNumber: location.address.streetNumber,
      });
      if (!globalStretchType) {
        setType(stretchType);
      }
      setVariant('stretch');
      setNumberOfExperts(1);
      setLocationId(location.id);
      setLocationMedia(location.media);
      history.replace('/stretch-treatments');
    },
    onError: () => {
      history.goBack();
    },
  });

  return <></>;
};

export default HandleStretchRedirect;