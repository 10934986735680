import { Device } from '@capacitor/device';
import { Preferences as Storage } from '@capacitor/preferences';
import type ILocaleService from 'application/services/ILocaleService';
import i18n from 'infrastructure/i18n';
import { injectable } from 'inversify';

const LANGUAGE_STORAGE_KEY = 'saved_locale';

const AVAILABLE_LOCALE = ['en', 'de'];

@injectable()
class NativeLocaleService implements ILocaleService {
  async initialize(): Promise<void> {
    const storedLanguageCode = await Storage.get({
      key: LANGUAGE_STORAGE_KEY,
    });

    if (storedLanguageCode.value) {
      await i18n.changeLanguage(storedLanguageCode.value);
      return;
    }

    const deviceLanguageCode = await Device.getLanguageCode();

    const localeCode = deviceLanguageCode.value.substr(0, 2);

    if (AVAILABLE_LOCALE.includes(localeCode)) {
      await i18n.changeLanguage(localeCode);
      return;
    }

    await i18n.changeLanguage('en');
  }

  async setManualLanguage(language: string): Promise<void> {
    await Storage.set({
      key: LANGUAGE_STORAGE_KEY,
      value: language,
    });
    await i18n.changeLanguage(language);
  }
}

export default NativeLocaleService;
