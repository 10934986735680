import type { QueryLazyOptions } from '@apollo/client';
import { gql, useLazyQuery } from '@apollo/client';

import type { HookData, TranslatableString } from '../../types';

const GET_LOCATION_CONFIRMATION_QUERY = gql`
  query GetLocationConfirmation($id: ID!) {
    location(id: $id) {
      confirmationStepTexts {
        value {
          lang
          value
        }
      }
    }
  }
`;

type GetLocationConfirmationTextFunc = (
  options?: QueryLazyOptions<Record<string, unknown>> | undefined,
) => unknown;

type LocationConfirmationTextsQuery = {
  location: {
    confirmationStepTexts: { value: TranslatableString }[];
  };
};

const useLocationConfirmationText = (): HookData<TranslatableString[]> & {
  getLocationConfirmationText: GetLocationConfirmationTextFunc;
} => {
  const [getLocationConfirmationText, { data, error, loading, called }] =
    useLazyQuery<LocationConfirmationTextsQuery>(
      GET_LOCATION_CONFIRMATION_QUERY,
      {
        fetchPolicy: 'network-only',
      },
    );

  return {
    getLocationConfirmationText,
    value: data?.location.confirmationStepTexts
      ? data.location.confirmationStepTexts.map(
          (el: { value: TranslatableString }) => el.value,
        )
      : null,
    loading: !called || loading,
    error: error?.clientErrors?.[0],
  };
};

export default useLocationConfirmationText;
