import { gql, useQuery } from '@apollo/client';

const GET_CITY_NAME = gql`
  query GetCitiesForPostalCode($postalCode: String!) {
    citiesForPostalCode(postalCode: $postalCode)
  }
`;

export default function useCityName({
  onLoad,
  postalCode,
}: {
  onLoad: (cityName: string) => void;
  postalCode?: string;
}): void {
  useQuery(GET_CITY_NAME, {
    variables: {
      postalCode: postalCode || '',
    },
    onCompleted: (data) => {
      if (data.citiesForPostalCode.length) {
        onLoad(data.citiesForPostalCode[0]);
      }
    },
    //TODO: quick fix difference cache fetch is causing
    // that other formik values are cleared in BookingAddress
    skip: !postalCode,
    fetchPolicy: 'network-only',
  });
}
