import { gql } from '@apollo/client';
import type IPasswordChangeAdapter from 'application/pages/PasswordChange/IPasswordChangeAdapter';
import {
  InvalidPasswordError,
  NoPasswordError,
} from 'application/pages/PasswordChange/IPasswordChangeAdapter';
import client from 'infrastructure/apollo';
import { getGraphQLErrorMessages } from 'infrastructure/utils';

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

const INVALID_PASSWORD_ERROR = 'invalid_credentials';
const NO_PASSWORD_ERROR = 'user_has_no_password';

const GraphQLPasswordChangeAdapter: IPasswordChangeAdapter = {
  async changePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<void> {
    try {
      await client.mutate({
        mutation: CHANGE_PASSWORD_MUTATION,
        variables: {
          oldPassword,
          newPassword,
        },
      });
    } catch (error) {
      const messages = getGraphQLErrorMessages(error);
      if (messages?.includes(INVALID_PASSWORD_ERROR)) {
        throw new InvalidPasswordError();
      }
      if (messages?.includes(NO_PASSWORD_ERROR)) {
        throw new NoPasswordError();
      }
      throw error;
    }
  },
};

export default GraphQLPasswordChangeAdapter;
