import type { ObjectID } from 'application/types';
import { useParams } from 'react-router';

import type { BookingAndCartCombined } from '../../types/mapBookingJSONToObject';

export default function useSelectedBooking(
  bookings: BookingAndCartCombined[] | null,
): BookingAndCartCombined | null {
  const { bookingId } = useParams<{ bookingId: ObjectID }>();

  const booking = bookings?.find?.((b) => b.id === bookingId);

  if (!booking) {
    return null;
  }
  return booking;
}
