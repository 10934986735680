import { gql, useQuery } from '@apollo/client';
import type { ExpertProfile } from 'application/pages/ExpertProfile/IExpertProfileAdapter';
import type IExpertProfileAdapter from 'application/pages/ExpertProfile/IExpertProfileAdapter';
import type {
  HookData,
  MediaData,
  ObjectID,
  TranslatableString,
} from 'application/types';
import { useMemo } from 'react';

const GET_EXPERT = gql`
  query GetExpert($id: ID!) {
    expertProfile(id: $id) {
      id
      profilePictureUrl
      age
      name
      slogan {
        lang
        value
      }
      media {
        type
        uri
      }
      description {
        lang
        value
      }
      experience {
        lang
        value
      }
      languages {
        lang
        value
      }
      treatmentTypes
      bookingCount
    }
    treatmentTypeInfo {
      id
      name {
        lang
        value
      }
    }
  }
`;

export type TreatmentTypeInfo = {
  id: ObjectID;
  name: TranslatableString;
};

type QueryExpert = {
  id: ObjectID;
  profilePictureUrl: string;
  age: number;
  name: string;
  slogan: TranslatableString;
  media: MediaData[];
  description: TranslatableString;
  experience: TranslatableString;
  languages: TranslatableString;
  treatmentTypes: ObjectID[];
  bookingCount: number;
};

const ExpertProfileAdapter: IExpertProfileAdapter = {
  useExpertProfile(id: ObjectID): HookData<ExpertProfile> {
    const query = useQuery<{
      treatmentTypeInfo: TreatmentTypeInfo[];
      expertProfile: QueryExpert | null;
    }>(GET_EXPERT, {
      variables: {
        id,
      },
    });

    return useMemo(
      () => ({
        loading: query.loading,
        value: query.data?.expertProfile
          ? {
              id: query.data.expertProfile.id,
              treatmentTypes: query.data.treatmentTypeInfo.filter(
                (treatmentType) =>
                  query.data?.expertProfile?.treatmentTypes?.includes(
                    treatmentType.id,
                  ),
              ),
              media: query.data.expertProfile.media,
              description: query.data.expertProfile.description,
              age: query.data.expertProfile.age,
              experience: query.data.expertProfile.experience,
              name: query.data.expertProfile.name,
              profilePicture: query.data.expertProfile.profilePictureUrl,
              slogan: query.data.expertProfile.slogan,
              languages: query.data.expertProfile.languages,
              bookingCount: query.data.expertProfile.bookingCount,
            }
          : null,
        error: query.error,
      }),
      [query.data, query.loading, query.error],
    );
  },
};

export default ExpertProfileAdapter;
