import type { TreatmentLocation } from 'application/types';
import * as React from 'react';

interface LocationFormatterProps {
  location: TreatmentLocation;
}

const LocationFormatter: React.FC<LocationFormatterProps> = ({ location }) => (
  <span>
    {location.street} {location.streetNumber}, {location.postalCode}{' '}
    {location.locality}
  </span>
);

export default LocationFormatter;
