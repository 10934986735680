export class InvalidEmailError extends Error {
  constructor() {
    super('invalid_email');
  }
}
export class InvalidCodeError extends Error {
  constructor() {
    super('invalid_code');
  }
}

export class ExpiredCodeError extends Error {
  constructor() {
    super('expired_code');
  }
}

export default interface IPasswordResetAdapter {
  sendPasswordResetCode(email: string): Promise<void>;
  resetPassword(email: string, code: string): Promise<void>;
}
