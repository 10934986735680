import './location.scss';

import {
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
} from '@ionic/react';

import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import SoulhouseLocations from './SoulhouseLocations';
import { useLocations } from './useLocations';
import LocationTile from 'ui/theme/images/Location.jpeg';
import { useEffect, useRef, useState } from 'react';
import { useTreatmentBuilder } from '../../state/TreatmentContext';

const RESULTS_PER_PAGE = 5;

const Locations = (): JSX.Element => {
  const mt = useContextTranslation('misc');

  const { value: locations, loading, fetchMore } = useLocations('normal');
  const infiniteScroll = useRef<HTMLIonInfiniteScrollElement>(null);
  const wasTriggered = useRef<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const disabled = Boolean(
    locations && locations.length < page * RESULTS_PER_PAGE && !loading,
  );
  const { setVariant } = useTreatmentBuilder();

  useEffect(() => {
    setVariant('location');
  }, []);

  useEffect(() => {
    if (wasTriggered.current) {
      // eslint-disable-next-line no-console
      infiniteScroll.current?.complete().catch(console.error);
      wasTriggered.current = false;
    }
  }, [locations]);

  if (loading || !locations) {
    return <IonSpinner color="secondary" />;
  }

  return (
    <SubPageLayout
      withBanner
      beforeContent={
        <div className="gallery-header">
          <img className="gallery-img" src={LocationTile} alt={'media'} />
        </div>
      }
    >
      <IonGrid>
        <SoulhouseLocations locations={locations} />
        <div>
          <IonInfiniteScroll
            ref={infiniteScroll}
            threshold="10%"
            disabled={disabled}
            onIonInfinite={() => {
              fetchMore?.({
                variables: {
                  pagination: {
                    offset: RESULTS_PER_PAGE * (page + 1),
                    limit: RESULTS_PER_PAGE,
                  },
                },
              });
              wasTriggered.current = true;
              setPage((p: number) => p + 1);
            }}
          >
            <IonInfiniteScrollContent loadingText={mt('loading')} />
          </IonInfiniteScroll>
        </div>
      </IonGrid>
    </SubPageLayout>
  );
};

export default Locations;
