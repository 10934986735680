import { gql } from '@apollo/client';
import type { CreateReviewInput } from 'application/pages/CreateReview/IReviewAdapter';
import type IReviewAdapter from 'application/pages/CreateReview/IReviewAdapter';
import type { ObjectID } from 'application/types';
import client from 'infrastructure/apollo';

const CREATE_REVIEW = gql`
  mutation CreateReview($input: CreateReviewInput!) {
    createReview(input: $input)
  }
`;

const ReviewAdapter: IReviewAdapter = {
  async createReview(
    bookingId: ObjectID,
    input: CreateReviewInput,
  ): Promise<void> {
    await client.mutate({
      mutation: CREATE_REVIEW,
      variables: {
        input: {
          ...input,
          bookingId,
        },
      },
      refetchQueries: ['MyBookings'],
    });
  },
};

export default ReviewAdapter;
