import './bookingFootrerWithSlots.scss';

import type { ITreatmentAdapter } from 'application/pages/Treatments/ITreatmentAdapter';
import { Availabilities } from 'application/pages/Treatments/TreatmentResult/TreatmentResult';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import { useInject } from 'inversify-hooks';
import { useContextTranslation } from 'ui/translation';

import injectables from '../../../application/injectables';
import Button from '../Button/Button';

interface BookingFooterWithSlotsProps {
  expertIndex: number;
  nextEnabled?: boolean;
  onNext: () => void;
  nextText?: string;
}

const BookingFooterWithChild = (
  {
    expertIndex,
    onNext,
    nextEnabled = true,
    nextText = 'next',
  }: BookingFooterWithSlotsProps): JSX.Element => {
  const t = useContextTranslation('misc.booking_summary');
  const [adapter] = useInject<ITreatmentAdapter>(
    injectables.pages.TreatmentAdapter,
  );

  const {
    locationId,
    location,
    date,
    experts,
    numberOfExperts,
  } = useTreatmentBuilder();

  const selectedExperts = experts?.map((expert) => expert.expertId) || [];
  const treatmentTypeIds = experts?.map((expert) => expert.type.id) || [];

  const resultsSlots = adapter.useNextExpertsSlotsQuery({
    postalCode: location?.postalCode || '',
    date,
    numberOfExperts: numberOfExperts || 0,
    selectedExpertIds: selectedExperts.slice(0, expertIndex + 1),
    locationId,
    treatmentTypeIds,
  });

  const availableSlots = resultsSlots.value?.map((slot) => new Date(slot.slot));

  return (
    <div className="booking-footer-slots">
      <div className="text" />
      <div className="next-container">
        <div className="availabilities-container">
          {availableSlots && (
            <Availabilities showBookingRequestCTA slots={availableSlots} />
          )}
        </div>
        <Button shrink disabled={!nextEnabled} onClick={onNext}>
          {t(nextText)}
        </Button>
      </div>
    </div>
  );
};

export default BookingFooterWithChild;
