import { useIonToast } from '@ionic/react';
import injectables from 'application/injectables';
import type ICalendarAdapter from 'application/pages/BookingDetailsModal/ICalendarAdapter';
import { addMinutes } from 'date-fns';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useContextTranslation, useTranslateString } from 'ui/translation';
import formatLocation from 'ui/utils/formatLocation';

import type { BookingAndCartCombined } from '../types/mapBookingJSONToObject';

// TODO Refactor required
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */

const useParseCalendarDescription = () => {
  const t = useContextTranslation('misc');
  const translate = useTranslateString();

  // TODO new translations for spa?
  return (cart: BookingAndCartCombined) => {
    if (cart.bookings.length > 1) {
      return t('calendar.event.descriptionMulti', {
        length: cart.length,
        firstType: translate(cart.bookings[0].treatmentType.name),
        secondType: translate(cart.bookings[1].treatmentType.name),
        firstExpertName: cart.bookings[0].expert?.name,
        secondExpertName: cart.bookings[1].expert?.name,
        bookingCode: cart.customId,
      });
    }

    return t('calendar.event.description', {
      length: cart.length,
      type: translate(cart.bookings[0].treatmentType.name),
      expertName: cart.bookings[0].expert?.name,
      bookingCode: cart.customId,
    });
  };
};

export default function useAddCartToCalendar(
  cart: BookingAndCartCombined | null,
): (setIsAddedToCalendar: any) => void {
  const t = useContextTranslation('misc');
  const [adapter] = useInject<ICalendarAdapter>(
    injectables.pages.CalendarAdapter,
  );
  const parseDescription = useParseCalendarDescription();
  const [showToast] = useIonToast();

  return React.useCallback(
    (setIsAddedToCalendar) => {
      if (!cart) {
        return;
      }
      adapter
        .addTreatmentToCalendar({
          name: t('calendar.event.name'),
          description: parseDescription(cart),
          location: formatLocation(cart.address),
          start: cart.time,
          end: addMinutes(cart.time, cart.length),
        })
        .then(() => {
          setIsAddedToCalendar(true);
        })
        .catch(() => {
          void showToast({
            message: t('something_went_wrong'),
            duration: 3000,
          });
        });
    },
    [adapter, cart, showToast],
  );
}
