import {
  createGesture,
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import type ITreatmentCustomGoBackAdapter from '../../../application/pages/Treatments/ITreatmentCustomGoBackAdapter';

// TODO refactor required -> hooks should be not used in class components
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */

const CustomGoBackAdapter: ITreatmentCustomGoBackAdapter = {
  useCustomGoBack(fulfilled: boolean, setFulfilled) {
    if (!isPlatform('capacitor')) {
      return;
    }
    const history = useHistory();

    const isFulfilled = useRef(false);
    const gesture = createGesture({
      el: document,
      threshold: 0,
      gestureName: 'my-gesture',
      gesturePriority: 41,
      onEnd: (detail) => {
        const { innerWidth: width } = window;
        const velocity = detail.velocityX;
        const z = width / 2.0;
        const shouldComplete =
          velocity > 0.3 &&
          detail.deltaX > z &&
          Math.abs(detail.deltaY) < Math.abs(detail.deltaX);

        if (shouldComplete) {
          if (isFulfilled.current) {
            setFulfilled(false);
          } else {
            history.goBack();
          }
        }
      },
    });

    const backListener = (e: any) => {
      e.detail.register(41, (processNextHandler: () => void) => {
        if (isFulfilled.current) {
          setFulfilled(false);
        } else {
          processNextHandler();
        }
      });
    };

    useIonViewWillEnter(() => {
      document.addEventListener('ionBackButton', backListener);
      gesture.enable(true);
    });

    useEffect(() => {
      isFulfilled.current = fulfilled;
    }, [fulfilled]);

    useIonViewWillLeave(() => {
      document.removeEventListener('ionBackButton', backListener);
      gesture.enable(false);
    });
  },
};

export default CustomGoBackAdapter;
