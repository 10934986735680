import './voucherPaymentConfirmationModal.scss';

import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import voucherImage from 'application/pages/Home/images/route-voucher.jpg';
import * as React from 'react';
import CustomModal from 'ui/elements/CustomModal/CustomModal';
import { useContextTranslation } from 'ui/translation';

interface BookingDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const VoucherPaymentConfirmationModal: React.FC<BookingDetailsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const t = useContextTranslation('page.voucher.payment_confirmation');
  const mt = useContextTranslation('misc');

  return (
    <CustomModal
      isOpen={isOpen}
      cssClass="booking-modal voucher-payment-modal"
      backdropDismiss={false}
    >
      <IonContent>
        <button
          type="button"
          aria-label={mt('close')}
          className="close-button"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <FontAwesomeIcon className="header-icon" icon={faCheckCircle} />
              <h3>{t('title')}</h3>
              <p className="message">{t('message')}</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div>
                <img
                  alt="Confirm decorator"
                  src={voucherImage}
                  className="payment-confirmation-img"
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <div>
              <p className="spam-notice">{t('spam_notice')}</p>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </CustomModal>
  );
};

export default VoucherPaymentConfirmationModal;
