import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useInject } from 'inversify-hooks';
import { useState } from 'react';

import type { IShareAdapter } from 'infrastructure/adapters/native/NativeShareAdapter';
import CustomAlert from 'ui/elements/CustomModal/CustomAlert';
import {
  useContextTranslation,
  useTranslateOptionalString,
} from 'ui/translation';
import injectables from '../../injectables';
import type { LocationWithDetails } from '../useLocations';
import { ReactComponent as ShareIcon } from 'ui/theme/images/arrow-forward-up.svg';

const LocationDetails = ({
  location,
}: {
  location: LocationWithDetails;
}): JSX.Element => {
  const t = useContextTranslation('page.location_details');
  const translate = useTranslateOptionalString();
  const [shareAdapter] = useInject<IShareAdapter>(injectables.ShareAdapter);

  const [showCopiedAlert, setShowCopiedAlert] = useState(false);

  const handleShare = async () => {
    const shareResult = await shareAdapter.handleUrlNativeShare(
      `/locations?details=${location.id}`,
    );
    if (shareResult.notifyUser) {
      setShowCopiedAlert(true);
    }
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12">
          <div className="info-card">
            <button
              className="share"
              type="button"
              aria-label={t('share')}
              onClick={() => void handleShare()}
            >
              <ShareIcon />
            </button>
            <h4>{translate(location.cardHeader.xsmall)}</h4>
            <p className="slogan">{translate(location.cardSubheader)}</p>
            <div className="details-container">
              <div className="cell">
                <div className="label">{t('opening_hours')}</div>
                <div className="value">
                  {translate(location.details.locationHours)}
                </div>
              </div>
              <div className="cell">
                <div className="label">{t('contact')}</div>
                <div className="value">
                  <a className="phone" href="tel:+494080813059">
                    +49 40 80813059
                  </a>
                  <br />
                  support@soulhouse.me
                </div>
              </div>
              <div className="cell">
                <div className="label">{t('features')}</div>
                <div className="value">
                  {translate(location.details.features)}
                </div>
              </div>
            </div>
          </div>
        </IonCol>
        {Boolean(location.description?.length) && (
          <IonCol className="description" size="12">
            <h4>{t('about_location')}</h4>
            <p>{translate(location.description)}</p>
          </IonCol>
        )}
      </IonRow>
      <CustomAlert
        isOpen={showCopiedAlert}
        message={t('share_alert')}
        button={t('alert_close')}
        onDidDismiss={() => setShowCopiedAlert(false)}
      />
    </IonGrid>
  );
};

export default LocationDetails;
