import { IonCol } from '@ionic/react';
import type { TreatmentTimeRange } from 'application/types';
import { isToday, startOfDay } from 'date-fns';
import type { Dispatch, SetStateAction } from 'react';
import Calendar from 'react-calendar';
import { useContextTranslation } from 'ui/translation';
import formatLocalizedDate from 'ui/utils/formatLocalizedDate';

import TimeRangeSelector from './TimeRangeSelector';

const NoAvailabilitiesCalendarWrapper = ({
  date,
  setDate,
  timeRange,
  setTimeRange,
}: {
  date?: Date;
  timeRange?: TreatmentTimeRange;
  setDate: Dispatch<SetStateAction<Date | undefined>>;
  setTimeRange: Dispatch<SetStateAction<TreatmentTimeRange | undefined>>;
}): JSX.Element => {
  const t = useContextTranslation('page.treatment.date');

  return (
    <>
      <IonCol size="12" className="calendar-wrapper">
        <h4 className="calendar-info">{t('available_days_info')}</h4>
        <Calendar
          value={date}
          onChange={(d: Date[]) => {
            const newDate = Array.isArray(d) ? d[0] : d;
            setDate(newDate);
            if (isToday(newDate)) {
              setTimeRange(undefined);
            }
          }}
          prev2Label={null}
          next2Label={null}
          minDate={startOfDay(new Date())}
          minDetail="month"
          formatMonthYear={(locale, d) => formatLocalizedDate(d, 'MMMM yyyy')}
          formatShortWeekday={(locale, d) => formatLocalizedDate(d, 'EEEEE')}
        />
      </IonCol>
      <IonCol size="12">
        <h4>{t('time_period_header')}</h4>
        <TimeRangeSelector
          date={date}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
        />
      </IonCol>
    </>
  );
};

export default NoAvailabilitiesCalendarWrapper;
