import styles from './assistedStertch.module.scss';
import { ResultsWithAvailabilitiesCard } from '../Treatments/TreatmentResult/ResultsWithAvabilities';
import { IonAvatar, IonCard } from '@ionic/react';
import {
  MinimalPriceForExpert,
  ViewExpertProfileButton,
} from '../../../ui/elements/ExpertCard/ExpertNameWithRating';
import ExpertRating from '../../../ui/elements/ExpertCard/ExpertRating';
import { useTreatmentBuilder } from '../../state/TreatmentContext';
import classNames from 'classnames';
import { assistedStretchType } from './AssistedStretchAtoms';
import { useAtomValue } from 'jotai';

const AssistedStretchResults: React.FC<ResultsWithAvailabilitiesCard> = (
  {
    result,
  }) => {
  const { expertId, date, setExpertId, setExperts, setType } = useTreatmentBuilder();
  const type = useAtomValue(assistedStretchType);


  const handleSelectExpert =
    () => {
      if (!date || !type) {
        return;
      }
      setExpertId(result.id);
      setType(type);
      setExperts([{
        expertId: result.id,
        name: result.name,
        profilePictureUrl: result.profilePictureUrl,
        type,
      }]);
    };

  return (
    <IonCard
      className={
        classNames({
          [styles.resultCard]: true,
          [styles.active]: Boolean(date),
          [styles.selected]: result.id === expertId,
        })
      }
      onClick={handleSelectExpert}>
      <IonAvatar>
        <img src={result.profilePictureUrl} alt={'profile_picture'} />
      </IonAvatar>
      <div className={styles.expertInfo}>
        <div className={styles.name}>{result.name}</div>
        <ExpertRating rating={result.rating} />
        <div className={styles.price}>
          <MinimalPriceForExpert expertTreatmentTypes={result.treatmentTypes} />
        </div>
        <ViewExpertProfileButton expertId={result.id} />
      </div>
    </IonCard>
  );
};

export default AssistedStretchResults;