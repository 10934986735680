import { FacebookLogin } from '@capacitor-community/facebook-login';
import { isPlatform } from '@ionic/react';
import type { ExternalAuthResponse } from 'application/state/IExternalAuthProvider';
import type IExternalAuthProvider from 'application/state/IExternalAuthProvider';
import type { TreatmentClientData } from 'application/types';
import config from 'config';
import { injectable } from 'inversify';

declare global {
  interface Window {
    fbAsyncInit: () => void;
  }
}

if (!isPlatform('capacitor') && config.facebookAppId) {
  window.fbAsyncInit = () => {
    FB.init({
      appId: config.facebookAppId,
      cookie: false, // enable cookies to allow the server to access the session
      xfbml: false, // parse social plugins on this page
      version: 'v5.0', // use graph api current version
    });
  };

  // Load the SDK asynchronously
  ((d, id) => {
    const fjs = d.getElementsByTagName('script')[0];
    if (d.getElementById(id)) return;
    const js = d.createElement('script');
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode?.insertBefore(js, fjs);
  })(document, 'facebook-jssdk');
}

@injectable()
export default class FacebookAuthProvider implements IExternalAuthProvider {
  async authenticate(): Promise<ExternalAuthResponse> {
    const response = await FacebookLogin.login({
      permissions: ['email', 'public_profile'],
    });

    if (!response.accessToken) {
      throw new Error('Authentication failed');
    }

    return {
      clientSecret: response.accessToken.token,
    };
  }

  async getClientData(): Promise<
    Partial<Pick<TreatmentClientData, 'phoneNumber' | 'name' | 'companyName'>>
  > {
    const result = await FacebookLogin.getCurrentAccessToken();
    if (!result.accessToken) {
      throw new Error('Not logged in');
    }
    const profile = await FacebookLogin.getProfile<{ name: string }>({
      fields: ['name'],
    });

    return {
      name: profile.name || '',
    };
  }

  async reset(): Promise<void> {
    try {
      const result = await FacebookLogin.getCurrentAccessToken();
      if (result.accessToken) {
        await FacebookLogin.logout();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error while resetting FB Auth', e);
    }
  }

  isAvailable(): boolean {
    return Boolean(config.facebookAppId) || isPlatform('capacitor');
  }
}
