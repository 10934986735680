import './bookingPayment.scss';

import { useIonAlert, useIonViewWillEnter } from '@ionic/react';
import injectables from 'application/injectables';
import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { loggingServiceSymbol } from 'application/services/IErrorLoggingService';
import type { ITrackingService } from 'application/services/ITrackingService';
import { useAuth } from 'application/state/AuthProvider';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import BookingSummaryFooter from 'ui/elements/BookingSummaryFooter';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { translateString, useContextTranslation } from 'ui/translation';

import BeforeContentGallery, {
  defaultMedia,
} from '../../../ui/elements/BeforeContentGallery';
import type { PlaceMultiBookingsResult } from './IBookingPaymentAdapter';
import PaymentHandlerModal from './PaymentHandlerModal';
import PlaceBooking from './PlaceBooking';
import type { PlaceBookingHandle } from './PlaceBooking';

// TODO: refactor required
/* eslint-disable @typescript-eslint/no-unsafe-return */

const BookingPayment: React.FC = () => {
  const { i18n } = useTranslation();
  const t = useContextTranslation('page.booking_payment');
  const history = useHistory();
  const {
    experts,
    date,
    length,
    location,
    details,
    clientData,
    setClientData,
    locationMedia,
    locationId,
    isSpaBooking,
    type,
    slotTime,
  } = useTreatmentBuilder();

  const { isAuthenticated } = useAuth();
  const [showAlert] = useIonAlert();
  const [trackingService] = useInject<ITrackingService>(
    injectables.services.TrackingService,
  );

  const [loggingService] =
    useInject<IErrorLoggingService>(loggingServiceSymbol);

  const placeBookingRef = React.useRef<PlaceBookingHandle>(null);
  const [nextEnabled, setNextEnabled] = React.useState<boolean>(false);
  const [paymentState, setPaymentState] = React.useState<
    (PlaceMultiBookingsResult & { paymentMethod?: string }) | undefined
  >();

  const hasCompleteData = Boolean(
    date &&
      length &&
      location &&
      (experts || (isSpaBooking && type)) &&
      (isAuthenticated || clientData),
  );

  useIonViewWillEnter(() => {
    if (isAuthenticated) {
      setClientData(null);
    }
    if (!hasCompleteData) {
      history.replace('/treatments');
    }
  }, [hasCompleteData, history]);

  const redirectToDetails = (booking: PlaceMultiBookingsResult) => {
    history.replace(`/bookings/${booking.shoppingCartId}/${booking.token}`, {
      direction: 'forward',
    });
  };

  const onPaymentSuccess = () => {
    if (!paymentState) {
      return;
    }
    trackingService.trackEvent({
      name: 'booking_purchase_completed',
      data: {
        price: paymentState.totalPrice?.value
          ? paymentState.totalPrice.value
          : 0,
        transaction_id: paymentState.customId,
      },
    });

    redirectToDetails(paymentState);
  };

  return (
    <SubPageLayout
      className="booking-payment"
      beforeContent={
        <BeforeContentGallery
          media={locationMedia || experts?.[0].type.media || defaultMedia}
        />
      }
      footerContent={() =>
        length &&
        (experts || (isSpaBooking && type)) && (
          // This part must have been extracted from PlaceBooking due to UI positioning.
          // Please treat it (and all ForwardRef's stuff as a part of PlaceBooking component)
          <BookingSummaryFooter
            onNext={() => placeBookingRef.current?.placeBooking()}
            nextEnabled={nextEnabled}
            nextText="confirm_booking"
            confirmButton
          />
        )
      }
    >
      {slotTime &&
        length &&
        details &&
        location &&
        (experts || (isSpaBooking && type)) && (
          <PlaceBooking
            ref={placeBookingRef}
            treatment={{
              details,
              clientData: clientData || undefined,
              length,
              experts,
              slotTime,
              location,
              locationId: locationId || undefined,
              type: type?.id,
            }}
            onPlacementAvailable={(available) => setNextEnabled(available)}
            onBookingPlaced={(placeBookingResult, paymentMethod) => {
              setPaymentState({
                ...placeBookingResult,
                paymentMethod,
              });
              if (placeBookingResult.totalPrice?.intValue === 0) {
                redirectToDetails(placeBookingResult);
              }
            }}
          />
        )}
      <PaymentHandlerModal
        instance={
          paymentState?.paymentMethod && paymentState.totalPrice?.intValue !== 0
            ? {
                subject: 'booking',
                targetId: paymentState.shoppingCartId,
                method: paymentState.paymentMethod,
                secret: paymentState.token,
                price: paymentState.totalPrice,
                name: t('line_item', {
                  length: paymentState.bookings[0].length,
                  name: experts?.[0].type?.name
                    ? translateString(experts?.[0].type.name, i18n.language)
                    : 'unknown',
                }),
              }
            : undefined
        }
        title={t('payment_header')}
        onError={(e) => {
          void showAlert(t('payment_error', { message: e?.message }));
          loggingService.traceException(e);
          history.replace('/', { unmount: true, direction: 'back' });
        }}
        onSuccess={onPaymentSuccess}
      />
    </SubPageLayout>
  );
};

export default BookingPayment;
