import { IonSpinner } from '@ionic/react';
import type { TreatmentType, HookData } from 'application/types';
import * as React from 'react';
import ExpandableSelect from 'ui/elements/ExpandableSelect';
import { useContextTranslation, useTranslateString } from 'ui/translation';
import { ReactComponent as TreatmentIcon } from './images/physiotherapy.svg';
import classNames from 'classnames';

interface TreatmentTypeSelectorProps {
  onClick: () => void;
  expanded: boolean;
  selectedType: TreatmentType | null;
  onChange: (selectedType: TreatmentType) => void;
  disabled?: boolean;
  treatmentTypes: HookData<TreatmentType[]>;
}

const TreatmentTypeSelector: React.FC<TreatmentTypeSelectorProps> = ({
  onClick,
  expanded,
  selectedType,
  onChange,
  disabled = false,
  treatmentTypes,
}) => {
  const t = useContextTranslation('page.treatment');
  const translate = useTranslateString();

  if (treatmentTypes.loading) {
    return <IonSpinner color="secondary" name="dots" />;
  }

  if (!treatmentTypes.value?.length) {
    return <></>;
  }

  const treatmentOptions = treatmentTypes.value.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.id]: translate(currentValue.name),
    }),
    {},
  );

  const selectClassName = classNames({
    'button-selector': true,
    'treatment-type-selected': true,
    disabled,
    'not-active': !selectedType,
  });

  return (
    <ExpandableSelect
      className={selectClassName}
      placeholder={t('treatment_type_select')}
      expanded={expanded}
      options={treatmentOptions}
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick();
      }}
      value={selectedType?.id && selectedType.id}
      buttonIcon={<TreatmentIcon />}
      onSelectChange={(event) => {
        const selectType = treatmentTypes.value?.find(
          (el) => el.id === event.currentTarget.value,
        );
        if (!selectType) {
          return;
        }
        onChange(selectType);
      }}
    />
  );
};

export default TreatmentTypeSelector;
