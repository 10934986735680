import './paypalPayments.scss';

import { gql, useMutation } from '@apollo/client';
import { IonAlert, IonButton, IonLoading } from '@ionic/react';
import {
  FUNDING,
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import type { PaymentParams } from 'application/pages/BookingPayment/IPaymentProvider';
import type IPaymentProvider from 'application/pages/BookingPayment/IPaymentProvider';
import config from 'config';
import { injectable } from 'inversify';
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Prompt } from 'react-router';
import { useContextTranslation } from 'ui/translation';

// TODO refactor required -> hooks should be not used in class components
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

const CREATE_PAYPAL_ORDER = gql`
  mutation CreatePaypalOrder(
    $targetId: String!
    $subject: String!
    $paymentMethod: String
  ) {
    createPayPalPaymentRequest(
      targetId: $targetId
      subject: $subject
      paymentMethod: $paymentMethod
    ) {
      approveUrl
      paymentId
      status
    }
  }
`;

const EXECUTE_PAYPAL_PAYMENT = gql`
  mutation ExecutePaypalPayment(
    $paymentId: String!
    $targetId: String!
    $subject: String!
  ) {
    executePayment(
      paymentId: $paymentId
      targetId: $targetId
      subject: $subject
    )
  }
`;

const FAIL_PAYPAL_PAYMENT = gql`
  mutation FailPaypalPayment(
    $subject: String!
    $targetId: String!
    $errorData: String!
  ) {
    failPayment(targetId: $targetId, subject: $subject, errorData: $errorData)
  }
`;

type CreatePaypalOrderResponse = {
  approveUrl: string;
  paymentId: string;
  status: string;
};

function usePaypalOrder(params: {
  targetId: string;
  subject: string;
}): () => Promise<CreatePaypalOrderResponse> {
  const [createPaypalOrder] = useMutation(CREATE_PAYPAL_ORDER, {
    variables: {
      targetId: params.targetId,
      subject: params.subject,
      paymentMethod: 'paypal',
    },
  });

  return () =>
    createPaypalOrder().then(({ data }) => data.createPayPalPaymentRequest);
}

function useExecutePaypalPayment(params: {
  paymentId: string;
  targetId: string;
  subject: string;
}): () => Promise<void> {
  const [executePaypalPayment] = useMutation(EXECUTE_PAYPAL_PAYMENT, {
    variables: {
      paymentId: params.paymentId,
      targetId: params.targetId,
      subject: params.subject,
    },
  });

  return () => executePaypalPayment().then(() => {});
}

function useFailPaypalPayment(params: {
  subject: string;
  targetId: string;
}): (errorData: string) => Promise<void> {
  const [failPaypalPayment] = useMutation(FAIL_PAYPAL_PAYMENT, {
    variables: {
      subject: params.subject,
      targetId: params.targetId,
      errorData: '',
    },
  });

  return (errorData: string) =>
    failPaypalPayment({
      variables: {
        subject: params.subject,
        targetId: params.targetId,
        errorData,
      },
    }).then(() => {});
}

function PaypalScriptLoader({
  onLoad,
  children,
}: {
  onLoad: () => void;
  children: React.ReactNode;
}) {
  const [{ isPending, options, isResolved }, dispatch] =
    usePayPalScriptReducer();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      dispatch({ type: 'resetOptions', value: options });
    }
  }, [dispatch, options]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isPending || !isResolved) {
      return;
    }
    onLoad();
  }, [isPending, isResolved, onLoad]);

  if (isPending || !isResolved) {
    return <IonLoading isOpen />;
  }

  return children as JSX.Element;
}

type PayPalButtonsState = {
  status: 'WAITING_FOR_INIT' | 'INITIALIZED' | 'LOADING_SCRIPT';
};
const paypalButtonsInitialState: PayPalButtonsState = {
  status: 'LOADING_SCRIPT',
};
type PayPalButtonsAction = { type: 'LOADED' | 'INITIALIZED' | 'RESET_BUTTONS' };
const paypalButtonsReducer = (
  state: PayPalButtonsState,
  action: PayPalButtonsAction,
): PayPalButtonsState => {
  switch (action.type) {
    case 'LOADED':
      return { status: 'WAITING_FOR_INIT' };
    case 'INITIALIZED':
      return { status: 'INITIALIZED' };
    case 'RESET_BUTTONS':
      return { status: 'WAITING_FOR_INIT' };
    default:
      return state;
  }
};

@injectable()
export default class PayPalPaymentProvider implements IPaymentProvider {
  key = 'paypal';

  weight = 20;

  getName(): string {
    return 'PayPal';
  }

  isAvailable(): boolean {
    return true;
  }

  Render(params: PaymentParams): JSX.Element {
    const [paymentDone, setPaymentDone] = React.useState<boolean>(false);
    const [cancelPaymentModalOpened, setCancelPaymentModalOpened] =
      React.useState<boolean>(false);
    const [paymentId, setPaymentId] = useState<string>('');
    const [state, dispatch] = useReducer(
      paypalButtonsReducer,
      paypalButtonsInitialState,
    );

    useEffect(() => {
      if (state.status === 'INITIALIZED') {
        dispatch({ type: 'RESET_BUTTONS' });
      }
    }, [params.instance.subject, params.instance.targetId]);

    const onLoad = useCallback(() => {
      dispatch({ type: 'LOADED' });
    }, []);

    const [rerenderCount, setRerenderCount] = useState(0);
    const t = useContextTranslation('payment.paypal');

    const createOrder = usePaypalOrder({
      subject: params.instance.subject,
      targetId: params.instance.targetId,
    });

    const executePayment = useExecutePaypalPayment({
      paymentId,
      targetId: params.instance.targetId,
      subject: params.instance.subject,
    });

    const failPayment = useFailPaypalPayment({
      subject: params.instance.subject,
      targetId: params.instance.targetId,
    });

    const intervalRef = useRef<number>();

    const clearRefInterval = useRef(() => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    });

    useEffect(() => {
      if (state.status === 'WAITING_FOR_INIT') {
        clearRefInterval.current();
        intervalRef.current = setInterval(() => {
          if (state.status === 'WAITING_FOR_INIT') {
            setRerenderCount((prev) => prev + 1);
            return;
          }
          clearRefInterval.current();
        }, 2000) as any as number;
        return () => clearRefInterval.current();
      }
      clearRefInterval.current();
      return () => {};
    }, [state.status]);

    useEffect(() => {
      if (rerenderCount > 10) {
        setPaymentDone(true);
        params.onError(new Error('cannot load buttons'));
      }
    }, [rerenderCount, params.onError, failPayment]);

    return (
      <PayPalScriptProvider
        options={{
          'client-id': config.paypalClientId,
          currency: 'EUR',
          debug: false,
        }}
        deferLoading
      >
        <PaypalScriptLoader onLoad={onLoad}>
          <p className="paypal-heading">{t('header')}</p>
          <div className="paypal-buttons">
            <PayPalButtons
              fundingSource={FUNDING.PAYPAL}
              style={{
                label: 'pay',
              }}
              forceReRender={[rerenderCount]}
              createOrder={() =>
                createOrder().then((res) => {
                  setPaymentId(res.paymentId);
                  return res.paymentId;
                })
              }
              onApprove={() => {
                setPaymentDone(true);
                return executePayment().then(params.onSuccess);
              }}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onError={async (e) => {
                await failPayment(JSON.stringify(e));
                setPaymentDone(true);
                params.onError(new Error('Paypal payment error'));
              }}
              onInit={() => {
                dispatch({ type: 'INITIALIZED' });
              }}
            />
          </div>

          <Prompt when={!paymentDone} message={t('leave_warning')} />
          <IonAlert
            isOpen={cancelPaymentModalOpened}
            message={t('cancel_confirmation_message')}
            onDidDismiss={() => setCancelPaymentModalOpened(false)}
            buttons={[
              {
                text: t('no'),
                role: 'cancel',
              },
              {
                text: t('yes'),
                handler: () => {
                  setPaymentDone(true);
                  params.onError(new Error(t('cancelled')));
                },
              },
            ]}
          />
          <IonButton
            className="cancel-payment"
            color="danger"
            fill="clear"
            expand="block"
            onClick={() => setCancelPaymentModalOpened(true)}
          >
            {t('cancel')}
          </IonButton>
        </PaypalScriptLoader>
      </PayPalScriptProvider>
    );
  }
}
