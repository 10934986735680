import pages from './pages/injectables';
import services from './services/injectables';
import state from './state/injectables';

export default {
  services,
  pages,
  state,
};

export const hardwareInjectables = {
  HandleHardwareBackButtonAdapter: Symbol('HandleHardwareBackButtonAdapter'),
};
