import styles from 'application/pages/Treatments/TreatmentResult/EpxertInfo.module.scss';
import { useContextTranslation } from '../../translation';
import { expertDetailsAtom } from '../../../application/pages/Treatments/TreatmentResult/treatmentAtoms';
import { useSetAtom } from 'jotai';
import { ObjectID } from '../../../application/types';
import { Trans } from 'react-i18next';
import { useTreatmentBuilder } from '../../../application/state/TreatmentContext';
import { useMinimalPriceForExpert } from '../../../application/pages/Treatments/DateModal/dateModalUtils';

export const ViewExpertProfileButton = ({ expertId }: { expertId: ObjectID }) => {
  const t = useContextTranslation('page.treatment');
  const setExpertId = useSetAtom(expertDetailsAtom);
  return (
    <button
      type="button"
      className={styles.details}
      onClick={(e) => {
        e.stopPropagation();
        setExpertId(expertId);
      }}
    >
      {t('view_profile')}
    </button>
  );
};


export const MinimalPriceForExpert = ({ expertTreatmentTypes }: { expertTreatmentTypes: string[] }) => {
  const t = useContextTranslation('page.treatment');
  const { minimalPrice, date, variant, locationId } = useTreatmentBuilder();
  const getMinimalPrice = useMinimalPriceForExpert(locationId, variant);
  const price = minimalPrice || getMinimalPrice(expertTreatmentTypes, date?.timeRange);

  return (
    <>
      {price ? (
        <Trans t={t} i18nKey="price_from" values={{price}}>
          From
          {/*// TODO fix lint */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore  */}
          <span className={styles.priceLabel}>{{ price }} €</span>
        </Trans>
      ) : null}
    </>
  );
};