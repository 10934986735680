import type IHandleHardwareBackButtonAdapter from 'application/IHandleHardwareBackButtonAdapter';
import { hardwareInjectables } from 'application/injectables';
import type IBookingDetailsAdapter from 'application/pages/BookingDetails/IBookingDetailsAdapter';
import type ICalendarAdapter from 'application/pages/BookingDetailsModal/ICalendarAdapter';
import type IBookingPaymentAdapter from 'application/pages/BookingPayment/IBookingPaymentAdapter';
import type IPaymentProvider from 'application/pages/BookingPayment/IPaymentProvider';
import type IReviewAdapter from 'application/pages/CreateReview/IReviewAdapter';
import type IEditProfileAdapter from 'application/pages/EditProfile/IEditProfileAdapter';
import type IExpertProfileAdapter from 'application/pages/ExpertProfile/IExpertProfileAdapter';
import pageInjectables from 'application/pages/injectables';
import type ICredentialsLoginAdapter from 'application/pages/Login/ICredentialsLoginAdapter';
import type IExternalAuthenticationAdapter from 'application/pages/Login/IExternalAuthenticationAdapter';
import type IAccountBookingsAdapter from 'application/pages/MyBookings/IAccountBookingsAdapter';
import type IPasswordChangeAdapter from 'application/pages/PasswordChange/IPasswordChangeAdapter';
import type IPasswordResetAdapter from 'application/pages/PasswordReset/IPasswordResetAdapter';
import type IRegistrationAdapter from 'application/pages/Register/IRegistrationAdapter';
import type ISavedCardsAdapter from 'application/pages/SavedCards/ISavedCardsAdapter';
import type { IOpenSourceNoticesAdapter } from 'application/pages/Settings/IOpenSourceNoticesAdapter';
import type { ISofortPaymentAdapter } from 'application/pages/SofortPayment/ISofortPaymentAdapter';
import type IBookingRequestAdapter from 'application/pages/Treatments/BookingRequest/IBookingRequestAdapter';
import type { ITreatmentAdapter } from 'application/pages/Treatments/ITreatmentAdapter';
import type ITreatmentCustomGoBackAdapter from 'application/pages/Treatments/ITreatmentCustomGoBackAdapter';
import type ITreatmentTypeDetailsAdapter from 'application/pages/TreatmentTypeDetails/ITreatmentTypeDetailsAdapter';
import type IVoucherPaymentAdapter from 'application/pages/VoucherPayment/IVoucherPaymentAdapter';
import type IClientMetadataAdapter from 'application/state/IClientMetadataAdapter';
import type IExternalAuthProvider from 'application/state/IExternalAuthProvider';
import stateInjectables from 'application/state/injectables';
import ApplePayPaymentProvider from 'infrastructure/adapters/payment/ApplePayPaymentProvider';
import GooglePayPaymentProvider from 'infrastructure/adapters/payment/GooglePayPaymentProvider';
import { container } from 'inversify-props';

/* GraphQL resolvers */
import GraphQLAccountBookingsAdapter from './graphql/AccountBookingsAdapter';
import GraphQLBookingDetailsAdapter from './graphql/BookingDetailsAdapter';
import GraphQLBookingPaymentAdapter from './graphql/BookingPaymentAdapter';
import BookingRequestGQLAdapter from './graphql/BookingRequestAdapter';
import CachedGraphQLClientMetadataAdapter from './graphql/CachedClientMetadataAdapter';
import CredentialLoginAdapter from './graphql/CredentialLoginAdapter';
import GraphQLEditProfileAdapter from './graphql/EditProfileAdapter';
import GraphQLExpertProfileAdapter from './graphql/ExpertProfileAdapter';
import ExternalAuthenticationAdapter from './graphql/ExternalAuthenticationAdapter';
import GraphQLPasswordChangeAdapter from './graphql/PasswordChangeAdapter';
import GraphQLPasswordResetAdapter from './graphql/PasswordResetAdapter';
import GraphQLRegistrationAdapter from './graphql/RegistrationAdapter';
import ReviewGQLAdapter from './graphql/ReviewAdapter';
import GraphQLSavedCardsAdapter from './graphql/SavedCardsAdapter';
import GraphQLTreatmentAdapter from './graphql/TreatmentAdapter';
import GraphQLTreatmentDetailsAdapter from './graphql/TreatmentTypeDetailsAdapter';
import GraphQLVoucherPaymentAdapter from './graphql/VoucherPaymentAdapter';
/* Native resolvers */
import AppleAuthProvider from './native/AppleAuthProvider';
import CustomGoBackAdapter from './native/CustomGoBackAdapter';
import FacebookAuthProvider from './native/FacebookAuthProvider';
import HandleHardwareBackButtonAdapter from './native/HandleHardwareBackButtonAdapter';
import NativeCalendarAdapter from './native/NativeCalendarAdapter';
import type { IShareAdapter } from './native/NativeShareAdapter';
import NativeShareAdapter from './native/NativeShareAdapter';
import OpenSourceNoticesAdapter from './native/OpenSourceNoticesAdapter';
import CardPaymentProvider from './payment/CardPaymentProvider';
import PayPalPaymentProvider from './payment/PayPalPaymentProvider';
/* Payments */
import SofortPaymentAdapter from './payment/SofortPaymentAdapter';
import SofortPaymentProvider from './payment/SofortPaymentProvider';
import StripeProvider, { STRIPE_PROVIDER_NAME } from './payment/StripeProvider';
import WebStripePaymentProvider from './payment/WebStripePaymentProvider';

container
  .bind<ITreatmentAdapter>(pageInjectables.TreatmentAdapter)
  .toConstantValue(GraphQLTreatmentAdapter);
container
  .bind<IExpertProfileAdapter>(pageInjectables.ExpertProfileAdapter)
  .toConstantValue(GraphQLExpertProfileAdapter);
container
  .bind<IRegistrationAdapter>(pageInjectables.RegistrationAdapter)
  .toConstantValue(GraphQLRegistrationAdapter);
container
  .bind<IPasswordChangeAdapter>(pageInjectables.PasswordChangeAdapter)
  .toConstantValue(GraphQLPasswordChangeAdapter);
container
  .bind<IPasswordResetAdapter>(pageInjectables.PasswordResetAdapter)
  .toConstantValue(GraphQLPasswordResetAdapter);
container
  .bind<IBookingPaymentAdapter>(pageInjectables.BookingPaymentAdapter)
  .toConstantValue(GraphQLBookingPaymentAdapter);
container
  .bind<IAccountBookingsAdapter>(pageInjectables.AccountBookingsAdapter)
  .toConstantValue(GraphQLAccountBookingsAdapter);
container
  .bind<IBookingDetailsAdapter>(pageInjectables.BookingDetailsAdapter)
  .toConstantValue(GraphQLBookingDetailsAdapter);
container
  .bind<IReviewAdapter>(pageInjectables.ReviewAdapter)
  .toConstantValue(ReviewGQLAdapter);
container
  .bind<IVoucherPaymentAdapter>(pageInjectables.VoucherPaymentAdapter)
  .toConstantValue(GraphQLVoucherPaymentAdapter);
container
  .bind<ISavedCardsAdapter>(pageInjectables.SavedCardsAdapter)
  .toConstantValue(GraphQLSavedCardsAdapter);
container
  .bind<IBookingRequestAdapter>(pageInjectables.BookingRequestAdapter)
  .toConstantValue(BookingRequestGQLAdapter);
container.addSingleton<IClientMetadataAdapter>(
  CachedGraphQLClientMetadataAdapter,
  stateInjectables.ClientMetadataAdapter,
);
container
  .bind<ICalendarAdapter>(pageInjectables.CalendarAdapter)
  .toConstantValue(NativeCalendarAdapter);
container
  .bind<ICredentialsLoginAdapter>(pageInjectables.CredentialsLoginAdapter)
  .toConstantValue(CredentialLoginAdapter);
container
  .bind<IExternalAuthenticationAdapter>(
    pageInjectables.ExternalAuthenticationProvider,
  )
  .toConstantValue(ExternalAuthenticationAdapter);
container
  .bind<IEditProfileAdapter>(pageInjectables.EditProfileAdapter)
  .toConstantValue(GraphQLEditProfileAdapter);
container
  .bind<IShareAdapter>(pageInjectables.ShareAdapter)
  .to(NativeShareAdapter);
container
  .bind<ITreatmentCustomGoBackAdapter>(pageInjectables.CustomGoBackAdapter)
  .toConstantValue(CustomGoBackAdapter);
container
  .bind<ITreatmentTypeDetailsAdapter>(pageInjectables.TreatmentDetailsAdapter)
  .toConstantValue(GraphQLTreatmentDetailsAdapter);
container
  .bind<IHandleHardwareBackButtonAdapter>(
    hardwareInjectables.HandleHardwareBackButtonAdapter,
  )
  .toConstantValue(HandleHardwareBackButtonAdapter);
container
  .bind<IOpenSourceNoticesAdapter>(pageInjectables.OpenSourceNoticesAdapter)
  .toConstantValue(OpenSourceNoticesAdapter);
/* Payments */
container
  .bind<StripeProvider>(STRIPE_PROVIDER_NAME)
  .to(StripeProvider)
  .inSingletonScope();
container
  .bind<IPaymentProvider>(pageInjectables.PaymentProvider)
  .to(CardPaymentProvider)
  .inSingletonScope()
  .whenTargetNamed('card_payment');
container
  .bind<IPaymentProvider>(pageInjectables.PaymentProvider)
  .to(GooglePayPaymentProvider)
  .inSingletonScope()
  .whenTargetNamed('google_pay');
container
  .bind<IPaymentProvider>(pageInjectables.PaymentProvider)
  .to(ApplePayPaymentProvider)
  .inSingletonScope()
  .whenTargetNamed('apple_pay');
container
  .bind<IPaymentProvider>(pageInjectables.PaymentProvider)
  .to(WebStripePaymentProvider)
  .inSingletonScope()
  .whenTargetNamed('stripe_web');
container
  .bind<IPaymentProvider>(pageInjectables.PaymentProvider)
  .to(SofortPaymentProvider)
  .inSingletonScope()
  .whenTargetNamed('sofort');
container
  .bind<IPaymentProvider>(pageInjectables.PaymentProvider)
  .to(PayPalPaymentProvider)
  .inSingletonScope()
  .whenTargetNamed('paypal');
container
  .bind<ISofortPaymentAdapter>(pageInjectables.SofortPaymentAdapter)
  .to(SofortPaymentAdapter)
  .inSingletonScope();

/* Auth providers */
container
  .bind<IExternalAuthProvider>(stateInjectables.ExternalAuthProvider)
  .to(FacebookAuthProvider)
  .whenTargetNamed('facebook');
container
  .bind<IExternalAuthProvider>(stateInjectables.ExternalAuthProvider)
  .to(AppleAuthProvider)
  .whenTargetNamed('apple');
