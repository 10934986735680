import { IonSpinner } from '@ionic/react';

import CustomModal from '../../../../ui/elements/CustomModal/CustomModal';
import SubPageWrapperForModal from '../../../../ui/elements/CustomModal/SubPageWrapperForModal';
import type { ObjectID } from '../../../types';
import { useLocationById } from '../useLocations';
import LocationDetails from './LocationDetails';
import BeforeContentSwiper from '../../../../ui/elements/Swiper/BeforeContentSwiper';

const LocationDetailsModal = ({
  locationId,
  resetLocation,
}: {
  locationId: ObjectID;
  resetLocation: () => void;
}): JSX.Element => {
  const location = useLocationById(locationId);

  return (
    <CustomModal
      isOpen={Boolean(locationId)}
      onDidDismiss={(e) => {
        e.stopPropagation();
        resetLocation();
      }}
    >
      <SubPageWrapperForModal
        wrapperClassName="treatment-details info-single-row"
        beforeContent={
          <div className="treatment-details-before-content modal">
            {location.value && (
              <BeforeContentSwiper media={location.value.media} />
            )}
          </div>
        }
        onClose={(e) => {
          e.stopPropagation();
          resetLocation();
        }}
      >
        {!location.value && <IonSpinner color="secondary" />}
        {location.value && <LocationDetails location={location.value} />}
      </SubPageWrapperForModal>
    </CustomModal>
  );
};

export default LocationDetailsModal;
