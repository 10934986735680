import './createReview.scss';

import { useIonAlert } from '@ionic/react';
import injectables from 'application/injectables';
import type IReviewAdapter from 'application/pages/CreateReview/IReviewAdapter';
import type { ObjectID } from 'application/types';
import BookingDetailsAdapter from 'infrastructure/adapters/graphql/BookingDetailsAdapter';
import { useInject } from 'inversify-hooks';
import React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import TreatmentTypeGalleryHeader from 'ui/elements/TreatmentTypeGalleryHeader';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';

import CreateReviewForm from './CreateReviewForm';

// TODO: refactor required
/* eslint-disable @typescript-eslint/unbound-method */

const CreateReviewFromLink: React.FC = () => {
  const [showAlert] = useIonAlert();
  const t = useContextTranslation('page.create_review');
  const mt = useContextTranslation('misc');
  const history = useHistory();
  const [reviewAdapter] = useInject<IReviewAdapter>(
    injectables.pages.ReviewAdapter,
  );

  const { bookingId, bookingToken } = useParams<{
    bookingId: ObjectID;
    bookingToken: ObjectID;
  }>();

  const booking = BookingDetailsAdapter.useMultipleBookingsDetails(
    bookingId,
    bookingToken,
  ).value;

  React.useEffect(() => {
    if (booking?.hasReview) {
      void showAlert({
        message: t('already_created'),
        buttons: [mt('close')],
        onDidDismiss: () => {
          history.replace('/home');
        },
      });
    }
  }, [booking?.hasReview]);

  return (
    <SubPageLayout
      beforeContent={
        booking?.bookings[0].treatmentType && (
          <TreatmentTypeGalleryHeader
            type={booking?.bookings[0].treatmentType}
            single
          />
        )
      }
    >
      {booking && (
        <CreateReviewForm
          booking={booking}
          createReview={reviewAdapter.createReview}
        />
      )}
    </SubPageLayout>
  );
};

export default CreateReviewFromLink;
