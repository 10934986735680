import { IonCol, IonGrid, IonLoading, IonRow, useIonAlert } from '@ionic/react';
import type { PaymentParams } from 'application/pages/BookingPayment/IPaymentProvider';
import type { StripePaymentSheet } from 'infrastructure/adapters/payment/useStripePaymentIntent';
import useStripePaymentIntent from 'infrastructure/adapters/payment/useStripePaymentIntent';
import * as React from 'react';
import { useInject } from 'inversify-hooks';
import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { loggingServiceSymbol } from 'application/services/IErrorLoggingService';

const RenderNativeStripePaymentProvider = (
  { instance, onError }: PaymentParams,
  createPayment: (sheet: StripePaymentSheet) => Promise<void>,
): JSX.Element => {
  const paymentSheet = useStripePaymentIntent({
    subject: instance.subject,
    targetId: instance.targetId,
    paymentMethod: 'card',
  });

  const [showAlert] = useIonAlert();

  const [loggingService] =
    useInject<IErrorLoggingService>(loggingServiceSymbol);

  React.useEffect(() => {
    if (!paymentSheet.value) {
      if (paymentSheet.error) {
        void showAlert({
          message: `Could not create payment: ${paymentSheet.error.message}`,
        });
        loggingService.traceException(paymentSheet.error);
      }
      return;
    }
    const sheet = paymentSheet.value;
    createPayment(sheet).catch((error) => {
      onError(error);
      loggingService.traceException(error);
    });
    // eslint-disable-next-line @typescript-eslint/unbound-method
  },  [paymentSheet.value, loggingService.traceException]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <div className="stripe-payment">
            <IonLoading isOpen={paymentSheet.loading} />
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default RenderNativeStripePaymentProvider;
