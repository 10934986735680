import { gql, useQuery } from '@apollo/client';
import type IAccountBookingsAdapter from 'application/pages/MyBookings/IAccountBookingsAdapter';
import type { HookData, ObjectID, WithRefetch } from 'application/types';
import type {
  BookingAndCartCombined,
  StoredBooking,
  StoredCart,
} from 'application/types/mapBookingJSONToObject';
import { mapCartJSONToObject } from 'application/types/mapBookingJSONToObject';

import client from '../../apollo';

const MY_BOOKINGS_QUERY = gql`
  query MyBookings {
    myCarts {
      hasReview
      address {
        locality
        postalCode
        street
        streetNumber
      }
      client
      customId
      clientData {
        companyName
        email
        name
        birthday
        surname
        companyName
      }
      createdAt
      details {
        massageTable
        notes
        pets
      }
      bookings {
        expert {
          name
          age
          profilePictureUrl
          media {
            type
            uri
          }
          slogan {
            lang
            value
          }
          experience {
            lang
            value
          }
          languages {
            lang
            value
          }
          id
        }
        treatmentType {
          name {
            lang
            value
          }
          description {
            lang
            value
          }
          id
          media {
            type
            uri
          }
        }
        id
      }
      locationId
      id
      length
      price {
        components {
          type
          value
        }
        total
        totalTax
      }
      status
      time

      updatedAt
      token
      isInSpa
      isWithoutOffset
    }
  }
`;

const CANCEL_BOOKING = gql`
  mutation CancelBooking($bookingId: ID!, $token: String) {
    cancelBooking(bookingId: $bookingId, token: $token)
  }
`;

const CANCEL_CART = gql`
  mutation CancelCart($cartId: ID!, $token: String) {
    cancelCart(cartId: $cartId, token: $token)
  }
`;

interface MyBookingsQueryResult {
  myBookings: StoredBooking[];
  myCarts: StoredCart[];
}

const AccountBookingsAdapter: IAccountBookingsAdapter = {
  useAccountBookings(): WithRefetch<
    HookData<{
      bookings: BookingAndCartCombined[];
    }>
  > {
    const query = useQuery<MyBookingsQueryResult>(MY_BOOKINGS_QUERY, {
      fetchPolicy: 'network-only',
    });

    return {
      loading: query.loading,
      error: query.error,
      value: query.data
        ? {
            bookings: [...query.data.myCarts.map(mapCartJSONToObject)],
          }
        : null,
      refetch: () => {
        query.refetch().catch(() => {});
      },
    };
  },

  async cancelBooking(bookingId: ObjectID, token?: string): Promise<void> {
    await client.mutate({
      mutation: CANCEL_BOOKING,
      variables: {
        bookingId,
        token,
      },
      refetchQueries: ['MyBookings'],
    });
  },

  async cancelCart(cartId: ObjectID, token?: string): Promise<void> {
    await client.mutate({
      mutation: CANCEL_CART,
      variables: {
        cartId,
        token,
      },
      refetchQueries: ['MyBookings'],
    });
  },
};

export default AccountBookingsAdapter;
