export class CredentialsError extends Error {
  constructor() {
    super('invalid_credentials');
  }
}
export class AccountNotVerifiedError extends Error {
  constructor() {
    super('account_not_verified');
  }
}

export class AccountBlocked extends Error {
  constructor() {
    super('account_blocked');
  }
}

export default interface ICredentialsLoginAdapter {
  getAccessToken(email: string, password: string): Promise<string>;
}
