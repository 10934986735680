import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import type { SwiperContainer } from 'swiper/element/bundle';
import { register } from 'swiper/element/bundle';
import type { SwiperOptions } from 'swiper/types';
import './swiper.scss';
import 'swiper/css';

type SwiperProps = SwiperOptions & {
  children: React.ReactNode;
  className?: string;
};

const Swiper = forwardRef<SwiperContainer, SwiperProps>(
  function Swiper(props, outerRef) {
    const swiperRef = useRef<SwiperContainer>(null);
    const { children, ...rest } = props;

    useImperativeHandle(outerRef, () => swiperRef.current!, []);

    useEffect(() => {
      // Register Swiper web component
      register();

      const params = {
        ...rest,
        injectStyles: [
          `
      .swiper-button-prev svg, .swiper-button-next svg {
      display: none !important;

      }
      `,
        ],
      };
      if (swiperRef.current) {
        Object.assign(swiperRef.current, params);

        swiperRef.current.initialize();
      }
    }, [rest]);

    return (
      <swiper-container
        init={false}
        ref={swiperRef}
        class={`swiper-container ${props.className ? props.className : ''}`}
      >
        {children}
      </swiper-container>
    );
  },
);

const SwiperSlide = ({
  children,
  className,
  ...rest
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <swiper-slide {...rest}>
      <div className={`swiper-content ${className ? className : ''}`}>
        {children}
      </div>
    </swiper-slide>
  );
};

export { SwiperSlide, Swiper };
