import 'application/pages/Treatments/DateModal/date.scss';

import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonRow,
  IonText,
} from '@ionic/react';
import type { TreatmentDate, TreatmentTimeRange } from 'application/types';
import { slotsBuilder } from 'application/types/utils';
import { format, isEqual } from 'date-fns';
import type { Dispatch, SetStateAction } from 'react';
import * as React from 'react';
import CustomModal from 'ui/elements/CustomModal/CustomModal';
import { useContextTranslation } from 'ui/translation';

import NoAvailabilitiesCalendarWrapper from '../DateModal/NoAvailabilitiesCalendarWrapper';
import Button from 'ui/elements/Button/Button';

// TODO: refactor required
/* eslint-disable @typescript-eslint/no-floating-promises */

interface DateModalProps {
  open: boolean;
  onClose: () => void;
  selectedDate: TreatmentDate | null;
  onSelect: (date: TreatmentDate) => void;
}

const Availabilities: React.FC<{
  timeRange: TreatmentTimeRange;
  expertSelectedSlot: Date | null;
  setExpertSelectedSlot: Dispatch<SetStateAction<Date | null>>;
  selectedDate: Date;
}> = ({
  timeRange,
  expertSelectedSlot,
  setExpertSelectedSlot,
  selectedDate,
}) => {
  const t = useContextTranslation('page.treatment');
  const generatedSlots = React.useMemo(
    () => slotsBuilder(selectedDate, timeRange),
    [selectedDate, timeRange],
  );

  return (
    <>
      {generatedSlots.length ? (
        generatedSlots.map((date) => (
          <button
            key={date.toISOString()}
            className={
              expertSelectedSlot && isEqual(date, expertSelectedSlot)
                ? 'selected'
                : undefined
            }
            type="button"
            onClick={() => {
              setExpertSelectedSlot(date);
            }}
          >
            {format(date, 'H:mm')}
          </button>
        ))
      ) : (
        <IonText color="tertiary">
          <h6>{t('no_availabilities_1')}</h6>
          <h6 className="no-availabilities-text">{t('no_availabilities_2')}</h6>
        </IonText>
      )}
    </>
  );
};

const BookingRequestCalendarWrapper: React.FC<DateModalProps> = ({
  open,
  onClose,
  selectedDate,
  onSelect,
}) => {
  const t = useContextTranslation('page.treatment.date');
  const [date, setDate] = React.useState<Date>();
  const [timeRange, setTimeRange] = React.useState<TreatmentTimeRange>();
  const [expertSelectedSlot, setExpertSelectedSlot] =
    React.useState<Date | null>(null);

  React.useEffect(() => {
    setDate(selectedDate?.date);
    setTimeRange(selectedDate?.timeRange);
    setExpertSelectedSlot(selectedDate?.date || null);
  }, [selectedDate?.date, selectedDate?.timeRange]);

  const onSubmit = () => {
    if (!date || !timeRange) {
      return;
    }
    onSelect({ date: expertSelectedSlot || new Date(), timeRange });
  };

  return (
    <CustomModal cssClass="date-modal" isOpen={open} onDidDismiss={onClose}>
      <IonHeader>
        <div className="header-container">
          <button
            type="button"
            aria-label={t('back')}
            onClick={() => onClose()}
          />
          <div className="title">{t('header_title')}</div>
        </div>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <NoAvailabilitiesCalendarWrapper
              date={date}
              setDate={setDate}
              timeRange={timeRange}
              setTimeRange={setTimeRange}
            />
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonCol size="12" className="availabilities-container spa">
          {date && timeRange && (
            <Availabilities
              timeRange={timeRange}
              expertSelectedSlot={expertSelectedSlot}
              setExpertSelectedSlot={setExpertSelectedSlot}
              selectedDate={date}
            />
          )}
        </IonCol>
        <Button
          color="primary"
          disabled={!(date && timeRange && expertSelectedSlot)}
          onClick={onSubmit}
        >
          {t('next')}
        </Button>
      </IonFooter>
    </CustomModal>
  );
};

export default BookingRequestCalendarWrapper;
