import BookingFooterWithChild from '../../../ui/elements/BookingFooter/BookingFooterWithNextExpertSlots';
import { useTreatmentBuilder } from '../../state/TreatmentContext';
import BeforeContentGallery, { defaultMedia } from '../../../ui/elements/BeforeContentGallery';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { IonCol, IonGrid, IonRow, IonSpinner, useIonViewWillEnter } from '@ionic/react';
import styles from '../../../ui/elements/ExpandableSelect/expandableSelect.module.scss';
import ButtonSelector from '../../../ui/elements/ButtonSelector';
import LocationFormatter from '../../../ui/elements/LocationFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import TreatmentDateSelector from '../Treatments/TreatmentDateSelector';
import { useHistory } from 'react-router';
import type { AvailableExpertsQuery, ITreatmentAdapter } from '../Treatments/ITreatmentAdapter';
import injectables from '../../injectables';
import { useInject } from 'inversify-hooks';
import TreatmentResults from '../Treatments/TreatmentResults';
import { useEffect, useState } from 'react';
import AssistedStretchResults from './AssistedStretchResults';
import { useTreatmentTypeInfo } from '../../../infrastructure/adapters/graphql/TreatmentAdapter';
import { assistedStretchType } from './AssistedStretchAtoms';
import { useAtom } from 'jotai';
import { ObjectID, TreatmentDate, TreatmentLocation, TreatmentType } from '../../types';

const RESULTS_PER_PAGE = 5;

const StretchSearchContent = ({ locationId, date, location, stretchType }: {
  locationId: ObjectID,
  date: TreatmentDate | null,
  location: TreatmentLocation,
  stretchType: TreatmentType
}) => {
  const [adapter] = useInject<ITreatmentAdapter>(
    injectables.pages.TreatmentAdapter,
  );

  const results = adapter.useAvailableExpertsQuery({
    locationId,
    postalCode:
      location?.postalCode && location.postalCode?.length > 3
        ? location.postalCode
        : null,
    date: date || null,
    pagination: {
      offset: 0,
      limit: RESULTS_PER_PAGE,
    },
  });
  const [page, setPage] = useState<number>(1);
  const [globalStretchType, setType] = useAtom(assistedStretchType);

  useEffect(() => {
    if (stretchType && !globalStretchType) {
      setType(
        stretchType,
      );
    }

  }, [stretchType, globalStretchType, locationId]);

  return (
    <>
      {((results.loading || !location)) ?
        <IonSpinner color="secondary" /> :
        <TreatmentResults
          results={results}
          page={page}
          setPage={setPage}
          timeRange={date?.timeRange}
          location={location}
          renderResults={(result: AvailableExpertsQuery) => {
            return (
              <AssistedStretchResults
                result={result}
              />
            );
          }}
        />
      }
    </>
  );
};

const AssistedStretchSearch = () => {
  const history = useHistory();

  const {
    type,
    slotTime,
    experts,
    location,
    date,
    isSpaBooking,
    locationId,
    setDate,
  } = useTreatmentBuilder();

  const { value: ttypes } = useTreatmentTypeInfo({
    treatmentTypeVariant: 'stretch',
    ignoreOnlySpa: true,
    locationId: locationId ? locationId : null,
  });
  const stretchType = ttypes?.[0];

  useIonViewWillEnter(() => {
    if (!locationId) {
      history.push('/assisted-stretch', {
        direction: 'back',
      });
    }
  }, []);


  const hasChosenTreatment = Boolean(
    date &&
    location?.postalCode &&
    (experts?.[0].type || (isSpaBooking && type)),
  );


  const footerContent = () => {
    if (hasChosenTreatment) {
      return (
        <BookingFooterWithChild
          onNext={() => {
            history.push('/booking/summary');
          }}
          expertIndex={1}
          nextEnabled={Boolean(hasChosenTreatment && slotTime)}
        />
      );
    }
    return <></>;
  };

  // shared util
  const onGoBack = (handleGoBack: () => void) => {
    handleGoBack();
  };

  return (
    <SubPageLayout
      beforeContent={
        <BeforeContentGallery
          //TODO location or tt media?
          media={defaultMedia}
        />
      }
      onGoBack={onGoBack}
      footerContent={() => footerContent()}
      withBanner
    >
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <section
              className={[styles.hasValue, styles.expandableSelect].join(' ')}
            >
              <ButtonSelector
                onClick={() => {
                }}
                icon={<FontAwesomeIcon icon={faHome} />}
                options={{
                  buttonClassName: styles.expandButton,
                  textClassName: styles.textContainer,
                  iconClassName: styles.iconContainer,
                  arrowClassName: styles.arrowContainer,
                }}
              >
                {location ? <LocationFormatter location={location} /> : <></>}
              </ButtonSelector>
            </section>
            <IonCol size="12">
              <TreatmentDateSelector
                date={date}
                onSelect={(selectedDate) => {
                  setDate(selectedDate);
                }}
              />
            </IonCol>
          </IonCol>
        </IonRow>
        {(location && locationId && stretchType) ?
          (
            <StretchSearchContent
              stretchType={stretchType}
              location={location}
              locationId={locationId}
              date={date} />
          ) :
          <></>
        }
      </IonGrid>
    </SubPageLayout>
  );
};

export default AssistedStretchSearch;