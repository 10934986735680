import { PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe';
import { isPlatform } from '@ionic/react';
import type IPaymentProvider from 'application/pages/BookingPayment/IPaymentProvider';
import type { PaymentParams } from 'application/pages/BookingPayment/IPaymentProvider';
import { injectable } from 'inversify';

import i18n from '../../i18n';
import renderNativeStripePaymentProvider from './renderNativeStripePaymentProvider';
import type { StripePaymentSheet } from './useStripePaymentIntent';

@injectable()
class CardPaymentProvider implements IPaymentProvider {
  key = 'card_payment';

  weight = 10;

  getName(): string {
    return i18n.t('payment.stripe_native.title');
  }

  Render(params: PaymentParams): JSX.Element {
    const createPayment = (sheet: StripePaymentSheet) =>
      Stripe.createPaymentSheet({
        customerId: sheet.customerId,
        paymentIntentClientSecret: sheet.paymentIntent,
        customerEphemeralKeySecret: sheet.ephemeralKey,
        merchantDisplayName: 'Soulhouse',
      })
        .then(() => Stripe.presentPaymentSheet())
        .then(result => {
          if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
            params.onSuccess();
            return;
          }
          throw new Error(result.paymentResult);
        });

    return renderNativeStripePaymentProvider(params, createPayment);
  }

  isAvailable(): boolean {
    return isPlatform('capacitor');
  }
}

export default CardPaymentProvider;
