import { IonLoading, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import injectables from 'application/injectables';
import type {
  ISofortPaymentAdapter,
  SofortPaymentParams,
} from 'application/pages/SofortPayment/ISofortPaymentAdapter';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import SubPageLayout from 'ui/layout/SubPageLayout';

type SofortPaymentQueryParams = Pick<
  SofortPaymentParams,
  'paymentIntentSecret' | 'secret' | 'subject' | 'targetId'
>;

const SofortPayment: React.FC = () => {
  const [adapter] = useInject<ISofortPaymentAdapter>(
    injectables.pages.SofortPaymentAdapter,
  );
  const location = useLocation();
  const [showAlert] = useIonAlert();
  const params = useParams<SofortPaymentQueryParams>();

  const [loading, setLoading] = React.useState<boolean>(true);

  const handlePayment = async () => {
    const queryParams = new URLSearchParams(location.search);
    console.log('queryParams', queryParams);
    const returnPrefix = queryParams.get('return_prefix');
    const basePath = window.location.href.substring(
      0,
      window.location.href.indexOf(window.location.pathname),
    );

    await adapter.createPaymentAndRedirect({
      ...params,
      returnUrl: `${basePath}/payment/callback?subject=${
        params.subject
      }&targetId=${params.targetId}&method=sofort&secret=${params.secret}${
        returnPrefix ? `&return_prefix=${returnPrefix}` : ''
      }`,
    });
  };

  useIonViewDidEnter(() => {
    setLoading(true);
    handlePayment()
      .catch((e) => {
        void showAlert(`Sofort error: ${e.message}`);
      })
      .finally(() => setLoading(false));
  }, [adapter]);
  return (
    <SubPageLayout>
      <IonLoading isOpen={loading} />
    </SubPageLayout>
  );
};

export default SofortPayment;
