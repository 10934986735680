import './savedCards.scss';

import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRow,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import injectables from 'application/injectables';
import type ISavedCardsAdapter from 'application/pages/SavedCards/ISavedCardsAdapter';
import { useAuth } from 'application/state/AuthProvider';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';

const SavedCardList: React.FC = () => {
  const t = useContextTranslation('page.saved_cards');
  const mt = useContextTranslation('misc');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [adapter] = useInject<ISavedCardsAdapter>(
    injectables.pages.SavedCardsAdapter,
  );
  const [showAlert] = useIonAlert();

  const cards = adapter.useSavedCards();
  const onDeleteCard = (cardId: string) => {
    setLoading(true);
    adapter
      .deleteSavedCard(cardId)
      .catch(() => {
        void showAlert(mt('something_went_wrong'));
      })
      .finally(() => setLoading(false));
  };

  const onDeletePrompt = (cardId: string) => {
    void showAlert({
      message: t('delete_prompt'),
      buttons: [
        mt('cancel'),
        { text: t('delete_ok'), handler: () => onDeleteCard(cardId) },
      ],
    });
  };
  return (
    <>
      <IonLoading isOpen={cards.loading || loading} />
      {cards.error && <div>{mt('something_went_wrong')}</div>}
      {cards.value?.length === 0 && <div>{t('no_cards')}</div>}
      <IonList>
        {cards.value?.map((card) => (
          <IonItem key={card.id}>
            <IonLabel>
              {card.brand.toUpperCase()}, {card.expiryMonth}/{card.expiryYear},
              x-{card.numberSuffix}
            </IonLabel>
            <IonButton
              color="danger"
              slot="end"
              onClick={() => onDeletePrompt(card.id)}
            >
              {t('delete')}
            </IonButton>
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

const SavedCards: React.FC = () => {
  const t = useContextTranslation('page.saved_cards');
  const auth = useAuth();
  const history = useHistory();

  useIonViewWillEnter(() => {
    if (!auth.isAuthenticated) {
      history.replace('/home');
    }
  }, [auth.isAuthenticated]);

  return (
    <SubPageLayout className="saved-cards">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h1>{t('title')}</h1>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">{auth.isAuthenticated && <SavedCardList />}</IonCol>
        </IonRow>
      </IonGrid>
    </SubPageLayout>
  );
};

export default SavedCards;
