import { faHotel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonTextarea } from '@ionic/react';
import { BookingPlace } from 'application/types';
import { Field, Form } from 'formik';
import * as React from 'react';
import ExpandableSelect from 'ui/elements/ExpandableSelect';
import { useContextTranslation } from 'ui/translation';
import IonicField from 'ui/utils/IonicField';

import { useTreatmentBuilder } from '../../state/TreatmentContext';
import { ReactComponent as MassageTableIcon } from './massage.svg';

const BookingDetailsForm: React.FC<{
  inSpa: boolean;
  multipleBooking?: boolean;
}> = ({ inSpa = false, multipleBooking = false }) => {
  const t = useContextTranslation('page.booking_summary.form');
  const { isSpaBooking } = useTreatmentBuilder();

  const massageTableLabels = React.useMemo(
    () => ({
      1: t('table_available_yes'),
      0: t('table_available_no'),
    }),
    [t],
  );

  const multipleMassageTablesLabels = React.useMemo(
    () => ({
      0: t('table_available_no'),
      1: t('table_available_number', { number: 1 }),
      2: t('table_available_number', { number: 2 }),
    }),
    [t],
  );

  const placeLabels = React.useMemo(
    () => ({
      [BookingPlace.Home]: t('place_home'),
      [BookingPlace.Hotel]: t('place_hotel'),
      [BookingPlace.Company]: t('place_company'),
      [BookingPlace.Event]: t('place_event'),
      [BookingPlace.Endorsement]: t('place_endorsement'),
    }),
    [t],
  );

  const [expanded, setExpanded] = React.useState<'massage' | 'place' | false>(
    false,
  );

  return (
    <Form className="booking-details-form">
      <Field
        component={IonicField}
        ionicComponent={ExpandableSelect}
        options={placeLabels}
        className={`button-selector place-selector${inSpa ? ' hidden' : ''}`}
        name="place"
        placeholder={t('place_label')}
        expanded={expanded === 'place'}
        onClick={() => setExpanded(expanded === 'place' ? false : 'place')}
        value={BookingPlace.Home}
        onSelectChange={() => {
          setExpanded(false);
        }}
        buttonIcon={<FontAwesomeIcon icon={faHotel} />}
      />
      <Field
        component={IonicField}
        ionicComponent={ExpandableSelect}
        options={
          multipleBooking ? multipleMassageTablesLabels : massageTableLabels
        }
        className={`button-selector massage-table-selector${
          inSpa ? ' hidden' : ''
        }`}
        placeholder={t('table_available')}
        name="massageTable"
        required
        expanded={expanded === 'massage'}
        onClick={() => setExpanded(expanded === 'massage' ? false : 'massage')}
        onSelectChange={() => {
          setExpanded(false);
        }}
        buttonIcon={<MassageTableIcon />}
      />
      <Field
        component={IonicField}
        ionicComponent={IonTextarea}
        className="form-input"
        name="notes"
        showErrors
        type="text"
        placeholder={t(
          isSpaBooking ? 'spa_notes_placeholder' : 'notes_placeholder',
        )}
      />
    </Form>
  );
};

export default BookingDetailsForm;
