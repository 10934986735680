import './buttonSelector.scss';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import classNames from 'classnames';

const ButtonSelector = ({
  onClick,
  options,
  icon,
  expandable,
  children,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: JSX.Element;
  expandable?: boolean;
  children: JSX.Element;
  options?: {
    buttonClassName?: string;
    textClassName?: string;
    iconClassName?: string;
    arrowClassName?: string;
  };
}) => (
  <button
    type="button"
    className={classNames(options?.buttonClassName)}
    onClick={onClick}
  >
    {icon && (
      <div className={classNames('icon-container', options?.iconClassName)}>
        {icon}
      </div>
    )}
    <div className={classNames('text-container', options?.textClassName)}>
      {children}
    </div>
    {expandable && (
      <div className={classNames('arrow-container', options?.arrowClassName)}>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    )}
  </button>
);

export default ButtonSelector;
