import './Login.scss';

import {
  IonCol,
  IonGrid,
  IonRow,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import injectables from 'application/injectables';
import type { ConfirmAccountLocationState } from 'application/pages/Register/ConfirmAccount';
import { useAuth } from 'application/state/AuthProvider';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import * as Yup from 'yup';

import type ICredentialsLoginAdapter from './ICredentialsLoginAdapter';
import { AccountNotVerifiedError } from './ICredentialsLoginAdapter';
import { LoginForm } from './LoginForm';
import type { LoginFormValues } from './LoginForm';
import SocialLoginButtons from './SocialLoginButtons';

export interface LoginRouteState {
  email?: string;
  password?: string;
  redirectUri?: string;
}

const Login: React.FC = () => {
  const t = useContextTranslation('page.login');
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation<LoginRouteState>();
  const [adapter] = useInject<ICredentialsLoginAdapter>(
    injectables.pages.CredentialsLoginAdapter,
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('email_required'))
          .email(t('email_format')),
        password: Yup.string()
          .required(t('password_required'))
          .min(3, t('password_required')),
      }),
    [t],
  );

  const onSubmit = React.useCallback<
    (
      values: LoginFormValues,
      helpers: FormikHelpers<LoginFormValues>,
    ) => Promise<void>
  >(
    async (
      values: LoginFormValues,
      helpers: FormikHelpers<LoginFormValues>,
    ) => {
      try {
        helpers.setStatus(null);
        const accessToken = await adapter.getAccessToken(
          values.email,
          values.password,
        );
        await auth.login(accessToken);
        setTimeout(() => {
          if (auth.redirectUri) {
            history.replace(auth.redirectUri, {
              direction: 'forward',
            });
          } else {
            history.replace('/home', { direction: 'back', unmount: true });
          }
        }, 250);
      } catch (e) {
        if (e instanceof AccountNotVerifiedError) {
          history.push('register/confirm', {
            email: values.email,
            password: values.password,
          } as ConfirmAccountLocationState);
        } else {
          helpers.setStatus({
            error: e,
          });
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [t, auth.login, history],
  );

  const initialValues = {
    email: location.state?.email || '',
    password: location.state?.password || '',
  };

  useIonViewWillEnter(() => {
    if (auth.isAuthenticated) {
      history.replace('/home');
    }
  }, []);

  useIonViewWillLeave(() => {
    if (history.location.pathname !== '/register') {
      auth.setRedirectUri(null);
    }
  });

  return (
    <SubPageLayout className={"login-page"}>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h1>{t('title')}</h1>
          </IonCol>
        </IonRow>
        <Formik
          initialStatus={null}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <LoginForm />
        </Formik>
        <IonRow className="ion-justify-content-center login-footer">
          <IonCol size="12" className="register">
            <Trans t={t} i18nKey="login_note">
              login_note
              <Link className="ion-link register-link" to="/register">
                register
              </Link>
              end
            </Trans>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center ">
          <IonCol size="12" sizeMd="8" sizeLg="8" className="register">
            <div className="login-divider"><p className="divider-text">{t("or")}</p></div>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center social-buttons-wrapper">
          <IonCol size="12" sizeMd="8" sizeLg="8">
            <SocialLoginButtons />
          </IonCol>
        </IonRow>
      </IonGrid>
    </SubPageLayout>
  );
};

export default Login;
