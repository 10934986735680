import './treatmentTypeGalleryHeader.scss';

import defaultPicture from 'ui/theme/images/default-picture.jpg';
import type { TreatmentTypeMedia, WithMedia } from 'application/types';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';
import { Swiper } from './Swiper/Swiper';

interface TreatmentTypeGalleryHeaderProps {
  type: (WithMedia & { id: string }) | null;
  small?: boolean;
  single?: boolean;
}

const defaultMedia: TreatmentTypeMedia[] = [
  {
    type: 'image',
    uri: defaultPicture,
  },
];

const TreatmentTypeGalleryHeader: React.FC<TreatmentTypeGalleryHeaderProps> = ({
  type,
  small,
  single,
}) => {
  const t = useContextTranslation('misc');

  const media = type ? type.media : defaultMedia;
  const displayedMedia = small || single ? media.slice(0, 1) : media;
  return (
    <div className={`treatment-type-gallery-header${small ? ' small' : ''}`}>
      <Swiper>
        {displayedMedia.map((mediaItem) =>
          mediaItem.type === 'video' ? (
            <video
              key={mediaItem.uri}
              className={!type ? 'default-video' : ''}
              playsInline
              controls={false}
              src={mediaItem.uri}
              muted
              autoPlay
              loop
            />
          ) : (
            <img src={mediaItem.uri} alt={t('media')} key={mediaItem.uri} />
          ),
        )}
      </Swiper>
    </div>
  );
};

export default TreatmentTypeGalleryHeader;
