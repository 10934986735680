import { IonCol, IonLoading, IonRow } from '@ionic/react';
import { Field, useFormikContext } from 'formik';
import * as React from 'react';
import { Trans } from 'react-i18next';
import DateInput from 'ui/elements/DateInput/DateInput';
import { useContextTranslation } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';
import IonicField from 'ui/utils/IonicField';
import IonicPhoneField from 'ui/utils/IonicPhoneField';
import Button from 'ui/elements/Button/Button';
import i18n from '../../../infrastructure/i18n';
import { Browser } from '@capacitor/browser';

export interface RegisterFormValues {
  name: string;
  surname: string;
  birthday: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  password: string;
  repeatPassword: string;
}

export interface RegisterFormProps {
  hideCredentials?: boolean;
  hideNames?: boolean;
}

export const RegisterForm: React.FC<RegisterFormProps> = (
  {
    hideCredentials,
    hideNames,
  }) => {
  const { isSubmitting, status } = useFormikContext();
  const t = useContextTranslation('page.register');
  const statusErrorText = status?.error && t('error_unknown');
  return (
    <AutocompleteForm>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            autocomplete="organization"
            component={IonicField}
            name="companyName"
            type="text"
            placeholder={t('company_name_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      {!hideNames ? (
        <>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <Field
                component={IonicField}
                autocomplete="given-name"
                name="name"
                type="text"
                required
                placeholder={t('name_placeholder')}
                showErrors
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <Field
                component={IonicField}
                autocomplete="family-name"
                name="surname"
                type="text"
                required
                placeholder={t('surname_placeholder')}
                showErrors
              />
            </IonCol>
          </IonRow>
        </>
      ) : null}
      {!hideCredentials ? (
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <Field
              component={IonicField}
              name="email"
              type="email"
              inputMode="email"
              autocomplete="email"
              required
              placeholder={t('email_placeholder')}
              showErrors
            />
          </IonCol>
        </IonRow>
      ) : null}
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={DateInput}
            label={t('birthday_placeholder')}
            autocomplete="bday"
            name="birthday"
            required
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicPhoneField}
            className="phoneNumber"
            defaultCountry="DE"
            name="phoneNumber"
            placeholder={t('phone_number_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      {!hideCredentials ? (
        <>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <Field
                component={IonicField}
                name="password"
                type="password"
                autocomplete="new-password"
                required
                placeholder={t('password_placeholder')}
                showErrors
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <Field
                component={IonicField}
                name="repeatPassword"
                type="password"
                autocomplete="new-password"
                required
                placeholder={t('repeat_password_placeholder')}
                showErrors
              />
            </IonCol>
          </IonRow>
        </>
      ) : null}
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <p className="conditions">
            <Trans t={t} i18nKey="submit_note">
              submit_note
              <button type={'button'} className="link" onClick={() => {
                Browser.open({
                  url: `https://soulhouse.me/${i18n.language === 'de' ? 'allgemeine-geschaeftsbedingungen' : 'en/general-terms-conditions'}`,
                  presentationStyle: 'fullscreen',
                }).catch(() => {
                });
              }}>
                toc
              </button>
              and
              <button type={'button'} className="link" onClick={() => {
                Browser.open({
                  url: `https://soulhouse.me/${i18n.language === 'de' ? 'datenschutzerklaerung' : 'en/data-protection-statement'}`,
                  presentationStyle: 'fullscreen',
                }).catch(() => {
                });
              }}
              >
                privacy
              </button>
              end
            </Trans>
          </p>
          <Button className="submit-button" type="submit">
            {t('submit_label')}
          </Button>
          {statusErrorText && (
            <div className="form-error">{statusErrorText}</div>
          )}
        </IonCol>
      </IonRow>
      <IonLoading isOpen={isSubmitting} message={t('loading')} />
      <input type="submit" className="invisible-auto-submit" />
    </AutocompleteForm>
  );
};
