/* eslint-disable react/prop-types */
import { IonInput } from '@ionic/react';
import type { FieldProps } from 'formik';
import * as React from 'react';
import type { HTMLAttributes } from 'react';

export interface IonicFieldProps
  extends FieldProps<string | number | null>,
    HTMLAttributes<HTMLIonInputElement> {
  label?: string;
  ionicComponent?: React.ForwardRefExoticComponent<unknown>;
  showErrors?: boolean;
}

const IonicField: React.FC<IonicFieldProps> = ({
  field,
  form,
  showErrors,
  className,
  label,
  ionicComponent,
  children,
  ...rest
}) => {
  const IonicComponent = ionicComponent || IonInput;
  return (
    <>
      {label && <div className="form-label">{label}</div>}
      <IonicComponent
        {...rest}
        className={`form-input${className ? ` ${className}` : ''}`}
        value={field.value}
        name={field.name}
        onIonBlur={field.onBlur}
        onIonChange={(e) => {
          if (e.type !== 'change') {
            return;
          }
          field.onChange(e);
          void form.setFieldTouched(field.name, true, false);
        }}
        onIonInput={(e) => {
          field.onChange(e);
          void form.setFieldTouched(field.name, true, false);
        }}
      >
        {children}
      </IonicComponent>
      {showErrors && form.touched[field.name] && form.errors[field.name] && (
        <div className="form-error">{form.errors[field.name]}</div>
      )}
    </>
  );
};

export default IonicField;
