import { useQuery } from '@apollo/client';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { Location } from 'application/types';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { GET_LOCATION_BY_ID } from './useLocations';

export default function HandleLocationRedirect(): JSX.Element {
  const { locationId } = useParams<{
    locationId: string;
  }>();

  const { setLocationId, setLocation, setLocationMedia } =
    useTreatmentBuilder();

  const history = useHistory();

  useQuery(GET_LOCATION_BY_ID, {
    variables: {
      id: locationId,
    },
    onCompleted: (data) => {
      const location = data.location as Location;
      setLocation({
        type: 'atHome',
        locality: location.address.locality,
        street: location.address.street,
        postalCode: location.address.postalCode,
        streetNumber: location.address.streetNumber,
      });
      setLocationId(location.id);
      setLocationMedia(location.media);
      history.replace('/treatments');
    },
    onError: () => {
      history.goBack();
    },
  });

  return <></>;
}
