import './Account.scss';

import {
  IonCol,
  IonGrid,
  IonLoading,
  IonRow,
  IonText,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import DeleteAccountDialog from 'application/pages/Account/DeleteAccount';
import { useAuth } from 'application/state/AuthProvider';
import type IExternalAuthProvider from 'application/state/IExternalAuthProvider';
import { format } from 'date-fns';
import { useInject } from 'inversify-hooks';
import { container } from 'inversify-props';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import HookDataContainer from 'ui/utils/HookDataContainer';

import injectables from '../../injectables';
import type ILoginService from '../../services/ILoginService';
import Button from 'ui/elements/Button/Button';

const AccountData: React.FC = () => {
  const [loginService]: ILoginService[] = useInject<ILoginService>(
    injectables.services.LoginService,
  );
  const userProfile = loginService.useUserProfile();
  useIonViewWillEnter(() => {
    userProfile.refetch?.();
  });

  const t = useContextTranslation('page.account');

  return (
    <HookDataContainer data={userProfile}>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeMd="4">
          {t('name_label')}
        </IonCol>
        <IonCol size="12" sizeMd="8" sizeLg="4">
          <IonText color="secondary">{userProfile.value?.name}</IonText>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeMd="4">
          {t('surname_label')}
        </IonCol>
        <IonCol size="12" sizeMd="8" sizeLg="4">
          <IonText color="secondary">{userProfile.value?.surname}</IonText>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeMd="4">
          {t('email_label')}
        </IonCol>
        <IonCol size="12" sizeMd="8" sizeLg="4">
          <IonText color="secondary">{userProfile.value?.email}</IonText>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeMd="4">
          {t('birthday_label')}
        </IonCol>
        <IonCol size="12" sizeMd="8" sizeLg="4">
          <IonText color="secondary">
            {userProfile.value?.birthday &&
              format(userProfile.value.birthday, 'dd/MM/yyyy')}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeMd="4">
          {t('phone_label')}
        </IonCol>
        <IonCol size="12" sizeMd="8" sizeLg="4">
          <IonText color="secondary">{userProfile.value?.phoneNumber}</IonText>
        </IonCol>
      </IonRow>
      {userProfile.value?.companyName && (
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" sizeMd="4">
            {t('company_label')}
          </IonCol>
          <IonCol size="12" sizeMd="8" sizeLg="4">
            <IonText color="secondary">
              {userProfile.value?.companyName}
            </IonText>
          </IonCol>
        </IonRow>
      )}
    </HookDataContainer>
  );
};

const Account: React.FC = () => {
  const t = useContextTranslation('page.account');
  const auth = useAuth();
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [showAlert] = useIonAlert();
  const mt = useContextTranslation('misc');

  useIonViewWillEnter(() => {
    if (!auth.isAuthenticated) {
      history.replace('/home');
    }
  }, [auth.isAuthenticated]);

  const onLogout = () => {
    setLoading(true);
    auth
      .logout()
      .then(async () => {
        const providers = container.getAll<IExternalAuthProvider>(
          injectables.state.ExternalAuthProvider,
        );
        await Promise.all(providers.map((p) => p.reset()));

        history.replace('/home', { unmount: true, direction: 'back' });
      })
      .catch((e) => {
        setError(e);
        void showAlert(mt('something_went_wrong'));
      })
      .finally(() => setLoading(false));
  };
  const onChangePassword = () => {
    history.push('/passwordChange');
  };

  const onEditProfile = () => {
    history.push('/editProfile');
  };

  return (
    <SubPageLayout className="account-details">
      <IonLoading isOpen={loading} spinner="dots" message={mt('loading')} />
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h1>{t('title')}</h1>
          </IonCol>
        </IonRow>
        {auth.isAuthenticated && <AccountData />}
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" sizeMd="8" sizeLg="8">
            {auth.isAuthenticated && (
              <Button
                className="profileBtn"
                color="primary"
                routerLink="/savedCards"
              >
                {t('saved_cards')}
              </Button>
            )}
            {/* {auth.isAuthenticated && ( */}
            {/*   <Button */}
            {/*     className="profileBtn" */}
            {/*     color="primary" */}
            {/*     routerLink="/referral" */}
            {/*   > */}
            {/*     {t('soulhouse_wallet')} */}
            {/*   </Button> */}
            {/* )} */}
            {!auth.externalProvider && (
              <Button
                className="profileBtn"
                color="primary"
                onClick={onChangePassword}
              >
                {t('change_password')}
              </Button>
            )}
            {auth.isAuthenticated && (
              <Button
                className="profileBtn"
                color="primary"
                onClick={onEditProfile}
              >
                {t('edit_profile')}
              </Button>
            )}
            {auth.isAuthenticated && (
              <Button className="profileBtn" color="primary" onClick={onLogout}>
                {t('logout')}
              </Button>
            )}

            {error && (
              <div className="form-error">
                {typeof error === 'object' ? error.message : error}
              </div>
            )}
          </IonCol>
        </IonRow>
        {auth.isAuthenticated && (
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="8" sizeLg="8">
              <DeleteAccountDialog t={t} />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </SubPageLayout>
  );
};

export default Account;
