import './bookingSummaryTable.scss';

import {
  faCalendar,
  faClock,
  faStar,
} from '@fortawesome/free-regular-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonItem, IonList } from '@ionic/react';
import type {
  TreatmentLength,
  TreatmentLocation,
  TreatmentType,
} from 'application/types';
import * as React from 'react';
import LocationFormatter from 'ui/elements/LocationFormatter';
import { TranslatableFormatter, useContextTranslation } from 'ui/translation';
import formatLocalizedDate from 'ui/utils/formatLocalizedDate';

import { ReactComponent as TreatmentIcon } from '../../application/pages/Treatments/images/physiotherapy.svg';
import type { TreatmentExpert } from '../../application/state/TreatmentContext';

interface BookingSummaryTableProps {
  expert?: TreatmentExpert;
  length?: TreatmentLength;
  type?: TreatmentType;
  slotTime: Date;
  location: TreatmentLocation;
}

const BookingSummaryTable: React.FC<BookingSummaryTableProps> = ({
  location,
  type,
  expert,
  length,
  slotTime,
}) => {
  const t = useContextTranslation('misc');
  const time = formatLocalizedDate(slotTime, 'cccccc, dd.MM.yyyy, H:mm');
  return (
    <IonList className="booking-summary-table" mode="ios" lines="full">
      {type && (
        <IonItem detail={false} mode="ios">
          <TreatmentIcon className="treatment-icon" />
          <span className="text">
            <TranslatableFormatter value={type.name} />
          </span>
        </IonItem>
      )}
      {expert?.type && (
        <IonItem detail={false} mode="ios">
          <TreatmentIcon className="treatment-icon" />
          <span className="text">
            <TranslatableFormatter value={expert.type.name} />
          </span>
        </IonItem>
      )}
      <IonItem detail={false} mode="ios">
        <FontAwesomeIcon icon={faCalendar} />
        <span className="text">{t('time_format', { time })}</span>
      </IonItem>
      {length && (
        <IonItem detail={false} mode="ios">
          <FontAwesomeIcon icon={faClock} />
          <span className="text">
            {t('length_format', { length: length.length })}
          </span>
        </IonItem>
      )}
      {expert && (
        <IonItem detail={false} mode="ios">
          <FontAwesomeIcon icon={faStar} />
          <span className="text">
            {t('expert_name', { name: expert.name })}
          </span>
        </IonItem>
      )}

      {location.locality && (
        <IonItem detail={false} lines="none" mode="ios">
          <FontAwesomeIcon icon={faHome} />
          <span className="text">
            <LocationFormatter location={location} />
          </span>
        </IonItem>
      )}
    </IonList>
  );
};

export default BookingSummaryTable;
