import { Preferences as Storage } from '@capacitor/preferences';
import type { ClientMetadata } from 'application/state/IClientMetadataAdapter';

import ClientMetadataAdapter from './ClientMetadataAdapter';

const STORAGE_KEY = 'metadata_cache';

export default class CachedClientMetadataAdapter extends ClientMetadataAdapter {
  async sync(metadata: ClientMetadata): Promise<void> {
    const cachedRaw = await Storage.get({ key: STORAGE_KEY });
    if (cachedRaw.value) {
      try {
        const cached: ClientMetadata = JSON.parse(cachedRaw.value);
        if (
          cached.locale === metadata.locale &&
          cached.notificationToken === metadata.notificationToken
        ) {
          // no need to sync metadata
          return;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Could not parse stored metadata', e);
      }
    }

    await super.sync(metadata);

    await Storage.set({
      key: STORAGE_KEY,
      value: JSON.stringify(metadata),
    });
  }
}
