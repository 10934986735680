import { IonSpinner } from '@ionic/react';
import type { DiscountStatus } from 'application/types';
import currency from 'currency.js';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';
import Currency from 'ui/utils/Currency';

type PriceComponent = {
  type: string;
  discountCode: string;
  value: string;
};

type VoucherPrice = {
  discountResultStatus: DiscountStatus;
  priceComponents: PriceComponent[];
  total: currency;
};

const BillSummary: React.FC<{
  vouchersPrice: VoucherPrice;
  voucherAmount?: number;
  voucherValue: number | undefined;
}> = ({ vouchersPrice, voucherAmount, voucherValue }) => {
  const t = useContextTranslation('page.voucher_payment');

  if (!vouchersPrice) {
    return <IonSpinner color="secondary" />;
  }

  const billComponents = vouchersPrice?.priceComponents.reduce(
    (acc: PriceComponent[], comp) => {
      const index = acc.findIndex((item) => item?.type === comp.type);
      if (index === -1) {
        return [...acc, comp];
      }
      const newValue = currency(acc[index].value).add(comp.value);

      acc[index] = {
        ...acc[index],
        value: newValue.value.toString(),
      };
      return acc;
    },
    [],
  );

  return (
    <div className="bill-summary">
      <h4>{t('vouchers')}</h4>
      <div className="prices">
        <div className="price">
          <div className="label price-component">{t('value')}</div>
          <div className="value">
            {voucherValue && <Currency value={currency(voucherValue)} />}
          </div>
        </div>
        <div className="price">
          <div className="label price-component">{t('amount')}</div>
          <div className="value">{voucherAmount}</div>
        </div>
        {billComponents?.map((component) => (
          <div className="price" key={component.type}>
            <div className="label price-component">{t(component.type)}</div>
            <div className="value">
              <Currency value={currency(component.value)} />
            </div>
          </div>
        ))}
        <div className="price total">
          <div className="label">{t('total')} </div>
          <div className="value">
            <Currency value={currency(vouchersPrice.total)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillSummary;
