import styles from './expertCard.module.scss'
import { IonIcon } from '@ionic/react';
import { star, starOutline } from 'ionicons/icons';
import Rating from '../Rating';

const ExpertRating = ({ rating }: { rating: number }) => {
  return (
    <div className={styles.rating}>
      {(rating / 10).toFixed(1)}
      <Rating
        start={0}
        stop={5}
        initialRating={Math.round(rating / 10)}
        emptySymbol={<IonIcon icon={starOutline} />}
        fullSymbol={<IonIcon icon={star} />}
        readonly
        className={styles.ratingStars}
      />
    </div>
  );
};

export default ExpertRating;