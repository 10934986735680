import { IonCol, useIonAlert } from '@ionic/react';
import AddressForm from 'application/pages/BookingContact/AddressForm';
import { useAuth } from 'application/state/AuthProvider';
import type {
  TreatmentDate,
  TreatmentLength,
  TreatmentLocation,
  TreatmentType,
} from 'application/types';
import type { FormikHelpers } from 'formik';
import { FormikProvider, useFormik } from 'formik';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useContextTranslation } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';

import injectables from '../../../injectables';
import type { IErrorLoggingService } from '../../../services/IErrorLoggingService';
import { loggingServiceSymbol } from '../../../services/IErrorLoggingService';
import type { ITrackingService } from '../../../services/ITrackingService';
import injectablesType from '../../injectables';
import type { ITreatmentAdapter } from '../ITreatmentAdapter';
import LengthSelectorWithOutTimeRanges from '../TreatmentLengthSelector/LengthSelectorWithOutTimeRanges';
import TreatmentTypeSelector from '../TreatmentTypeSelector';
import AuthenticatedUserAutoFill from './AuthenticatedUserAutoFill';
import BookingRequestDateSelector from './BookingRequestDateSelector';
import BookingRequestFields from './BookingRequestFields';
import {
  bookingRequestInitialValues,
  useValidationSchema,
} from './bookingRequestUtils';
import type IBookingRequestAdapter from './IBookingRequestAdapter';
import type { BookingRequestData } from './IBookingRequestAdapter';
import Button from 'ui/elements/Button/Button';

// TODO: refactor required
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-floating-promises */

type ExpandedOptions = 'treatments' | 'length' | null;

type BookingRequestFormValues = Omit<BookingRequestData, 'address'> &
  BookingRequestData['address'];

const BookingRequestForm = ({
  address,
}: {
  address: TreatmentLocation;
}): JSX.Element => {
  const [service] = useInject<ITreatmentAdapter>(
    injectablesType.TreatmentAdapter,
  );

  const availableTypes = service.useTreatmentTypes();
  const t = useContextTranslation('page.treatment');

  const { isAuthenticated } = useAuth();

  const [type, setType] = useState<TreatmentType | null>(null);
  const [date, setDate] = useState<TreatmentDate | null>(null);
  const [length, setLength] = useState<TreatmentLength | null>(null);
  const [validationSchema] = useValidationSchema();
  const mt = useContextTranslation('misc');
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [showAlert] = useIonAlert();

  const [bookingRequestAdapter] = useInject<IBookingRequestAdapter>(
    injectables.pages.BookingRequestAdapter,
  );
  const [loggingAdapter] =
    useInject<IErrorLoggingService>(loggingServiceSymbol);
  const [trackingService] = useInject<ITrackingService>(
    injectables.services.TrackingService,
  );

  const onSubmit = React.useCallback<
    (
      values: BookingRequestFormValues,
      formikHelpers: FormikHelpers<BookingRequestFormValues>,
    ) => void
  >(
    async (values, formikHelpers) => {
      try {
        await bookingRequestAdapter.sendBookingRequest({
          ...values,
          address: {
            street: values.street,
            postalCode: values.postalCode,
            locality: values.locality,
            streetNumber: values.streetNumber,
          },
        });
        if (type && length) {
          trackingService.trackEvent({
            name: 'booking_request',
            data: {
              type,
              length,
            },
          });
        }
        formikHelpers.setSubmitting(false);
        setSubmitted(true);
      } catch (e) {
        loggingAdapter.traceException(e);
        formikHelpers.setSubmitting(false);
        void showAlert(mt('something_went_wrong'));
      }
    },
    [
      bookingRequestAdapter.sendBookingRequest,
      setSubmitted,
      loggingAdapter.traceException,
      showAlert,
      trackingService.trackEvent,
    ],
  );

  const formik = useFormik<BookingRequestFormValues>({
    validationSchema,
    initialValues: bookingRequestInitialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
  });

  const [expanded, setExpanded] = React.useState<ExpandedOptions>(null);

  useEffect(() => {
    formik.setFieldValue('postalCode', address.postalCode);
  }, [address.postalCode]);

  return (
    <>
      <IonCol size="12">
        <p className="no-experts-text">{t('no_any_availabilities_1')}</p>
        <p>{t('no_any_availabilities_form')}</p>
      </IonCol>
      {submitted ? (
        <p>Booking request sent</p>
      ) : (
        <FormikProvider value={formik}>
          <IonCol size="12">
            <IonCol size="12">
              <TreatmentTypeSelector
                onClick={() =>
                  setExpanded(expanded === 'treatments' ? null : 'treatments')
                }
                onChange={(selectedType) => {
                  formik.setFieldValue('treatmentTypeId', selectedType.id);
                  setType(selectedType);
                  setExpanded(null);
                }}
                expanded={expanded === 'treatments'}
                selectedType={type}
                treatmentTypes={availableTypes}
              />
            </IonCol>
            <IonCol size="12">
              <BookingRequestDateSelector
                date={date}
                onSelect={(selectedDate) => {
                  formik.setFieldValue('date', selectedDate);
                  setDate(selectedDate);
                }}
              />
            </IonCol>
            <IonCol size="12">
              <LengthSelectorWithOutTimeRanges
                onClick={() =>
                  setExpanded(expanded === 'length' ? null : 'length')
                }
                expanded={expanded === 'length'}
                treatmentType={type}
                selectedLength={length}
                onSelect={(selectedLength) => {
                  formik.setFieldValue('length', selectedLength.length);
                  setLength(selectedLength);
                  setExpanded(null);
                }}
              />
            </IonCol>
            <AutocompleteForm>
              {isAuthenticated ? (
                <AuthenticatedUserAutoFill formik={formik} />
              ) : (
                <BookingRequestFields />
              )}
              <div className="booking-request-address">
                <AddressForm />
              </div>
              <IonCol size="12" className="booking-request-col">
                <Button
                  type="submit"
                  className="booking-request"
                  disabled={!type || !length || !address.postalCode || !date}
                >
                  {t('booking_request_label')}
                </Button>
              </IonCol>
            </AutocompleteForm>
          </IonCol>
        </FormikProvider>
      )}
    </>
  );
};

export default BookingRequestForm;
