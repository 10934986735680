import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { TreatmentTimeRange } from 'application/types';
import { addHours, isAfter, set } from 'date-fns';
import * as React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import { useContextTranslation } from 'ui/translation';
import { isSameDay } from 'ui/utils/isTileDisabled';

import type { CurrentlyAvailableBookings } from '../ITreatmentAdapter';

type PeriodEndTime = {
  [key: string]: number;
};

interface TimePeriodButtonProps {
  timeRange?: TreatmentTimeRange;
  date?: Date;
  setTimeRange: Dispatch<SetStateAction<TreatmentTimeRange | undefined>>;
  price: number | null;
  availableBookings?: CurrentlyAvailableBookings;
  timeRangePeriod: TreatmentTimeRange;
}

const periodEndTime: PeriodEndTime = {
  morning: 12,
  afternoon: 17,
  evening: 22,
};

const TimePeriodSelectorButton: React.FC<TimePeriodButtonProps> = ({
  timeRange,
  date,
  setTimeRange,
  price,
  availableBookings,
  timeRangePeriod,
}) => {
  const t = useContextTranslation('page.treatment.date');
  const { isSpaBooking, setMinimalPrice } = useTreatmentBuilder();

  const availabilityForSelectedDate = useMemo(() => {
    if (!date) {
      return null;
    }
    return availableBookings?.availableDays?.find((el: { date: Date }) =>
      isSameDay(new Date(el.date), date),
    );
  }, [date]);

  const minimalPrice =
    availabilityForSelectedDate?.minimalTreatmentPrice[timeRangePeriod] &&
    Number(
      availabilityForSelectedDate.minimalTreatmentPrice[timeRangePeriod] / 100,
    );

  const { numberOfExperts } = useTreatmentBuilder();
  const selectorDisabled =
    !date ||
    isAfter(
      addHours(new Date(), 2),
      set(date, { hours: periodEndTime[timeRangePeriod], minutes: 59 }),
    ) ||
    (!!availableBookings &&
      !availabilityForSelectedDate?.availableExperts[timeRangePeriod]) ||
    (Number(numberOfExperts) > 1 &&
      Number(availabilityForSelectedDate?.availableExperts[timeRangePeriod]) <
        2);

  return (
    <button
      className={`time-period ${timeRangePeriod} ${
        timeRange === timeRangePeriod ? ' selected' : ''
      }${selectorDisabled ? ' disabled' : ''}`}
      type="button"
      disabled={selectorDisabled}
      onClick={() => {
        setTimeRange(timeRangePeriod);
        if (minimalPrice) setMinimalPrice(minimalPrice);
      }}
    >
      <span className="icon" />
      <div className="name">{t(timeRangePeriod)}</div>
      <div className="description">{t(`${timeRangePeriod}_desc`)}</div>
      {(price || minimalPrice) && (
        <div className="price">
          {t('from_price', {
            price: `${minimalPrice || price} €`,
          })}
        </div>
      )}
      {!isSpaBooking && availableBookings && date && (
        <div className="available_experts">
          {availabilityForSelectedDate?.availableExperts[timeRangePeriod]
            ? t('available_experts', {
                total: availableBookings?.totalExperts,
                available:
                  availabilityForSelectedDate.availableExperts[timeRangePeriod],
              })
            : t('no_experts_available')}
        </div>
      )}
    </button>
  );
};

export default TimePeriodSelectorButton;
