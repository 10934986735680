import { App as CapacitorApp } from '@capacitor/app';
import {
  RouteManagerContext,
  isPlatform,
  useIonAlert,
  BackButtonEvent,
} from '@ionic/react';
import type IHandleHardwareBackButtonAdapter from 'application/IHandleHardwareBackButtonAdapter';
import React from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useContextTranslation } from 'ui/translation';

function isCustomEvent(event: Event): event is CustomEvent {
  return 'detail' in event;
}

const HandleHardwareBackButtonAdapter: IHandleHardwareBackButtonAdapter = {
  useHandleHardwareBackButton() {
    const t = useContextTranslation('layout.before_exit');
    const location = useLocation();
    const routeManager = React.useContext(RouteManagerContext);
    const history = useHistory();
    const [alert] = useIonAlert();

    React.useEffect(() => {
      if (!isPlatform('capacitor')) {
        return;
      }

      const backButtonListener = (ev: BackButtonEvent) => {
        ev.detail.register(41, (processNextHandler: () => void) => {
          if (location.pathname === '/home') {
            void alert({
              message: t('message'),
              buttons: [
                t('cancel'),
                {
                  text: t('confirm'),
                  handler: () => {
                    void CapacitorApp.exitApp();
                  },
                },
              ],
            });
          }
          if (!routeManager.canGoBack()) {
            history.replace('/home', { unmount: true, direction: 'back' });
          } else {
            processNextHandler();
          }
        });
      };

      document.addEventListener('ionBackButton', (e) => {
        if (!isCustomEvent(e)) throw new Error('not a custom event');
        backButtonListener(e);
      });

      return () => {
        document.removeEventListener('ionBackButton', (e) => {
          if (!isCustomEvent(e)) throw new Error('not a custom event');
          backButtonListener(e);
        });
      };
    }, [alert, history, location.pathname, routeManager, t]);
  },
};

export default HandleHardwareBackButtonAdapter;
