import { gql } from '@apollo/client';
import { Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import type {
  ITrackingService,
  MailingAgreementEvent,
  TrackingEvent,
  TreatmentParamsSelectedEvent,
  TreatmentSlotSelectedEvent,
} from 'application/services/ITrackingService';
import { AppTrackingTransparencyStatus } from 'capacitor-app-tracking-transparency';
import { formatISO } from 'date-fns';
import client from 'infrastructure/apollo';
import i18n from 'infrastructure/i18n';
import { injectable } from 'inversify';
import { translateString } from 'ui/translation';

const NEWSLETTER_SIGNUP = gql`
  mutation NewsletterSignup(
    $email: String!
    $name: String!
    $postalCode: String!
    $agreed: Boolean!
  ) {
    newsletterSignup(
      email: $email
      name: $name
      postalCode: $postalCode
      agreed: $agreed
    )
  }
`;

export function useSubscribeToNewsletter() {
  return async (
    email: string,
    name: string,
    postalCode: string,
    agreed: boolean,
  ): Promise<void> => {
    await client.mutate({
      mutation: NEWSLETTER_SIGNUP,
      variables: {
        email,
        name,
        postalCode,
        agreed,
      },
    });
  };
}

// eslint-disable-next-line no-underscore-dangle,@typescript-eslint/naming-convention
declare const _cio: {
  identify(data: Record<string, unknown>): void;
  track(eventId: string, data: Record<string, unknown>): void;
  page(location: string, data: Record<string, unknown>): void;
  newsletter(data: Record<string, unknown>): void;
};

declare const dataLayer: {
  push(pushArgs: { event: string; [key: string]: unknown }): void;
};

@injectable()
class CustomerioGTMTrackingService implements ITrackingService {
  private enabled = false;

  trackMailingAgreement(event: MailingAgreementEvent) {
    const identificationData: Record<string, unknown> = {
      mailing_agreement: event.data.agreed,
      email: event.data.email,
      name: event.data.name,
      postalCode: event.data.postalCode,
    };

    _cio.newsletter(identificationData);
  }

  private static getTreatmentEventData(
    event: TreatmentSlotSelectedEvent | TreatmentParamsSelectedEvent,
  ) {
    return {
      length: event.data?.length,
      location_postal_code: event.data.location.postalCode,
      location_type: event.data.location.type,
      location_locality: event.data.location.locality,
      location_street: event.data.location.street,
      location_street_number: event.data.location.streetNumber,
    };
  }

  trackTreatmentSlotSelected(event: TreatmentSlotSelectedEvent) {
    _cio.track(event.name, {
      ...CustomerioGTMTrackingService.getTreatmentEventData(event),
      time: formatISO(event.data.slotTime),
      expert_id: event.data.expert.expertId,
      expert_name: event.data.expert.name,
      type_id: event.data.expert.type.id,
      type_name: translateString(event.data.expert.type.name, i18n.language),
      expert_profile_picture_url: event.data.expert.profilePictureUrl,
    });
  }

  trackTreatmentParamsSelectedEvent(event: TreatmentParamsSelectedEvent) {
    _cio.track(event.name, {
      ...CustomerioGTMTrackingService.getTreatmentEventData(event),
      date: formatISO(event.data.date.date),
      time_range: event.data.date.timeRange,
    });
  }

  trackEvent(event: TrackingEvent) {
    if (!this.enabled) {
      return;
    }
    try {
      switch (event.name) {
        case 'mailing_agreement':
          this.trackMailingAgreement(event);
          break;
        case 'page_view':
          _cio.page(event.data.url, event.data.params);
          dataLayer.push({
            event: 'virtualPageView',
            page: {
              url: event.data.url,
            },
          });
          break;
        case 'treatment_slot_selected':
          this.trackTreatmentSlotSelected(event);
          break;
        case 'treatment_params_selected':
          this.trackTreatmentParamsSelectedEvent(event);
          break;
        case 'startup':
          _cio.identify({
            id: event.data.id,
          });
          break;
        case 'booking_purchase_completed':
          dataLayer.push({
            event: 'purchase',
            ecommerce: {
              transaction_id: event.data.transaction_id,
              value: event.data.price,
              currency: 'EUR',
              type: 'Booking',
              items: [
                {
                  item_name: 'Booking',
                  item_id: 'booking',
                  index: 0,
                  currency: 'EUR',
                  price: event.data.price,
                },
              ],
            },
          });
          break;
        case 'voucher_purchase_completed':
          dataLayer.push({
            event: 'purchase',
            ecommerce: {
              transaction_id: event.data.transaction_id,
              value: event.data.price,
              currency: 'EUR',
              type: 'Voucher',
              items: [
                {
                  item_name: 'Voucher',
                  item_id: 'voucher',
                  index: 0,
                  currency: 'EUR',
                  price: event.data.price,
                },
              ],
            },
          });
          break;
        case 'booking_request':
          dataLayer.push({
            event: event.name,
            treatment_type_id: event.data.type.id,
            length: event.data.length,
          });
          break;
        default:
          break;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Could not track event', event, e);
    }
  }

  async initialize(): Promise<void> {
    const att = Plugins.CapacitorAppTrackingTransparency;
    if (isPlatform('capacitor') && isPlatform('ios')) {
      const tracking = await att.getStatus();
      if (tracking.value === AppTrackingTransparencyStatus.unrequested) {
        const requestResponse = await att.requestPermission();
        if (
          requestResponse.value === AppTrackingTransparencyStatus.authorized
        ) {
          this.enabled = true;
        }
      }
      if (tracking.value === AppTrackingTransparencyStatus.authorized) {
        this.enabled = true;
      }
    } else {
      this.enabled = true;
    }

    if (this.enabled) {
      await import('./customerio');
    }
  }
}

export default CustomerioGTMTrackingService;
