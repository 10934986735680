import './bookingSummaryTable.scss';

import { faCalendar, faStar } from '@fortawesome/free-regular-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonItem, IonList } from '@ionic/react';
import { ReactComponent as TreatmentIcon } from 'application/pages/Treatments/images/physiotherapy.svg';
import type { TreatmentLocation } from 'application/types';
import LocationFormatter from 'ui/elements/LocationFormatter';
import { TranslatableFormatter, useContextTranslation } from 'ui/translation';
import formatLocalizedDate from 'ui/utils/formatLocalizedDate';

import { ReactComponent as BookingIcon } from './multipleBookingIcon.svg';
import { TreatmentExpert } from '../../../state/TreatmentContext';

interface BookingSummaryTableProps {
  experts: TreatmentExpert[];
  slotTime: Date;
  location: TreatmentLocation;
}

const MultipleBookingsSummaryTable = ({
  location,
  experts,
  slotTime,
}: BookingSummaryTableProps): JSX.Element => {
  const t = useContextTranslation('misc');
  const tpage = useContextTranslation('page.booking_summary.multiple_booking');

  const time = formatLocalizedDate(slotTime, 'cccccc, dd.MM.yyyy, H:mm');
  return (
    <>
      <IonList className="booking-summary-table" mode="ios" lines="full">
        <IonItem detail={false} mode="ios">
          <BookingIcon />
          <span className="text">{tpage('header')}</span>
        </IonItem>
        <IonItem detail={false} mode="ios">
          <FontAwesomeIcon icon={faCalendar} />
          <span className="text">{t('time_format', { time })}</span>
        </IonItem>
        {location.locality && (
          <IonItem detail={false} lines="none" mode="ios">
            <FontAwesomeIcon icon={faHome} />
            <span className="text">
              <LocationFormatter location={location} />
            </span>
          </IonItem>
        )}
      </IonList>

      {experts.length &&
        experts.map((expert: TreatmentExpert, index) => (
          <>
            <h4 className="table-subheading">
              {tpage('guest', { number: index + 1 })}
            </h4>
            <IonList className="booking-summary-table" mode="ios" lines="full">
              <IonItem detail={false} mode="ios">
                <TreatmentIcon className="treatment-icon" />
                <span className="text">
                  <TranslatableFormatter value={expert.type.name} />
                </span>
              </IonItem>
              <IonItem detail={false} mode="ios">
                <FontAwesomeIcon icon={faStar} />
                <span className="text">
                  {t('expert_name', { name: expert.name })}
                </span>
              </IonItem>
            </IonList>
          </>
        ))}
    </>
  );
};

export default MultipleBookingsSummaryTable;
