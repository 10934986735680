import './headerProfilePicture.scss';

import { IonAvatar } from '@ionic/react';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';

import type { TreatmentExpert } from '../../../application/state/TreatmentContext';

interface HeaderProfilePictureProps {
  experts: TreatmentExpert[];
}

const HeaderProfilePicture: React.FC<HeaderProfilePictureProps> = ({
  experts,
}) => {
  const t = useContextTranslation('misc');
  return (
    <div className="profile-photo">
      {experts.map((expert) => (
        <IonAvatar key={expert.expertId}>
          <img src={expert.profilePictureUrl} alt={t('profile_picture')} />
        </IonAvatar>
      ))}
    </div>
  );
};

export default HeaderProfilePicture;
