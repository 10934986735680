import { IonSpinner } from '@ionic/react';
import type { HookData } from 'application/types';

import { useContextTranslation } from '../translation';
import React from 'react';

interface HookDataContainerProps {
  data: HookData<unknown>;
}

const HookDataContainer: React.FC<HookDataContainerProps> = ({
  data,
  children,
}) => {
  const t = useContextTranslation('misc.hook_data');
  if (data.loading) {
    return (
      <div className="hook-data-loading">
        <IonSpinner color="secondary" name="crescent" />
      </div>
    );
  }

  if (data.error) {
    return (
      <div className="hook-data-error">
        <h3>{t('error_occurred')}</h3>
        <code>{data.error.message}</code>
      </div>
    );
  }

  return <>{children}</>;
};

export default HookDataContainer;
