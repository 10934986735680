import { gql } from '@apollo/client';
import { AccountBlocked } from 'application/pages/Login/ICredentialsLoginAdapter';
import type { ExternalAuthenticationResult } from 'application/pages/Login/IExternalAuthenticationAdapter';
import type IExternalAuthenticationAdapter from 'application/pages/Login/IExternalAuthenticationAdapter';
import { AccountProviderMismatch } from 'application/pages/Login/IExternalAuthenticationAdapter';
import client from 'infrastructure/apollo';
import { getGraphQLErrorMessages } from 'infrastructure/utils';

const ACCOUNT_PROVIDER_MISMATCH = 'account_provider_mismatch';
const ACCOUNT_BLOCKED_ERROR = 'blocked';

const AUTHENTICATE_EXTERNALLY_MUTATION = gql`
  mutation AuthenticateExternally(
    $providerName: String!
    $clientSecret: String!
    $name: String
    $surname: String
  ) {
    loginData: authenticateExternally(
      providerName: $providerName
      clientSecret: $clientSecret
      name: $name
      surname: $surname
    ) {
      accessToken
      registrationToken
      status
    }
  }
`;

interface ExternalAuthenticationResponse {
  loginData: ExternalAuthenticationResult;
}

const ExternalAuthenticationAdapter: IExternalAuthenticationAdapter = {
  async getAuthenticationResult(
    providerName: string,
    clientSecret: string,
    name?: string,
    surname?: string,
  ): Promise<ExternalAuthenticationResult> {
    try {
      const response = await client.mutate<ExternalAuthenticationResponse>({
        mutation: AUTHENTICATE_EXTERNALLY_MUTATION,
        variables: {
          providerName,
          clientSecret,
          name,
          surname,
        },
      });
      if (!response.data) {
        throw new Error('No login data returned');
      }
      return response.data.loginData;
    } catch (error) {
      const messages = getGraphQLErrorMessages(error);
      if (messages) {
        if (messages.includes(ACCOUNT_PROVIDER_MISMATCH)) {
          throw new AccountProviderMismatch();
        }
        if (messages.includes(ACCOUNT_BLOCKED_ERROR)) {
          throw new AccountBlocked();
        }
      }
      throw error;
    }
  },
};

export default ExternalAuthenticationAdapter;
