import { TreatmentTimeRange } from 'application/types';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';
import isPhoneNumber from 'ui/utils/isPhoneNumber';
import * as Yup from 'yup';

export const bookingRequestInitialValues = {
  email: '',
  name: '',
  surname: '',
  phoneNumber: '',
  date: {
    date: new Date(),
    timeRange: TreatmentTimeRange.Morning,
  },
  length: 0,
  treatmentTypeId: '',
  postalCode: '',
  locality: '',
  street: '',
  streetNumber: '',
};

export const useValidationSchema = () => {
  const t = useContextTranslation('page.treatment');

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('email_required'))
          .email(t('email_format')),
        name: Yup.string().required(t('name_required')),
        surname: Yup.string().required(t('surname_required')),
        length: Yup.number().required(t('name_required')),
        treatmentTypeId: Yup.string().required(),
        postalCode: Yup.string().required(),
        locality: Yup.string().required(),
        street: Yup.string().required(),
        streetNumber: Yup.string().required(),
        date: Yup.object().shape({
          date: Yup.date().required(),
          timeRange: Yup.string().required(),
        }),
        phoneNumber: Yup.string()
          .optional()
          .max(32, t('phone_number_invalid_format'))
          .test({
            message: t('phone_number_invalid_format'),
            test: (value) => {
              if (!value) {
                return true;
              }
              return isPhoneNumber(value);
            },
          }),
      }),
    [t],
  );

  return [validationSchema];
};
