import { IonCol, IonGrid, IonRow, useIonViewWillEnter } from '@ionic/react';
import useCityName from 'application/pages/BookingAddress/useCityName';
import AddressForm, {
  addressInitialValues,
  addressRequiredFields,
  generateAddressValidationSchema,
} from 'application/pages/BookingContact/AddressForm';
import { useAuth } from 'application/state/AuthProvider';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { Address } from 'application/types';
import type { FormikHelpers } from 'formik';
import { FormikProvider, getIn, useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';
import BookingSummaryFooter from 'ui/elements/BookingSummaryFooter';
import TreatmentTypeGalleryHeader from 'ui/elements/TreatmentTypeGalleryHeader';
import SubPageLayout from 'ui/layout/SubPageLayout';
import HeaderProfilePicture from 'ui/layout/SubPageLayout/HeaderProfilePicture';
import { useContextTranslation } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';
import * as Yup from 'yup';

// TODO: refactor required
/* eslint-disable  @typescript-eslint/no-floating-promises */
/* eslint-disable  @typescript-eslint/no-explicit-any */

export default function BookingAddress(): JSX.Element {
  const t = useContextTranslation('page.booking_address');
  const history = useHistory();
  const { setLocation, location, experts, date, length, type, locationId } =
    useTreatmentBuilder();

  const { isAuthenticated } = useAuth();

  const onSubmit = React.useCallback<
    (values: Address, helpers: FormikHelpers<Address>) => void
  >(
    (values, helpers) => {
      setLocation({
        postalCode: values.postalCode,
        locality: values.locality,
        street: values.street,
        streetNumber: values.streetNumber,
        type: location?.type || 'atHome',
      });
      helpers.setSubmitting(false);
      history.push('/booking/payment');
    },
    [history, setLocation, location?.type],
  );

  const formik = useFormik<Address>({
    onSubmit,
    validationSchema: Yup.object().shape(generateAddressValidationSchema(t)),
    initialValues: addressInitialValues,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const hasCompleteData = Boolean(
    date && length && location && experts?.every((exp) => exp.type),
  );

  const { setValues, setFieldTouched } = formik;

  useIonViewWillEnter(() => {
    setValues({
      ...addressInitialValues,
      ...(location || {}),
    });

    if (location) {
      Object.keys(location).forEach((key) => {
        if (location[key as keyof Address]) {
          setFieldTouched(key as keyof Address);
        }
      });
    }

    if (!hasCompleteData) {
      history.replace('/treatments');
    }

    if (!isAuthenticated) {
      history.replace('/booking/contact');
    }
  }, [setValues, location, setFieldTouched, hasCompleteData]);

  const nativeFormSubmit = () => {
    const element = document.querySelector<HTMLFormElement>(
      '.booking-address form',
    );
    element?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  };

  const enableNext = React.useMemo(() => {
    const untouchedFields = addressRequiredFields.filter(
      (el): boolean => !getIn(formik.touched, el),
    );
    return !untouchedFields.length;
  }, [formik.touched]);

  useCityName({
    onLoad: (cityName) => {
      if (!formik.values.locality) {
        setValues({
          ...formik.values,
          locality: cityName,
        });
        setFieldTouched('locality');
      }
    },
    postalCode: formik.values.postalCode,
  });
  const EXPERT_INDEX = 0;

  return (
    <SubPageLayout
      className="booking-address"
      beforeContent={type && <TreatmentTypeGalleryHeader type={type} small />}
      footerContent={() =>
        length &&
        experts && (
          <BookingSummaryFooter
            length={length}
            expert={experts.length < 2 ? experts[EXPERT_INDEX] : undefined}
            onNext={() => nativeFormSubmit()}
            nextEnabled={enableNext}
          />
        )
      }
    >
      {experts && <HeaderProfilePicture experts={experts} />}
      <IonGrid>
        <IonRow className="booking-header">
          <IonCol size="12">
            <h4>{t('title')}</h4>
          </IonCol>
        </IonRow>
        <FormikProvider value={formik}>
          <AutocompleteForm>
            <AddressForm disabled={Boolean(locationId)} />
          </AutocompleteForm>
        </FormikProvider>
      </IonGrid>
    </SubPageLayout>
  );
}
