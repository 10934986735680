import './createReview.scss';

import injectables from 'application/injectables';
import BookingProvider from 'application/pages/CreateReview/BookingProvider';
import type IReviewAdapter from 'application/pages/CreateReview/IReviewAdapter';
import { useInject } from 'inversify-hooks';
import React from 'react';
import TreatmentTypeGalleryHeader from 'ui/elements/TreatmentTypeGalleryHeader';
import SubPageLayout from 'ui/layout/SubPageLayout';

import CreateReviewForm from './CreateReviewForm';

// TODO: refactor required
/* eslint-disable @typescript-eslint/unbound-method */

const CreateReview: React.FC = () => {
  const [reviewAdapter] = useInject<IReviewAdapter>(
    injectables.pages.ReviewAdapter,
  );

  return (
    <BookingProvider>
      {(booking) => (
        <SubPageLayout
          beforeContent={
            booking?.bookings[0]?.treatmentType && (
              <TreatmentTypeGalleryHeader
                type={booking.bookings[0].treatmentType}
                single
              />
            )
          }
        >
          {booking && (
            <CreateReviewForm
              booking={booking}
              createReview={reviewAdapter.createReview}
            />
          )}
        </SubPageLayout>
      )}
    </BookingProvider>
  );
};

export default CreateReview;
