import './style.scss';

import {
  IonCol,
  IonGrid,
  IonRow,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import injectables from 'application/injectables';
import type { ConfirmAccountLocationState } from 'application/pages/Register/ConfirmAccount';
import type { RegisterFormValues } from 'application/pages/Register/RegisterForm';
import { RegisterForm } from 'application/pages/Register/RegisterForm';
import type INotificationService from 'application/services/INotificationService';
import { useAuth } from 'application/state/AuthProvider';
import { formatISO, parseISO, set } from 'date-fns';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import isPhoneNumber from 'ui/utils/isPhoneNumber';
import * as Yup from 'yup';

import type IRegistrationAdapter from './IRegistrationAdapter';
import { AccountExistError } from './IRegistrationAdapter';

// TODO: refactor required
/* eslint-disable @typescript-eslint/unbound-method */

const initialValues = {
  email: '',
  name: '',
  surname: '',
  birthday: formatISO(new Date()),
  phoneNumber: '',
  companyName: '',
  password: '',
  repeatPassword: '',
};

const Register: React.FC = () => {
  const t = useContextTranslation('page.register');
  const td = useContextTranslation('layout.date_input');
  const { i18n } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const [notificationService] = useInject<INotificationService>(
    injectables.services.NotificationService,
  );
  const [adapter] = useInject<IRegistrationAdapter>(
    injectables.pages.RegistrationAdapter,
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('email_required'))
          .email(t('email_format')),
        password: Yup.string()
          .required(t('password_required'))
          .min(8, ({ min }) => t('password_min_length', { min }))
          .max(32, ({ max }) => t('password_max_length', { max })),
        name: Yup.string()
          .required(t('name_required'))
          .min(3, ({ min }) => t('name_min_length', { min }))
          .max(128, ({ max }) => t('name_max_length', { max })),
        surname: Yup.string()
          .required(t('surname_required'))
          .min(3, ({ min }) => t('surname_min_length', { min }))
          .max(128, ({ max }) => t('surname_max_length', { max })),
        birthday: Yup.date()
          .typeError(td('invalid_date'))
          .max(new Date(), td('invalid_date'))
          .min(
            set(new Date(), {
              year: 1920,
              month: 1,
              date: 1,
            }),
            td('invalid_date'),
          ),
        companyName: Yup.string()
          .optional()
          .max(256, ({ max }) =>
            t('company_name_max_length', {
              max,
            }),
          ),
        repeatPassword: Yup.string()
          .required(t('password_required'))
          .oneOf([Yup.ref('password'), null], t('password_not_match')),
        phoneNumber: Yup.string()
          .required(t('phone_number_required'))
          .max(32, t('phone_number_invalid_format'))
          .test({
            message: t('phone_number_invalid_format'),
            test: (value) => !!value && isPhoneNumber(value),
          }),
      }),
    [t],
  );

  const onSubmit = React.useCallback<
    (
      values: RegisterFormValues,
      helpers: FormikHelpers<RegisterFormValues>,
    ) => Promise<void>
  >(
    async (
      values: RegisterFormValues,
      helpers: FormikHelpers<RegisterFormValues>,
    ) => {
      try {
        const {
          email,
          name,
          surname,
          birthday,
          password,
          phoneNumber,
          companyName,
        } = values;
        await adapter.createAccount({
          email,
          name,
          surname,
          birthday: parseISO(birthday),
          password,
          phoneNumber,
          ...(companyName ? { companyName } : {}),
          locale: i18n.language || undefined,
          notificationToken: notificationService.getToken() || undefined,
        });

        helpers.setStatus(null);
        history.push('/register/confirm', {
          email,
          password,
        } as ConfirmAccountLocationState);
      } catch (e) {
        if (e instanceof AccountExistError) {
          helpers.setErrors({
            email: t('email_account_exists'),
          });
          return;
        }
        helpers.setStatus({
          error: e,
        });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [t, adapter.createAccount, history, i18n.language, notificationService],
  );

  useIonViewWillEnter(() => {
    if (auth.isAuthenticated) {
      history.replace('/home');
    }
  }, []);

  useIonViewWillLeave(() => {
    if (history.location.pathname !== '/login') {
      auth.setRedirectUri(null);
    }
  });

  return (
    <SubPageLayout>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h1>{t('title')}</h1>
          </IonCol>
        </IonRow>
        <Formik
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <RegisterForm />
        </Formik>
      </IonGrid>
    </SubPageLayout>
  );
};

export default Register;
