import { gql } from '@apollo/client';
import type IEditProfileAdapter from 'application/pages/EditProfile/IEditProfileAdapter';
import type { EditProfileData } from 'application/pages/EditProfile/IEditProfileAdapter';

import client from '../../apollo';

const EDIT_PROFILE_MUTATION = gql`
  mutation updateClient($clientUpdateData: ClientUpdateData) {
    updateClient(clientUpdateData: $clientUpdateData)
  }
`;

const GraphQLEditProfileAdapter: IEditProfileAdapter = {
  async editProfile(clientUpdateData: EditProfileData): Promise<void> {
    await client.mutate({
      mutation: EDIT_PROFILE_MUTATION,
      variables: {
        clientUpdateData: {
          ...clientUpdateData,
        },
      },
      refetchQueries: ['client'],
    });
  },
};

export default GraphQLEditProfileAdapter;
