import './postalCodeInput.scss';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonInput, useIonViewWillEnter } from '@ionic/react';
import type { TreatmentLocation } from 'application/types';
import * as React from 'react';
import { useState } from 'react';
import { useContextTranslation } from 'ui/translation';
import type { IonInputCustomEvent } from '@ionic/core/dist/types/components';
import type { InputInputEventDetail } from '@ionic/core/dist/types/components/input/input-interface';

// TODO Refactor required
/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-call */

interface PostalCodeInputProps {
  onSelect: (location: TreatmentLocation | null) => void;
  location: TreatmentLocation | null;
  disabled?: boolean;
}

const PostalCodeInput: React.FC<PostalCodeInputProps> = ({
  onSelect,
  location,
  disabled = false,
}) => {
  const t = useContextTranslation('page.treatment');
  const [postalCode, setPostalCode] = useState<string | null>(
    location?.postalCode || '',
  );

  // history,push is caching page, so we need to clear the postal code state when we go back to the page and context is cleared
  useIonViewWillEnter(() => {
    if (!location?.postalCode) {
      setPostalCode(null);
    }
  }, [location]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onSelect({
        type: 'atHome',
        postalCode: postalCode || '',
        locality: '',
        street: '',
        streetNumber: '',
      });
    }, 750);

    return () => {
      clearTimeout(timeout);
    };
  }, [postalCode, onSelect]);

  return (
    <section className="button-selector treatment-location">
      <div
        className={`postal-code-wrapper ${
          Number(location?.postalCode.length) > 3 ? ' fulfilled' : ''
        }`}
      >
        <span className="postal-code-icon">
          <FontAwesomeIcon icon={faHome} />
        </span>
        <IonInput
          className="postal-code-input"
          name="postal-code-input"
          maxlength={8}
          value={postalCode || ''}
          onIonInput={(e: IonInputCustomEvent<InputInputEventDetail>) =>
            setPostalCode(e.detail.value || '')
          }
          disabled={disabled}
          onBeforeInput={(e: any) => {
            if (/^[a-zA-Z]*$/.test(e.data)) {
              e.preventDefault();
            }
          }}
          placeholder={t('postal_code_placeholder')}
        />
      </div>
    </section>
  );
};

export default PostalCodeInput;
