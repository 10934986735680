import ReactRating, { RatingComponentProps } from "react-rating";

type Props = RatingComponentProps;

const Rating = (props: Props) => {
  const Root = ReactRating as unknown as ((props: Props) => JSX.Element);

  return (
    <Root {...props} />
  );
}

export default Rating;