import React from 'react';
import { useContextTranslation } from 'ui/translation';
import ExpandableSelect from '../../../ui/elements/ExpandableSelect';

const VoucherAmountSelector: React.FC<{
  expanded: boolean;
  selectedNumber?: number;
  selectedValue?: string;
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  expandSection: () => void;
}> = ({ expanded, selectedNumber, onSelect, expandSection, selectedValue }) => {
  const t = useContextTranslation('page.voucher');

  const getVoucherLabel = (number: number) => {
    if (selectedValue) {
      return t('voucher_number_with_value_label', {
        count: number,
        value: selectedValue,
      });
    }
    return t('voucher_number_label', {
      count: number,
    });
  };

  const AVAILABLE_VALUES = {
    1: getVoucherLabel(1),
    2: getVoucherLabel(2),
    3: getVoucherLabel(3),
    4: getVoucherLabel(4),
    5: getVoucherLabel(5),
  };

  return (
    <ExpandableSelect
      placeholder={t('voucher_number_select')}
      options={AVAILABLE_VALUES}
      onSelectChange={onSelect}
      expanded={expanded}
      value={selectedNumber?.toString()}
      onClick={expandSection}
    />
  );
};

export default VoucherAmountSelector;
