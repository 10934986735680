import { format as nativeFormat } from 'date-fns';
import { de, enGB } from 'date-fns/locale';
import i18n from 'infrastructure/i18n';

export default (date: Date, format: string): string => {
  const locale = i18n.language === 'de' ? de : enGB;

  return nativeFormat(date, format, {
    locale,
  });
};
