import { Browser } from '@capacitor/browser';
import { isPlatform, useIonAlert } from '@ionic/react';
import type currency from 'currency.js';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';

import injectables from './injectables';
import type IBookingDetailsAdapter from './pages/BookingDetails/IBookingDetailsAdapter';
import type { ITrackingService } from './services/ITrackingService';

const PaymentCallbackHandlerWrapper = ({
  price,
}: {
  price?: currency;
}): JSX.Element => {
  const t = useContextTranslation('payment.sofort');
  const mt = useContextTranslation('misc');
  const location = useLocation();
  const history = useHistory();
  const [showAlert] = useIonAlert();
  const [trackingService] = useInject<ITrackingService>(
    injectables.services.TrackingService,
  );

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const method = params.get('method');
    const subject = params.get('subject');
    const targetId = params.get('targetId');
    const redirectStatus = params.get('redirect_status');
    const returnPrefix = params.get('return_prefix');
    if (!method || !subject || !targetId) {
      history.replace('/', { unmount: true });
    }

    const navigateInApp = (path: string) => {
      if (returnPrefix && !isPlatform('capacitor')) {
        window.location.href = `${returnPrefix}:/${path}`;
        return;
      }
      history.replace(path, { unmount: true });
    };

    if (isPlatform('ios') && method === 'sofort') {
      Browser.close().catch(() => {});
    }

    if (
      method === 'sofort' &&
      redirectStatus !== 'succeeded' &&
      redirectStatus !== 'pending'
    ) {
      void showAlert({
        message: t('redirect_error', {
          status: redirectStatus || 'unknown',
        }),
        buttons: [mt('ok')],
        onDidDismiss: () => navigateInApp('/home'),
      });
      return;
    }

    if (subject === 'booking') {
      const token = params.get('secret');
      if (!token) {
        navigateInApp('/bookings');
      }
      trackingService.trackEvent({
        name: 'booking_purchase_completed',
        data: {
          price: price ? Number(price) : 0,
          transaction_id: targetId || '',
        },
      });

      navigateInApp(`/bookings/${targetId}/${token}`);
    }
    if (subject === 'voucher') {
      trackingService.trackEvent({
        name: 'voucher_purchase_completed',
        data: {
          price: 0,
          transaction_id: targetId || '',
        },
      });
      navigateInApp('/vouchers/finished');
    }
  }, []);
  return <></>;
};

const PaymentCallbackHandler: React.FC = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const subject = params.get('subject');
  const targetId = params.get('targetId');
  const token = params.get('secret');

  const [adapter] = useInject<IBookingDetailsAdapter>(
    injectables.pages.BookingDetailsAdapter,
  );
  const bookingDetails = adapter.useMultipleBookingsDetails(
    targetId || '',
    token || '',
  );
  const price = bookingDetails.value?.price.total;
  return (
    <SubPageLayout className="payment-callback-handler">
      {(subject === 'voucher' ||
        (!bookingDetails.loading && bookingDetails.value)) && (
        <PaymentCallbackHandlerWrapper price={price} />
      )}
    </SubPageLayout>
  );
};

export default PaymentCallbackHandler;
