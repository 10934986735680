import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLoading,
  IonRow,
  IonSpinner,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import injectables from 'application/injectables';
import type IPaymentProvider from 'application/pages/BookingPayment/IPaymentProvider';
import type { PaymentParams } from 'application/pages/BookingPayment/IPaymentProvider';
import type ISavedCardsAdapter from 'application/pages/SavedCards/ISavedCardsAdapter';
import { useAuth } from 'application/state/AuthProvider';
import type StripeProvider from 'infrastructure/adapters/payment/StripeProvider';
import { STRIPE_PROVIDER_NAME } from 'infrastructure/adapters/payment/StripeProvider';
import useStripePaymentIntent from 'infrastructure/adapters/payment/useStripePaymentIntent';
import i18n from 'infrastructure/i18n';
import { injectable } from 'inversify';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { Prompt } from 'react-router';
import ExpandableSelect from 'ui/elements/ExpandableSelect';
import { useContextTranslation } from 'ui/translation';
import Button from 'ui/elements/Button/Button';

// TODO refactor required -> hooks should be not used in class components
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

const SavedCardSelector: React.FC<{
  paymentMethodId: string | null;
  onSelected: (paymentMethod: string | null) => any;
}> = ({ paymentMethodId, onSelected }) => {
  const t = useContextTranslation('payment.stripe_web');
  const [adapter] = useInject<ISavedCardsAdapter>(
    injectables.pages.SavedCardsAdapter,
  );

  const savedCards = adapter.useSavedCards();

  const [expanded, setExpanded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (savedCards.value?.length && savedCards.value[0].id) {
      onSelected(savedCards.value[0].id);
    }
  }, [savedCards.value]);

  return (
    <div className="saved-card-selector">
      {savedCards.loading && <IonSpinner color="secondary" name="dots" />}
      {Boolean(savedCards.value?.length) && (
        <>
          <p>{t('choose_card')}</p>
          <ExpandableSelect
            placeholder="Card"
            name="saved-cards-select"
            options={{
              ...savedCards.value?.reduce<{ [key: string]: string }>(
                (acc, card) => {
                  acc[card.id] = `${card.brand.toUpperCase()} x-${
                    card.numberSuffix
                  }, ${card.expiryMonth}/${card.expiryYear}`;
                  return acc;
                },
                {},
              ),
              none: t('enter_manually'),
            }}
            value={paymentMethodId === null ? 'none' : paymentMethodId}
            onIonChange={(event) =>
              onSelected(
                event.currentTarget.value === 'none'
                  ? null
                  : event.currentTarget.value,
              )
            }
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
            onSelectChange={() => {
              setExpanded(false);
            }}
          />
        </>
      )}
    </div>
  );
};

const CardCheckout: React.FC<{
  clientSecret: string;
  onSuccess: () => any;
}> = ({ clientSecret, onSuccess }) => {
  const t = useContextTranslation('payment.stripe_web');
  const [saveCard, setSaveCard] = React.useState<boolean>(false);
  const [paymentMethodId, setPaymentMethodId] = React.useState<string | null>(
    null,
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardComplete, setCardComplete] = React.useState<boolean>(false);
  const [paymentDone, setPaymentDone] = React.useState<boolean>(false);
  const [showAlert] = useIonAlert();
  const stripe = useStripe();
  const elements = useElements();
  const { isAuthenticated } = useAuth();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!elements || !stripe) {
      return;
    }

    const element = elements.getElement(CardElement);
    const paymentMethod =
      paymentMethodId ||
      (element && {
        card: element,
      });
    if (!paymentMethod) {
      return;
    }
    setLoading(true);
    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
        setup_future_usage:
          saveCard && isAuthenticated && !paymentMethodId
            ? 'on_session'
            : undefined,
      });
      if (result.error) {
        void showAlert(t('payment_error', { message: result.error.message }));
        return;
      }
      setPaymentDone(true);
      onSuccess();
    } catch (e) {
      if (typeof e === 'string') {
        await showAlert(t('payment_error', { message: e }));
      } else if (e instanceof Error) {
        await showAlert(t('payment_error', { message: e.message }));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit}>
      <IonLoading isOpen={loading} />
      <Prompt
        when={!paymentDone}
        message={(location) => {
          return location.pathname.startsWith('/bookings')
            ? true
            : t('leave_warning');
        }}
      />

      <IonGrid>
        {isAuthenticated && (
          <IonRow>
            <IonCol size="12">
              <SavedCardSelector
                onSelected={(id) => setPaymentMethodId(id)}
                paymentMethodId={paymentMethodId}
              />
            </IonCol>
          </IonRow>
        )}
        {!paymentMethodId && (
          <IonRow>
            <IonCol size="12">
              <div className="card-element-container">
                <CardElement
                  onChange={(event: StripeCardElementChangeEvent) => {
                    setCardComplete(event.complete);
                  }}
                />
              </div>
              {isAuthenticated && (
                <IonItem className="save-card-prompt">
                  <IonCheckbox
                    slot="start"
                    color="secondary"
                    mode="ios"
                    checked={saveCard}
                    onIonChange={(e) => setSaveCard(e.detail.checked)}
                  />
                  {t('save_card_prompt')}
                </IonItem>
              )}
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol size="12">
            <Button
              className="pay-button"
              type="submit"
              disabled={
                !stripe || !elements || !(paymentMethodId || cardComplete)
              }
            >
              {t('pay')}
            </Button>
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
};

@injectable()
class StripePaymentProvider implements IPaymentProvider {
  getName(): string {
    return i18n.t('payment.stripe_web.title');
  }

  Render(params: PaymentParams): JSX.Element {
    const mt = useContextTranslation('misc');
    const [stripeProvider] = useInject<StripeProvider>(STRIPE_PROVIDER_NAME);
    const paymentSheet = useStripePaymentIntent({
      subject: params.instance.subject,
      targetId: params.instance.targetId,
      paymentMethod: 'card',
    });

    const [showAlert] = useIonAlert();

    React.useEffect(() => {
      if (!paymentSheet.value) {
        if (paymentSheet.error) {
          void showAlert({
            message: `Could not create payment: ${paymentSheet.error.message}`,
          });
        }
      }
    }, [paymentSheet.error, paymentSheet.value, showAlert]);

    return (
      <div className="stripe-payment">
        <IonLoading isOpen={paymentSheet.loading} />
        {paymentSheet.error && <div>{mt('something_went_wrong')}</div>}
        {paymentSheet.value && (
          <Elements
            options={{
              locale: i18n.language as 'de' | 'en',
            }}
            stripe={stripeProvider.getStripe()}
          >
            <CardCheckout
              clientSecret={paymentSheet.value.paymentIntent}
              onSuccess={params.onSuccess}
            />
          </Elements>
        )}
      </div>
    );
  }

  key = 'stripe_web';

  weight = 10;

  isAvailable(): boolean {
    return !isPlatform('capacitor');
  }
}

export default StripePaymentProvider;
