import styles from './expandableSelect.module.scss';
import { IonItem, IonLabel } from '@ionic/react';
import * as React from 'react';
import useCalcMaxHeight from '../../utils/useCalcMaxHeight';
import ButtonSelector from '../ButtonSelector';
import { ChangeEvent } from 'react';
import classNames from 'classnames';

type ExpandableSelectValues = { [key: string]: string };

interface ExpandableSelectProps {
  className?: string;
  placeholder: string;
  name?: string;
  options: ExpandableSelectValues;
  value?: string | null;
  onSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  expanded: boolean;
  onClick: () => void;
  disabled?: boolean;
  onIonChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  buttonIcon?: JSX.Element;
}

const ExpandableSelect: React.FC<ExpandableSelectProps> = ({
  className,
  placeholder,
  options,
  value,
  name,
  onSelectChange,
  expanded,
  onClick,
  onIonChange,
  buttonIcon,
  disabled = false,
}) => {
  const changeRef = React.useRef<HTMLSelectElement | null>(null);
  const [ref, maxHeight] = useCalcMaxHeight(expanded);

  const mergedClasses = classNames({
    [styles.expandableSelect]: true,
    [styles.expanded]: expanded,
    [styles.hasValue]: value && !expanded,
    [styles.disabled]: disabled,
    [styles.isWithIcon]: Boolean(buttonIcon),
    ...(className ? { [className]: true } : {}),
  });

  return (
    <div className={mergedClasses}>
      <select
        name={name}
        ref={changeRef}
        value={value || undefined}
        onChange={(e) => {
          onSelectChange(e);
          onIonChange?.(e);
        }}
        data-testid="current-select"
      >
        {Object.entries(options).map(([key, label]) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </select>
      <ButtonSelector
        onClick={onClick}
        icon={buttonIcon}
        expandable
        options={{
          buttonClassName: styles.expandButton,
          textClassName: styles.textContainer,
          iconClassName: styles.iconContainer,
          arrowClassName: styles.arrowContainer,
        }}
      >
        <div className={styles.textContainer} data-testid="text-container">
          {value && !expanded ? options[value] : placeholder}
        </div>
      </ButtonSelector>
      <div
        className={classNames({
          [styles.selectableContainer]: true,
          [styles.selectableHidden]: !maxHeight,
        })}
        ref={ref}
      >
        {Object.entries(options).map(([key, label]) => (
          <IonItem
            color={key !== value ? '' : 'linen'}
            key={key}
            lines="none"
            onClick={() => {
              const { current } = changeRef;
              if (current) {
                current.value = key;
                current.dispatchEvent(new Event('change', { bubbles: true }));
              }
            }}
          >
            <IonLabel color={key === value ? 'light' : 'primary'}>
              {label}
            </IonLabel>
          </IonItem>
        ))}
      </div>
    </div>
  );
};

export default ExpandableSelect;
