import type { TreatmentClientData } from 'application/types';

export interface RegistrationData {
  name: string;
  surname: string;
  birthday: Date;
  phoneNumber: string;
  email: string;
  companyName?: string;
  password: string;
  locale?: string;
  notificationToken?: string;
}

export type ExternalRegistrationData = Pick<
  TreatmentClientData,
  'phoneNumber' | 'name' | 'surname' | 'companyName' | 'locale'
> & { birthday: Date };
export interface ExternalRegistrationParams extends ExternalRegistrationData {
  registrationToken: string;
  providerName: string;
}

export interface ExternalRegistrationResult {
  accessToken: string;
}

export class AccountExistError extends Error {}
export class InvalidCodeError extends Error {}

export default interface IRegistrationAdapter {
  createAccount(data: RegistrationData): Promise<void>;
  createExternallyAuthenticatedAccount(
    data: ExternalRegistrationParams,
  ): Promise<ExternalRegistrationResult>;
  confirmAccount(email: string, verificationCode: string): Promise<void>;
}
