import { gql, useMutation } from '@apollo/client';
import { useIonAlert } from '@ionic/react';
import type { HookData } from 'application/types';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useContextTranslation } from 'ui/translation';

const CREATE_PAYMENT = gql`
  mutation CreatePayment(
    $targetId: ID!
    $subject: String!
    $paymentMethod: String
  ) {
    paymentSheet: createStripePayment(
      subject: $subject
      targetId: $targetId
      paymentMethod: $paymentMethod
    ) {
      customerId
      ephemeralKey
      paymentIntent
    }
  }
`;

interface StripePaymentParams {
  targetId: string;
  subject: string;
  paymentMethod?: string;
}

export interface StripePaymentSheet {
  customerId: string;
  ephemeralKey: string;
  paymentIntent: string;
}

function useStripePaymentIntent(
  params: StripePaymentParams,
): HookData<StripePaymentSheet> {
  const mt = useContextTranslation('misc');
  const history = useHistory();
  const [showAlert] = useIonAlert();
  const [createPayment] = useMutation<{
    paymentSheet: StripePaymentSheet;
  }>(CREATE_PAYMENT, {
    variables: {
      targetId: params.targetId,
      subject: params.subject,
      paymentMethod: params.paymentMethod,
    },
  });

  const [state, setState] = React.useState<HookData<StripePaymentSheet>>({
    value: null,
    loading: true,
  });

  React.useEffect(() => {
    createPayment()
      .then((result) => {
        if (!result.data) {
          throw new Error('empty_response');
        }
        setState({
          loading: false,
          value: result.data.paymentSheet,
        });
      })
      .catch((error) => {
        setState({
          loading: false,
          value: null,
          error,
        });
        void showAlert(mt('something_went_wrong'), [
          {
            text: mt('ok'),
            handler: () => history.replace('/bookings', { unmount: true }),
          },
        ]);
      });
  }, [createPayment, history, mt, showAlert]);

  return state;
}

export default useStripePaymentIntent;
