import * as Sentry from '@sentry/capacitor';
import { init as sentryReactInit } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { injectable } from 'inversify';

@injectable()
export default class SentryErrorLoggingService implements IErrorLoggingService {
  initialize(): void {
    if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENV) {
      Sentry.init(
        {
          dsn: process.env.REACT_APP_SENTRY_DSN || '',
          integrations: [new Integrations.BrowserTracing()],
          tracesSampleRate: 1.0,
          environment: process.env.REACT_APP_SENTRY_ENV,
          ignoreErrors: ['ChunkLoadError', 'NotSupportedError'],
        },
        sentryReactInit,
      );
    }
  }

  traceException(e: Error): void {
    Sentry.captureException(e);
  }
}
