import { TreatmentTimeRange } from 'application/types';
import type { Dispatch, SetStateAction } from 'react';

import type { CurrentlyAvailableBookings } from '../ITreatmentAdapter';
import TimePeriodSelectorButton from './TimePeriodSelectorButton';

interface TimeRangeSelectorProps {
  date?: Date;
  timeRange?: TreatmentTimeRange;
  setTimeRange: Dispatch<SetStateAction<TreatmentTimeRange | undefined>>;
  availableBookings?: CurrentlyAvailableBookings;
}

const globalMinimalPrice = (
  range: TreatmentTimeRange,
  availableBookings?: CurrentlyAvailableBookings,
) => {
  const minPrice =
    availableBookings?.availableDays
      ?.flatMap((tp) => tp.minimalTreatmentPrice)
      .map((price) => price[range])
      .reduce<number | null>(
        (min, val) => (!min || val < min ? val : min),
        null,
      ) || null;
  return minPrice ? minPrice / 100 : null;
};

const TimeRangeSelector = ({
  date,
  timeRange,
  setTimeRange,
  availableBookings,
}: TimeRangeSelectorProps): JSX.Element => {
  const morningPrice = globalMinimalPrice(
    TreatmentTimeRange.Morning,
    availableBookings,
  );
  const afternoonPrice = globalMinimalPrice(
    TreatmentTimeRange.Afternoon,
    availableBookings,
  );
  const eveningPrice = globalMinimalPrice(
    TreatmentTimeRange.Evening,
    availableBookings,
  );

  return (
    <div className="time-period-selector">
      <TimePeriodSelectorButton
        timeRange={timeRange}
        timeRangePeriod={TreatmentTimeRange.Morning}
        setTimeRange={setTimeRange}
        availableBookings={availableBookings}
        date={date}
        price={morningPrice}
      />
      <TimePeriodSelectorButton
        timeRange={timeRange}
        timeRangePeriod={TreatmentTimeRange.Afternoon}
        setTimeRange={setTimeRange}
        availableBookings={availableBookings}
        date={date}
        price={afternoonPrice}
      />
      <TimePeriodSelectorButton
        timeRange={timeRange}
        timeRangePeriod={TreatmentTimeRange.Evening}
        setTimeRange={setTimeRange}
        availableBookings={availableBookings}
        date={date}
        price={eveningPrice}
      />
    </div>
  );
};

export default TimeRangeSelector;
