import { IonCol, IonGrid, IonRow } from '@ionic/react';
import injectables from 'application/pages/injectables';
import type { PasswordChangeFormValues } from 'application/pages/PasswordChange/PasswordChangeForm';
import { PasswordChangeForm } from 'application/pages/PasswordChange/PasswordChangeForm';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory } from 'react-router';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import * as Yup from 'yup';

import type IPasswordChangeAdapter from './IPasswordChangeAdapter';
import {
  InvalidPasswordError,
  NoPasswordError,
} from './IPasswordChangeAdapter';

// TODO: refactor required
/* eslint-disable @typescript-eslint/unbound-method */

const initialValues = {
  oldPassword: '',
  newPassword: '',
  repeatPassword: '',
};

const PasswordChange: React.FC = () => {
  const t = useContextTranslation('page.password_change');
  const history = useHistory();
  const [adapter] = useInject<IPasswordChangeAdapter>(
    injectables.PasswordChangeAdapter,
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        oldPassword: Yup.string()
          .required(t('password_required'))
          .min(8, ({ min }) => t('password_min_length', { min }))
          .max(32, ({ max }) => t('password_max_length', { max })),
        newPassword: Yup.string()
          .required(t('password_required'))
          .min(8, ({ min }) => t('password_min_length', { min }))
          .max(32, ({ max }) => t('password_max_length', { max }))
          .notOneOf([Yup.ref('oldPassword'), null], t('new_password_match')),
        repeatPassword: Yup.string()
          .required(t('password_required'))
          .oneOf([Yup.ref('newPassword'), null], t('password_not_match')),
      }),
    [t],
  );

  const onSubmit = React.useCallback<
    (
      values: PasswordChangeFormValues,
      helpers: FormikHelpers<PasswordChangeFormValues>,
    ) => Promise<void>
  >(
    async (
      values: PasswordChangeFormValues,
      helpers: FormikHelpers<PasswordChangeFormValues>,
    ) => {
      try {
        const { oldPassword, newPassword } = values;
        await adapter.changePassword(oldPassword, newPassword);

        helpers.setStatus(null);
        history.replace('/account', { unmount: true, direction: 'back' });
      } catch (e) {
        if (e instanceof InvalidPasswordError) {
          helpers.setErrors({
            oldPassword: t('invalid_password'),
          });
          return;
        }
        if (e instanceof NoPasswordError) {
          helpers.setErrors({
            oldPassword: t('user_has_no_password'),
          });
          return;
        }
        helpers.setStatus({
          error: e,
        });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [t, adapter.changePassword, history],
  );

  return (
    <SubPageLayout>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h1>{t('title')}</h1>
          </IonCol>
        </IonRow>
        <Formik
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <PasswordChangeForm />
        </Formik>
      </IonGrid>
    </SubPageLayout>
  );
};

export default PasswordChange;
