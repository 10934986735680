import EventEmitter from 'events';

/* eslint-disable import/no-duplicates */
// todo: check for eslint-config-airbnb-typescript update with a fix
import { createHttpLink, gql, useQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Preferences as Storage } from '@capacitor/preferences';
import type ILoginService from 'application/services/ILoginService';
import type { LoginServiceEvents } from 'application/services/ILoginService';
import type { UserProfile } from 'application/services/ILoginService';
import type {
  HookData,
  Modify,
  ObjectID,
  TypedEventEmitter,
  WithRefetch,
} from 'application/types';
import { parseISO } from 'date-fns';
import { injectable } from 'inversify';
import jwtDecode from 'jwt-decode';

import config from '../../config';
import client from '../apollo';

const ACCESS_TOKEN_KEY = 'access_token';

const PROFILE_QUERY = gql`
  query getClientDetails($userId: ID) {
    client(id: $userId) {
      id
      name
      surname
      birthday
      email
      phoneNumber
      companyName
      verified
      blocked
      referralCode
    }
  }
`;

interface RefreshTokenMutationResponse {
  loginData: {
    accessToken: string;
  };
}

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken {
    loginData: refreshAccessToken {
      accessToken
    }
  }
`;

export interface ProfileQueryResponse {
  client: Modify<
    UserProfile,
    {
      birthday: string;
    }
  >;
}

interface JWTPayload {
  userId: ObjectID;
  externalProvider?: string;
  role: string;
  exp: number;
}

function parseJWTToken(token: string): JWTPayload {
  return jwtDecode<JWTPayload>(token);
}

@injectable()
export default class StorageLoginService implements ILoginService {
  eventEmitter: TypedEventEmitter<LoginServiceEvents> = new EventEmitter();

  cachedToken: string | null = null;

  private userId: ObjectID | null = null;

  private externalProvider: string | null = null;

  private async setCachedToken(token: string | null) {
    if (!token) {
      this.cachedToken = null;
      this.userId = null;
      this.externalProvider = null;
      return;
    }
    const payload = parseJWTToken(token);

    const refreshDate = new Date();
    refreshDate.setDate(refreshDate.getDate() + 20);

    if (Date.now() / 1000 > payload.exp) {
      throw new Error('token_expired');
    }
    if (payload.role !== 'client') {
      throw new Error('unsupported_role');
    }

    let refreshedToken;
    if (refreshDate.getTime() / 1000 > payload.exp) {
      refreshedToken = await this.accessTokenRefresh();
    }

    this.cachedToken = refreshedToken || token;
    this.userId = payload.userId;
    this.externalProvider = payload.externalProvider || null;
    this.setApolloAccessToken();
  }

  async initialize(): Promise<void> {
    const stored = await Storage.get({
      key: ACCESS_TOKEN_KEY,
    });
    try {
      await this.setCachedToken(stored.value);
    } catch (e) {
      await this.logout();
    }
  }

  private async accessTokenRefresh() {
    if (this.cachedToken != null) {
      const payload = parseJWTToken(this.cachedToken);
      if (Date.now() / 1000 < payload.exp) {
        const response = await client.mutate<RefreshTokenMutationResponse>({
          mutation: REFRESH_TOKEN_MUTATION,
        });
        if (!response.data) {
          throw new Error('No access token returned');
        }
        const { accessToken } = response.data.loginData;
        if (accessToken) {
          await Storage.set({
            key: ACCESS_TOKEN_KEY,
            value: accessToken,
          });
        }
        return accessToken;
      }
    }
    return null;
  }

  private setApolloAccessToken() {
    client.setLink(
      setContext((_, { headers }) => ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          ...headers,
          authorization: this.cachedToken
            ? `Bearer ${this.cachedToken}`
            : undefined,
        },
      }))
        .concat(
          onError(({ graphQLErrors }) => {
            if (graphQLErrors) {
              graphQLErrors.forEach((error) => {
                if (error.extensions?.code === 'UNAUTHENTICATED') {
                  this.logout()
                    .finally(() => {
                      this.eventEmitter.emit('error', error);
                    })
                    // TODO handle error
                    .catch(() => {
                      throw new Error();
                    });
                }
              });
            }
          }),
        )
        .concat(
          createHttpLink({
            uri: `${config.apiUrl}/graphql`,
          }),
        ),
    );
  }

  async login(accessToken: string): Promise<void> {
    await Storage.set({
      key: ACCESS_TOKEN_KEY,
      value: accessToken,
    });
    await this.setCachedToken(accessToken);
  }

  async logout(): Promise<void> {
    await this.setCachedToken(null);
    await Storage.remove({ key: ACCESS_TOKEN_KEY });
    await client.clearStore();
  }

  useUserProfile(): WithRefetch<HookData<UserProfile>> {
    // TODO move to client.query?
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const query = useQuery<ProfileQueryResponse>(PROFILE_QUERY, {
      fetchPolicy: 'network-only',
      variables: {
        userId: this.userId,
      },
    });

    return {
      error: query.error,
      loading: query.loading,
      value: query.data?.client
        ? {
            ...query.data.client,
            birthday: query.data.client.birthday
              ? parseISO(query.data.client.birthday)
              : null,
          }
        : null,
      refetch: () => {
        query.refetch().catch(() => {});
      },
    };
  }

  getUserId(): ObjectID | null {
    return this.userId;
  }

  getExternalAuthProvider(): string | null {
    return this.externalProvider;
  }
}
