import { ApplePayEventsEnum, Stripe } from '@capacitor-community/stripe';
import { isPlatform, useIonAlert } from '@ionic/react';
import type IPaymentProvider from 'application/pages/BookingPayment/IPaymentProvider';
import type { PaymentParams } from 'application/pages/BookingPayment/IPaymentProvider';
import config from 'config';
import renderNativeStripePaymentProvider from 'infrastructure/adapters/payment/renderNativeStripePaymentProvider';
import type { StripePaymentSheet } from 'infrastructure/adapters/payment/useStripePaymentIntent';
import i18n from 'infrastructure/i18n';
import { injectable } from 'inversify';

// TODO refactor required -> hooks should be not used in class components
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

let isNativeAvailable = false;
if (config.applePayMerchantId && isPlatform('capacitor') && isPlatform('ios')) {
  Stripe.isApplePayAvailable()
    .then(() => {
      isNativeAvailable = true;
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.warn('Apple Pay not available', e);
    });
}

@injectable()
class ApplePayPaymentProvider implements IPaymentProvider {
  key = 'apple_pay';

  weight = 0;

  getName(): string {
    return i18n.t('payment.stripe_native.title_apple_pay');
  }

  Render(params: PaymentParams): JSX.Element {
    const [showAlert] = useIonAlert();
    const createPayment: (sheet: StripePaymentSheet) => Promise<any> = (
      sheet,
    ) =>
      Stripe.createApplePay({
        merchantIdentifier: String(config.applePayMerchantId),
        paymentIntentClientSecret: sheet.paymentIntent,
        paymentSummaryItems: [
          {
            label: `Soulhouse GmbH: ${params.instance.name}`,
            amount: params.instance.price?.value || 0,
          },
        ],
        countryCode: 'DE',
        currency: 'EUR',
      })
        .then(() => Stripe.presentApplePay())
        .then((result) => {
          if (result.paymentResult === ApplePayEventsEnum.Completed) {
            params.onSuccess();
            return null;
          }
          if (result.paymentResult === ApplePayEventsEnum.Canceled) {
            return new Promise((resolve, reject) => {
              void showAlert({
                message: i18n.t('payment.stripe_native.cancel_info'),
                buttons: [
                  {
                    role: 'cancel',
                    text: i18n.t('misc.cancel'),
                    handler: () => reject(new Error(result.paymentResult)),
                  },
                  {
                    role: 'resume',
                    text: i18n.t('payment.stripe_native.cancel_info_retry'),
                    handler: () =>
                      createPayment(sheet).then(resolve).catch(reject),
                  },
                ],
              });
            });
          }
          throw new Error(result.paymentResult);
        });
    return renderNativeStripePaymentProvider(params, createPayment);
  }

  isAvailable(): boolean {
    return (
      isPlatform('capacitor') &&
      isPlatform('ios') &&
      isNativeAvailable &&
      Boolean(config.applePayMerchantId)
    );
  }
}

export default ApplePayPaymentProvider;
