import './treatmentTypeGalleryHeader.scss';

import defaultPicture from 'ui/theme/images/default-picture.jpg';
import type { MediaData } from 'application/types';
import { useContextTranslation } from 'ui/translation';

import { Swiper, SwiperSlide } from './Swiper/Swiper';

interface BeforeContentGalleryProps {
  media: MediaData[];
}

export const defaultMedia: MediaData[] = [
  {
    type: 'image',
    uri: defaultPicture,
  },
];

const BeforeContentGallery = ({
  media,
}: BeforeContentGalleryProps): JSX.Element => {
  const t = useContextTranslation('misc');

  return (
    <div className="treatment-type-gallery-header">
      <Swiper navigation slidesPerView={1}>
        {media?.map((mediaItem) => (
          <SwiperSlide key={mediaItem.uri}>
            {mediaItem.type === 'video' ? (
              <video
                playsInline
                controls={false}
                src={mediaItem.uri}
                muted
                autoPlay
                loop
              />
            ) : (
              <img src={mediaItem.uri} alt={t('media')} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BeforeContentGallery;
