import { IonCol, IonLoading, IonRow } from '@ionic/react';
import { Field, useFormikContext } from 'formik';
import * as React from 'react';
import DateInput from 'ui/elements/DateInput/DateInput';
import { useContextTranslation } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';
import IonicField from 'ui/utils/IonicField';
import IonicPhoneField from 'ui/utils/IonicPhoneField';
import Button from 'ui/elements/Button/Button';

export interface EditProfileFormValues {
  name: string;
  surname: string;
  birthday: string;
  phoneNumber: string;
  companyName: string;
}

export const EditProfileForm: React.FC = () => {
  const { isSubmitting, status } = useFormikContext();
  const t = useContextTranslation('page.edit_profile');
  const statusErrorText = status?.error && t('error_unknown');
  return (
    <AutocompleteForm>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            autocomplete="organization"
            component={IonicField}
            name="companyName"
            type="text"
            placeholder={t('company_name_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="name"
            type="name"
            autocomplete="name"
            required
            placeholder={t('name_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="surname"
            type="name"
            autocomplete="surname"
            required
            placeholder={t('surname_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            label={t('birthday_placeholder')}
            component={DateInput}
            name="birthday"
            autocomplete="birthday"
            required
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicPhoneField}
            className="phoneNumber"
            defaultCountry="DE"
            name="phoneNumber"
            placeholder={t('phone_number_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeMd="8" sizeLg="8">
          <Button className="submit-button" type="submit">
            {t('submit_label')}
          </Button>
          {statusErrorText && (
            <div className="form-error">{statusErrorText}</div>
          )}
        </IonCol>
      </IonRow>
      <IonLoading isOpen={isSubmitting} message={t('loading')} />
      <input type="submit" className="invisible-auto-submit" />
    </AutocompleteForm>
  );
};
