import { Preferences as Storage } from '@capacitor/preferences';
import { IonButton, IonInput, useIonViewWillEnter } from '@ionic/react';
import {
  DiscountStatus,
  ReferralApplianceStatus,
  VoucherApplianceStatus,
} from 'application/types';
import * as React from 'react';
import type { TFunction } from 'react-i18next';
import { useContextTranslation } from 'ui/translation';
import Button from '../../../ui/elements/Button/Button';

// TODO: refactor required
/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable  @typescript-eslint/no-unsafe-enum-comparison*/

interface DiscountCodeFieldProps {
  discountStatus: DiscountStatus;
  voucherStatus: VoucherApplianceStatus;
  referralStatus: ReferralApplianceStatus;
  onCodeSet: (code?: string) => void;
  payWithReferral: boolean;
}

const REFERRAL_CODE = 'referral_Code';

const getCodeStatusError = (
  discountStatus: string,
  voucherStatus: string,
  referralStatus: string,
  t: TFunction,
): string | null => {
  if (voucherStatus === VoucherApplianceStatus.INACTIVE) {
    return t('discount_inactive');
  }
  if (discountStatus === DiscountStatus.EXPIRED) {
    return t('discount_expired');
  }
  if (discountStatus === DiscountStatus.NOT_REDEEMABLE) {
    return t('discount_not_reusable');
  }
  if (
    discountStatus === DiscountStatus.NOT_APPLICABLE ||
    discountStatus === VoucherApplianceStatus.BELOW_MINIMAL_PRICE
  ) {
    return t('discount_not_applicable');
  }
  if (voucherStatus === VoucherApplianceStatus.USED) {
    return t('voucher_used');
  }
  if (discountStatus === DiscountStatus.USED) {
    return t('discount_used');
  }
  if (referralStatus === ReferralApplianceStatus.USED) {
    return t('referral_used');
  }
  if (voucherStatus === VoucherApplianceStatus.EXPIRED) {
    return t('voucher_expired');
  }
  if (referralStatus === ReferralApplianceStatus.NO_BALANCE) {
    return t('referral_no_balance');
  }
  if (
    discountStatus === DiscountStatus.APPLIED ||
    voucherStatus === VoucherApplianceStatus.APPLIED ||
    referralStatus === ReferralApplianceStatus.APPLIED
  ) {
    return null;
  }
  if (
    discountStatus === DiscountStatus.NOT_FOUND ||
    discountStatus === DiscountStatus.DISABLED ||
    voucherStatus === VoucherApplianceStatus.NOT_FOUND ||
    referralStatus === ReferralApplianceStatus.NOT_FOUND
  ) {
    return t('discount_not_found');
  }

  return null;
};

const DiscountCodeField: React.FC<DiscountCodeFieldProps> = ({
  discountStatus,
  voucherStatus,
  referralStatus,
  onCodeSet,
  payWithReferral,
}) => {
  const t = useContextTranslation('page.booking_payment');

  const [enteredCode, setEnteredCode] = React.useState<string>('');

  useIonViewWillEnter(() => {
    (async () => {
      setEnteredCode('');
      const code = await Storage.get({
        key: REFERRAL_CODE,
      });
      if (code.value) {
        setEnteredCode(code.value);
        onCodeSet(code.value);
      }
    })().catch(() => {});
  }, []);

  React.useEffect(() => {
    if (payWithReferral) {
      setEnteredCode('');
    }
  }, [payWithReferral]);

  const appliedAnyCode =
    discountStatus === DiscountStatus.APPLIED ||
    voucherStatus === VoucherApplianceStatus.APPLIED ||
    referralStatus === ReferralApplianceStatus.APPLIED;

  return (
    <div className="discount-code">
      <IonInput
        name="discount-code"
        className="form-input"
        value={enteredCode}
        minlength={4}
        onIonInput={(e) => setEnteredCode(e.detail.value || '')}
        disabled={appliedAnyCode || payWithReferral}
        placeholder={t('discount_code_placeholder')}
      />
      {enteredCode.length && !appliedAnyCode && !payWithReferral ? (
        <Button
          disabled={enteredCode.length < 4}
          onClick={() => onCodeSet(enteredCode)}
        >
          {t('apply_code')}
        </Button>
      ) : null}
      {appliedAnyCode && (
        <IonButton
          expand="block"
          color="danger"
          onClick={() => {
            setEnteredCode('');
            onCodeSet(undefined);
          }}
        >
          {t('remove_code')}
        </IonButton>
      )}
      {(discountStatus !== DiscountStatus.NONE ||
        voucherStatus !== VoucherApplianceStatus.NONE ||
        referralStatus !== ReferralApplianceStatus.NONE) && (
        <div className="form-error">
          {getCodeStatusError(discountStatus, voucherStatus, referralStatus, t)}
        </div>
      )}
    </div>
  );
};

export default DiscountCodeField;
