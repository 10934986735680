import './SocialLoginButtons.scss';

import { IonLoading, useIonAlert } from '@ionic/react';
import injectables from 'application/injectables';
import { AccountBlocked } from 'application/pages/Login/ICredentialsLoginAdapter';
import type IExternalAuthenticationAdapter from 'application/pages/Login/IExternalAuthenticationAdapter';
import {
  AccountProviderMismatch,
  ExternalAuthenticationStatus,
} from 'application/pages/Login/IExternalAuthenticationAdapter';
import type { LoginRouteState } from 'application/pages/Login/Login';
import { useAuth } from 'application/state/AuthProvider';
import type IExternalAuthProvider from 'application/state/IExternalAuthProvider';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import { useVoucherContext } from 'application/state/VoucherContext';
import { useInject } from 'inversify-hooks';
import { container } from 'inversify-props';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import Button from 'ui/elements/Button/Button';
import { useContextTranslation } from 'ui/translation';
import { ReactComponent as AppleIcon } from './images/apple_sing_in.svg';
import FacebookIcon from './images/facebook_Logo.png';

const SocialLoginButtons: React.FC = () => {
  const t = useContextTranslation('page.login');
  const mt = useContextTranslation('misc');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showAlert] = useIonAlert();
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation<LoginRouteState>();
  const treatment = useTreatmentBuilder();
  const voucher = useVoucherContext();
  const [adapter] = useInject<IExternalAuthenticationAdapter>(
    injectables.pages.ExternalAuthenticationProvider,
  );

  const facebookProvider = container.getNamed<IExternalAuthProvider>(
    injectables.state.ExternalAuthProvider,
    'facebook',
  );
  const appleProvider = container.getNamed<IExternalAuthProvider>(
    injectables.state.ExternalAuthProvider,
    'apple',
  );

  const onProviderLogin =
    (providerName: string) => (event: React.MouseEvent) => {
      event.preventDefault();
      setLoading(true);

      container
        .getNamed<IExternalAuthProvider>(
          injectables.state.ExternalAuthProvider,
          providerName,
        )
        .authenticate()
        .then(async (providerResult) => {
          try {
            const result = await adapter.getAuthenticationResult(
              providerName,
              providerResult.clientSecret,
              providerResult.name,
              providerResult.surname,
            );
            if (
              result.status ===
              ExternalAuthenticationStatus.APPLE_NOT_FIRST_LOGIN
            ) {
              setLoading(false);
              void showAlert(t('apple_not_first_login'), [
                {
                  role: 'cancel',
                  text: mt('close'),
                },
              ]);
              return;
            }

            if (result.status === ExternalAuthenticationStatus.LOGGED_IN) {
              await auth.login(result.accessToken);
              const {
                slotTime,
                length,
                location: treatmentLocation,
                experts,
                details,
                locationId,
                isSpaBooking,
              } = treatment;
              if (
                treatmentLocation &&
                details &&
                slotTime &&
                length &&
                (isSpaBooking || experts)
              ) {
                history.replace(
                  locationId ? '/booking/payment' : '/booking/address',
                  { direction: 'forward' },
                );
              } else if (voucher.voucherAmount && voucher.voucherValue) {
                history.replace('/vouchers/payment', { direction: 'forward' });
              } else {
                history.replace('/home', { direction: 'back', unmount: true });
              }
              return;
            }

            if (
              result.status === ExternalAuthenticationStatus.ACCOUNT_CREATED
            ) {
              auth.setExternalRegistration({
                registrationToken: result.registrationToken,
              });
              history.replace('/register/external', {
                ...location.state,
                name: providerResult.name,
                surname: providerResult.surname,
                providerName,
                externalAuthenticationStatus: result.status,
              });
            }
          } catch (error) {
            if (error instanceof AccountProviderMismatch) {
              void showAlert(t('external_account_provider_mismatch'));
              return;
            }
            if (error instanceof AccountBlocked) {
              void showAlert(t('account_blocked'));
              return;
            }
            void showAlert(mt('something_went_wrong'));
          }
        })
        .catch(() => {
          void showAlert(mt('something_went_wrong'));
        })
        .finally(() => setLoading(false));
    };

  return (
    <>
      <IonLoading isOpen={loading} spinner="dots" message={mt('loading')} />
      {appleProvider.isAvailable() && (
        <Button
          onClick={onProviderLogin('apple')}
          className="social-button apple"
          type="button"
        >
          <AppleIcon className="icon"/>
          <p>{t('sign_in_with_apple')}</p>
        </Button>
      )}
      {facebookProvider.isAvailable() && (
        <Button
          onClick={onProviderLogin('facebook')}
          className="social-button facebook"
          type="button"
        >
          <img src={FacebookIcon} className="icon" alt={"facebook logo"}/>
          <p>{t('sign_in_with_facebook')}</p>
        </Button>
      )}
    </>
  );
};

export default SocialLoginButtons;
