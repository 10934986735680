import './Header.scss';

import { IonItem, IonLabel, IonList, IonPopover } from '@ionic/react';
import { useAuth } from 'application/state/AuthProvider';
import { Link } from 'react-router-dom';
import { useContextTranslation } from 'ui/translation';
import { ReactComponent as UserIcon } from './images/user.svg';
import { useState } from 'react';
import { Browser } from '@capacitor/browser';
import i18n from 'infrastructure/i18n';

interface PopoverState {
  isOpen: boolean;
  event?: Event;
}

const Header = () => {
  const [menuPopoverState, setMenuPopoverState] = useState<PopoverState>({
    isOpen: false,
  });
  const t = useContextTranslation('layout.menu');

  const { isAuthenticated } = useAuth();
  return (
    <div className="header">
      <Link className="logo" to="/home" replace>
        Soulhouse
      </Link>
      <IonPopover
        className="menu-popover"
        isOpen={menuPopoverState.isOpen}
        event={menuPopoverState.event}
        onDidDismiss={() => setMenuPopoverState({ isOpen: false })}
        mode="ios"
      >
        <IonList onClick={() => setMenuPopoverState({ isOpen: false })}>
          {isAuthenticated ? (
            <IonItem
              detail={false}
              routerLink="/account"
              routerOptions={{ unmount: true }}
            >
              <IonLabel>{t('my_account')}</IonLabel>
            </IonItem>
          ) : (
            <IonItem detail={false} routerLink="/login">
              <IonLabel>{t('sign_in')}</IonLabel>
            </IonItem>
          )}
          <IonItem detail={false} routerLink="/bookings">
            <IonLabel>{t('my_bookings')}</IonLabel>
          </IonItem>
          <IonItem detail={false} routerLink="/settings">
            <IonLabel>{t('settings')}</IonLabel>
          </IonItem>
          <IonItem
            detail={false} button onClick={() => {
            Browser.open({
              url: `https://soulhouse.me/${i18n.language === 'de' ? 'allgemeine-geschaeftsbedingungen' : 'en/general-terms-conditions'}`,
              presentationStyle: 'fullscreen',
              windowName: 'TOC',
            }).catch(() => {
            });
          }}>
            <IonLabel>{t('toc')}</IonLabel>
          </IonItem>
          <IonItem
            detail={false} button
            onClick={() => {
              Browser.open({
                url: `https://soulhouse.me/${i18n.language === 'de' ? 'datenschutzerklaerung' : 'en/data-protection-statement'}`,
                presentationStyle: 'fullscreen',
                windowName: 'TOC',
              }).catch(() => {
              });
            }}>
            <IonLabel>{t('data_protection')}</IonLabel>
          </IonItem>
          <IonItem
            detail={false}
            button
            onClick={() => {
              Browser.open({
                url: `https://soulhouse.me/${i18n.language === 'de' ? 'impressum' : 'en/imprint'}`,
                presentationStyle: 'fullscreen',
                windowName: 'TOC',
              }).catch(() => {
              });
            }}>
            <IonLabel>{t('imprint')}</IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>
      <button
        type="button"
        className="menu-button"
        onClick={(event) =>
          setMenuPopoverState({
            isOpen: true,
            event: event.nativeEvent,
          })
        }
      >
        <UserIcon className="user-icon" />
      </button>
    </div>
  );
};

export default Header;
