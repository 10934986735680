import { GooglePayEventsEnum, Stripe } from '@capacitor-community/stripe';
import { isPlatform } from '@ionic/react';
import type IPaymentProvider from 'application/pages/BookingPayment/IPaymentProvider';
import type { PaymentParams } from 'application/pages/BookingPayment/IPaymentProvider';
import { injectable } from 'inversify';

import i18n from '../../i18n';
import renderNativeStripePaymentProvider from './renderNativeStripePaymentProvider';
import type { StripePaymentSheet } from './useStripePaymentIntent';

let isNativeAvailable = false;

if (isPlatform('capacitor') && isPlatform('android')) {
  Stripe.isGooglePayAvailable()
    .then(() => {
      isNativeAvailable = true;
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.warn('Google Pay not available', e);
    });
}

@injectable()
class GooglePayPaymentProvider implements IPaymentProvider {
  getName(): string {
    return i18n.t('payment.stripe_native.title_google_pay');
  }

  key = 'google_pay';

  weight = 0;

  Render(params: PaymentParams): JSX.Element {
    const createPayment = (sheet: StripePaymentSheet) =>
      Stripe.createGooglePay({
        paymentIntentClientSecret: sheet.paymentIntent,
      })
        .then(() => Stripe.presentGooglePay())
        .then(result => {
          if (result.paymentResult === GooglePayEventsEnum.Completed) {
            params.onSuccess();
            return;
          }
          throw new Error(result.paymentResult);
        });

    return renderNativeStripePaymentProvider(params, createPayment);
  }

  isAvailable(): boolean {
    return (
      isPlatform('capacitor') && isPlatform('android') && isNativeAvailable
    );
  }
}

export default GooglePayPaymentProvider;
