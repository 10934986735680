import { IonCol, IonGrid, IonRow, useIonViewWillEnter } from '@ionic/react';
import BookingDetailsForm from 'application/pages/BookingSummary/BookingDetailsForm';
import { useAuth } from 'application/state/AuthProvider';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import { BookingPlace, TreatmentDetails } from 'application/types';
import type { FormikHelpers } from 'formik';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import BookingSummaryFooter from 'ui/elements/BookingSummaryFooter';
import SubPageLayout from 'ui/layout/SubPageLayout';
import HeaderProfilePicture from 'ui/layout/SubPageLayout/HeaderProfilePicture';
import { useContextTranslation } from 'ui/translation';
import * as Yup from 'yup';

import BeforeContentGallery, {
  defaultMedia,
} from '../../../../ui/elements/BeforeContentGallery';
import MultipleBookingsSummaryTable from './MultipleBookingsSummaryTable';
import MultipleExpertsTreatmentLengthSelector from './MultipleExpertsTreatmentLengthSelector';

// TODO Refactor required
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-floating-promises */

type TreatmentDetailsValues = Omit<TreatmentDetails, 'massageTable'> & {
  massageTable: string | null;
};

const MultipleExpertsBookingSummary = (): JSX.Element => {
  const {
    slotTime,
    experts,
    date,
    length,
    location,
    details,
    locationId,
    locationMedia,
    setDetails,
    setLength,
  } = useTreatmentBuilder();
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const t = useContextTranslation('page.booking_summary');
  const [expandedLength, setExpandedLength] = React.useState<boolean>(false);

  const onSubmit = React.useCallback<
    (
      values: TreatmentDetailsValues,
      helpers: FormikHelpers<TreatmentDetailsValues>,
    ) => any
  >(
    (values, helpers) => {
      helpers.setSubmitting(false);
      setDetails({
        ...values,
        massageTable: values.massageTable,
      });
      if (isAuthenticated && locationId) {
        history.push('/booking/payment');
        return;
      }
      if (isAuthenticated) {
        history.push('/booking/address');
        return;
      }
      history.push('/booking/contact');
    },
    [history, isAuthenticated],
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        massageTable: Yup.string().required(),
        notes: Yup.string().max(1000, t('form.notes_too_long')).optional(),
      }),
    [t],
  );

  const formik = useFormik<TreatmentDetailsValues>({
    initialValues: details
      ? {
          ...details,
        }
      : {
          massageTable: locationId ? '0' : null,
          notes: '',
          place: locationId ? BookingPlace.Hotel : BookingPlace.Home,
        },
    validationSchema,
    onSubmit,
  });

  const hasCompleteData = Boolean(
    slotTime && date && location && experts?.[1]?.type,
  );

  useIonViewWillEnter(() => {
    if (!hasCompleteData) {
      history.replace('/treatments');
    }
    if (!details) {
      formik.resetForm();
    }
    formik.validateField('massageTable');
  }, [hasCompleteData, history, details]);

  useEffect(() => {
    setDetails({
      ...formik.values,
    });
  }, [formik.values]);

  return (
    <SubPageLayout
      className="booking-summary"
      beforeContent={
        <BeforeContentGallery
          media={locationMedia || experts?.[0].type.media || defaultMedia}
        />
      }
      footerContent={({ showAskToScroll }) =>
        slotTime && (
          <>
            <div
              className={`ask-to-scroll ${showAskToScroll ? 'show' : 'hidden'}`}
            >
              <p className="ask-to-scroll__text">{t('ask-to-scroll')}</p>
            </div>
            <BookingSummaryFooter
              nextEnabled={formik.isValid && !!length}
              onNext={() => {
                formik.submitForm();
              }}
            />
          </>
        )
      }
    >
      {slotTime && location && experts?.length && (
        <>
          <HeaderProfilePicture experts={experts} />
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol className="no-padding-top" size="12">
                <h4>{t('header')}</h4>
                <MultipleBookingsSummaryTable
                  slotTime={slotTime}
                  experts={experts}
                  location={location}
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12">
                <h4 className="booking-summary-form_header">
                  {t('form_header')}
                </h4>
                {date?.timeRange && (
                  <MultipleExpertsTreatmentLengthSelector
                    locationId={locationId || undefined}
                    slotTime={slotTime}
                    onClick={() => setExpandedLength(!expandedLength)}
                    expanded={expandedLength}
                    treatmentLength={length}
                    treatmentTimeRange={date.timeRange}
                    experts={experts}
                    onSelect={(selectedLength) => {
                      setLength(selectedLength);
                      setExpandedLength(false);
                    }}
                  />
                )}
                <FormikProvider value={formik}>
                  <BookingDetailsForm
                    inSpa={Boolean(locationId)}
                    multipleBooking
                  />
                </FormikProvider>
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      )}
    </SubPageLayout>
  );
};

export default MultipleExpertsBookingSummary;
