import type { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { isPlatform } from '@ionic/react';
import type { ExternalRegistrationData } from 'application/pages/Register/IRegistrationAdapter';
import type { ExternalAuthResponse } from 'application/state/IExternalAuthProvider';
import type IExternalAuthProvider from 'application/state/IExternalAuthProvider';
import config from 'config';
import { injectable } from 'inversify';

@injectable()
export default class AppleAuthProvider implements IExternalAuthProvider {
  private cachedResponse: SignInWithAppleResponse | null = null;

  async authenticate(): Promise<ExternalAuthResponse> {
    this.cachedResponse = null;
    if (
      !config.appleClientId ||
      (!config.appleRedirectUri && !isPlatform('capacitor'))
    ) {
      throw new Error('Missing Apple configuration to authenticate');
    }
    const result = await SignInWithApple.authorize({
      clientId: config.appleClientId,
      redirectURI: config.appleRedirectUri || '',
      scopes: 'name email',
    });
    this.cachedResponse = result;

    return {
      clientSecret: `${config.appleClientId}|${result.response.authorizationCode}`,
      name: result.response.givenName || undefined,
      surname: result.response.familyName || undefined,
    };
  }

  async getClientData(): Promise<Partial<ExternalRegistrationData>> {
    return await Promise.resolve({});
  }

  isAvailable(): boolean {
    return (
      (isPlatform('capacitor') && isPlatform('ios')) || !isPlatform('capacitor')
    );
  }

  async reset(): Promise<void> {
    return Promise.resolve();
  }
}
