import { Share } from '@capacitor/share';
import { isPlatform } from '@ionic/react';
import config from 'config';
import { injectable } from 'inversify';

export interface ShareResult {
  notifyUser: boolean;
}

export interface IShareAdapter {
  handleUrlNativeShare(url: string, text?: string): Promise<ShareResult>;
}

@injectable()
class NativeShareAdapter implements IShareAdapter {
  async handleUrlNativeShare(
    data: string,
    text?: string,
  ): Promise<ShareResult> {
    const url = `${config.universalLinkPrefix}${data}`;

    if (!isPlatform('capacitor')) {
      await navigator.clipboard.writeText(url);
      return { notifyUser: true };
    }

    await Share.share({
      url,
      ...(text ? { text } : {}),
    });

    return { notifyUser: false };
  }
}

export default NativeShareAdapter;
