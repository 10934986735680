import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';
import { isPlatform } from '@ionic/react';
import type INotificationService from 'application/services/INotificationService';
import config from 'config';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import type IHandleHardwareBackButtonAdapter from './IHandleHardwareBackButtonAdapter';
import injectables, { hardwareInjectables } from './injectables';

let customSchemePrefix = 'not_possible';
if (isPlatform('capacitor')) {
  void App.getInfo().then((result) => {
    customSchemePrefix = `${result.id}://`;
  });
}

if (isPlatform('capacitor')) {
  window.document.addEventListener('readystatechange', (e) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.target?.readyState === 'complete') {
      setTimeout(() => {
        SplashScreen.hide({
          fadeOutDuration: 150,
        }).catch(() => {});
      }, 100);
    }
  });
}

const AppUrlListener: React.FC = () => {
  const [adapter] = useInject<IHandleHardwareBackButtonAdapter>(
    hardwareInjectables.HandleHardwareBackButtonAdapter,
  );
  const [notificationService] = useInject<INotificationService>(
    injectables.services.NotificationService,
  );
  adapter.useHandleHardwareBackButton();

  const history = useHistory();

  React.useEffect(() => {
    const listener = App.addListener('appUrlOpen', (data) => {
      if (data.url.includes(config.universalLinkPrefix)) {
        // handle universal link
        const path = data.url.substring(config.universalLinkPrefix.length);
        if (path.includes('?')) {
          const basePath = window.location.href.substring(
            0,
            window.location.href.indexOf(window.location.pathname),
          );
          window.location.href = `${basePath}${path}`;
          return;
        }
        history.replace(path);
      }
      if (data.url.includes(customSchemePrefix)) {
        if (isPlatform('ios')) {
          // eslint-disable-next-line no-console
          Browser.close().catch(console.error);
        }
        // handle custom scheme link (ex. from Sofort completed payment)
        const path = data.url.substring(customSchemePrefix.length);
        history.replace(`/${path}`, { unmount: true });
      }
    });

    notificationService.setRouteListener((path, isFirst) => {
      if (isFirst) {
        history.replace(path, {
          direction: 'forward',
        });
      } else {
        history.push(path, {
          direction: 'forward',
        });
      }
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      listener.remove().catch(() => {});
    };
  }, [history]);

  return null;
};

export default AppUrlListener;
