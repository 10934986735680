import './customAlert.scss';

import * as React from 'react';
import Modal from 'react-modal';
import { useContextTranslation } from 'ui/translation';

interface CustomAlertProps {
  isOpen: boolean;
  onDidDismiss?: () => void;
  message: string;
  button?: string;
  cssClass?: string;
  overlayCssClass?: string;
  backdropDismiss?: boolean;
}

Modal.setAppElement('#root');

const CustomAlert: React.FC<CustomAlertProps> = ({
  isOpen,
  onDidDismiss,
  message,
  button,
  cssClass,
  overlayCssClass,
  backdropDismiss = true,
}) => {
  const mt = useContextTranslation('misc');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onDidDismiss}
      className={`custom-modal alert-modal ${cssClass || ''}`}
      overlayClassName={`custom-modal-overlay alert-overlay ${
        overlayCssClass || ''
      }`}
      shouldCloseOnOverlayClick={backdropDismiss}
      shouldCloseOnEsc={backdropDismiss}
    >
      <div className="modal-wrapper">
        <div className="alert-modal-wrapper">
          <div className="alert-modal-head" />
          <div className="alert-modal-message">{message}</div>
          <div className="alert-modal-button">
            <button type="button" className="cancel-btn" onClick={onDidDismiss}>
              {button || mt('close')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomAlert;
